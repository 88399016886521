// Generated by purs bundle 0.14.4
var PS = {};
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Class.LayoutNode"] = $PS["App.Class.LayoutNode"] || {};
  var exports = $PS["App.Class.LayoutNode"];
  var render = function (dict) {
      return dict.render;
  };
  exports["render"] = render;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.DragAction"] = $PS["App.Control.DragAction"] || {};
  var exports = $PS["App.Control.DragAction"];     
  var StopPropagation = (function () {
      function StopPropagation(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      StopPropagation.create = function (value0) {
          return function (value1) {
              return new StopPropagation(value0, value1);
          };
      };
      return StopPropagation;
  })();
  var MouseDown = (function () {
      function MouseDown(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      MouseDown.create = function (value0) {
          return function (value1) {
              return new MouseDown(value0, value1);
          };
      };
      return MouseDown;
  })();
  var MouseMove = (function () {
      function MouseMove(value0) {
          this.value0 = value0;
      };
      MouseMove.create = function (value0) {
          return new MouseMove(value0);
      };
      return MouseMove;
  })();
  var MouseUp = (function () {
      function MouseUp(value0) {
          this.value0 = value0;
      };
      MouseUp.create = function (value0) {
          return new MouseUp(value0);
      };
      return MouseUp;
  })();
  exports["StopPropagation"] = StopPropagation;
  exports["MouseDown"] = MouseDown;
  exports["MouseMove"] = MouseMove;
  exports["MouseUp"] = MouseUp;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayBind = function (arr) {
    return function (f) {
      var result = [];
      for (var i = 0, l = arr.length; i < l; i++) {
        Array.prototype.push.apply(result, f(arr[i]));
      }
      return result;
    };
  };
})(PS["Control.Bind"] = PS["Control.Bind"] || {});
(function(exports) {
  "use strict";

  exports.arrayApply = function (fs) {
    return function (xs) {
      var l = fs.length;
      var k = xs.length;
      var result = new Array(l*k);
      var n = 0;
      for (var i = 0; i < l; i++) {
        var f = fs[i];
        for (var j = 0; j < k; j++) {
          result[n++] = f(xs[j]);
        }
      }
      return result;
    };
  };
})(PS["Control.Apply"] = PS["Control.Apply"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Semigroupoid"] = $PS["Control.Semigroupoid"] || {};
  var exports = $PS["Control.Semigroupoid"];
  var semigroupoidFn = {
      compose: function (f) {
          return function (g) {
              return function (x) {
                  return f(g(x));
              };
          };
      }
  };
  var compose = function (dict) {
      return dict.compose;
  };
  exports["compose"] = compose;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Category"] = $PS["Control.Category"] || {};
  var exports = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];                
  var identity = function (dict) {
      return dict.identity;
  };
  var categoryFn = {
      identity: function (x) {
          return x;
      },
      Semigroupoid0: function () {
          return Control_Semigroupoid.semigroupoidFn;
      }
  };
  exports["identity"] = identity;
  exports["categoryFn"] = categoryFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Function"] = $PS["Data.Function"] || {};
  var exports = $PS["Data.Function"];
  var flip = function (f) {
      return function (b) {
          return function (a) {
              return f(a)(b);
          };
      };
  };
  var $$const = function (a) {
      return function (v) {
          return a;
      };
  };
  var applyFlipped = function (x) {
      return function (f) {
          return f(x);
      };
  };
  exports["flip"] = flip;
  exports["const"] = $$const;
  exports["applyFlipped"] = applyFlipped;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }
      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});
(function(exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Unit"] = $PS["Data.Unit"] || {};
  var exports = $PS["Data.Unit"];
  var $foreign = $PS["Data.Unit"];
  exports["unit"] = $foreign.unit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Functor"] = $PS["Data.Functor"] || {};
  var exports = $PS["Data.Functor"];
  var $foreign = $PS["Data.Functor"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];                  
  var map = function (dict) {
      return dict.map;
  };
  var $$void = function (dictFunctor) {
      return map(dictFunctor)(Data_Function["const"](Data_Unit.unit));
  };
  var voidLeft = function (dictFunctor) {
      return function (f) {
          return function (x) {
              return map(dictFunctor)(Data_Function["const"](x))(f);
          };
      };
  };
  var functorFn = {
      map: Control_Semigroupoid.compose(Control_Semigroupoid.semigroupoidFn)
  };
  var functorArray = {
      map: $foreign.arrayMap
  };
  exports["map"] = map;
  exports["void"] = $$void;
  exports["voidLeft"] = voidLeft;
  exports["functorFn"] = functorFn;
  exports["functorArray"] = functorArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Apply"] = $PS["Control.Apply"] || {};
  var exports = $PS["Control.Apply"];
  var $foreign = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var applyArray = {
      apply: $foreign.arrayApply,
      Functor0: function () {
          return Data_Functor.functorArray;
      }
  };
  var apply = function (dict) {
      return dict.apply;
  };
  var applySecond = function (dictApply) {
      return function (a) {
          return function (b) {
              return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
          };
      };
  };
  var lift2 = function (dictApply) {
      return function (f) {
          return function (a) {
              return function (b) {
                  return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b);
              };
          };
      };
  };
  exports["apply"] = apply;
  exports["applySecond"] = applySecond;
  exports["lift2"] = lift2;
  exports["applyArray"] = applyArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Bind"] = $PS["Control.Bind"] || {};
  var exports = $PS["Control.Bind"];
  var $foreign = $PS["Control.Bind"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];          
  var discard = function (dict) {
      return dict.discard;
  };
  var bindArray = {
      bind: $foreign.arrayBind,
      Apply0: function () {
          return Control_Apply.applyArray;
      }
  };
  var bind = function (dict) {
      return dict.bind;
  };
  var bindFlipped = function (dictBind) {
      return Data_Function.flip(bind(dictBind));
  };
  var composeKleisliFlipped = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bindFlipped(dictBind)(f)(g(a));
              };
          };
      };
  };
  var discardUnit = {
      discard: function (dictBind) {
          return bind(dictBind);
      }
  };
  var join = function (dictBind) {
      return function (m) {
          return bind(dictBind)(m)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["discard"] = discard;
  exports["join"] = join;
  exports["composeKleisliFlipped"] = composeKleisliFlipped;
  exports["bindArray"] = bindArray;
  exports["discardUnit"] = discardUnit;
})(PS);
(function(exports) {
  /* eslint-disable no-eq-null, eqeqeq */
  "use strict";

  function id(x) {
    return x;
  }                        
  exports.fromNumber = id;
  exports.fromString = id;
  exports.fromArray = id;
  exports.fromObject = id;

  exports.jsonNull = null;

  exports.stringify = function (j) {
    return JSON.stringify(j);
  };

  function isArray(a) {
    return Object.prototype.toString.call(a) === "[object Array]";
  }

  exports._caseJson = function (isNull, isBool, isNum, isStr, isArr, isObj, j) {
    if (j == null) return isNull();
    else if (typeof j === "boolean") return isBool(j);
    else if (typeof j === "number") return isNum(j);
    else if (typeof j === "string") return isStr(j);
    else if (Object.prototype.toString.call(j) === "[object Array]")
      return isArr(j);
    else return isObj(j);
  };

  exports._compare = function _compare(EQ, GT, LT, a, b) {
    if (a == null) {
      if (b == null) return EQ;
      else return LT;
    } else if (typeof a === "boolean") {
      if (typeof b === "boolean") {
        // boolean / boolean
        if (a === b) return EQ;
        else if (a === false) return LT;
        else return GT;
      } else if (b == null) return GT;
      else return LT;
    } else if (typeof a === "number") {
      if (typeof b === "number") {
        if (a === b) return EQ;
        else if (a < b) return LT;
        else return GT;
      } else if (b == null) return GT;
      else if (typeof b === "boolean") return GT;
      else return LT;
    } else if (typeof a === "string") {
      if (typeof b === "string") {
        if (a === b) return EQ;
        else if (a < b) return LT;
        else return GT;
      } else if (b == null) return GT;
      else if (typeof b === "boolean") return GT;
      else if (typeof b === "number") return GT;
      else return LT;
    } else if (isArray(a)) {
      if (isArray(b)) {
        for (var i = 0; i < Math.min(a.length, b.length); i++) {
          var ca = _compare(EQ, GT, LT, a[i], b[i]);
          if (ca !== EQ) return ca;
        }
        if (a.length === b.length) return EQ;
        else if (a.length < b.length) return LT;
        else return GT;
      } else if (b == null) return GT;
      else if (typeof b === "boolean") return GT;
      else if (typeof b === "number") return GT;
      else if (typeof b === "string") return GT;
      else return LT;
    } else {
      if (b == null) return GT;
      else if (typeof b === "boolean") return GT;
      else if (typeof b === "number") return GT;
      else if (typeof b === "string") return GT;
      else if (isArray(b)) return GT;
      else {
        var akeys = Object.keys(a);
        var bkeys = Object.keys(b);
        if (akeys.length < bkeys.length) return LT;
        else if (akeys.length > bkeys.length) return GT;
        var keys = akeys.concat(bkeys).sort();
        for (var j = 0; j < keys.length; j++) {
          var k = keys[j];
          if (a[k] === undefined) return LT;
          else if (b[k] === undefined) return GT;
          var ck = _compare(EQ, GT, LT, a[k], b[k]);
          if (ck !== EQ) return ck;
        }
        return EQ;
      }
    }
  };
})(PS["Data.Argonaut.Core"] = PS["Data.Argonaut.Core"] || {});
(function(exports) {
  "use strict";

  var refEq = function (r1) {
    return function (r2) {
      return r1 === r2;
    };
  };

  exports.eqBooleanImpl = refEq;
  exports.eqIntImpl = refEq;
  exports.eqNumberImpl = refEq;
  exports.eqStringImpl = refEq;

  exports.eqArrayImpl = function (f) {
    return function (xs) {
      return function (ys) {
        if (xs.length !== ys.length) return false;
        for (var i = 0; i < xs.length; i++) {
          if (!f(xs[i])(ys[i])) return false;
        }
        return true;
      };
    };
  };
})(PS["Data.Eq"] = PS["Data.Eq"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Eq"] = $PS["Data.Eq"] || {};
  var exports = $PS["Data.Eq"];
  var $foreign = $PS["Data.Eq"];
  var eqString = {
      eq: $foreign.eqStringImpl
  };
  var eqNumber = {
      eq: $foreign.eqNumberImpl
  };
  var eqInt = {
      eq: $foreign.eqIntImpl
  };
  var eqBoolean = {
      eq: $foreign.eqBooleanImpl
  };
  var eq = function (dict) {
      return dict.eq;
  };
  var eqArray = function (dictEq) {
      return {
          eq: $foreign.eqArrayImpl(eq(dictEq))
      };
  };
  var notEq = function (dictEq) {
      return function (x) {
          return function (y) {
              return eq(eqBoolean)(eq(dictEq)(x)(y))(false);
          };
      };
  };
  exports["eq"] = eq;
  exports["notEq"] = notEq;
  exports["eqInt"] = eqInt;
  exports["eqNumber"] = eqNumber;
  exports["eqString"] = eqString;
  exports["eqArray"] = eqArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Maybe"] = $PS["Data.Maybe"] || {};
  var exports = $PS["Data.Maybe"];
  var Control_Category = $PS["Control.Category"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];                
  var Nothing = (function () {
      function Nothing() {

      };
      Nothing.value = new Nothing();
      return Nothing;
  })();
  var Just = (function () {
      function Just(value0) {
          this.value0 = value0;
      };
      Just.create = function (value0) {
          return new Just(value0);
      };
      return Just;
  })();
  var maybe$prime = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Nothing) {
                  return v(Data_Unit.unit);
              };
              if (v2 instanceof Just) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Maybe (line 243, column 1 - line 243, column 62): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var maybe = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Nothing) {
                  return v;
              };
              if (v2 instanceof Just) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Maybe (line 230, column 1 - line 230, column 51): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var isNothing = maybe(true)(Data_Function["const"](false));
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = {
      map: function (v) {
          return function (v1) {
              if (v1 instanceof Just) {
                  return new Just(v(v1.value0));
              };
              return Nothing.value;
          };
      }
  };
  var fromMaybe$prime = function (a) {
      return maybe$prime(a)(Control_Category.identity(Control_Category.categoryFn));
  };
  var fromMaybe = function (a) {
      return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
  };
  var fromJust = function (dictPartial) {
      return function (v) {
          if (v instanceof Just) {
              return v.value0;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 281, column 1 - line 281, column 46): " + [ v.constructor.name ]);
      };
  };
  var eqMaybe = function (dictEq) {
      return {
          eq: function (x) {
              return function (y) {
                  if (x instanceof Nothing && y instanceof Nothing) {
                      return true;
                  };
                  if (x instanceof Just && y instanceof Just) {
                      return Data_Eq.eq(dictEq)(x.value0)(y.value0);
                  };
                  return false;
              };
          }
      };
  };
  var applyMaybe = {
      apply: function (v) {
          return function (v1) {
              if (v instanceof Just) {
                  return Data_Functor.map(functorMaybe)(v.value0)(v1);
              };
              if (v instanceof Nothing) {
                  return Nothing.value;
              };
              throw new Error("Failed pattern match at Data.Maybe (line 68, column 1 - line 70, column 30): " + [ v.constructor.name, v1.constructor.name ]);
          };
      },
      Functor0: function () {
          return functorMaybe;
      }
  };
  var bindMaybe = {
      bind: function (v) {
          return function (v1) {
              if (v instanceof Just) {
                  return v1(v.value0);
              };
              if (v instanceof Nothing) {
                  return Nothing.value;
              };
              throw new Error("Failed pattern match at Data.Maybe (line 126, column 1 - line 128, column 28): " + [ v.constructor.name, v1.constructor.name ]);
          };
      },
      Apply0: function () {
          return applyMaybe;
      }
  };
  var applicativeMaybe = {
      pure: Just.create,
      Apply0: function () {
          return applyMaybe;
      }
  };
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["fromMaybe'"] = fromMaybe$prime;
  exports["isJust"] = isJust;
  exports["isNothing"] = isNothing;
  exports["fromJust"] = fromJust;
  exports["functorMaybe"] = functorMaybe;
  exports["applyMaybe"] = applyMaybe;
  exports["applicativeMaybe"] = applicativeMaybe;
  exports["bindMaybe"] = bindMaybe;
  exports["eqMaybe"] = eqMaybe;
})(PS);
(function(exports) {
  "use strict";

  var unsafeCompareImpl = function (lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };                                         
  exports.ordIntImpl = unsafeCompareImpl;
  exports.ordNumberImpl = unsafeCompareImpl;
  exports.ordStringImpl = unsafeCompareImpl;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Ordering"] = $PS["Data.Ordering"] || {};
  var exports = $PS["Data.Ordering"];
  var LT = (function () {
      function LT() {

      };
      LT.value = new LT();
      return LT;
  })();
  var GT = (function () {
      function GT() {

      };
      GT.value = new GT();
      return GT;
  })();
  var EQ = (function () {
      function EQ() {

      };
      EQ.value = new EQ();
      return EQ;
  })();
  var eqOrdering = {
      eq: function (v) {
          return function (v1) {
              if (v instanceof LT && v1 instanceof LT) {
                  return true;
              };
              if (v instanceof GT && v1 instanceof GT) {
                  return true;
              };
              if (v instanceof EQ && v1 instanceof EQ) {
                  return true;
              };
              return false;
          };
      }
  };
  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
  exports["eqOrdering"] = eqOrdering;
})(PS);
(function(exports) {
  "use strict";

  exports.numSub = function (n1) {
    return function (n2) {
      return n1 - n2;
    };
  };
})(PS["Data.Ring"] = PS["Data.Ring"] || {});
(function(exports) {
  "use strict";

  exports.numAdd = function (n1) {
    return function (n2) {
      return n1 + n2;
    };
  };

  exports.numMul = function (n1) {
    return function (n2) {
      return n1 * n2;
    };
  };
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Semiring"] = $PS["Data.Semiring"] || {};
  var exports = $PS["Data.Semiring"];
  var $foreign = $PS["Data.Semiring"];
  var zero = function (dict) {
      return dict.zero;
  };
  var semiringNumber = {
      add: $foreign.numAdd,
      zero: 0.0,
      mul: $foreign.numMul,
      one: 1.0
  };
  exports["zero"] = zero;
  exports["semiringNumber"] = semiringNumber;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Ring"] = $PS["Data.Ring"] || {};
  var exports = $PS["Data.Ring"];
  var $foreign = $PS["Data.Ring"];
  var Data_Semiring = $PS["Data.Semiring"];
  var sub = function (dict) {
      return dict.sub;
  };
  var ringNumber = {
      sub: $foreign.numSub,
      Semiring0: function () {
          return Data_Semiring.semiringNumber;
      }
  };
  var negate = function (dictRing) {
      return function (a) {
          return sub(dictRing)(Data_Semiring.zero(dictRing.Semiring0()))(a);
      };
  };
  exports["negate"] = negate;
  exports["ringNumber"] = ringNumber;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Ord"] = $PS["Data.Ord"] || {};
  var exports = $PS["Data.Ord"];
  var $foreign = $PS["Data.Ord"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Ring = $PS["Data.Ring"];
  var Data_Semiring = $PS["Data.Semiring"];
  var ordString = {
      compare: $foreign.ordStringImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
      Eq0: function () {
          return Data_Eq.eqString;
      }
  };
  var ordNumber = {
      compare: $foreign.ordNumberImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
      Eq0: function () {
          return Data_Eq.eqNumber;
      }
  };
  var ordInt = {
      compare: $foreign.ordIntImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value),
      Eq0: function () {
          return Data_Eq.eqInt;
      }
  };
  var compare = function (dict) {
      return dict.compare;
  };
  var greaterThanOrEq = function (dictOrd) {
      return function (a1) {
          return function (a2) {
              var v = compare(dictOrd)(a1)(a2);
              if (v instanceof Data_Ordering.LT) {
                  return false;
              };
              return true;
          };
      };
  };
  var lessThanOrEq = function (dictOrd) {
      return function (a1) {
          return function (a2) {
              var v = compare(dictOrd)(a1)(a2);
              if (v instanceof Data_Ordering.GT) {
                  return false;
              };
              return true;
          };
      };
  };
  var max = function (dictOrd) {
      return function (x) {
          return function (y) {
              var v = compare(dictOrd)(x)(y);
              if (v instanceof Data_Ordering.LT) {
                  return y;
              };
              if (v instanceof Data_Ordering.EQ) {
                  return x;
              };
              if (v instanceof Data_Ordering.GT) {
                  return x;
              };
              throw new Error("Failed pattern match at Data.Ord (line 176, column 3 - line 179, column 12): " + [ v.constructor.name ]);
          };
      };
  };
  var abs = function (dictOrd) {
      return function (dictRing) {
          return function (x) {
              var $62 = greaterThanOrEq(dictOrd)(x)(Data_Semiring.zero(dictRing.Semiring0()));
              if ($62) {
                  return x;
              };
              return Data_Ring.negate(dictRing)(x);
          };
      };
  };
  exports["compare"] = compare;
  exports["lessThanOrEq"] = lessThanOrEq;
  exports["max"] = max;
  exports["abs"] = abs;
  exports["ordInt"] = ordInt;
  exports["ordNumber"] = ordNumber;
  exports["ordString"] = ordString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Core"] = $PS["Data.Argonaut.Core"] || {};
  var exports = $PS["Data.Argonaut.Core"];
  var $foreign = $PS["Data.Argonaut.Core"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];                  
  var verbJsonType = function (def) {
      return function (f) {
          return function (g) {
              return g(def)(f);
          };
      };
  };
  var toJsonType = verbJsonType(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  var isJsonType = verbJsonType(false)(Data_Function["const"](true));
  var ordJson = {
      compare: function (a) {
          return function (b) {
              return $foreign["_compare"](Data_Ordering.EQ.value, Data_Ordering.GT.value, Data_Ordering.LT.value, a, b);
          };
      },
      Eq0: function () {
          return eqJson;
      }
  };
  var eqJson = {
      eq: function (j1) {
          return function (j2) {
              return Data_Eq.eq(Data_Ordering.eqOrdering)(Data_Ord.compare(ordJson)(j1)(j2))(Data_Ordering.EQ.value);
          };
      }
  };
  var caseJsonString = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };                                        
  var toString = toJsonType(caseJsonString);
  var caseJsonObject = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, j);
          };
      };
  };                                        
  var toObject = toJsonType(caseJsonObject);
  var caseJsonNumber = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };                                        
  var caseJsonNull = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](f, Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };
  var isNull = isJsonType(caseJsonNull);      
  var caseJsonArray = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), j);
          };
      };
  };                                      
  var toArray = toJsonType(caseJsonArray);
  exports["caseJsonNumber"] = caseJsonNumber;
  exports["caseJsonString"] = caseJsonString;
  exports["isNull"] = isNull;
  exports["toString"] = toString;
  exports["toArray"] = toArray;
  exports["toObject"] = toObject;
  exports["eqJson"] = eqJson;
  exports["fromNumber"] = $foreign.fromNumber;
  exports["fromString"] = $foreign.fromString;
  exports["fromArray"] = $foreign.fromArray;
  exports["fromObject"] = $foreign.fromObject;
  exports["jsonNull"] = $foreign.jsonNull;
  exports["stringify"] = $foreign.stringify;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Applicative"] = $PS["Control.Applicative"] || {};
  var exports = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Unit = $PS["Data.Unit"];                  
  var pure = function (dict) {
      return dict.pure;
  };
  var unless = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (!v) {
                  return v1;
              };
              if (v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 66, column 1 - line 66, column 65): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var when = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (v) {
                  return v1;
              };
              if (!v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 61, column 1 - line 61, column 63): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var liftA1 = function (dictApplicative) {
      return function (f) {
          return function (a) {
              return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
          };
      };
  };
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
  exports["unless"] = unless;
  exports["when"] = when;
})(PS);
(function(exports) {
  "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showNumberImpl = function (n) {
    var str = n.toString();
    return isNaN(str + ".0") ? str : str + ".0";
  };

  exports.showStringImpl = function (s) {
    var l = s.length;
    return "\"" + s.replace(
      /[\0-\x1F\x7F"\\]/g, // eslint-disable-line no-control-regex
      function (c, i) {
        switch (c) {
          case "\"":
          case "\\":
            return "\\" + c;
          case "\x07": return "\\a";
          case "\b": return "\\b";
          case "\f": return "\\f";
          case "\n": return "\\n";
          case "\r": return "\\r";
          case "\t": return "\\t";
          case "\v": return "\\v";
        }
        var k = i + 1;
        var empty = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
        return "\\" + c.charCodeAt(0).toString(10) + empty;
      }
    ) + "\"";
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Show"] = $PS["Data.Show"] || {};
  var exports = $PS["Data.Show"];
  var $foreign = $PS["Data.Show"];                   
  var showString = {
      show: $foreign.showStringImpl
  };
  var showNumber = {
      show: $foreign.showNumberImpl
  };
  var showInt = {
      show: $foreign.showIntImpl
  };
  var show = function (dict) {
      return dict.show;
  };
  exports["show"] = show;
  exports["showInt"] = showInt;
  exports["showNumber"] = showNumber;
  exports["showString"] = showString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Decode.Error"] = $PS["Data.Argonaut.Decode.Error"] || {};
  var exports = $PS["Data.Argonaut.Decode.Error"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Show = $PS["Data.Show"];                
  var TypeMismatch = (function () {
      function TypeMismatch(value0) {
          this.value0 = value0;
      };
      TypeMismatch.create = function (value0) {
          return new TypeMismatch(value0);
      };
      return TypeMismatch;
  })();
  var UnexpectedValue = (function () {
      function UnexpectedValue(value0) {
          this.value0 = value0;
      };
      UnexpectedValue.create = function (value0) {
          return new UnexpectedValue(value0);
      };
      return UnexpectedValue;
  })();
  var AtIndex = (function () {
      function AtIndex(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      AtIndex.create = function (value0) {
          return function (value1) {
              return new AtIndex(value0, value1);
          };
      };
      return AtIndex;
  })();
  var AtKey = (function () {
      function AtKey(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      AtKey.create = function (value0) {
          return function (value1) {
              return new AtKey(value0, value1);
          };
      };
      return AtKey;
  })();
  var Named = (function () {
      function Named(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Named.create = function (value0) {
          return function (value1) {
              return new Named(value0, value1);
          };
      };
      return Named;
  })();
  var MissingValue = (function () {
      function MissingValue() {

      };
      MissingValue.value = new MissingValue();
      return MissingValue;
  })();
  var printJsonDecodeError = function (err) {
      var go = function (v) {
          if (v instanceof TypeMismatch) {
              return "  Expected value of type '" + (v.value0 + "'.");
          };
          if (v instanceof UnexpectedValue) {
              return "  Unexpected value " + (Data_Argonaut_Core.stringify(v.value0) + ".");
          };
          if (v instanceof AtIndex) {
              return "  At array index " + (Data_Show.show(Data_Show.showInt)(v.value0) + (":\x0a" + go(v.value1)));
          };
          if (v instanceof AtKey) {
              return "  At object key '" + (v.value0 + ("':\x0a" + go(v.value1)));
          };
          if (v instanceof Named) {
              return "  Under '" + (v.value0 + ("':\x0a" + go(v.value1)));
          };
          if (v instanceof MissingValue) {
              return "  No value was found.";
          };
          throw new Error("Failed pattern match at Data.Argonaut.Decode.Error (line 37, column 8 - line 43, column 44): " + [ v.constructor.name ]);
      };
      return "An error occurred while decoding a JSON value:\x0a" + go(err);
  };
  exports["TypeMismatch"] = TypeMismatch;
  exports["UnexpectedValue"] = UnexpectedValue;
  exports["AtIndex"] = AtIndex;
  exports["AtKey"] = AtKey;
  exports["Named"] = Named;
  exports["MissingValue"] = MissingValue;
  exports["printJsonDecodeError"] = printJsonDecodeError;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Either"] = $PS["Data.Either"] || {};
  var exports = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var Left = (function () {
      function Left(value0) {
          this.value0 = value0;
      };
      Left.create = function (value0) {
          return new Left(value0);
      };
      return Left;
  })();
  var Right = (function () {
      function Right(value0) {
          this.value0 = value0;
      };
      Right.create = function (value0) {
          return new Right(value0);
      };
      return Right;
  })();
  var note = function (a) {
      return Data_Maybe.maybe(new Left(a))(Right.create);
  };
  var functorEither = {
      map: function (f) {
          return function (m) {
              if (m instanceof Left) {
                  return new Left(m.value0);
              };
              if (m instanceof Right) {
                  return new Right(f(m.value0));
              };
              throw new Error("Failed pattern match at Data.Either (line 31, column 1 - line 31, column 52): " + [ m.constructor.name ]);
          };
      }
  };
  var either = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return v(v2.value0);
              };
              if (v2 instanceof Right) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Either (line 208, column 1 - line 208, column 64): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var applyEither = {
      apply: function (v) {
          return function (v1) {
              if (v instanceof Left) {
                  return new Left(v.value0);
              };
              if (v instanceof Right) {
                  return Data_Functor.map(functorEither)(v.value0)(v1);
              };
              throw new Error("Failed pattern match at Data.Either (line 70, column 1 - line 72, column 30): " + [ v.constructor.name, v1.constructor.name ]);
          };
      },
      Functor0: function () {
          return functorEither;
      }
  };
  var bindEither = {
      bind: either(function (e) {
          return function (v) {
              return new Left(e);
          };
      })(function (a) {
          return function (f) {
              return f(a);
          };
      }),
      Apply0: function () {
          return applyEither;
      }
  };
  var applicativeEither = {
      pure: Right.create,
      Apply0: function () {
          return applyEither;
      }
  };
  var altEither = {
      alt: function (v) {
          return function (v1) {
              if (v instanceof Left) {
                  return v1;
              };
              return v;
          };
      },
      Functor0: function () {
          return functorEither;
      }
  };
  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["note"] = note;
  exports["functorEither"] = functorEither;
  exports["applyEither"] = applyEither;
  exports["applicativeEither"] = applicativeEither;
  exports["altEither"] = altEither;
  exports["bindEither"] = bindEither;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Bifunctor"] = $PS["Data.Bifunctor"] || {};
  var exports = $PS["Data.Bifunctor"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];              
  var bimap = function (dict) {
      return dict.bimap;
  };
  var lmap = function (dictBifunctor) {
      return function (f) {
          return bimap(dictBifunctor)(f)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var bifunctorEither = {
      bimap: function (v) {
          return function (v1) {
              return function (v2) {
                  if (v2 instanceof Data_Either.Left) {
                      return new Data_Either.Left(v(v2.value0));
                  };
                  if (v2 instanceof Data_Either.Right) {
                      return new Data_Either.Right(v1(v2.value0));
                  };
                  throw new Error("Failed pattern match at Data.Bifunctor (line 32, column 1 - line 34, column 36): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
              };
          };
      }
  };
  exports["bimap"] = bimap;
  exports["lmap"] = lmap;
  exports["bifunctorEither"] = bifunctorEither;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Boolean"] = $PS["Data.Boolean"] || {};
  var exports = $PS["Data.Boolean"];
  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS);
(function(exports) {
  "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }
        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }
        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Maybe.First"] = $PS["Data.Maybe.First"] || {};
  var exports = $PS["Data.Maybe.First"];
  var Data_Maybe = $PS["Data.Maybe"];
  var semigroupFirst = {
      append: function (v) {
          return function (v1) {
              if (v instanceof Data_Maybe.Just) {
                  return v;
              };
              return v1;
          };
      }
  };
  var monoidFirst = {
      mempty: Data_Maybe.Nothing.value,
      Semigroup0: function () {
          return semigroupFirst;
      }
  };
  exports["monoidFirst"] = monoidFirst;
})(PS);
(function(exports) {
  "use strict";

  exports.concatArray = function (xs) {
    return function (ys) {
      if (xs.length === 0) return ys;
      if (ys.length === 0) return xs;
      return xs.concat(ys);
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Semigroup"] = $PS["Data.Semigroup"] || {};
  var exports = $PS["Data.Semigroup"];
  var $foreign = $PS["Data.Semigroup"];
  var semigroupArray = {
      append: $foreign.concatArray
  };
  var append = function (dict) {
      return dict.append;
  };
  exports["append"] = append;
  exports["semigroupArray"] = semigroupArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Monoid"] = $PS["Data.Monoid"] || {};
  var exports = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var monoidArray = {
      mempty: [  ],
      Semigroup0: function () {
          return Data_Semigroup.semigroupArray;
      }
  };
  var mempty = function (dict) {
      return dict.mempty;
  };
  exports["mempty"] = mempty;
  exports["monoidArray"] = monoidArray;
})(PS);
(function(exports) {
  "use strict";

  // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Unsafe.Coerce"] = $PS["Unsafe.Coerce"] || {};
  var exports = $PS["Unsafe.Coerce"];
  var $foreign = $PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Safe.Coerce"] = $PS["Safe.Coerce"] || {};
  var exports = $PS["Safe.Coerce"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var coerce = function (dictCoercible) {
      return Unsafe_Coerce.unsafeCoerce;
  };
  exports["coerce"] = coerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Newtype"] = $PS["Data.Newtype"] || {};
  var exports = $PS["Data.Newtype"];
  var Safe_Coerce = $PS["Safe.Coerce"];                
  var wrap = function (dictNewtype) {
      return Safe_Coerce.coerce();
  };
  var unwrap = function (dictNewtype) {
      return Safe_Coerce.coerce();
  };
  var over = function (dictNewtype) {
      return function (dictNewtype1) {
          return function (v) {
              return Safe_Coerce.coerce();
          };
      };
  };
  exports["wrap"] = wrap;
  exports["unwrap"] = unwrap;
  exports["over"] = over;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Foldable"] = $PS["Data.Foldable"] || {};
  var exports = $PS["Data.Foldable"];
  var $foreign = $PS["Data.Foldable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Maybe_First = $PS["Data.Maybe.First"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unit = $PS["Data.Unit"];                
  var foldr = function (dict) {
      return dict.foldr;
  };
  var traverse_ = function (dictApplicative) {
      return function (dictFoldable) {
          return function (f) {
              return foldr(dictFoldable)((function () {
                  var $316 = Control_Apply.applySecond(dictApplicative.Apply0());
                  return function ($317) {
                      return $316(f($317));
                  };
              })())(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
          };
      };
  };
  var for_ = function (dictApplicative) {
      return function (dictFoldable) {
          return Data_Function.flip(traverse_(dictApplicative)(dictFoldable));
      };
  };
  var foldl = function (dict) {
      return dict.foldl;
  };
  var foldableMaybe = {
      foldr: function (v) {
          return function (z) {
              return function (v1) {
                  if (v1 instanceof Data_Maybe.Nothing) {
                      return z;
                  };
                  if (v1 instanceof Data_Maybe.Just) {
                      return v(v1.value0)(z);
                  };
                  throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
              };
          };
      },
      foldl: function (v) {
          return function (z) {
              return function (v1) {
                  if (v1 instanceof Data_Maybe.Nothing) {
                      return z;
                  };
                  if (v1 instanceof Data_Maybe.Just) {
                      return v(z)(v1.value0);
                  };
                  throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
              };
          };
      },
      foldMap: function (dictMonoid) {
          return function (v) {
              return function (v1) {
                  if (v1 instanceof Data_Maybe.Nothing) {
                      return Data_Monoid.mempty(dictMonoid);
                  };
                  if (v1 instanceof Data_Maybe.Just) {
                      return v(v1.value0);
                  };
                  throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ v.constructor.name, v1.constructor.name ]);
              };
          };
      }
  };
  var foldableEither = {
      foldr: function (v) {
          return function (z) {
              return function (v1) {
                  if (v1 instanceof Data_Either.Left) {
                      return z;
                  };
                  if (v1 instanceof Data_Either.Right) {
                      return v(v1.value0)(z);
                  };
                  throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
              };
          };
      },
      foldl: function (v) {
          return function (z) {
              return function (v1) {
                  if (v1 instanceof Data_Either.Left) {
                      return z;
                  };
                  if (v1 instanceof Data_Either.Right) {
                      return v(z)(v1.value0);
                  };
                  throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
              };
          };
      },
      foldMap: function (dictMonoid) {
          return function (v) {
              return function (v1) {
                  if (v1 instanceof Data_Either.Left) {
                      return Data_Monoid.mempty(dictMonoid);
                  };
                  if (v1 instanceof Data_Either.Right) {
                      return v(v1.value0);
                  };
                  throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ v.constructor.name, v1.constructor.name ]);
              };
          };
      }
  };
  var foldMapDefaultR = function (dictFoldable) {
      return function (dictMonoid) {
          return function (f) {
              return foldr(dictFoldable)(function (x) {
                  return function (acc) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableArray = {
      foldr: $foreign.foldrArray,
      foldl: $foreign.foldlArray,
      foldMap: function (dictMonoid) {
          return foldMapDefaultR(foldableArray)(dictMonoid);
      }
  };
  var foldMap = function (dict) {
      return dict.foldMap;
  };
  var lookup = function (dictFoldable) {
      return function (dictEq) {
          return function (a) {
              var $323 = Data_Newtype.unwrap();
              var $324 = foldMap(dictFoldable)(Data_Maybe_First.monoidFirst)(function (v) {
                  var $305 = Data_Eq.eq(dictEq)(a)(v.value0);
                  if ($305) {
                      return new Data_Maybe.Just(v.value1);
                  };
                  return Data_Maybe.Nothing.value;
              });
              return function ($325) {
                  return $323($324($325));
              };
          };
      };
  };
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldMap"] = foldMap;
  exports["traverse_"] = traverse_;
  exports["for_"] = for_;
  exports["lookup"] = lookup;
  exports["foldableArray"] = foldableArray;
  exports["foldableMaybe"] = foldableMaybe;
  exports["foldableEither"] = foldableEither;
})(PS);
(function(exports) {
  "use strict";

  exports.fromNumberImpl = function (just) {
    return function (nothing) {
      return function (n) {
        /* jshint bitwise: false */
        return (n | 0) === n ? just(n) : nothing;
      };
    };
  };

  exports.toNumber = function (n) {
    return n;
  };

  exports.fromStringAsImpl = function (just) {
    return function (nothing) {
      return function (radix) {
        var digits;
        if (radix < 11) {
          digits = "[0-" + (radix - 1).toString() + "]";
        } else if (radix === 11) {
          digits = "[0-9a]";
        } else {
          digits = "[0-9a-" + String.fromCharCode(86 + radix) + "]";
        }
        var pattern = new RegExp("^[\\+\\-]?" + digits + "+$", "i");

        return function (s) {
          /* jshint bitwise: false */
          if (pattern.test(s)) {
            var i = parseInt(s, radix);
            return (i | 0) === i ? just(i) : nothing;
          } else {
            return nothing;
          }
        };
      };
    };
  };
})(PS["Data.Int"] = PS["Data.Int"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Int"] = $PS["Data.Int"] || {};
  var exports = $PS["Data.Int"];
  var $foreign = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var fromStringAs = $foreign.fromStringAsImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var fromString = fromStringAs(10);
  var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  exports["fromNumber"] = fromNumber;
  exports["fromString"] = fromString;
  exports["toNumber"] = $foreign.toNumber;
})(PS);
(function(exports) {
  "use strict";

  // jshint maxparams: 3

  exports.traverseArrayImpl = (function () {
    function array1(a) {
      return [a];
    }

    function array2(a) {
      return function (b) {
        return [a, b];
      };
    }

    function array3(a) {
      return function (b) {
        return function (c) {
          return [a, b, c];
        };
      };
    }

    function concat2(xs) {
      return function (ys) {
        return xs.concat(ys);
      };
    }

    return function (apply) {
      return function (map) {
        return function (pure) {
          return function (f) {
            return function (array) {
              function go(bot, top) {
                switch (top - bot) {
                case 0: return pure([]);
                case 1: return map(array1)(f(array[bot]));
                case 2: return apply(map(array2)(f(array[bot])))(f(array[bot + 1]));
                case 3: return apply(apply(map(array3)(f(array[bot])))(f(array[bot + 1])))(f(array[bot + 2]));
                default:
                  // This slightly tricky pivot selection aims to produce two
                  // even-length partitions where possible.
                  var pivot = bot + Math.floor((top - bot) / 4) * 2;
                  return apply(map(concat2)(go(bot, pivot)))(go(pivot, top));
                }
              }
              return go(0, array.length);
            };
          };
        };
      };
    };
  })();
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Traversable"] = $PS["Data.Traversable"] || {};
  var exports = $PS["Data.Traversable"];
  var $foreign = $PS["Data.Traversable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var traverse = function (dict) {
      return dict.traverse;
  };
  var traversableMaybe = {
      traverse: function (dictApplicative) {
          return function (v) {
              return function (v1) {
                  if (v1 instanceof Data_Maybe.Nothing) {
                      return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
                  };
                  if (v1 instanceof Data_Maybe.Just) {
                      return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe.Just.create)(v(v1.value0));
                  };
                  throw new Error("Failed pattern match at Data.Traversable (line 115, column 1 - line 119, column 33): " + [ v.constructor.name, v1.constructor.name ]);
              };
          };
      },
      sequence: function (dictApplicative) {
          return function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
              };
              if (v instanceof Data_Maybe.Just) {
                  return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe.Just.create)(v.value0);
              };
              throw new Error("Failed pattern match at Data.Traversable (line 115, column 1 - line 119, column 33): " + [ v.constructor.name ]);
          };
      },
      Functor0: function () {
          return Data_Maybe.functorMaybe;
      },
      Foldable1: function () {
          return Data_Foldable.foldableMaybe;
      }
  };
  var sequenceDefault = function (dictTraversable) {
      return function (dictApplicative) {
          return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var traversableArray = {
      traverse: function (dictApplicative) {
          return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map((dictApplicative.Apply0()).Functor0()))(Control_Applicative.pure(dictApplicative));
      },
      sequence: function (dictApplicative) {
          return sequenceDefault(traversableArray)(dictApplicative);
      },
      Functor0: function () {
          return Data_Functor.functorArray;
      },
      Foldable1: function () {
          return Data_Foldable.foldableArray;
      }
  };
  var sequence = function (dict) {
      return dict.sequence;
  };
  exports["traverse"] = traverse;
  exports["sequence"] = sequence;
  exports["traversableArray"] = traversableArray;
  exports["traversableMaybe"] = traversableMaybe;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.List.Types"] = $PS["Data.List.Types"] || {};
  var exports = $PS["Data.List.Types"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Traversable = $PS["Data.Traversable"];    
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var NonEmptyList = function (x) {
      return x;
  };
  var listMap = function (f) {
      var chunkedRevMap = function ($copy_chunksAcc) {
          return function ($copy_v) {
              var $tco_var_chunksAcc = $copy_chunksAcc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(chunksAcc, v) {
                  if (v instanceof Cons && (v.value1 instanceof Cons && v.value1.value1 instanceof Cons)) {
                      $tco_var_chunksAcc = new Cons(v, chunksAcc);
                      $copy_v = v.value1.value1.value1;
                      return;
                  };
                  var unrolledMap = function (v1) {
                      if (v1 instanceof Cons && (v1.value1 instanceof Cons && v1.value1.value1 instanceof Nil)) {
                          return new Cons(f(v1.value0), new Cons(f(v1.value1.value0), Nil.value));
                      };
                      if (v1 instanceof Cons && v1.value1 instanceof Nil) {
                          return new Cons(f(v1.value0), Nil.value);
                      };
                      return Nil.value;
                  };
                  var reverseUnrolledMap = function ($copy_v1) {
                      return function ($copy_acc) {
                          var $tco_var_v1 = $copy_v1;
                          var $tco_done1 = false;
                          var $tco_result;
                          function $tco_loop(v1, acc) {
                              if (v1 instanceof Cons && (v1.value0 instanceof Cons && (v1.value0.value1 instanceof Cons && v1.value0.value1.value1 instanceof Cons))) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_acc = new Cons(f(v1.value0.value0), new Cons(f(v1.value0.value1.value0), new Cons(f(v1.value0.value1.value1.value0), acc)));
                                  return;
                              };
                              $tco_done1 = true;
                              return acc;
                          };
                          while (!$tco_done1) {
                              $tco_result = $tco_loop($tco_var_v1, $copy_acc);
                          };
                          return $tco_result;
                      };
                  };
                  $tco_done = true;
                  return reverseUnrolledMap(chunksAcc)(unrolledMap(v));
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_chunksAcc, $copy_v);
              };
              return $tco_result;
          };
      };
      return chunkedRevMap(Nil.value);
  };
  var functorList = {
      map: listMap
  };                                                                   
  var foldableList = {
      foldr: function (f) {
          return function (b) {
              var rev = (function () {
                  var go = function ($copy_acc) {
                      return function ($copy_v) {
                          var $tco_var_acc = $copy_acc;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(acc, v) {
                              if (v instanceof Nil) {
                                  $tco_done = true;
                                  return acc;
                              };
                              if (v instanceof Cons) {
                                  $tco_var_acc = new Cons(v.value0, acc);
                                  $copy_v = v.value1;
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.List.Types (line 108, column 7 - line 108, column 23): " + [ acc.constructor.name, v.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_acc, $copy_v);
                          };
                          return $tco_result;
                      };
                  };
                  return go(Nil.value);
              })();
              var $205 = Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b);
              return function ($206) {
                  return $205(rev($206));
              };
          };
      },
      foldl: function (f) {
          var go = function ($copy_b) {
              return function ($copy_v) {
                  var $tco_var_b = $copy_b;
                  var $tco_done1 = false;
                  var $tco_result;
                  function $tco_loop(b, v) {
                      if (v instanceof Nil) {
                          $tco_done1 = true;
                          return b;
                      };
                      if (v instanceof Cons) {
                          $tco_var_b = f(b)(v.value0);
                          $copy_v = v.value1;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.List.Types (line 112, column 12 - line 114, column 30): " + [ v.constructor.name ]);
                  };
                  while (!$tco_done1) {
                      $tco_result = $tco_loop($tco_var_b, $copy_v);
                  };
                  return $tco_result;
              };
          };
          return go;
      },
      foldMap: function (dictMonoid) {
          return function (f) {
              return Data_Foldable.foldl(foldableList)(function (acc) {
                  var $207 = Data_Semigroup.append(dictMonoid.Semigroup0())(acc);
                  return function ($208) {
                      return $207(f($208));
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      }
  };
  var semigroupList = {
      append: function (xs) {
          return function (ys) {
              return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
          };
      }
  };
  var traversableList = {
      traverse: function (dictApplicative) {
          return function (f) {
              var $222 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value));
              var $223 = Data_Foldable.foldl(foldableList)(function (acc) {
                  var $225 = Control_Apply.lift2(dictApplicative.Apply0())(Data_Function.flip(Cons.create))(acc);
                  return function ($226) {
                      return $225(f($226));
                  };
              })(Control_Applicative.pure(dictApplicative)(Nil.value));
              return function ($224) {
                  return $222($223($224));
              };
          };
      },
      sequence: function (dictApplicative) {
          return Data_Traversable.traverse(traversableList)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
      },
      Functor0: function () {
          return functorList;
      },
      Foldable1: function () {
          return foldableList;
      }
  };
  var unfoldable1List = {
      unfoldr1: function (f) {
          return function (b) {
              var go = function ($copy_source) {
                  return function ($copy_memo) {
                      var $tco_var_source = $copy_source;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(source, memo) {
                          var v = f(source);
                          if (v.value1 instanceof Data_Maybe.Just) {
                              $tco_var_source = v.value1.value0;
                              $copy_memo = new Cons(v.value0, memo);
                              return;
                          };
                          if (v.value1 instanceof Data_Maybe.Nothing) {
                              $tco_done = true;
                              return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(new Cons(v.value0, memo));
                          };
                          throw new Error("Failed pattern match at Data.List.Types (line 136, column 22 - line 138, column 61): " + [ v.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_source, $copy_memo);
                      };
                      return $tco_result;
                  };
              };
              return go(b)(Nil.value);
          };
      }
  };
  var unfoldableList = {
      unfoldr: function (f) {
          return function (b) {
              var go = function ($copy_source) {
                  return function ($copy_memo) {
                      var $tco_var_source = $copy_source;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(source, memo) {
                          var v = f(source);
                          if (v instanceof Data_Maybe.Nothing) {
                              $tco_done = true;
                              return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(memo);
                          };
                          if (v instanceof Data_Maybe.Just) {
                              $tco_var_source = v.value0.value1;
                              $copy_memo = new Cons(v.value0.value0, memo);
                              return;
                          };
                          throw new Error("Failed pattern match at Data.List.Types (line 143, column 22 - line 145, column 52): " + [ v.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_source, $copy_memo);
                      };
                      return $tco_result;
                  };
              };
              return go(b)(Nil.value);
          };
      },
      Unfoldable10: function () {
          return unfoldable1List;
      }
  };
  var applyList = {
      apply: function (v) {
          return function (v1) {
              if (v instanceof Nil) {
                  return Nil.value;
              };
              if (v instanceof Cons) {
                  return Data_Semigroup.append(semigroupList)(Data_Functor.map(functorList)(v.value0)(v1))(Control_Apply.apply(applyList)(v.value1)(v1));
              };
              throw new Error("Failed pattern match at Data.List.Types (line 158, column 1 - line 160, column 48): " + [ v.constructor.name, v1.constructor.name ]);
          };
      },
      Functor0: function () {
          return functorList;
      }
  };
  var applicativeList = {
      pure: function (a) {
          return new Cons(a, Nil.value);
      },
      Apply0: function () {
          return applyList;
      }
  };
  var altList = {
      alt: Data_Semigroup.append(semigroupList),
      Functor0: function () {
          return functorList;
      }
  };
  var plusList = {
      empty: Nil.value,
      Alt0: function () {
          return altList;
      }
  };
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["NonEmptyList"] = NonEmptyList;
  exports["semigroupList"] = semigroupList;
  exports["functorList"] = functorList;
  exports["foldableList"] = foldableList;
  exports["unfoldableList"] = unfoldableList;
  exports["traversableList"] = traversableList;
  exports["applicativeList"] = applicativeList;
  exports["plusList"] = plusList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Tuple"] = $PS["Data.Tuple"] || {};
  var exports = $PS["Data.Tuple"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];        
  var Tuple = (function () {
      function Tuple(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Tuple.create = function (value0) {
          return function (value1) {
              return new Tuple(value0, value1);
          };
      };
      return Tuple;
  })();
  var uncurry = function (f) {
      return function (v) {
          return f(v.value0)(v.value1);
      };
  };
  var snd = function (v) {
      return v.value1;
  };
  var functorTuple = {
      map: function (f) {
          return function (m) {
              return new Tuple(m.value0, f(m.value1));
          };
      }
  };
  var fst = function (v) {
      return v.value0;
  };
  var eqTuple = function (dictEq) {
      return function (dictEq1) {
          return {
              eq: function (x) {
                  return function (y) {
                      return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq1)(x.value1)(y.value1);
                  };
              }
          };
      };
  };
  var ordTuple = function (dictOrd) {
      return function (dictOrd1) {
          return {
              compare: function (x) {
                  return function (y) {
                      var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                      if (v instanceof Data_Ordering.LT) {
                          return Data_Ordering.LT.value;
                      };
                      if (v instanceof Data_Ordering.GT) {
                          return Data_Ordering.GT.value;
                      };
                      return Data_Ord.compare(dictOrd1)(x.value1)(y.value1);
                  };
              },
              Eq0: function () {
                  return eqTuple(dictOrd.Eq0())(dictOrd1.Eq0());
              }
          };
      };
  };
  exports["Tuple"] = Tuple;
  exports["fst"] = fst;
  exports["snd"] = snd;
  exports["uncurry"] = uncurry;
  exports["eqTuple"] = eqTuple;
  exports["ordTuple"] = ordTuple;
  exports["functorTuple"] = functorTuple;
})(PS);
(function(exports) {
  "use strict";

  exports.unfoldrArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var maybe = f(value);
                if (isNothing(maybe)) return result;
                var tuple = fromJust(maybe);
                result.push(fst(tuple));
                value = snd(tuple);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable"] = PS["Data.Unfoldable"] || {});
(function(exports) {
  "use strict";

  exports.unfoldr1ArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var tuple = f(value);
                result.push(fst(tuple));
                var maybe = snd(tuple);
                if (isNothing(maybe)) return result;
                value = fromJust(maybe);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable1"] = PS["Data.Unfoldable1"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Unfoldable1"] = $PS["Data.Unfoldable1"] || {};
  var exports = $PS["Data.Unfoldable1"];
  var $foreign = $PS["Data.Unfoldable1"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var unfoldable1Array = {
      unfoldr1: $foreign.unfoldr1ArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd)
  };
  exports["unfoldable1Array"] = unfoldable1Array;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Unfoldable"] = $PS["Data.Unfoldable"] || {};
  var exports = $PS["Data.Unfoldable"];
  var $foreign = $PS["Data.Unfoldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];  
  var unfoldr = function (dict) {
      return dict.unfoldr;
  };
  var unfoldableArray = {
      unfoldr: $foreign.unfoldrArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd),
      Unfoldable10: function () {
          return Data_Unfoldable1.unfoldable1Array;
      }
  };
  exports["unfoldr"] = unfoldr;
  exports["unfoldableArray"] = unfoldableArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.List"] = $PS["Data.List"] || {};
  var exports = $PS["Data.List"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];                                  
  var uncons = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Data_List_Types.Cons) {
          return new Data_Maybe.Just({
              head: v.value0,
              tail: v.value1
          });
      };
      throw new Error("Failed pattern match at Data.List (line 263, column 1 - line 263, column 66): " + [ v.constructor.name ]);
  };
  var toUnfoldable = function (dictUnfoldable) {
      return Data_Unfoldable.unfoldr(dictUnfoldable)(function (xs) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(function (rec) {
              return new Data_Tuple.Tuple(rec.head, rec.tail);
          })(uncons(xs));
      });
  };
  var tail = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Data_List_Types.Cons) {
          return new Data_Maybe.Just(v.value1);
      };
      throw new Error("Failed pattern match at Data.List (line 249, column 1 - line 249, column 43): " + [ v.constructor.name ]);
  };
  var reverse = (function () {
      var go = function ($copy_acc) {
          return function ($copy_v) {
              var $tco_var_acc = $copy_acc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(acc, v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return acc;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List (line 372, column 3 - line 372, column 19): " + [ acc.constructor.name, v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_acc, $copy_v);
              };
              return $tco_result;
          };
      };
      return go(Data_List_Types.Nil.value);
  })();
  var $$null = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };
  var insertAt = function (v) {
      return function (v1) {
          return function (v2) {
              if (v === 0) {
                  return new Data_Maybe.Just(new Data_List_Types.Cons(v1, v2));
              };
              if (v2 instanceof Data_List_Types.Cons) {
                  return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                      return new Data_List_Types.Cons(v2.value0, v3);
                  })(insertAt(v - 1 | 0)(v1)(v2.value1));
              };
              return Data_Maybe.Nothing.value;
          };
      };
  };
  var head = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Data_List_Types.Cons) {
          return new Data_Maybe.Just(v.value0);
      };
      throw new Error("Failed pattern match at Data.List (line 234, column 1 - line 234, column 22): " + [ v.constructor.name ]);
  };
  var fromFoldable = function (dictFoldable) {
      return Data_Foldable.foldr(dictFoldable)(Data_List_Types.Cons.create)(Data_List_Types.Nil.value);
  };
  var findIndex = function (fn) {
      var go = function ($copy_v) {
          return function ($copy_v1) {
              var $tco_var_v = $copy_v;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v, v1) {
                  if (v1 instanceof Data_List_Types.Cons) {
                      if (fn(v1.value0)) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v);
                      };
                      if (Data_Boolean.otherwise) {
                          $tco_var_v = v + 1 | 0;
                          $copy_v1 = v1.value1;
                          return;
                      };
                  };
                  if (v1 instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  throw new Error("Failed pattern match at Data.List (line 305, column 3 - line 305, column 35): " + [ v.constructor.name, v1.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_v, $copy_v1);
              };
              return $tco_result;
          };
      };
      return go(0);
  };
  var filter = function (p) {
      var go = function ($copy_acc) {
          return function ($copy_v) {
              var $tco_var_acc = $copy_acc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(acc, v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return reverse(acc);
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (p(v.value0)) {
                          $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                          $copy_v = v.value1;
                          return;
                      };
                      if (Data_Boolean.otherwise) {
                          $tco_var_acc = acc;
                          $copy_v = v.value1;
                          return;
                      };
                  };
                  throw new Error("Failed pattern match at Data.List (line 394, column 3 - line 394, column 27): " + [ acc.constructor.name, v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_acc, $copy_v);
              };
              return $tco_result;
          };
      };
      return go(Data_List_Types.Nil.value);
  };
  var elemIndex = function (dictEq) {
      return function (x) {
          return findIndex(function (v) {
              return Data_Eq.eq(dictEq)(v)(x);
          });
      };
  };
  exports["toUnfoldable"] = toUnfoldable;
  exports["fromFoldable"] = fromFoldable;
  exports["null"] = $$null;
  exports["head"] = head;
  exports["elemIndex"] = elemIndex;
  exports["insertAt"] = insertAt;
  exports["reverse"] = reverse;
  exports["filter"] = filter;
})(PS);
(function(exports) {
  "use strict";

  exports.defer = function (thunk) {
    var v = null;
    return function() {
      if (thunk === undefined) return v;

      v = thunk();
      thunk = undefined; // eslint-disable-line no-param-reassign
      return v;
    };
  };

  exports.force = function (l) {
    return l();
  };
})(PS["Data.Lazy"] = PS["Data.Lazy"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Lazy"] = $PS["Data.Lazy"] || {};
  var exports = $PS["Data.Lazy"];
  var $foreign = $PS["Data.Lazy"];
  var functorLazy = {
      map: function (f) {
          return function (l) {
              return $foreign.defer(function (v) {
                  return f($foreign.force(l));
              });
          };
      }
  };
  exports["functorLazy"] = functorLazy;
  exports["defer"] = $foreign.defer;
  exports["force"] = $foreign.force;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Lazy"] = $PS["Control.Lazy"] || {};
  var exports = $PS["Control.Lazy"];
  var defer = function (dict) {
      return dict.defer;
  };
  exports["defer"] = defer;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.List.Lazy.Types"] = $PS["Data.List.Lazy.Types"] || {};
  var exports = $PS["Data.List.Lazy.Types"];
  var Control_Lazy = $PS["Control.Lazy"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Lazy = $PS["Data.Lazy"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];                    
  var List = function (x) {
      return x;
  };
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var nil = List(Data_Lazy.defer(function (v) {
      return Nil.value;
  }));
  var step = (function () {
      var $225 = Data_Newtype.unwrap();
      return function ($226) {
          return Data_Lazy.force($225($226));
      };
  })();
  var lazyList = {
      defer: function (f) {
          return List(Data_Lazy.defer(function ($227) {
              return step(f($227));
          }));
      }
  };
  var cons = function (x) {
      return function (xs) {
          return List(Data_Lazy.defer(function (v) {
              return new Cons(x, xs);
          }));
      };
  };
  var foldableList = {
      foldr: function (op) {
          return function (z) {
              return function (xs) {
                  var rev = Data_Foldable.foldl(foldableList)(Data_Function.flip(cons))(nil);
                  return Data_Foldable.foldl(foldableList)(Data_Function.flip(op))(z)(rev(xs));
              };
          };
      },
      foldl: function (op) {
          var go = function ($copy_b) {
              return function ($copy_xs) {
                  var $tco_var_b = $copy_b;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(b, xs) {
                      var v = step(xs);
                      if (v instanceof Nil) {
                          $tco_done = true;
                          return b;
                      };
                      if (v instanceof Cons) {
                          $tco_var_b = op(b)(v.value0);
                          $copy_xs = v.value1;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.List.Lazy.Types (line 128, column 7 - line 130, column 40): " + [ v.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_b, $copy_xs);
                  };
                  return $tco_result;
              };
          };
          return go;
      },
      foldMap: function (dictMonoid) {
          return function (f) {
              return Data_Foldable.foldl(foldableList)(function (b) {
                  return function (a) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(b)(f(a));
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      }
  };
  var unfoldable1List = {
      unfoldr1: (function () {
          var go = function (f) {
              return function (b) {
                  return Control_Lazy.defer(lazyList)(function (v) {
                      var v1 = f(b);
                      if (v1.value1 instanceof Data_Maybe.Just) {
                          return cons(v1.value0)(go(f)(v1.value1.value0));
                      };
                      if (v1.value1 instanceof Data_Maybe.Nothing) {
                          return cons(v1.value0)(nil);
                      };
                      throw new Error("Failed pattern match at Data.List.Lazy.Types (line 152, column 28 - line 154, column 33): " + [ v1.constructor.name ]);
                  });
              };
          };
          return go;
      })()
  };
  var unfoldableList = {
      unfoldr: (function () {
          var go = function (f) {
              return function (b) {
                  return Control_Lazy.defer(lazyList)(function (v) {
                      var v1 = f(b);
                      if (v1 instanceof Data_Maybe.Nothing) {
                          return nil;
                      };
                      if (v1 instanceof Data_Maybe.Just) {
                          return cons(v1.value0.value0)(go(f)(v1.value0.value1));
                      };
                      throw new Error("Failed pattern match at Data.List.Lazy.Types (line 158, column 28 - line 160, column 39): " + [ v1.constructor.name ]);
                  });
              };
          };
          return go;
      })(),
      Unfoldable10: function () {
          return unfoldable1List;
      }
  };
  exports["List"] = List;
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["step"] = step;
  exports["foldableList"] = foldableList;
  exports["unfoldableList"] = unfoldableList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.List.Lazy"] = $PS["Data.List.Lazy"] || {};
  var exports = $PS["Data.List.Lazy"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Lazy = $PS["Data.Lazy"];
  var Data_List_Lazy_Types = $PS["Data.List.Lazy.Types"];
  var Data_Newtype = $PS["Data.Newtype"];
  var filter = function (p) {
      var go = function ($copy_v) {
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(v) {
              if (v instanceof Data_List_Lazy_Types.Nil) {
                  $tco_done = true;
                  return Data_List_Lazy_Types.Nil.value;
              };
              if (v instanceof Data_List_Lazy_Types.Cons) {
                  if (p(v.value0)) {
                      $tco_done = true;
                      return new Data_List_Lazy_Types.Cons(v.value0, filter(p)(v.value1));
                  };
                  if (Data_Boolean.otherwise) {
                      $copy_v = Data_List_Lazy_Types.step(v.value1);
                      return;
                  };
              };
              throw new Error("Failed pattern match at Data.List.Lazy (line 416, column 3 - line 416, column 15): " + [ v.constructor.name ]);
          };
          while (!$tco_done) {
              $tco_result = $tco_loop($copy_v);
          };
          return $tco_result;
      };
      var $268 = Data_Functor.map(Data_Lazy.functorLazy)(go);
      var $269 = Data_Newtype.unwrap();
      return function ($270) {
          return Data_List_Lazy_Types.List($268($269($270)));
      };
  };
  exports["filter"] = filter;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Map.Internal"] = $PS["Data.Map.Internal"] || {};
  var exports = $PS["Data.Map.Internal"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_List_Lazy = $PS["Data.List.Lazy"];
  var Data_List_Lazy_Types = $PS["Data.List.Lazy.Types"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];                
  var Leaf = (function () {
      function Leaf() {

      };
      Leaf.value = new Leaf();
      return Leaf;
  })();
  var Two = (function () {
      function Two(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Two.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Two(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Two;
  })();
  var Three = (function () {
      function Three(value0, value1, value2, value3, value4, value5, value6) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
          this.value6 = value6;
      };
      Three.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return function (value6) {
                                  return new Three(value0, value1, value2, value3, value4, value5, value6);
                              };
                          };
                      };
                  };
              };
          };
      };
      return Three;
  })();
  var TwoLeft = (function () {
      function TwoLeft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoLeft(value0, value1, value2);
              };
          };
      };
      return TwoLeft;
  })();
  var TwoRight = (function () {
      function TwoRight(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoRight(value0, value1, value2);
              };
          };
      };
      return TwoRight;
  })();
  var ThreeLeft = (function () {
      function ThreeLeft(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeLeft(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeLeft;
  })();
  var ThreeMiddle = (function () {
      function ThreeMiddle(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeMiddle.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeMiddle(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeMiddle;
  })();
  var ThreeRight = (function () {
      function ThreeRight(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeRight(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeRight;
  })();
  var KickUp = (function () {
      function KickUp(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      KickUp.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new KickUp(value0, value1, value2, value3);
                  };
              };
          };
      };
      return KickUp;
  })();
  var values = function (v) {
      if (v instanceof Leaf) {
          return Data_List_Types.Nil.value;
      };
      if (v instanceof Two) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(values(v.value3)));
      };
      if (v instanceof Three) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value3))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value5))(values(v.value6)))));
      };
      throw new Error("Failed pattern match at Data.Map.Internal (line 626, column 1 - line 626, column 40): " + [ v.constructor.name ]);
  };
  var singleton = function (k) {
      return function (v) {
          return new Two(Leaf.value, k, v, Leaf.value);
      };
  };
  var toUnfoldable = function (dictUnfoldable) {
      return function (m) {
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof Leaf) {
                          $copy_v = v.value1;
                          return;
                      };
                      if (v.value0 instanceof Two && (v.value0.value0 instanceof Leaf && v.value0.value3 instanceof Leaf)) {
                          $tco_done = true;
                          return Data_Maybe.Just.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0.value1, v.value0.value2), v.value1));
                      };
                      if (v.value0 instanceof Two && v.value0.value0 instanceof Leaf) {
                          $tco_done = true;
                          return Data_Maybe.Just.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0.value1, v.value0.value2), new Data_List_Types.Cons(v.value0.value3, v.value1)));
                      };
                      if (v.value0 instanceof Two) {
                          $copy_v = new Data_List_Types.Cons(v.value0.value0, new Data_List_Types.Cons(singleton(v.value0.value1)(v.value0.value2), new Data_List_Types.Cons(v.value0.value3, v.value1)));
                          return;
                      };
                      if (v.value0 instanceof Three) {
                          $copy_v = new Data_List_Types.Cons(v.value0.value0, new Data_List_Types.Cons(singleton(v.value0.value1)(v.value0.value2), new Data_List_Types.Cons(v.value0.value3, new Data_List_Types.Cons(singleton(v.value0.value4)(v.value0.value5), new Data_List_Types.Cons(v.value0.value6, v.value1)))));
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 591, column 18 - line 600, column 71): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 590, column 3 - line 590, column 19): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return Data_Unfoldable.unfoldr(dictUnfoldable)(go)(new Data_List_Types.Cons(m, Data_List_Types.Nil.value));
      };
  };
  var lookup = function (dictOrd) {
      return function (k) {
          var comp = Data_Ord.compare(dictOrd);
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Leaf) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Two) {
                      var v2 = comp(k)(v.value1);
                      if (v2 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      if (v2 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  if (v instanceof Three) {
                      var v3 = comp(k)(v.value1);
                      if (v3 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      var v4 = comp(k)(v.value4);
                      if (v4 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value5);
                      };
                      if (v3 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      if (v4 instanceof Data_Ordering.GT) {
                          $copy_v = v.value6;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 211, column 5 - line 211, column 22): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return go;
      };
  };
  var fromZipper = function ($copy_dictOrd) {
      return function ($copy_v) {
          return function ($copy_tree) {
              var $tco_var_dictOrd = $copy_dictOrd;
              var $tco_var_v = $copy_v;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(dictOrd, v, tree) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return tree;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof TwoLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(tree, v.value0.value0, v.value0.value1, v.value0.value2);
                          return;
                      };
                      if (v.value0 instanceof TwoRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(v.value0.value0, v.value0.value1, v.value0.value2, tree);
                          return;
                      };
                      if (v.value0 instanceof ThreeLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(tree, v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeMiddle) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, tree, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5, tree);
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 432, column 3 - line 437, column 88): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 429, column 1 - line 429, column 80): " + [ v.constructor.name, tree.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_dictOrd, $tco_var_v, $copy_tree);
              };
              return $tco_result;
          };
      };
  };
  var insert = function (dictOrd) {
      return function (k) {
          return function (v) {
              var up = function ($copy_v1) {
                  return function ($copy_v2) {
                      var $tco_var_v1 = $copy_v1;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(v1, v2) {
                          if (v1 instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Two(v2.value0, v2.value1, v2.value2, v2.value3);
                          };
                          if (v1 instanceof Data_List_Types.Cons) {
                              if (v1.value0 instanceof TwoLeft) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v2.value0, v2.value1, v2.value2, v2.value3, v1.value0.value0, v1.value0.value1, v1.value0.value2));
                              };
                              if (v1.value0 instanceof TwoRight) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0, v2.value1, v2.value2, v2.value3));
                              };
                              if (v1.value0 instanceof ThreeLeft) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v2.value0, v2.value1, v2.value2, v2.value3), v1.value0.value0, v1.value0.value1, new Two(v1.value0.value2, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeMiddle) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0), v2.value1, v2.value2, new Two(v2.value3, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeRight) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v1.value0.value3), v1.value0.value4, v1.value0.value5, new Two(v2.value0, v2.value1, v2.value2, v2.value3));
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.Map.Internal (line 468, column 5 - line 473, column 108): " + [ v1.value0.constructor.name, v2.constructor.name ]);
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 465, column 3 - line 465, column 56): " + [ v1.constructor.name, v2.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_v1, $copy_v2);
                      };
                      return $tco_result;
                  };
              };
              var comp = Data_Ord.compare(dictOrd);
              var down = function ($copy_ctx) {
                  return function ($copy_v1) {
                      var $tco_var_ctx = $copy_ctx;
                      var $tco_done1 = false;
                      var $tco_result;
                      function $tco_loop(ctx, v1) {
                          if (v1 instanceof Leaf) {
                              $tco_done1 = true;
                              return up(ctx)(new KickUp(Leaf.value, k, v, Leaf.value));
                          };
                          if (v1 instanceof Two) {
                              var v2 = comp(k)(v1.value1);
                              if (v2 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Two(v1.value0, k, v, v1.value3));
                              };
                              if (v2 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(v1.value1, v1.value2, v1.value3), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(v1.value0, v1.value1, v1.value2), ctx);
                              $copy_v1 = v1.value3;
                              return;
                          };
                          if (v1 instanceof Three) {
                              var v3 = comp(k)(v1.value1);
                              if (v3 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, k, v, v1.value3, v1.value4, v1.value5, v1.value6));
                              };
                              var v4 = comp(k)(v1.value4);
                              if (v4 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, k, v, v1.value6));
                              };
                              if (v3 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(v1.value1, v1.value2, v1.value3, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              if (v3 instanceof Data_Ordering.GT && v4 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(v1.value0, v1.value1, v1.value2, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value3;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(v1.value0, v1.value1, v1.value2, v1.value3, v1.value4, v1.value5), ctx);
                              $copy_v1 = v1.value6;
                              return;
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 448, column 3 - line 448, column 55): " + [ ctx.constructor.name, v1.constructor.name ]);
                      };
                      while (!$tco_done1) {
                          $tco_result = $tco_loop($tco_var_ctx, $copy_v1);
                      };
                      return $tco_result;
                  };
              };
              return down(Data_List_Types.Nil.value);
          };
      };
  };
  var pop = function (dictOrd) {
      return function (k) {
          var up = function ($copy_ctxs) {
              return function ($copy_tree) {
                  var $tco_var_ctxs = $copy_ctxs;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctxs, tree) {
                      if (ctxs instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return tree;
                      };
                      if (ctxs instanceof Data_List_Types.Cons) {
                          if (ctxs.value0 instanceof TwoLeft && (ctxs.value0.value2 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoRight && (ctxs.value0.value0 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6)));
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && (ctxs.value0.value2 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeRight && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value3 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value4, ctxs.value0.value5, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0, ctxs.value0.value5.value1, ctxs.value0.value5.value2, ctxs.value0.value5.value3)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0), ctxs.value0.value5.value1, ctxs.value0.value5.value2, new Two(ctxs.value0.value5.value3, ctxs.value0.value5.value4, ctxs.value0.value5.value5, ctxs.value0.value5.value6)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3), ctxs.value0.value3.value4, ctxs.value0.value3.value5, new Two(ctxs.value0.value3.value6, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 525, column 9 - line 542, column 136): " + [ ctxs.value0.constructor.name, tree.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 522, column 5 - line 542, column 136): " + [ ctxs.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctxs, $copy_tree);
                  };
                  return $tco_result;
              };
          };
          var removeMaxNode = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done1 = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Two && (m.value0 instanceof Leaf && m.value3 instanceof Leaf)) {
                          $tco_done1 = true;
                          return up(ctx)(Leaf.value);
                      };
                      if (m instanceof Two) {
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three && (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf))) {
                          $tco_done1 = true;
                          return up(new Data_List_Types.Cons(new TwoRight(Leaf.value, m.value1, m.value2), ctx))(Leaf.value);
                      };
                      if (m instanceof Three) {
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 554, column 5 - line 558, column 107): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done1) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          var maxNode = function ($copy_m) {
              var $tco_done2 = false;
              var $tco_result;
              function $tco_loop(m) {
                  if (m instanceof Two && m.value3 instanceof Leaf) {
                      $tco_done2 = true;
                      return {
                          key: m.value1,
                          value: m.value2
                      };
                  };
                  if (m instanceof Two) {
                      $copy_m = m.value3;
                      return;
                  };
                  if (m instanceof Three && m.value6 instanceof Leaf) {
                      $tco_done2 = true;
                      return {
                          key: m.value4,
                          value: m.value5
                      };
                  };
                  if (m instanceof Three) {
                      $copy_m = m.value6;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 545, column 33 - line 549, column 45): " + [ m.constructor.name ]);
              };
              while (!$tco_done2) {
                  $tco_result = $tco_loop($copy_m);
              };
              return $tco_result;
          };
          var comp = Data_Ord.compare(dictOrd);
          var down = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done3 = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Leaf) {
                          $tco_done3 = true;
                          return Data_Maybe.Nothing.value;
                      };
                      if (m instanceof Two) {
                          var v = comp(k)(m.value1);
                          if (m.value3 instanceof Leaf && v instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, up(ctx)(Leaf.value)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new TwoLeft(max.key, max.value, m.value3), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(m.value1, m.value2, m.value3), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three) {
                          var leaves = (function () {
                              if (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf)) {
                                  return true;
                              };
                              return false;
                          })();
                          var v = comp(k)(m.value4);
                          var v3 = comp(k)(m.value1);
                          if (leaves && v3 instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value4, m.value5, Leaf.value))));
                          };
                          if (leaves && v instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value1, m.value2, Leaf.value))));
                          };
                          if (v3 instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new ThreeLeft(max.key, max.value, m.value3, m.value4, m.value5, m.value6), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value3);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, removeMaxNode(new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, max.key, max.value, m.value6), ctx))(m.value3)));
                          };
                          if (v3 instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(m.value1, m.value2, m.value3, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          if (v3 instanceof Data_Ordering.GT && v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value3;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 495, column 34 - line 518, column 80): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done3) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          return down(Data_List_Types.Nil.value);
      };
  };
  var foldableMap = {
      foldl: function (f) {
          return function (z) {
              return function (m) {
                  return Data_Foldable.foldl(Data_List_Types.foldableList)(f)(z)(values(m));
              };
          };
      },
      foldr: function (f) {
          return function (z) {
              return function (m) {
                  return Data_Foldable.foldr(Data_List_Types.foldableList)(f)(z)(values(m));
              };
          };
      },
      foldMap: function (dictMonoid) {
          return function (f) {
              return function (m) {
                  return Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(f)(values(m));
              };
          };
      }
  };
  var empty = Leaf.value;
  var fromFoldable = function (dictOrd) {
      return function (dictFoldable) {
          return Data_Foldable.foldl(dictFoldable)(function (m) {
              return function (v) {
                  return insert(dictOrd)(v.value0)(v.value1)(m);
              };
          })(empty);
      };
  };
  var filterWithKey = function (dictOrd) {
      return function (predicate) {
          var $764 = fromFoldable(dictOrd)(Data_List_Lazy_Types.foldableList);
          var $765 = Data_List_Lazy.filter(Data_Tuple.uncurry(predicate));
          var $766 = toUnfoldable(Data_List_Lazy_Types.unfoldableList);
          return function ($767) {
              return $764($765($766($767)));
          };
      };
  };
  var filter = function (dictOrd) {
      return function (predicate) {
          return filterWithKey(dictOrd)(Data_Function["const"](predicate));
      };
  };
  var filterKeys = function (dictOrd) {
      return function (predicate) {
          return filterWithKey(dictOrd)(function ($768) {
              return Data_Function["const"](predicate($768));
          });
      };
  };
  var $$delete = function (dictOrd) {
      return function (k) {
          return function (m) {
              return Data_Maybe.maybe(m)(Data_Tuple.snd)(pop(dictOrd)(k)(m));
          };
      };
  };
  var alter = function (dictOrd) {
      return function (f) {
          return function (k) {
              return function (m) {
                  var v = f(lookup(dictOrd)(k)(m));
                  if (v instanceof Data_Maybe.Nothing) {
                      return $$delete(dictOrd)(k)(m);
                  };
                  if (v instanceof Data_Maybe.Just) {
                      return insert(dictOrd)(k)(v.value0)(m);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 563, column 15 - line 565, column 25): " + [ v.constructor.name ]);
              };
          };
      };
  };
  var update = function (dictOrd) {
      return function (f) {
          return function (k) {
              return function (m) {
                  return alter(dictOrd)(Data_Maybe.maybe(Data_Maybe.Nothing.value)(f))(k)(m);
              };
          };
      };
  };
  exports["empty"] = empty;
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["fromFoldable"] = fromFoldable;
  exports["toUnfoldable"] = toUnfoldable;
  exports["delete"] = $$delete;
  exports["pop"] = pop;
  exports["alter"] = alter;
  exports["update"] = update;
  exports["values"] = values;
  exports["filterKeys"] = filterKeys;
  exports["foldableMap"] = foldableMap;
})(PS);
(function(exports) {
  "use strict";

  exports.mapWithIndexArray = function (f) {
    return function (xs) {
      var l = xs.length;
      var result = Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(i)(xs[i]);
      }
      return result;
    };
  };
})(PS["Data.FunctorWithIndex"] = PS["Data.FunctorWithIndex"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.FunctorWithIndex"] = $PS["Data.FunctorWithIndex"] || {};
  var exports = $PS["Data.FunctorWithIndex"];
  var $foreign = $PS["Data.FunctorWithIndex"];
  var Data_Functor = $PS["Data.Functor"];          
  var mapWithIndex = function (dict) {
      return dict.mapWithIndex;
  };
  var functorWithIndexArray = {
      mapWithIndex: $foreign.mapWithIndexArray,
      Functor0: function () {
          return Data_Functor.functorArray;
      }
  };
  exports["mapWithIndex"] = mapWithIndex;
  exports["functorWithIndexArray"] = functorWithIndexArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.FoldableWithIndex"] = $PS["Data.FoldableWithIndex"] || {};
  var exports = $PS["Data.FoldableWithIndex"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_FunctorWithIndex = $PS["Data.FunctorWithIndex"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];              
  var foldrWithIndex = function (dict) {
      return dict.foldrWithIndex;
  };
  var foldMapWithIndexDefaultR = function (dictFoldableWithIndex) {
      return function (dictMonoid) {
          return function (f) {
              return foldrWithIndex(dictFoldableWithIndex)(function (i) {
                  return function (x) {
                      return function (acc) {
                          return Data_Semigroup.append(dictMonoid.Semigroup0())(f(i)(x))(acc);
                      };
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableWithIndexArray = {
      foldrWithIndex: function (f) {
          return function (z) {
              var $167 = Data_Foldable.foldr(Data_Foldable.foldableArray)(function (v) {
                  return function (y) {
                      return f(v.value0)(v.value1)(y);
                  };
              })(z);
              var $168 = Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Data_Tuple.Tuple.create);
              return function ($169) {
                  return $167($168($169));
              };
          };
      },
      foldlWithIndex: function (f) {
          return function (z) {
              var $170 = Data_Foldable.foldl(Data_Foldable.foldableArray)(function (y) {
                  return function (v) {
                      return f(v.value0)(y)(v.value1);
                  };
              })(z);
              var $171 = Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Data_Tuple.Tuple.create);
              return function ($172) {
                  return $170($171($172));
              };
          };
      },
      foldMapWithIndex: function (dictMonoid) {
          return foldMapWithIndexDefaultR(foldableWithIndexArray)(dictMonoid);
      },
      Foldable0: function () {
          return Data_Foldable.foldableArray;
      }
  };
  exports["foldableWithIndexArray"] = foldableWithIndexArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.TraversableWithIndex"] = $PS["Data.TraversableWithIndex"] || {};
  var exports = $PS["Data.TraversableWithIndex"];
  var Data_FoldableWithIndex = $PS["Data.FoldableWithIndex"];
  var Data_FunctorWithIndex = $PS["Data.FunctorWithIndex"];
  var Data_Traversable = $PS["Data.Traversable"];  
  var traverseWithIndexDefault = function (dictTraversableWithIndex) {
      return function (dictApplicative) {
          return function (f) {
              var $64 = Data_Traversable.sequence(dictTraversableWithIndex.Traversable2())(dictApplicative);
              var $65 = Data_FunctorWithIndex.mapWithIndex(dictTraversableWithIndex.FunctorWithIndex0())(f);
              return function ($66) {
                  return $64($65($66));
              };
          };
      };
  };
  var traverseWithIndex = function (dict) {
      return dict.traverseWithIndex;
  };
  var traversableWithIndexArray = {
      traverseWithIndex: function (dictApplicative) {
          return traverseWithIndexDefault(traversableWithIndexArray)(dictApplicative);
      },
      FunctorWithIndex0: function () {
          return Data_FunctorWithIndex.functorWithIndexArray;
      },
      FoldableWithIndex1: function () {
          return Data_FoldableWithIndex.foldableWithIndexArray;
      },
      Traversable2: function () {
          return Data_Traversable.traversableArray;
      }
  };
  exports["traverseWithIndex"] = traverseWithIndex;
  exports["traversableWithIndexArray"] = traversableWithIndexArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Decode.Decoders"] = $PS["Data.Argonaut.Decode.Decoders"] || {};
  var exports = $PS["Data.Argonaut.Decode.Decoders"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_TraversableWithIndex = $PS["Data.TraversableWithIndex"];
  var Data_Tuple = $PS["Data.Tuple"];
  var decodeString = Data_Argonaut_Core.caseJsonString(Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("String")))(Data_Either.Right.create);
  var decodeNumber = Data_Argonaut_Core.caseJsonNumber(Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("Number")))(Data_Either.Right.create);
  var decodeMaybe = function (decoder) {
      return function (json) {
          if (Data_Argonaut_Core.isNull(json)) {
              return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value);
          };
          if (Data_Boolean.otherwise) {
              return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(decoder(json));
          };
          throw new Error("Failed pattern match at Data.Argonaut.Decode.Decoders (line 37, column 1 - line 41, column 38): " + [ decoder.constructor.name, json.constructor.name ]);
      };
  };   
  var decodeJArray = (function () {
      var $22 = Data_Either.note(new Data_Argonaut_Decode_Error.TypeMismatch("Array"));
      return function ($23) {
          return $22(Data_Argonaut_Core.toArray($23));
      };
  })();
  var decodeList = function (decoder) {
      return Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
          var $24 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.Named.create("List"));
          var $25 = Data_Traversable.traverse(Data_List_Types.traversableList)(Data_Either.applicativeEither)(decoder);
          return function ($26) {
              return $24($25($26));
          };
      })())(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Either.functorEither)(Data_List.fromFoldable(Data_Foldable.foldableArray)))(decodeJArray));
  };
  var decodeInt = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
      var $54 = Data_Either.note(new Data_Argonaut_Decode_Error.TypeMismatch("Integer"));
      return function ($55) {
          return $54(Data_Int.fromNumber($55));
      };
  })())(decodeNumber);                                                                                                                                              
  var decodeArray = function (decoder) {
      return Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
          var $59 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.Named.create("Array"));
          var $60 = Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(Data_Either.applicativeEither)(function (i) {
              var $62 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.AtIndex.create(i));
              return function ($63) {
                  return $62(decoder($63));
              };
          });
          return function ($61) {
              return $59($60($61));
          };
      })())(decodeJArray);
  };
  var decodeTuple = function (decoderA) {
      return function (decoderB) {
          return function (json) {
              var f = function (v) {
                  if (v.length === 2) {
                      return Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(Data_Tuple.Tuple.create)(decoderA(v[0])))(decoderB(v[1]));
                  };
                  return Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("Tuple"));
              };
              return Control_Bind.bind(Data_Either.bindEither)(decodeArray(Data_Either.Right.create)(json))(f);
          };
      };
  };
  var decodeMap = function (dictOrd) {
      return function (decoderA) {
          return function (decoderB) {
              var $64 = Data_Functor.map(Data_Either.functorEither)(Data_Map_Internal.fromFoldable(dictOrd)(Data_List_Types.foldableList));
              var $65 = decodeList(decodeTuple(decoderA)(decoderB));
              return function ($66) {
                  return $64($65($66));
              };
          };
      };
  };
  exports["decodeMaybe"] = decodeMaybe;
  exports["decodeTuple"] = decodeTuple;
  exports["decodeNumber"] = decodeNumber;
  exports["decodeInt"] = decodeInt;
  exports["decodeString"] = decodeString;
  exports["decodeList"] = decodeList;
  exports["decodeMap"] = decodeMap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Symbol"] = $PS["Data.Symbol"] || {};
  var exports = $PS["Data.Symbol"];
  var reflectSymbol = function (dict) {
      return dict.reflectSymbol;
  };
  exports["reflectSymbol"] = reflectSymbol;
})(PS);
(function(exports) {
  "use strict";

  exports._copyST = function (m) {
    return function () {
      var r = {};
      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r[k] = m[k];
        }
      }
      return r;
    };
  };

  exports.empty = {};

  exports.runST = function (f) {
    return f();
  };

  exports._foldM = function (bind) {
    return function (f) {
      return function (mz) {
        return function (m) {
          var acc = mz;
          function g(k) {
            return function (z) {
              return f(z)(k)(m[k]);
            };
          }
          for (var k in m) {
            if (hasOwnProperty.call(m, k)) {
              acc = bind(acc)(g(k));
            }
          }
          return acc;
        };
      };
    };
  };

  exports._lookup = function (no, yes, k, m) {
    return k in m ? yes(m[k]) : no;
  };
})(PS["Foreign.Object"] = PS["Foreign.Object"] || {});
(function(exports) {
  "use strict";

  exports.map_ = function (f) {
    return function (a) {
      return function () {
        return f(a());
      };
    };
  };

  exports.foreach = function (as) {
    return function (f) {
      return function () {
        for (var i = 0, l = as.length; i < l; i++) {
          f(as[i])();
        }
      };
    };
  };
})(PS["Control.Monad.ST.Internal"] = PS["Control.Monad.ST.Internal"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.ST.Internal"] = $PS["Control.Monad.ST.Internal"] || {};
  var exports = $PS["Control.Monad.ST.Internal"];
  var $foreign = $PS["Control.Monad.ST.Internal"];
  var functorST = {
      map: $foreign.map_
  };
  exports["functorST"] = functorST;
  exports["foreach"] = $foreign.foreach;
})(PS);
(function(exports) {
  "use strict";                                                                                      

  exports.fromFoldableImpl = (function () {
    function Cons(head, tail) {
      this.head = head;
      this.tail = tail;
    }
    var emptyList = {};

    function curryCons(head) {
      return function (tail) {
        return new Cons(head, tail);
      };
    }

    function listToArray(list) {
      var result = [];
      var count = 0;
      var xs = list;
      while (xs !== emptyList) {
        result[count++] = xs.head;
        xs = xs.tail;
      }
      return result;
    }

    return function (foldr) {
      return function (xs) {
        return listToArray(foldr(curryCons)(emptyList)(xs));
      };
    };
  })();

  //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.length = function (xs) {
    return xs.length;
  };

  //------------------------------------------------------------------------------
  // Non-indexed reads -----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.unconsImpl = function (empty) {
    return function (next) {
      return function (xs) {
        return xs.length === 0 ? empty({}) : next(xs[0])(xs.slice(1));
      };
    };
  };

  //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing :  just(xs[i]);
        };
      };
    };
  };

  exports.findIndexImpl = function (just) {
    return function (nothing) {
      return function (f) {
        return function (xs) {
          for (var i = 0, l = xs.length; i < l; i++) {
            if (f(xs[i])) return just(i);
          }
          return nothing;
        };
      };
    };
  };

  exports._deleteAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (l) {
          if (i < 0 || i >= l.length) return nothing;
          var l1 = l.slice();
          l1.splice(i, 1);
          return just(l1);
        };
      };
    };
  };

  exports.filter = function (f) {
    return function (xs) {
      return xs.filter(f);
    };
  };

  //------------------------------------------------------------------------------
  // Sorting ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.sortByImpl = (function () {
    function mergeFromTo(compare, fromOrdering, xs1, xs2, from, to) {
      var mid;
      var i;
      var j;
      var k;
      var x;
      var y;
      var c;

      mid = from + ((to - from) >> 1);
      if (mid - from > 1) mergeFromTo(compare, fromOrdering, xs2, xs1, from, mid);
      if (to - mid > 1) mergeFromTo(compare, fromOrdering, xs2, xs1, mid, to);

      i = from;
      j = mid;
      k = from;
      while (i < mid && j < to) {
        x = xs2[i];
        y = xs2[j];
        c = fromOrdering(compare(x)(y));
        if (c > 0) {
          xs1[k++] = y;
          ++j;
        }
        else {
          xs1[k++] = x;
          ++i;
        }
      }
      while (i < mid) {
        xs1[k++] = xs2[i++];
      }
      while (j < to) {
        xs1[k++] = xs2[j++];
      }
    }

    return function (compare) {
      return function (fromOrdering) {
        return function (xs) {
          var out;

          if (xs.length < 2) return xs;

          out = xs.slice(0);
          mergeFromTo(compare, fromOrdering, out, xs.slice(0), 0, xs.length);

          return out;
        };
      };
    };
  })();

  //------------------------------------------------------------------------------
  // Subarrays -------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.slice = function (s) {
    return function (e) {
      return function (l) {
        return l.slice(s, e);
      };
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});
(function(exports) {
  "use strict";

  exports.pushAll = function (as) {
    return function (xs) {
      return function () {
        return xs.push.apply(xs, as);
      };
    };
  };

  exports.unsafeFreeze = function (xs) {
    return function () {
      return xs;
    };
  };

  function copyImpl(xs) {
    return function () {
      return xs.slice();
    };
  }                         

  exports.thaw = copyImpl;
})(PS["Data.Array.ST"] = PS["Data.Array.ST"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Array.ST"] = $PS["Data.Array.ST"] || {};
  var exports = $PS["Data.Array.ST"];
  var $foreign = $PS["Data.Array.ST"];                     
  var withArray = function (f) {
      return function (xs) {
          return function __do() {
              var result = $foreign.thaw(xs)();
              f(result)();
              return $foreign.unsafeFreeze(result)();
          };
      };
  };
  var push = function (a) {
      return $foreign.pushAll([ a ]);
  };
  exports["withArray"] = withArray;
  exports["push"] = push;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Array"] = $PS["Data.Array"] || {};
  var exports = $PS["Data.Array"];
  var $foreign = $PS["Data.Array"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Array_ST = $PS["Data.Array.ST"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var uncons = $foreign.unconsImpl(Data_Function["const"](Data_Maybe.Nothing.value))(function (x) {
      return function (xs) {
          return new Data_Maybe.Just({
              head: x,
              tail: xs
          });
      };
  });
  var sortBy = function (comp) {
      return $foreign.sortByImpl(comp)(function (v) {
          if (v instanceof Data_Ordering.GT) {
              return 1;
          };
          if (v instanceof Data_Ordering.EQ) {
              return 0;
          };
          if (v instanceof Data_Ordering.LT) {
              return -1 | 0;
          };
          throw new Error("Failed pattern match at Data.Array (line 831, column 31 - line 834, column 11): " + [ v.constructor.name ]);
      });
  };
  var snoc = function (xs) {
      return function (x) {
          return Data_Array_ST.withArray(Data_Array_ST.push(x))(xs)();
      };
  };
  var singleton = function (a) {
      return [ a ];
  };
  var $$null = function (xs) {
      return $foreign.length(xs) === 0;
  };                                                                                     
  var init = function (xs) {
      if ($$null(xs)) {
          return Data_Maybe.Nothing.value;
      };
      if (Data_Boolean.otherwise) {
          return new Data_Maybe.Just($foreign.slice(0)($foreign.length(xs) - 1 | 0)(xs));
      };
      throw new Error("Failed pattern match at Data.Array (line 340, column 1 - line 340, column 45): " + [ xs.constructor.name ]);
  };
  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var last = function (xs) {
      return index(xs)($foreign.length(xs) - 1 | 0);
  };
  var unsnoc = function (xs) {
      return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
          return function (v1) {
              return {
                  init: v,
                  last: v1
              };
          };
      })(init(xs)))(last(xs));
  };
  var head = function (xs) {
      return index(xs)(0);
  };
  var fromFoldable = function (dictFoldable) {
      return $foreign.fromFoldableImpl(Data_Foldable.foldr(dictFoldable));
  };
  var foldr = Data_Foldable.foldr(Data_Foldable.foldableArray);
  var findIndex = $foreign.findIndexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var deleteAt = $foreign["_deleteAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var deleteBy = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2.length === 0) {
                  return [  ];
              };
              return Data_Maybe.maybe(v2)(function (i) {
                  return Data_Maybe.fromJust()(deleteAt(i)(v2));
              })(findIndex(v(v1))(v2));
          };
      };
  };
  var cons = function (x) {
      return function (xs) {
          return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ x ])(xs);
      };
  };
  var concatMap = Data_Function.flip(Control_Bind.bind(Control_Bind.bindArray));
  var mapMaybe = function (f) {
      return concatMap((function () {
          var $109 = Data_Maybe.maybe([  ])(singleton);
          return function ($110) {
              return $109(f($110));
          };
      })());
  };
  var catMaybes = mapMaybe(Control_Category.identity(Control_Category.categoryFn));
  exports["fromFoldable"] = fromFoldable;
  exports["singleton"] = singleton;
  exports["cons"] = cons;
  exports["snoc"] = snoc;
  exports["head"] = head;
  exports["uncons"] = uncons;
  exports["unsnoc"] = unsnoc;
  exports["index"] = index;
  exports["concatMap"] = concatMap;
  exports["mapMaybe"] = mapMaybe;
  exports["catMaybes"] = catMaybes;
  exports["sortBy"] = sortBy;
  exports["deleteBy"] = deleteBy;
  exports["length"] = $foreign.length;
  exports["filter"] = $foreign.filter;
})(PS);
(function(exports) {
  "use strict";

  exports.runFn4 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return fn(a, b, c, d);
          };
        };
      };
    };
  };
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Function.Uncurried"] = $PS["Data.Function.Uncurried"] || {};
  var exports = $PS["Data.Function.Uncurried"];
  var $foreign = $PS["Data.Function.Uncurried"];
  exports["runFn4"] = $foreign.runFn4;
})(PS);
(function(exports) {
  "use strict";

  exports["new"] = function () {
    return {};
  };

  exports.poke = function (k) {
    return function (v) {
      return function (m) {
        return function () {
          m[k] = v;
          return m;
        };
      };
    };
  };
})(PS["Foreign.Object.ST"] = PS["Foreign.Object.ST"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Foreign.Object.ST"] = $PS["Foreign.Object.ST"] || {};
  var exports = $PS["Foreign.Object.ST"];
  var $foreign = $PS["Foreign.Object.ST"];
  exports["new"] = $foreign["new"];
  exports["poke"] = $foreign.poke;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Foreign.Object"] = $PS["Foreign.Object"] || {};
  var exports = $PS["Foreign.Object"];
  var $foreign = $PS["Foreign.Object"];
  var Control_Monad_ST_Internal = $PS["Control.Monad.ST.Internal"];
  var Data_Array = $PS["Data.Array"];
  var Data_Function = $PS["Data.Function"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];              
  var thawST = $foreign["_copyST"];
  var mutate = function (f) {
      return function (m) {
          return $foreign.runST(function __do() {
              var s = thawST(m)();
              f(s)();
              return s;
          });
      };
  };
  var lookup = Data_Function_Uncurried.runFn4($foreign["_lookup"])(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  var insert = function (k) {
      return function (v) {
          return mutate(Foreign_Object_ST.poke(k)(v));
      };
  };
  var fromFoldable = function (dictFoldable) {
      return function (l) {
          return $foreign.runST(function __do() {
              var s = Foreign_Object_ST["new"]();
              Control_Monad_ST_Internal.foreach(Data_Array.fromFoldable(dictFoldable)(l))(function (v) {
                  return Data_Functor["void"](Control_Monad_ST_Internal.functorST)(Foreign_Object_ST.poke(v.value0)(v.value1)(s));
              })();
              return s;
          });
      };
  };
  var fold = $foreign["_foldM"](Data_Function.applyFlipped);
  var foldMap = function (dictMonoid) {
      return function (f) {
          return fold(function (acc) {
              return function (k) {
                  return function (v) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(acc)(f(k)(v));
                  };
              };
          })(Data_Monoid.mempty(dictMonoid));
      };
  };
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["fromFoldable"] = fromFoldable;
  exports["foldMap"] = foldMap;
  exports["empty"] = $foreign.empty;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGet = function (label) {
    return function (rec) {
      return rec[label];
    };
  };

  exports.unsafeSet = function (label) {
    return function (value) {
      return function (rec) {
        var copy = {};
        for (var key in rec) {
          if ({}.hasOwnProperty.call(rec, key)) {
            copy[key] = rec[key];
          }
        }
        copy[label] = value;
        return copy;
      };
    };
  };
})(PS["Record.Unsafe"] = PS["Record.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Record.Unsafe"] = $PS["Record.Unsafe"] || {};
  var exports = $PS["Record.Unsafe"];
  var $foreign = $PS["Record.Unsafe"];
  exports["unsafeGet"] = $foreign.unsafeGet;
  exports["unsafeSet"] = $foreign.unsafeSet;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Record"] = $PS["Record"] || {};
  var exports = $PS["Record"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var insert = function (dictIsSymbol) {
      return function (dictLacks) {
          return function (dictCons) {
              return function (l) {
                  return function (a) {
                      return function (r) {
                          return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r);
                      };
                  };
              };
          };
      };
  };
  var get = function (dictIsSymbol) {
      return function (dictCons) {
          return function (l) {
              return function (r) {
                  return Record_Unsafe.unsafeGet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
              };
          };
      };
  };
  exports["get"] = get;
  exports["insert"] = insert;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Type.Proxy"] = $PS["Type.Proxy"] || {};
  var exports = $PS["Type.Proxy"];
  var $$Proxy = (function () {
      function $$Proxy() {

      };
      $$Proxy.value = new $$Proxy();
      return $$Proxy;
  })();
  exports["Proxy"] = $$Proxy;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Decode.Class"] = $PS["Data.Argonaut.Decode.Class"] || {};
  var exports = $PS["Data.Argonaut.Decode.Class"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Decoders = $PS["Data.Argonaut.Decode.Decoders"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record = $PS["Record"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var gDecodeJsonNil = {
      gDecodeJson: function (v) {
          return function (v1) {
              return new Data_Either.Right({});
          };
      }
  };
  var gDecodeJson = function (dict) {
      return dict.gDecodeJson;
  };
  var decodeRecord = function (dictGDecodeJson) {
      return function (dictRowToList) {
          return {
              decodeJson: function (json) {
                  var v = Data_Argonaut_Core.toObject(json);
                  if (v instanceof Data_Maybe.Just) {
                      return gDecodeJson(dictGDecodeJson)(v.value0)(Type_Proxy["Proxy"].value);
                  };
                  if (v instanceof Data_Maybe.Nothing) {
                      return Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("Object"));
                  };
                  throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 103, column 5 - line 105, column 46): " + [ v.constructor.name ]);
              }
          };
      };
  };
  var decodeJsonString = {
      decodeJson: Data_Argonaut_Decode_Decoders.decodeString
  };
  var decodeJsonNumber = {
      decodeJson: Data_Argonaut_Decode_Decoders.decodeNumber
  };
  var decodeJsonInt = {
      decodeJson: Data_Argonaut_Decode_Decoders.decodeInt
  };
  var decodeJsonField = function (dict) {
      return dict.decodeJsonField;
  };
  var gDecodeJsonCons = function (dictDecodeJsonField) {
      return function (dictGDecodeJson) {
          return function (dictIsSymbol) {
              return function (dictCons) {
                  return function (dictLacks) {
                      return {
                          gDecodeJson: function (object) {
                              return function (v) {
                                  var fieldName = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                  var fieldValue = Foreign_Object.lookup(fieldName)(object);
                                  var v1 = decodeJsonField(dictDecodeJsonField)(fieldValue);
                                  if (v1 instanceof Data_Maybe.Just) {
                                      return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.AtKey.create(fieldName))(v1.value0))(function (val) {
                                          return Control_Bind.bind(Data_Either.bindEither)(gDecodeJson(dictGDecodeJson)(object)(Type_Proxy["Proxy"].value))(function (rest) {
                                              return Data_Either.Right.create(Record.insert(dictIsSymbol)()()(Type_Proxy["Proxy"].value)(val)(rest));
                                          });
                                      });
                                  };
                                  if (v1 instanceof Data_Maybe.Nothing) {
                                      return Data_Either.Left.create(new Data_Argonaut_Decode_Error.AtKey(fieldName, Data_Argonaut_Decode_Error.MissingValue.value));
                                  };
                                  throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 127, column 5 - line 134, column 44): " + [ v1.constructor.name ]);
                              };
                          }
                      };
                  };
              };
          };
      };
  };
  var decodeJson = function (dict) {
      return dict.decodeJson;
  };
  var decodeJsonMaybe = function (dictDecodeJson) {
      return {
          decodeJson: Data_Argonaut_Decode_Decoders.decodeMaybe(decodeJson(dictDecodeJson))
      };
  };
  var decodeJsonTuple = function (dictDecodeJson) {
      return function (dictDecodeJson1) {
          return {
              decodeJson: Data_Argonaut_Decode_Decoders.decodeTuple(decodeJson(dictDecodeJson))(decodeJson(dictDecodeJson1))
          };
      };
  };
  var decodeList = function (dictDecodeJson) {
      return {
          decodeJson: Data_Argonaut_Decode_Decoders.decodeList(decodeJson(dictDecodeJson))
      };
  };
  var decodeMap = function (dictOrd) {
      return function (dictDecodeJson) {
          return function (dictDecodeJson1) {
              return {
                  decodeJson: Data_Argonaut_Decode_Decoders.decodeMap(dictOrd)(decodeJson(dictDecodeJson))(decodeJson(dictDecodeJson1))
              };
          };
      };
  };
  var decodeFieldMaybe = function (dictDecodeJson) {
      return {
          decodeJsonField: function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return Data_Maybe.Just.create(new Data_Either.Right(Data_Maybe.Nothing.value));
              };
              if (v instanceof Data_Maybe.Just) {
                  return Data_Maybe.Just.create(decodeJson(decodeJsonMaybe(dictDecodeJson))(v.value0));
              };
              throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 139, column 1 - line 143, column 49): " + [ v.constructor.name ]);
          }
      };
  };
  var decodeFieldId = function (dictDecodeJson) {
      return {
          decodeJsonField: function (j) {
              return Data_Functor.map(Data_Maybe.functorMaybe)(decodeJson(dictDecodeJson))(j);
          }
      };
  };
  exports["decodeJson"] = decodeJson;
  exports["decodeJsonTuple"] = decodeJsonTuple;
  exports["decodeJsonNumber"] = decodeJsonNumber;
  exports["decodeJsonInt"] = decodeJsonInt;
  exports["decodeJsonString"] = decodeJsonString;
  exports["decodeList"] = decodeList;
  exports["decodeMap"] = decodeMap;
  exports["decodeRecord"] = decodeRecord;
  exports["gDecodeJsonNil"] = gDecodeJsonNil;
  exports["gDecodeJsonCons"] = gDecodeJsonCons;
  exports["decodeFieldMaybe"] = decodeFieldMaybe;
  exports["decodeFieldId"] = decodeFieldId;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Alt"] = $PS["Control.Alt"] || {};
  var exports = $PS["Control.Alt"];
  var alt = function (dict) {
      return dict.alt;
  };
  exports["alt"] = alt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Types.Generic"] = $PS["Data.Argonaut.Types.Generic"] || {};
  var exports = $PS["Data.Argonaut.Types.Generic"];
  var defaultEncoding = {
      tagKey: "tag",
      valuesKey: "values",
      unwrapSingleArguments: false
  };
  exports["defaultEncoding"] = defaultEncoding;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Generic.Rep"] = $PS["Data.Generic.Rep"] || {};
  var exports = $PS["Data.Generic.Rep"];             
  var Inl = (function () {
      function Inl(value0) {
          this.value0 = value0;
      };
      Inl.create = function (value0) {
          return new Inl(value0);
      };
      return Inl;
  })();
  var Inr = (function () {
      function Inr(value0) {
          this.value0 = value0;
      };
      Inr.create = function (value0) {
          return new Inr(value0);
      };
      return Inr;
  })();
  var NoArguments = (function () {
      function NoArguments() {

      };
      NoArguments.value = new NoArguments();
      return NoArguments;
  })();
  var Argument = function (x) {
      return x;
  };
  var to = function (dict) {
      return dict.to;
  };
  var from = function (dict) {
      return dict.from;
  };
  exports["to"] = to;
  exports["from"] = from;
  exports["NoArguments"] = NoArguments;
  exports["Inl"] = Inl;
  exports["Inr"] = Inr;
  exports["Argument"] = Argument;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Decode.Generic"] = $PS["Data.Argonaut.Decode.Generic"] || {};
  var exports = $PS["Data.Argonaut.Decode.Generic"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Argonaut_Types_Generic = $PS["Data.Argonaut.Types.Generic"];
  var Data_Array = $PS["Data.Array"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var withTag = function (e) {
      return function (j) {
          return function (name) {
              var decodingErr = Data_Argonaut_Decode_Error.Named.create(name);
              return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(decodingErr(new Data_Argonaut_Decode_Error.TypeMismatch("Object")))(Data_Argonaut_Core.toObject(j)))(function (jObj) {
                  return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(decodingErr(new Data_Argonaut_Decode_Error.AtKey(e.tagKey, Data_Argonaut_Decode_Error.MissingValue.value)))(Foreign_Object.lookup(e.tagKey)(jObj)))(function (jTag) {
                      return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(decodingErr(Data_Argonaut_Decode_Error.AtKey.create(e.tagKey)(new Data_Argonaut_Decode_Error.TypeMismatch("String"))))(Data_Argonaut_Core.toString(jTag)))(function (tag) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(Control_Applicative.when(Data_Either.applicativeEither)(tag !== name)(Data_Either.Left.create(decodingErr(Data_Argonaut_Decode_Error.AtKey.create(e.tagKey)(Data_Argonaut_Decode_Error.UnexpectedValue.create(Data_Argonaut_Core.fromString(tag)))))))(function () {
                              return Control_Applicative.pure(Data_Either.applicativeEither)({
                                  tag: tag,
                                  decodingErr: decodingErr
                              });
                          });
                      });
                  });
              });
          };
      };
  };
  var withTagAndValues = function (e) {
      return function (j) {
          return function (name) {
              return Control_Bind.bind(Data_Either.bindEither)(withTag(e)(j)(name))(function (v) {
                  return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(v.decodingErr(new Data_Argonaut_Decode_Error.TypeMismatch("Object")))(Data_Argonaut_Core.toObject(j)))(function (jObj) {
                      return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(v.decodingErr(new Data_Argonaut_Decode_Error.AtKey(e.valuesKey, Data_Argonaut_Decode_Error.MissingValue.value)))(Foreign_Object.lookup(e.valuesKey)(jObj)))(function (values) {
                          return Control_Applicative.pure(Data_Either.applicativeEither)({
                              tag: v.tag,
                              values: values,
                              decodingErr: v.decodingErr
                          });
                      });
                  });
              });
          };
      };
  };
  var decodeRepWith = function (dict) {
      return dict.decodeRepWith;
  };
  var genericDecodeJsonWith = function (dictGeneric) {
      return function (dictDecodeRep) {
          return function (e) {
              var $64 = Data_Functor.map(Data_Either.functorEither)(Data_Generic_Rep.to(dictGeneric));
              var $65 = decodeRepWith(dictDecodeRep)(e);
              return function ($66) {
                  return $64($65($66));
              };
          };
      };
  };
  var genericDecodeJson = function (dictGeneric) {
      return function (dictDecodeRep) {
          return genericDecodeJsonWith(dictGeneric)(dictDecodeRep)(Data_Argonaut_Types_Generic.defaultEncoding);
      };
  };
  var decodeRepSum = function (dictDecodeRep) {
      return function (dictDecodeRep1) {
          return {
              decodeRepWith: function (e) {
                  return function (j) {
                      return Control_Alt.alt(Data_Either.altEither)(Data_Functor.map(Data_Either.functorEither)(Data_Generic_Rep.Inl.create)(decodeRepWith(dictDecodeRep)(e)(j)))(Data_Functor.map(Data_Either.functorEither)(Data_Generic_Rep.Inr.create)(decodeRepWith(dictDecodeRep1)(e)(j)));
                  };
              }
          };
      };
  };
  var decodeRepArgsNoArguments = {
      decodeRepArgs: function (js) {
          return new Data_Either.Right({
              init: Data_Generic_Rep.NoArguments.value,
              rest: js
          });
      }
  };
  var decodeRepArgsArgument = function (dictDecodeJson) {
      return {
          decodeRepArgs: function (js) {
              return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(new Data_Argonaut_Decode_Error.TypeMismatch("NonEmptyArray"))(Data_Array.uncons(js)))(function (v) {
                  return Data_Functor.map(Data_Either.functorEither)(function ($67) {
                      return (function (v1) {
                          return {
                              init: v1,
                              rest: v.tail
                          };
                      })(Data_Generic_Rep.Argument($67));
                  })(Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson)(v.head));
              });
          }
      };
  };
  var decodeRepArgs = function (dict) {
      return dict.decodeRepArgs;
  };
  var construct = function (dictDecodeRepArgs) {
      return function (e) {
          return function (valuesArray) {
              return function (decodingErr) {
                  return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(decodingErr)(decodeRepArgs(dictDecodeRepArgs)(valuesArray)))(function (v) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(Control_Applicative.when(Data_Either.applicativeEither)(Data_Eq.notEq(Data_Eq.eqArray(Data_Argonaut_Core.eqJson))(v.rest)([  ]))(Data_Either.Left.create(decodingErr(Data_Argonaut_Decode_Error.AtKey.create(e.valuesKey)(new Data_Argonaut_Decode_Error.UnexpectedValue(Data_Argonaut_Core.fromArray(v.rest)))))))(function () {
                          return Control_Applicative.pure(Data_Either.applicativeEither)(v.init);
                      });
                  });
              };
          };
      };
  };
  var decodeRepConstructorArg = function (dictIsSymbol) {
      return function (dictDecodeJson) {
          return {
              decodeRepWith: function (e) {
                  return function (j) {
                      var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                      return Control_Bind.bind(Data_Either.bindEither)(withTagAndValues(e)(j)(name))(function (v) {
                          if (e.unwrapSingleArguments) {
                              return construct(decodeRepArgsArgument(dictDecodeJson))(e)([ v.values ])(v.decodingErr);
                          };
                          return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(v.decodingErr(Data_Argonaut_Decode_Error.AtKey.create(e.valuesKey)(new Data_Argonaut_Decode_Error.TypeMismatch("Array"))))(Data_Argonaut_Core.toArray(v.values)))(function (valuesArray) {
                              return construct(decodeRepArgsArgument(dictDecodeJson))(e)(valuesArray)(v.decodingErr);
                          });
                      });
                  };
              }
          };
      };
  };
  var decodeRepConstructorNoArgs = function (dictIsSymbol) {
      return {
          decodeRepWith: function (e) {
              return function (j) {
                  var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                  return Control_Bind.bind(Data_Either.bindEither)(withTag(e)(j)(name))(function (v) {
                      return construct(decodeRepArgsNoArguments)(e)([  ])(v.decodingErr);
                  });
              };
          }
      };
  };
  exports["genericDecodeJson"] = genericDecodeJson;
  exports["decodeRepSum"] = decodeRepSum;
  exports["decodeRepConstructorNoArgs"] = decodeRepConstructorNoArgs;
  exports["decodeRepConstructorArg"] = decodeRepConstructorArg;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Encode.Encoders"] = $PS["Data.Argonaut.Encode.Encoders"] || {};
  var exports = $PS["Data.Argonaut.Encode.Encoders"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];                        
  var encodeTuple = function (encoderA) {
      return function (encoderB) {
          return function (v) {
              return Data_Argonaut_Core.fromArray([ encoderA(v.value0), encoderB(v.value1) ]);
          };
      };
  };
  var encodeString = Data_Argonaut_Core.fromString;
  var encodeNumber = Data_Argonaut_Core.fromNumber;
  var encodeMaybe = function (encoder) {
      return function (v) {
          if (v instanceof Data_Maybe.Nothing) {
              return Data_Argonaut_Core.jsonNull;
          };
          if (v instanceof Data_Maybe.Just) {
              return encoder(v.value0);
          };
          throw new Error("Failed pattern match at Data.Argonaut.Encode.Encoders (line 31, column 23 - line 33, column 22): " + [ v.constructor.name ]);
      };
  };
  var encodeList = function (encoder) {
      var $37 = Data_Functor.map(Data_Functor.functorArray)(encoder);
      var $38 = Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray);
      return function ($39) {
          return Data_Argonaut_Core.fromArray($37($38($39)));
      };
  };
  var encodeMap = function (dictOrd) {
      return function (encoderA) {
          return function (encoderB) {
              var $40 = encodeList(encodeTuple(encoderA)(encoderB));
              var $41 = Data_Map_Internal.toUnfoldable(Data_List_Types.unfoldableList);
              return function ($42) {
                  return $40($41($42));
              };
          };
      };
  };
  var encodeInt = function ($48) {
      return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($48));
  };
  exports["encodeMaybe"] = encodeMaybe;
  exports["encodeTuple"] = encodeTuple;
  exports["encodeNumber"] = encodeNumber;
  exports["encodeInt"] = encodeInt;
  exports["encodeString"] = encodeString;
  exports["encodeList"] = encodeList;
  exports["encodeMap"] = encodeMap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Encode.Class"] = $PS["Data.Argonaut.Encode.Class"] || {};
  var exports = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Encode_Encoders = $PS["Data.Argonaut.Encode.Encoders"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record = $PS["Record"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var gEncodeJsonNil = {
      gEncodeJson: function (v) {
          return function (v1) {
              return Foreign_Object.empty;
          };
      }
  };
  var gEncodeJson = function (dict) {
      return dict.gEncodeJson;
  };
  var encodeRecord = function (dictGEncodeJson) {
      return function (dictRowToList) {
          return {
              encodeJson: function (rec) {
                  return Data_Argonaut_Core.fromObject(gEncodeJson(dictGEncodeJson)(rec)(Type_Proxy["Proxy"].value));
              }
          };
      };
  };
  var encodeJsonJString = {
      encodeJson: Data_Argonaut_Encode_Encoders.encodeString
  };
  var encodeJsonJNumber = {
      encodeJson: Data_Argonaut_Encode_Encoders.encodeNumber
  };
  var encodeJsonInt = {
      encodeJson: Data_Argonaut_Encode_Encoders.encodeInt
  };
  var encodeJson = function (dict) {
      return dict.encodeJson;
  };
  var encodeJsonList = function (dictEncodeJson) {
      return {
          encodeJson: Data_Argonaut_Encode_Encoders.encodeList(encodeJson(dictEncodeJson))
      };
  };
  var encodeJsonMaybe = function (dictEncodeJson) {
      return {
          encodeJson: Data_Argonaut_Encode_Encoders.encodeMaybe(encodeJson(dictEncodeJson))
      };
  };
  var encodeJsonTuple = function (dictEncodeJson) {
      return function (dictEncodeJson1) {
          return {
              encodeJson: Data_Argonaut_Encode_Encoders.encodeTuple(encodeJson(dictEncodeJson))(encodeJson(dictEncodeJson1))
          };
      };
  };
  var encodeMap = function (dictOrd) {
      return function (dictEncodeJson) {
          return function (dictEncodeJson1) {
              return {
                  encodeJson: Data_Argonaut_Encode_Encoders.encodeMap(dictOrd)(encodeJson(dictEncodeJson))(encodeJson(dictEncodeJson1))
              };
          };
      };
  };
  var gEncodeJsonCons = function (dictEncodeJson) {
      return function (dictGEncodeJson) {
          return function (dictIsSymbol) {
              return function (dictCons) {
                  return {
                      gEncodeJson: function (row) {
                          return function (v) {
                              return Foreign_Object.insert(Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value))(encodeJson(dictEncodeJson)(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(row)))(gEncodeJson(dictGEncodeJson)(row)(Type_Proxy["Proxy"].value));
                          };
                      }
                  };
              };
          };
      };
  };
  exports["encodeJson"] = encodeJson;
  exports["encodeJsonMaybe"] = encodeJsonMaybe;
  exports["encodeJsonTuple"] = encodeJsonTuple;
  exports["encodeJsonJNumber"] = encodeJsonJNumber;
  exports["encodeJsonInt"] = encodeJsonInt;
  exports["encodeJsonJString"] = encodeJsonJString;
  exports["encodeJsonList"] = encodeJsonList;
  exports["encodeMap"] = encodeMap;
  exports["encodeRecord"] = encodeRecord;
  exports["gEncodeJsonNil"] = gEncodeJsonNil;
  exports["gEncodeJsonCons"] = gEncodeJsonCons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Encode.Generic"] = $PS["Data.Argonaut.Encode.Generic"] || {};
  var exports = $PS["Data.Argonaut.Encode.Generic"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Types_Generic = $PS["Data.Argonaut.Types.Generic"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var encodeRepWith = function (dict) {
      return dict.encodeRepWith;
  };
  var genericEncodeJsonWith = function (dictGeneric) {
      return function (dictEncodeRep) {
          return function (e) {
              var $50 = encodeRepWith(dictEncodeRep)(e);
              var $51 = Data_Generic_Rep.from(dictGeneric);
              return function ($52) {
                  return $50($51($52));
              };
          };
      };
  };
  var genericEncodeJson = function (dictGeneric) {
      return function (dictEncodeRep) {
          return genericEncodeJsonWith(dictGeneric)(dictEncodeRep)(Data_Argonaut_Types_Generic.defaultEncoding);
      };
  };
  var encodeRepSum = function (dictEncodeRep) {
      return function (dictEncodeRep1) {
          return {
              encodeRepWith: function (e) {
                  return function (v) {
                      if (v instanceof Data_Generic_Rep.Inl) {
                          return encodeRepWith(dictEncodeRep)(e)(v.value0);
                      };
                      if (v instanceof Data_Generic_Rep.Inr) {
                          return encodeRepWith(dictEncodeRep1)(e)(v.value0);
                      };
                      throw new Error("Failed pattern match at Data.Argonaut.Encode.Generic (line 36, column 1 - line 38, column 50): " + [ e.constructor.name, v.constructor.name ]);
                  };
              }
          };
      };
  };
  var encodeRepArgsNoArguments = {
      encodeRepArgs: function (v) {
          return [  ];
      }
  };
  var encodeRepArgsArgument = function (dictEncodeJson) {
      return {
          encodeRepArgs: function (v) {
              return [ Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson)(v) ];
          }
      };
  };
  var encodeRepArgs = function (dict) {
      return dict.encodeRepArgs;
  };
  var encodeRepConstructor = function (dictIsSymbol) {
      return function (dictEncodeRepArgs) {
          return {
              encodeRepWith: function (e) {
                  return function (v) {
                      var values = (function () {
                          var vs = encodeRepArgs(dictEncodeRepArgs)(v);
                          if (e.unwrapSingleArguments) {
                              if (vs.length === 1) {
                                  return vs[0];
                              };
                              return Data_Argonaut_Core.fromArray(vs);
                          };
                          return Data_Argonaut_Core.fromArray(vs);
                      })();
                      return Data_Argonaut_Core.fromObject(Foreign_Object.insert(e.tagKey)(Data_Argonaut_Core.fromString(Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value)))(Foreign_Object.insert(e.valuesKey)(values)(Foreign_Object.empty)));
                  };
              }
          };
      };
  };
  exports["genericEncodeJson"] = genericEncodeJson;
  exports["encodeRepSum"] = encodeRepSum;
  exports["encodeRepConstructor"] = encodeRepConstructor;
  exports["encodeRepArgsNoArguments"] = encodeRepArgsNoArguments;
  exports["encodeRepArgsArgument"] = encodeRepArgsArgument;
})(PS);
(function(exports) {
  "use strict";

  exports.clientX = function (e) {
    return e.clientX;
  };

  exports.clientY = function (e) {
    return e.clientY;
  };

  exports.shiftKey = function (e) {
    return e.shiftKey;
  };
})(PS["Web.UIEvent.MouseEvent"] = PS["Web.UIEvent.MouseEvent"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.UIEvent.MouseEvent"] = $PS["Web.UIEvent.MouseEvent"] || {};
  var exports = $PS["Web.UIEvent.MouseEvent"];
  var $foreign = $PS["Web.UIEvent.MouseEvent"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];  
  var toEvent = Unsafe_Coerce.unsafeCoerce;
  exports["toEvent"] = toEvent;
  exports["clientX"] = $foreign.clientX;
  exports["clientY"] = $foreign.clientY;
  exports["shiftKey"] = $foreign.shiftKey;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.DragState"] = $PS["App.Control.DragState"] || {};
  var exports = $PS["App.Control.DragState"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Decode_Generic = $PS["Data.Argonaut.Decode.Generic"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Encode_Generic = $PS["Data.Argonaut.Encode.Generic"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Int = $PS["Data.Int"];
  var Web_UIEvent_MouseEvent = $PS["Web.UIEvent.MouseEvent"];                
  var Hooked = (function () {
      function Hooked(value0) {
          this.value0 = value0;
      };
      Hooked.create = function (value0) {
          return new Hooked(value0);
      };
      return Hooked;
  })();
  var Dragging = (function () {
      function Dragging() {

      };
      Dragging.value = new Dragging();
      return Dragging;
  })();
  var toDragData = function (state) {
      return function (event) {
          var y = Data_Int.toNumber(Web_UIEvent_MouseEvent.clientY(event));
          var x = Data_Int.toNumber(Web_UIEvent_MouseEvent.clientX(event));
          return {
              x: x,
              y: y,
              dx: x - state.lastMouseX,
              dy: y - state.lastMouseY
          };
      };
  };
  var genericDragMode = {
      to: function (x) {
          if (x instanceof Data_Generic_Rep.Inl) {
              return new Hooked(x.value0);
          };
          if (x instanceof Data_Generic_Rep.Inr) {
              return Dragging.value;
          };
          throw new Error("Failed pattern match at App.Control.DragState (line 13, column 1 - line 13, column 54): " + [ x.constructor.name ]);
      },
      from: function (x) {
          if (x instanceof Hooked) {
              return new Data_Generic_Rep.Inl(x.value0);
          };
          if (x instanceof Dragging) {
              return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
          };
          throw new Error("Failed pattern match at App.Control.DragState (line 13, column 1 - line 13, column 54): " + [ x.constructor.name ]);
      }
  };
  var eqDragMode = {
      eq: function (x) {
          return function (y) {
              if (x instanceof Hooked && y instanceof Hooked) {
                  return x.value0 === y.value0;
              };
              if (x instanceof Dragging && y instanceof Dragging) {
                  return true;
              };
              return false;
          };
      }
  };
  var encodeDragMode = {
      encodeJson: Data_Argonaut_Encode_Generic.genericEncodeJson(genericDragMode)(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Hooked";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(Data_Argonaut_Encode_Class.encodeJsonJNumber)))(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Dragging";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsNoArguments)))
  };
  var decodeDragMode = {
      decodeJson: Data_Argonaut_Decode_Generic.genericDecodeJson(genericDragMode)(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "Hooked";
          }
      })(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Generic.decodeRepConstructorNoArgs({
          reflectSymbol: function () {
              return "Dragging";
          }
      })))
  };
  exports["Hooked"] = Hooked;
  exports["Dragging"] = Dragging;
  exports["toDragData"] = toDragData;
  exports["eqDragMode"] = eqDragMode;
  exports["encodeDragMode"] = encodeDragMode;
  exports["decodeDragMode"] = decodeDragMode;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.ResizeState"] = $PS["App.Control.ResizeState"] || {};
  var exports = $PS["App.Control.ResizeState"];
  var Data_Argonaut_Decode_Generic = $PS["Data.Argonaut.Decode.Generic"];
  var Data_Argonaut_Encode_Generic = $PS["Data.Argonaut.Encode.Generic"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];                  
  var EW = (function () {
      function EW() {

      };
      EW.value = new EW();
      return EW;
  })();
  var NS = (function () {
      function NS() {

      };
      NS.value = new NS();
      return NS;
  })();
  var genericDirection = {
      to: function (x) {
          if (x instanceof Data_Generic_Rep.Inl) {
              return EW.value;
          };
          if (x instanceof Data_Generic_Rep.Inr) {
              return NS.value;
          };
          throw new Error("Failed pattern match at App.Control.ResizeState (line 11, column 1 - line 11, column 56): " + [ x.constructor.name ]);
      },
      from: function (x) {
          if (x instanceof EW) {
              return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
          };
          if (x instanceof NS) {
              return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
          };
          throw new Error("Failed pattern match at App.Control.ResizeState (line 11, column 1 - line 11, column 56): " + [ x.constructor.name ]);
      }
  };
  var encodeDirection = {
      encodeJson: Data_Argonaut_Encode_Generic.genericEncodeJson(genericDirection)(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "EW";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsNoArguments))(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "NS";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsNoArguments)))
  };
  var directionEq = {
      eq: function (x) {
          return function (y) {
              if (x instanceof EW && y instanceof EW) {
                  return true;
              };
              if (x instanceof NS && y instanceof NS) {
                  return true;
              };
              return false;
          };
      }
  };
  var decodeDirection = {
      decodeJson: Data_Argonaut_Decode_Generic.genericDecodeJson(genericDirection)(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorNoArgs({
          reflectSymbol: function () {
              return "EW";
          }
      }))(Data_Argonaut_Decode_Generic.decodeRepConstructorNoArgs({
          reflectSymbol: function () {
              return "NS";
          }
      })))
  };
  exports["EW"] = EW;
  exports["NS"] = NS;
  exports["directionEq"] = directionEq;
  exports["encodeDirection"] = encodeDirection;
  exports["decodeDirection"] = decodeDirection;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["CSS.String"] = $PS["CSS.String"] || {};
  var exports = $PS["CSS.String"];
  var fromString = function (dict) {
      return dict.fromString;
  };
  exports["fromString"] = fromString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Profunctor"] = $PS["Data.Profunctor"] || {};
  var exports = $PS["Data.Profunctor"];                  
  var profunctorFn = {
      dimap: function (a2b) {
          return function (c2d) {
              return function (b2c) {
                  return function ($10) {
                      return c2d(b2c(a2b($10)));
                  };
              };
          };
      }
  };
  exports["profunctorFn"] = profunctorFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Profunctor.Strong"] = $PS["Data.Profunctor.Strong"] || {};
  var exports = $PS["Data.Profunctor.Strong"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var strongFn = {
      first: function (a2b) {
          return function (v) {
              return new Data_Tuple.Tuple(a2b(v.value0), v.value1);
          };
      },
      second: Data_Functor.map(Data_Tuple.functorTuple),
      Profunctor0: function () {
          return Data_Profunctor.profunctorFn;
      }
  };
  var second = function (dict) {
      return dict.second;
  };
  exports["second"] = second;
  exports["strongFn"] = strongFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["CSS.Property"] = $PS["CSS.Property"] || {};
  var exports = $PS["CSS.Property"];
  var CSS_String = $PS["CSS.String"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Profunctor_Strong = $PS["Data.Profunctor.Strong"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Show = $PS["Data.Show"];                  
  var Prefixed = (function () {
      function Prefixed(value0) {
          this.value0 = value0;
      };
      Prefixed.create = function (value0) {
          return new Prefixed(value0);
      };
      return Prefixed;
  })();
  var Plain = (function () {
      function Plain(value0) {
          this.value0 = value0;
      };
      Plain.create = function (value0) {
          return new Plain(value0);
      };
      return Plain;
  })();
  var Value = function (x) {
      return x;
  };
  var Key = function (x) {
      return x;
  };
  var value = function (dict) {
      return dict.value;
  };
  var semigroupPrefixed = {
      append: function (v) {
          return function (v1) {
              if (v instanceof Plain && v1 instanceof Plain) {
                  return Plain.create(v.value0 + v1.value0);
              };
              if (v instanceof Plain && v1 instanceof Prefixed) {
                  return Prefixed.create(Data_Functor.map(Data_Functor.functorArray)(Data_Profunctor_Strong.second(Data_Profunctor_Strong.strongFn)(function (v2) {
                      return v.value0 + v2;
                  }))(v1.value0));
              };
              if (v instanceof Prefixed && v1 instanceof Plain) {
                  return Prefixed.create(Data_Functor.map(Data_Functor.functorArray)(Data_Profunctor_Strong.second(Data_Profunctor_Strong.strongFn)(function (v2) {
                      return v1.value0 + v2;
                  }))(v.value0));
              };
              if (v instanceof Prefixed && v1 instanceof Prefixed) {
                  return Prefixed.create(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value0)(v1.value0));
              };
              throw new Error("Failed pattern match at CSS.Property (line 23, column 1 - line 27, column 59): " + [ v.constructor.name, v1.constructor.name ]);
          };
      }
  };
  var semigroupValue = {
      append: function (v) {
          return function (v1) {
              return Value(Data_Semigroup.append(semigroupPrefixed)(v)(v1));
          };
      }
  };
  var isStringPrefixed = {
      fromString: Plain.create
  };
  var isStringValue = {
      fromString: (function () {
          var $103 = CSS_String.fromString(isStringPrefixed);
          return function ($104) {
              return Value($103($104));
          };
      })()
  };
  var valNumber = {
      value: (function () {
          var $114 = CSS_String.fromString(isStringValue);
          var $115 = Data_Show.show(Data_Show.showNumber);
          return function ($116) {
              return $114($115($116));
          };
      })()
  };
  var isStringKey = {
      fromString: (function () {
          var $117 = CSS_String.fromString(isStringPrefixed);
          return function ($118) {
              return Key($117($118));
          };
      })()
  };
  var cast = function (v) {
      return v;
  };
  exports["value"] = value;
  exports["Prefixed"] = Prefixed;
  exports["Plain"] = Plain;
  exports["cast"] = cast;
  exports["isStringKey"] = isStringKey;
  exports["isStringValue"] = isStringValue;
  exports["semigroupValue"] = semigroupValue;
  exports["valNumber"] = valNumber;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Writer.Trans"] = $PS["Control.Monad.Writer.Trans"] || {};
  var exports = $PS["Control.Monad.Writer.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];                      
  var WriterT = function (x) {
      return x;
  };
  var runWriterT = function (v) {
      return v;
  };
  var mapWriterT = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var functorWriterT = function (dictFunctor) {
      return {
          map: function (f) {
              return mapWriterT(Data_Functor.map(dictFunctor)(function (v) {
                  return new Data_Tuple.Tuple(f(v.value0), v.value1);
              }));
          }
      };
  };
  var applyWriterT = function (dictSemigroup) {
      return function (dictApply) {
          return {
              apply: function (v) {
                  return function (v1) {
                      var k = function (v3) {
                          return function (v4) {
                              return new Data_Tuple.Tuple(v3.value0(v4.value0), Data_Semigroup.append(dictSemigroup)(v3.value1)(v4.value1));
                          };
                      };
                      return Control_Apply.apply(dictApply)(Data_Functor.map(dictApply.Functor0())(k)(v))(v1);
                  };
              },
              Functor0: function () {
                  return functorWriterT(dictApply.Functor0());
              }
          };
      };
  };
  var bindWriterT = function (dictSemigroup) {
      return function (dictBind) {
          return {
              bind: function (v) {
                  return function (k) {
                      return WriterT(Control_Bind.bind(dictBind)(v)(function (v1) {
                          var v2 = k(v1.value0);
                          return Data_Functor.map((dictBind.Apply0()).Functor0())(function (v3) {
                              return new Data_Tuple.Tuple(v3.value0, Data_Semigroup.append(dictSemigroup)(v1.value1)(v3.value1));
                          })(v2);
                      }));
                  };
              },
              Apply0: function () {
                  return applyWriterT(dictSemigroup)(dictBind.Apply0());
              }
          };
      };
  };
  var applicativeWriterT = function (dictMonoid) {
      return function (dictApplicative) {
          return {
              pure: function (a) {
                  return WriterT(Control_Applicative.pure(dictApplicative)(new Data_Tuple.Tuple(a, Data_Monoid.mempty(dictMonoid))));
              },
              Apply0: function () {
                  return applyWriterT(dictMonoid.Semigroup0())(dictApplicative.Apply0());
              }
          };
      };
  };
  var monadWriterT = function (dictMonoid) {
      return function (dictMonad) {
          return {
              Applicative0: function () {
                  return applicativeWriterT(dictMonoid)(dictMonad.Applicative0());
              },
              Bind1: function () {
                  return bindWriterT(dictMonoid.Semigroup0())(dictMonad.Bind1());
              }
          };
      };
  };
  var monadTellWriterT = function (dictMonoid) {
      return function (dictMonad) {
          return {
              tell: (function () {
                  var $127 = Control_Applicative.pure(dictMonad.Applicative0());
                  var $128 = Data_Tuple.Tuple.create(Data_Unit.unit);
                  return function ($129) {
                      return WriterT($127($128($129)));
                  };
              })(),
              Semigroup0: dictMonoid.Semigroup0,
              Monad1: function () {
                  return monadWriterT(dictMonoid)(dictMonad);
              }
          };
      };
  };
  exports["runWriterT"] = runWriterT;
  exports["functorWriterT"] = functorWriterT;
  exports["applyWriterT"] = applyWriterT;
  exports["applicativeWriterT"] = applicativeWriterT;
  exports["bindWriterT"] = bindWriterT;
  exports["monadTellWriterT"] = monadTellWriterT;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Writer"] = $PS["Control.Monad.Writer"] || {};
  var exports = $PS["Control.Monad.Writer"];
  var Control_Monad_Writer_Trans = $PS["Control.Monad.Writer.Trans"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Tuple = $PS["Data.Tuple"];
  var runWriter = (function () {
      var $2 = Data_Newtype.unwrap();
      return function ($3) {
          return $2(Control_Monad_Writer_Trans.runWriterT($3));
      };
  })();
  var execWriter = function (m) {
      return Data_Tuple.snd(runWriter(m));
  };
  exports["execWriter"] = execWriter;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Writer.Class"] = $PS["Control.Monad.Writer.Class"] || {};
  var exports = $PS["Control.Monad.Writer.Class"];   
  var tell = function (dict) {
      return dict.tell;
  };
  exports["tell"] = tell;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Identity"] = $PS["Data.Identity"] || {};
  var exports = $PS["Data.Identity"];              
  var Identity = function (x) {
      return x;
  };
  var functorIdentity = {
      map: function (f) {
          return function (m) {
              return f(m);
          };
      }
  };
  var applyIdentity = {
      apply: function (v) {
          return function (v1) {
              return v(v1);
          };
      },
      Functor0: function () {
          return functorIdentity;
      }
  };
  var bindIdentity = {
      bind: function (v) {
          return function (f) {
              return f(v);
          };
      },
      Apply0: function () {
          return applyIdentity;
      }
  };
  var applicativeIdentity = {
      pure: Identity,
      Apply0: function () {
          return applyIdentity;
      }
  };
  var monadIdentity = {
      Applicative0: function () {
          return applicativeIdentity;
      },
      Bind1: function () {
          return bindIdentity;
      }
  };
  exports["functorIdentity"] = functorIdentity;
  exports["applyIdentity"] = applyIdentity;
  exports["applicativeIdentity"] = applicativeIdentity;
  exports["bindIdentity"] = bindIdentity;
  exports["monadIdentity"] = monadIdentity;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["CSS.Stylesheet"] = $PS["CSS.Stylesheet"] || {};
  var exports = $PS["CSS.Stylesheet"];
  var CSS_Property = $PS["CSS.Property"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Writer = $PS["Control.Monad.Writer"];
  var Control_Monad_Writer_Class = $PS["Control.Monad.Writer.Class"];
  var Control_Monad_Writer_Trans = $PS["Control.Monad.Writer.Trans"];
  var Data_Array = $PS["Data.Array"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Property = (function () {
      function Property(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Property.create = function (value0) {
          return function (value1) {
              return new Property(value0, value1);
          };
      };
      return Property;
  })();
  var S = function (x) {
      return x;
  };
  var runS = function (v) {
      return Control_Monad_Writer.execWriter(v);
  };
  var rule = (function () {
      var $289 = Control_Monad_Writer_Class.tell(Control_Monad_Writer_Trans.monadTellWriterT(Data_Monoid.monoidArray)(Data_Identity.monadIdentity));
      return function ($290) {
          return S($289(Data_Array.singleton($290)));
      };
  })();
  var key = function (dictVal) {
      return function (k) {
          return function (v) {
              return rule(new Property(CSS_Property.cast(k), CSS_Property.value(dictVal)(v)));
          };
      };
  };
  var functorStyleM = {
      map: function (f) {
          return function (v) {
              return S(Data_Functor.map(Control_Monad_Writer_Trans.functorWriterT(Data_Identity.functorIdentity))(f)(v));
          };
      }
  };
  var applyStyleM = {
      apply: function (v) {
          return function (v1) {
              return S(Control_Apply.apply(Control_Monad_Writer_Trans.applyWriterT(Data_Semigroup.semigroupArray)(Data_Identity.applyIdentity))(v)(v1));
          };
      },
      Functor0: function () {
          return functorStyleM;
      }
  };
  var bindStyleM = {
      bind: function (v) {
          return function (f) {
              return S(Control_Bind.bind(Control_Monad_Writer_Trans.bindWriterT(Data_Semigroup.semigroupArray)(Data_Identity.bindIdentity))(v)(function ($295) {
                  return (function (v1) {
                      return v1;
                  })(f($295));
              }));
          };
      },
      Apply0: function () {
          return applyStyleM;
      }
  };
  var semigroupCSS = {
      append: Control_Apply.applySecond(applyStyleM)
  };
  var applicativeStyleM = {
      pure: (function () {
          var $296 = Control_Applicative.pure(Control_Monad_Writer_Trans.applicativeWriterT(Data_Monoid.monoidArray)(Data_Identity.applicativeIdentity));
          return function ($297) {
              return S($296($297));
          };
      })(),
      Apply0: function () {
          return applyStyleM;
      }
  };
  exports["Property"] = Property;
  exports["runS"] = runS;
  exports["key"] = key;
  exports["bindStyleM"] = bindStyleM;
  exports["applicativeStyleM"] = applicativeStyleM;
  exports["semigroupCSS"] = semigroupCSS;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["CSS.Display"] = $PS["CSS.Display"] || {};
  var exports = $PS["CSS.Display"];
  var CSS_Property = $PS["CSS.Property"];
  var CSS_String = $PS["CSS.String"];
  var CSS_Stylesheet = $PS["CSS.Stylesheet"];      
  var Position = function (x) {
      return x;
  };   
  var valPosition = {
      value: function (v) {
          return v;
      }
  };                                                                              
  var $$static = Position(CSS_String.fromString(CSS_Property.isStringValue)("static"));  
  var position = CSS_Stylesheet.key(valPosition)(CSS_String.fromString(CSS_Property.isStringKey)("position"));
  var absolute = Position(CSS_String.fromString(CSS_Property.isStringValue)("absolute"));
  exports["position"] = position;
  exports["static"] = $$static;
  exports["absolute"] = absolute;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["CSS.Size"] = $PS["CSS.Size"] || {};
  var exports = $PS["CSS.Size"];
  var CSS_Property = $PS["CSS.Property"];
  var CSS_String = $PS["CSS.String"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var valSize = {
      value: function (v) {
          return v;
      }
  };
  var px = function (i) {
      return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("px"));
  };
  exports["px"] = px;
  exports["valSize"] = valSize;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["CSS.Geometry"] = $PS["CSS.Geometry"] || {};
  var exports = $PS["CSS.Geometry"];
  var CSS_Property = $PS["CSS.Property"];
  var CSS_Size = $PS["CSS.Size"];
  var CSS_String = $PS["CSS.String"];
  var CSS_Stylesheet = $PS["CSS.Stylesheet"];                                                                
  var top = CSS_Stylesheet.key(CSS_Size.valSize)(CSS_String.fromString(CSS_Property.isStringKey)("top"));             
  var maxWidth = CSS_Stylesheet.key(CSS_Size.valSize)(CSS_String.fromString(CSS_Property.isStringKey)("max-width"));
  var maxHeight = CSS_Stylesheet.key(CSS_Size.valSize)(CSS_String.fromString(CSS_Property.isStringKey)("max-height"));  
  var left = CSS_Stylesheet.key(CSS_Size.valSize)(CSS_String.fromString(CSS_Property.isStringKey)("left"));
  exports["maxWidth"] = maxWidth;
  exports["maxHeight"] = maxHeight;
  exports["top"] = top;
  exports["left"] = left;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Data.NodeCommon"] = $PS["App.Data.NodeCommon"] || {};
  var exports = $PS["App.Data.NodeCommon"];
  var CSS_Display = $PS["CSS.Display"];
  var CSS_Geometry = $PS["CSS.Geometry"];
  var CSS_Size = $PS["CSS.Size"];
  var CSS_Stylesheet = $PS["CSS.Stylesheet"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Decode_Generic = $PS["Data.Argonaut.Decode.Generic"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Encode_Generic = $PS["Data.Argonaut.Encode.Generic"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Show = $PS["Data.Show"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];                
  var Absolute = (function () {
      function Absolute(value0) {
          this.value0 = value0;
      };
      Absolute.create = function (value0) {
          return new Absolute(value0);
      };
      return Absolute;
  })();
  var Static = (function () {
      function Static() {

      };
      Static.value = new Static();
      return Static;
  })();
  var NodeId = function (x) {
      return x;
  };
  var Top = (function () {
      function Top(value0) {
          this.value0 = value0;
      };
      Top.create = function (value0) {
          return new Top(value0);
      };
      return Top;
  })();
  var NextSibling = (function () {
      function NextSibling(value0) {
          this.value0 = value0;
      };
      NextSibling.create = function (value0) {
          return new NextSibling(value0);
      };
      return NextSibling;
  })();
  var FirstChild = (function () {
      function FirstChild(value0) {
          this.value0 = value0;
      };
      FirstChild.create = function (value0) {
          return new FirstChild(value0);
      };
      return FirstChild;
  })();
  var positionToCSS = function (v) {
      if (v instanceof Absolute) {
          return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Display.position(CSS_Display.absolute))(function () {
              return Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Geometry.left(CSS_Size.px(v.value0.x)))(function () {
                  return CSS_Geometry.top(CSS_Size.px(v.value0.y));
              });
          });
      };
      if (v instanceof Static) {
          return CSS_Display.position(CSS_Display["static"]);
      };
      throw new Error("Failed pattern match at App.Data.NodeCommon (line 30, column 17 - line 36, column 28): " + [ v.constructor.name ]);
  };
  var nodeIdShow = Data_Show.showInt;
  var nodeIdOrd = Data_Ord.ordInt;
  var nodeIdEq = Data_Eq.eqInt;
  var nodeIdEncode = Data_Argonaut_Encode_Class.encodeJsonInt;
  var nodeIdDecode = Data_Argonaut_Decode_Class.decodeJsonInt;
  var nextId = function (v) {
      return NodeId(v + 1 | 0);
  };
  var maxWidthToCSS = function (v) {
      if (v instanceof Data_Maybe.Just) {
          return CSS_Geometry.maxWidth(CSS_Size.px(v.value0));
      };
      if (v instanceof Data_Maybe.Nothing) {
          return Control_Applicative.pure(CSS_Stylesheet.applicativeStyleM)(Data_Unit.unit);
      };
      throw new Error("Failed pattern match at App.Data.NodeCommon (line 40, column 3 - line 42, column 25): " + [ v.constructor.name ]);
  };
  var maxHeightToCSS = function (v) {
      if (v instanceof Data_Maybe.Just) {
          return CSS_Geometry.maxHeight(CSS_Size.px(v.value0));
      };
      if (v instanceof Data_Maybe.Nothing) {
          return Control_Applicative.pure(CSS_Stylesheet.applicativeStyleM)(Data_Unit.unit);
      };
      throw new Error("Failed pattern match at App.Data.NodeCommon (line 46, column 3 - line 48, column 25): " + [ v.constructor.name ]);
  };
  var genericNodePosition = {
      to: function (x) {
          if (x instanceof Data_Generic_Rep.Inl) {
              return new Absolute(x.value0);
          };
          if (x instanceof Data_Generic_Rep.Inr) {
              return Static.value;
          };
          throw new Error("Failed pattern match at App.Data.NodeCommon (line 25, column 1 - line 25, column 62): " + [ x.constructor.name ]);
      },
      from: function (x) {
          if (x instanceof Absolute) {
              return new Data_Generic_Rep.Inl(x.value0);
          };
          if (x instanceof Static) {
              return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
          };
          throw new Error("Failed pattern match at App.Data.NodeCommon (line 25, column 1 - line 25, column 62): " + [ x.constructor.name ]);
      }
  };
  var genericNodePath = {
      to: function (x) {
          if (x instanceof Data_Generic_Rep.Inl) {
              return new Top(x.value0);
          };
          if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
              return new NextSibling(x.value0.value0);
          };
          if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
              return new FirstChild(x.value0.value0);
          };
          throw new Error("Failed pattern match at App.Data.NodeCommon (line 56, column 1 - line 56, column 54): " + [ x.constructor.name ]);
      },
      from: function (x) {
          if (x instanceof Top) {
              return new Data_Generic_Rep.Inl(x.value0);
          };
          if (x instanceof NextSibling) {
              return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
          };
          if (x instanceof FirstChild) {
              return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
          };
          throw new Error("Failed pattern match at App.Data.NodeCommon (line 56, column 1 - line 56, column 54): " + [ x.constructor.name ]);
      }
  };
  var eqNodePath = {
      eq: function (x) {
          return function (y) {
              if (x instanceof Top && y instanceof Top) {
                  return Data_Eq.eq(Data_Tuple.eqTuple(Data_Eq.eqNumber)(Data_Eq.eqNumber))(x.value0)(y.value0);
              };
              if (x instanceof NextSibling && y instanceof NextSibling) {
                  return Data_Eq.eq(nodeIdEq)(x.value0)(y.value0);
              };
              if (x instanceof FirstChild && y instanceof FirstChild) {
                  return Data_Eq.eq(nodeIdEq)(x.value0)(y.value0);
              };
              return false;
          };
      }
  };
  var encodeNodePosition = {
      encodeJson: Data_Argonaut_Encode_Generic.genericEncodeJson(genericNodePosition)(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Absolute";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
          reflectSymbol: function () {
              return "y";
          }
      })())({
          reflectSymbol: function () {
              return "x";
          }
      })())())))(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Static";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsNoArguments)))
  };
  var encodeNodePath = {
      encodeJson: Data_Argonaut_Encode_Generic.genericEncodeJson(genericNodePath)(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Top";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(Data_Argonaut_Encode_Class.encodeJsonTuple(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.encodeJsonJNumber))))(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "NextSibling";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(nodeIdEncode)))(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "FirstChild";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(nodeIdEncode)))))
  };
  var decodeNodePosition = {
      decodeJson: Data_Argonaut_Decode_Generic.genericDecodeJson(genericNodePosition)(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "Absolute";
          }
      })(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
          reflectSymbol: function () {
              return "y";
          }
      })()())({
          reflectSymbol: function () {
              return "x";
          }
      })()())()))(Data_Argonaut_Decode_Generic.decodeRepConstructorNoArgs({
          reflectSymbol: function () {
              return "Static";
          }
      })))
  };
  var decodeNodePath = {
      decodeJson: Data_Argonaut_Decode_Generic.genericDecodeJson(genericNodePath)(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "Top";
          }
      })(Data_Argonaut_Decode_Class.decodeJsonTuple(Data_Argonaut_Decode_Class.decodeJsonNumber)(Data_Argonaut_Decode_Class.decodeJsonNumber)))(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "NextSibling";
          }
      })(nodeIdDecode))(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "FirstChild";
          }
      })(nodeIdDecode))))
  };
  exports["NodeId"] = NodeId;
  exports["nextId"] = nextId;
  exports["Absolute"] = Absolute;
  exports["Static"] = Static;
  exports["positionToCSS"] = positionToCSS;
  exports["maxWidthToCSS"] = maxWidthToCSS;
  exports["maxHeightToCSS"] = maxHeightToCSS;
  exports["Top"] = Top;
  exports["NextSibling"] = NextSibling;
  exports["FirstChild"] = FirstChild;
  exports["nodeIdEq"] = nodeIdEq;
  exports["nodeIdOrd"] = nodeIdOrd;
  exports["nodeIdShow"] = nodeIdShow;
  exports["nodeIdEncode"] = nodeIdEncode;
  exports["nodeIdDecode"] = nodeIdDecode;
  exports["encodeNodePosition"] = encodeNodePosition;
  exports["decodeNodePosition"] = decodeNodePosition;
  exports["eqNodePath"] = eqNodePath;
  exports["encodeNodePath"] = encodeNodePath;
  exports["decodeNodePath"] = decodeNodePath;
})(PS);
(function(exports) {
  "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.HeytingAlgebra"] = $PS["Data.HeytingAlgebra"] || {};
  var exports = $PS["Data.HeytingAlgebra"];
  var $foreign = $PS["Data.HeytingAlgebra"];
  var tt = function (dict) {
      return dict.tt;
  };
  var not = function (dict) {
      return dict.not;
  };
  var implies = function (dict) {
      return dict.implies;
  };
  var ff = function (dict) {
      return dict.ff;
  };
  var disj = function (dict) {
      return dict.disj;
  };
  var heytingAlgebraBoolean = {
      ff: false,
      tt: true,
      implies: function (a) {
          return function (b) {
              return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
          };
      },
      conj: $foreign.boolConj,
      disj: $foreign.boolDisj,
      not: $foreign.boolNot
  };
  var conj = function (dict) {
      return dict.conj;
  };
  var heytingAlgebraFunction = function (dictHeytingAlgebra) {
      return {
          ff: function (v) {
              return ff(dictHeytingAlgebra);
          },
          tt: function (v) {
              return tt(dictHeytingAlgebra);
          },
          implies: function (f) {
              return function (g) {
                  return function (a) {
                      return implies(dictHeytingAlgebra)(f(a))(g(a));
                  };
              };
          },
          conj: function (f) {
              return function (g) {
                  return function (a) {
                      return conj(dictHeytingAlgebra)(f(a))(g(a));
                  };
              };
          },
          disj: function (f) {
              return function (g) {
                  return function (a) {
                      return disj(dictHeytingAlgebra)(f(a))(g(a));
                  };
              };
          },
          not: function (f) {
              return function (a) {
                  return not(dictHeytingAlgebra)(f(a));
              };
          }
      };
  };
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
  exports["heytingAlgebraFunction"] = heytingAlgebraFunction;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.MapMode"] = $PS["App.Control.MapMode"] || {};
  var exports = $PS["App.Control.MapMode"];
  var App_Control_DragState = $PS["App.Control.DragState"];
  var App_Control_ResizeState = $PS["App.Control.ResizeState"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Decode_Generic = $PS["Data.Argonaut.Decode.Generic"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Encode_Generic = $PS["Data.Argonaut.Encode.Generic"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];                              
  var Idle = (function () {
      function Idle() {

      };
      Idle.value = new Idle();
      return Idle;
  })();
  var Drag = (function () {
      function Drag(value0) {
          this.value0 = value0;
      };
      Drag.create = function (value0) {
          return new Drag(value0);
      };
      return Drag;
  })();
  var Resize = (function () {
      function Resize(value0) {
          this.value0 = value0;
      };
      Resize.create = function (value0) {
          return new Resize(value0);
      };
      return Resize;
  })();
  var Editing = (function () {
      function Editing(value0) {
          this.value0 = value0;
      };
      Editing.create = function (value0) {
          return new Editing(value0);
      };
      return Editing;
  })();
  var modeGeneric = {
      to: function (x) {
          if (x instanceof Data_Generic_Rep.Inl) {
              return Idle.value;
          };
          if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
              return new Drag(x.value0.value0);
          };
          if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
              return new Resize(x.value0.value0.value0);
          };
          if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
              return new Editing(x.value0.value0.value0);
          };
          throw new Error("Failed pattern match at App.Control.MapMode (line 15, column 1 - line 15, column 46): " + [ x.constructor.name ]);
      },
      from: function (x) {
          if (x instanceof Idle) {
              return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
          };
          if (x instanceof Drag) {
              return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
          };
          if (x instanceof Resize) {
              return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
          };
          if (x instanceof Editing) {
              return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
          };
          throw new Error("Failed pattern match at App.Control.MapMode (line 15, column 1 - line 15, column 46): " + [ x.constructor.name ]);
      }
  };
  var modeEq = {
      eq: function (x) {
          return function (y) {
              if (x instanceof Idle && y instanceof Idle) {
                  return true;
              };
              if (x instanceof Drag && y instanceof Drag) {
                  return Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.eqNodePath))(x.value0.closestBeacon)(y.value0.closestBeacon) && x.value0.lastMouseX === y.value0.lastMouseX && x.value0.lastMouseY === y.value0.lastMouseY && Data_Eq.eq(App_Data_NodeCommon.nodeIdEq)(x.value0.nodeId)(y.value0.nodeId) && x.value0.nodeXOffset === y.value0.nodeXOffset && x.value0.nodeYOffset === y.value0.nodeYOffset && Data_Eq.eq(App_Control_DragState.eqDragMode)(x.value0.state)(y.value0.state);
              };
              if (x instanceof Resize && y instanceof Resize) {
                  return Data_Eq.eq(App_Control_ResizeState.directionEq)(x.value0.direction)(y.value0.direction) && x.value0.height === y.value0.height && Data_Eq.eq(App_Data_NodeCommon.nodeIdEq)(x.value0.id)(y.value0.id) && x.value0.width === y.value0.width && x.value0.x === y.value0.x && x.value0.y === y.value0.y;
              };
              if (x instanceof Editing && y instanceof Editing) {
                  return Data_Eq.eq(App_Data_NodeCommon.nodeIdEq)(x.value0)(y.value0);
              };
              return false;
          };
      }
  };
  var modeEncode = {
      encodeJson: Data_Argonaut_Encode_Generic.genericEncodeJson(modeGeneric)(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Idle";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsNoArguments))(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Drag";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(App_Data_NodeCommon.encodeNodePath))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Data_NodeCommon.nodeIdEncode)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Control_DragState.encodeDragMode)(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
          reflectSymbol: function () {
              return "state";
          }
      })())({
          reflectSymbol: function () {
              return "nodeYOffset";
          }
      })())({
          reflectSymbol: function () {
              return "nodeXOffset";
          }
      })())({
          reflectSymbol: function () {
              return "nodeId";
          }
      })())({
          reflectSymbol: function () {
              return "lastMouseY";
          }
      })())({
          reflectSymbol: function () {
              return "lastMouseX";
          }
      })())({
          reflectSymbol: function () {
              return "closestBeacon";
          }
      })())())))(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Resize";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Control_ResizeState.encodeDirection)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Data_NodeCommon.nodeIdEncode)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
          reflectSymbol: function () {
              return "y";
          }
      })())({
          reflectSymbol: function () {
              return "x";
          }
      })())({
          reflectSymbol: function () {
              return "width";
          }
      })())({
          reflectSymbol: function () {
              return "id";
          }
      })())({
          reflectSymbol: function () {
              return "height";
          }
      })())({
          reflectSymbol: function () {
              return "direction";
          }
      })())())))(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "Editing";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(App_Data_NodeCommon.nodeIdEncode))))))
  };
  var modeDecode = {
      decodeJson: Data_Argonaut_Decode_Generic.genericDecodeJson(modeGeneric)(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorNoArgs({
          reflectSymbol: function () {
              return "Idle";
          }
      }))(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "Drag";
          }
      })(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(App_Data_NodeCommon.decodeNodePath))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Data_NodeCommon.nodeIdDecode))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Control_DragState.decodeDragMode))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
          reflectSymbol: function () {
              return "state";
          }
      })()())({
          reflectSymbol: function () {
              return "nodeYOffset";
          }
      })()())({
          reflectSymbol: function () {
              return "nodeXOffset";
          }
      })()())({
          reflectSymbol: function () {
              return "nodeId";
          }
      })()())({
          reflectSymbol: function () {
              return "lastMouseY";
          }
      })()())({
          reflectSymbol: function () {
              return "lastMouseX";
          }
      })()())({
          reflectSymbol: function () {
              return "closestBeacon";
          }
      })()())()))(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "Resize";
          }
      })(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Control_ResizeState.decodeDirection))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Data_NodeCommon.nodeIdDecode))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
          reflectSymbol: function () {
              return "y";
          }
      })()())({
          reflectSymbol: function () {
              return "x";
          }
      })()())({
          reflectSymbol: function () {
              return "width";
          }
      })()())({
          reflectSymbol: function () {
              return "id";
          }
      })()())({
          reflectSymbol: function () {
              return "height";
          }
      })()())({
          reflectSymbol: function () {
              return "direction";
          }
      })()())()))(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "Editing";
          }
      })(App_Data_NodeCommon.nodeIdDecode)))))
  };
  var isHookedToResize = function (v) {
      if (v instanceof Resize) {
          return true;
      };
      return false;
  };
  var isHookedToDrag = function (v) {
      if (v instanceof Drag) {
          return true;
      };
      return false;
  };
  var isDrag = function (v) {
      if (v instanceof Drag && Data_Eq.eq(App_Control_DragState.eqDragMode)(v.value0.state)(App_Control_DragState.Dragging.value)) {
          return true;
      };
      return false;
  };
  var reactsToMouse = function (v) {
      if (v instanceof Idle) {
          return true;
      };
      return isHookedToDrag(v) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isDrag)(v);
  };
  var getResizedNodeId = function (v) {
      if (v instanceof Resize) {
          return new Data_Maybe.Just(v.value0.id);
      };
      return Data_Maybe.Nothing.value;
  };
  var getEditNodeId = function (v) {
      if (v instanceof Editing) {
          return new Data_Maybe.Just(v.value0);
      };
      return Data_Maybe.Nothing.value;
  };
  var getDragNodeId = function (v) {
      if (v instanceof Drag && Data_Eq.eq(App_Control_DragState.eqDragMode)(v.value0.state)(App_Control_DragState.Dragging.value)) {
          return new Data_Maybe.Just(v.value0.nodeId);
      };
      return Data_Maybe.Nothing.value;
  };
  var getClosestBeacon = function (v) {
      if (v instanceof Drag) {
          return v.value0.closestBeacon;
      };
      return Data_Maybe.Nothing.value;
  };
  exports["Idle"] = Idle;
  exports["Drag"] = Drag;
  exports["Resize"] = Resize;
  exports["Editing"] = Editing;
  exports["isHookedToResize"] = isHookedToResize;
  exports["isHookedToDrag"] = isHookedToDrag;
  exports["isDrag"] = isDrag;
  exports["getDragNodeId"] = getDragNodeId;
  exports["getClosestBeacon"] = getClosestBeacon;
  exports["reactsToMouse"] = reactsToMouse;
  exports["getEditNodeId"] = getEditNodeId;
  exports["getResizedNodeId"] = getResizedNodeId;
  exports["modeEq"] = modeEq;
  exports["modeEncode"] = modeEncode;
  exports["modeDecode"] = modeDecode;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.StateChangeType"] = $PS["App.Control.StateChangeType"] || {};
  var exports = $PS["App.Control.StateChangeType"];
  var NoChange = (function () {
      function NoChange() {

      };
      NoChange.value = new NoChange();
      return NoChange;
  })();
  var Ephemeral = (function () {
      function Ephemeral() {

      };
      Ephemeral.value = new Ephemeral();
      return Ephemeral;
  })();
  var Persistent = (function () {
      function Persistent() {

      };
      Persistent.value = new Persistent();
      return Persistent;
  })();
  var typeEq = {
      eq: function (x) {
          return function (y) {
              if (x instanceof NoChange && y instanceof NoChange) {
                  return true;
              };
              if (x instanceof Ephemeral && y instanceof Ephemeral) {
                  return true;
              };
              if (x instanceof Persistent && y instanceof Persistent) {
                  return true;
              };
              return false;
          };
      }
  };
  exports["NoChange"] = NoChange;
  exports["Ephemeral"] = Ephemeral;
  exports["Persistent"] = Persistent;
  exports["typeEq"] = typeEq;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.NodeAction"] = $PS["App.Control.NodeAction"] || {};
  var exports = $PS["App.Control.NodeAction"];     
  var StopPropagation = (function () {
      function StopPropagation(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      StopPropagation.create = function (value0) {
          return function (value1) {
              return new StopPropagation(value0, value1);
          };
      };
      return StopPropagation;
  })();
  var DragAction = (function () {
      function DragAction(value0) {
          this.value0 = value0;
      };
      DragAction.create = function (value0) {
          return new DragAction(value0);
      };
      return DragAction;
  })();
  var ResizeAction = (function () {
      function ResizeAction(value0) {
          this.value0 = value0;
      };
      ResizeAction.create = function (value0) {
          return new ResizeAction(value0);
      };
      return ResizeAction;
  })();
  var Select = (function () {
      function Select(value0) {
          this.value0 = value0;
      };
      Select.create = function (value0) {
          return new Select(value0);
      };
      return Select;
  })();
  var EditLabel = (function () {
      function EditLabel(value0) {
          this.value0 = value0;
      };
      EditLabel.create = function (value0) {
          return new EditLabel(value0);
      };
      return EditLabel;
  })();
  var FinishEdit = (function () {
      function FinishEdit(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      FinishEdit.create = function (value0) {
          return function (value1) {
              return new FinishEdit(value0, value1);
          };
      };
      return FinishEdit;
  })();
  exports["StopPropagation"] = StopPropagation;
  exports["DragAction"] = DragAction;
  exports["ResizeAction"] = ResizeAction;
  exports["Select"] = Select;
  exports["EditLabel"] = EditLabel;
  exports["FinishEdit"] = FinishEdit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Data.CSSClasses"] = $PS["App.Data.CSSClasses"] || {};
  var exports = $PS["App.Data.CSSClasses"];
  var sibling_rail = "sibling_rail";
  var selection_container = "selection_container";
  var selected = "selected";
  var scroller = "scroller";
  var root = "root";
  var resized = "resized";
  var position_capture = "position_capture";
  var parent_edge = "parent_edge";  
  var ns_resizer = "ns_resizer";
  var node_label = "node_label";
  var node = "node";
  var ew_resizer = "ew_resizer";
  var dragged = "dragged";
  var contents_container = "contents_container";
  var closest = "closest";
  var child_area = "child_area";
  var child = "child";
  var beacon = "beacon";
  exports["beacon"] = beacon;
  exports["child"] = child;
  exports["child_area"] = child_area;
  exports["closest"] = closest;
  exports["contents_container"] = contents_container;
  exports["dragged"] = dragged;
  exports["node"] = node;
  exports["node_label"] = node_label;
  exports["parent_edge"] = parent_edge;
  exports["position_capture"] = position_capture;
  exports["root"] = root;
  exports["scroller"] = scroller;
  exports["selected"] = selected;
  exports["selection_container"] = selection_container;
  exports["sibling_rail"] = sibling_rail;
  exports["ew_resizer"] = ew_resizer;
  exports["ns_resizer"] = ns_resizer;
  exports["resized"] = resized;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.ResizeAction"] = $PS["App.Control.ResizeAction"] || {};
  var exports = $PS["App.Control.ResizeAction"];   
  var StopPropagation = (function () {
      function StopPropagation(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      StopPropagation.create = function (value0) {
          return function (value1) {
              return new StopPropagation(value0, value1);
          };
      };
      return StopPropagation;
  })();
  var EWStart = (function () {
      function EWStart(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      EWStart.create = function (value0) {
          return function (value1) {
              return new EWStart(value0, value1);
          };
      };
      return EWStart;
  })();
  var NSStart = (function () {
      function NSStart(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      NSStart.create = function (value0) {
          return function (value1) {
              return new NSStart(value0, value1);
          };
      };
      return NSStart;
  })();
  var MouseMove = (function () {
      function MouseMove(value0) {
          this.value0 = value0;
      };
      MouseMove.create = function (value0) {
          return new MouseMove(value0);
      };
      return MouseMove;
  })();
  var MouseUp = (function () {
      function MouseUp(value0) {
          this.value0 = value0;
      };
      MouseUp.create = function (value0) {
          return new MouseUp(value0);
      };
      return MouseUp;
  })();
  exports["StopPropagation"] = StopPropagation;
  exports["EWStart"] = EWStart;
  exports["NSStart"] = NSStart;
  exports["MouseMove"] = MouseMove;
  exports["MouseUp"] = MouseUp;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.State.Class"] = $PS["Control.Monad.State.Class"] || {};
  var exports = $PS["Control.Monad.State.Class"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];                
  var state = function (dict) {
      return dict.state;
  };
  var modify_ = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              return new Data_Tuple.Tuple(Data_Unit.unit, f(s));
          });
      };
  };
  var get = function (dictMonadState) {
      return state(dictMonadState)(function (s) {
          return new Data_Tuple.Tuple(s, s);
      });
  };
  exports["get"] = get;
  exports["modify_"] = modify_;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["DOM.HTML.Indexed.InputType"] = $PS["DOM.HTML.Indexed.InputType"] || {};
  var exports = $PS["DOM.HTML.Indexed.InputType"];
  var InputButton = (function () {
      function InputButton() {

      };
      InputButton.value = new InputButton();
      return InputButton;
  })();
  var InputCheckbox = (function () {
      function InputCheckbox() {

      };
      InputCheckbox.value = new InputCheckbox();
      return InputCheckbox;
  })();
  var InputColor = (function () {
      function InputColor() {

      };
      InputColor.value = new InputColor();
      return InputColor;
  })();
  var InputDate = (function () {
      function InputDate() {

      };
      InputDate.value = new InputDate();
      return InputDate;
  })();
  var InputDatetimeLocal = (function () {
      function InputDatetimeLocal() {

      };
      InputDatetimeLocal.value = new InputDatetimeLocal();
      return InputDatetimeLocal;
  })();
  var InputEmail = (function () {
      function InputEmail() {

      };
      InputEmail.value = new InputEmail();
      return InputEmail;
  })();
  var InputFile = (function () {
      function InputFile() {

      };
      InputFile.value = new InputFile();
      return InputFile;
  })();
  var InputHidden = (function () {
      function InputHidden() {

      };
      InputHidden.value = new InputHidden();
      return InputHidden;
  })();
  var InputImage = (function () {
      function InputImage() {

      };
      InputImage.value = new InputImage();
      return InputImage;
  })();
  var InputMonth = (function () {
      function InputMonth() {

      };
      InputMonth.value = new InputMonth();
      return InputMonth;
  })();
  var InputNumber = (function () {
      function InputNumber() {

      };
      InputNumber.value = new InputNumber();
      return InputNumber;
  })();
  var InputPassword = (function () {
      function InputPassword() {

      };
      InputPassword.value = new InputPassword();
      return InputPassword;
  })();
  var InputRadio = (function () {
      function InputRadio() {

      };
      InputRadio.value = new InputRadio();
      return InputRadio;
  })();
  var InputRange = (function () {
      function InputRange() {

      };
      InputRange.value = new InputRange();
      return InputRange;
  })();
  var InputReset = (function () {
      function InputReset() {

      };
      InputReset.value = new InputReset();
      return InputReset;
  })();
  var InputSearch = (function () {
      function InputSearch() {

      };
      InputSearch.value = new InputSearch();
      return InputSearch;
  })();
  var InputSubmit = (function () {
      function InputSubmit() {

      };
      InputSubmit.value = new InputSubmit();
      return InputSubmit;
  })();
  var InputTel = (function () {
      function InputTel() {

      };
      InputTel.value = new InputTel();
      return InputTel;
  })();
  var InputText = (function () {
      function InputText() {

      };
      InputText.value = new InputText();
      return InputText;
  })();
  var InputTime = (function () {
      function InputTime() {

      };
      InputTime.value = new InputTime();
      return InputTime;
  })();
  var InputUrl = (function () {
      function InputUrl() {

      };
      InputUrl.value = new InputUrl();
      return InputUrl;
  })();
  var InputWeek = (function () {
      function InputWeek() {

      };
      InputWeek.value = new InputWeek();
      return InputWeek;
  })();
  var renderInputType = function (v) {
      if (v instanceof InputButton) {
          return "button";
      };
      if (v instanceof InputCheckbox) {
          return "checkbox";
      };
      if (v instanceof InputColor) {
          return "color";
      };
      if (v instanceof InputDate) {
          return "date";
      };
      if (v instanceof InputDatetimeLocal) {
          return "datetime-local";
      };
      if (v instanceof InputEmail) {
          return "email";
      };
      if (v instanceof InputFile) {
          return "file";
      };
      if (v instanceof InputHidden) {
          return "hidden";
      };
      if (v instanceof InputImage) {
          return "image";
      };
      if (v instanceof InputMonth) {
          return "month";
      };
      if (v instanceof InputNumber) {
          return "number";
      };
      if (v instanceof InputPassword) {
          return "password";
      };
      if (v instanceof InputRadio) {
          return "radio";
      };
      if (v instanceof InputRange) {
          return "range";
      };
      if (v instanceof InputReset) {
          return "reset";
      };
      if (v instanceof InputSearch) {
          return "search";
      };
      if (v instanceof InputSubmit) {
          return "submit";
      };
      if (v instanceof InputTel) {
          return "tel";
      };
      if (v instanceof InputText) {
          return "text";
      };
      if (v instanceof InputTime) {
          return "time";
      };
      if (v instanceof InputUrl) {
          return "url";
      };
      if (v instanceof InputWeek) {
          return "week";
      };
      throw new Error("Failed pattern match at DOM.HTML.Indexed.InputType (line 28, column 19 - line 50, column 22): " + [ v.constructor.name ]);
  };
  exports["InputText"] = InputText;
  exports["renderInputType"] = renderInputType;
})(PS);
(function(exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad"] = $PS["Control.Monad"] || {};
  var exports = $PS["Control.Monad"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var unlessM = function (dictMonad) {
      return function (mb) {
          return function (m) {
              return Control_Bind.bind(dictMonad.Bind1())(mb)(function (b) {
                  return Control_Applicative.unless(dictMonad.Applicative0())(b)(m);
              });
          };
      };
  };
  var ap = function (dictMonad) {
      return function (f) {
          return function (a) {
              return Control_Bind.bind(dictMonad.Bind1())(f)(function (f$prime) {
                  return Control_Bind.bind(dictMonad.Bind1())(a)(function (a$prime) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(f$prime(a$prime));
                  });
              });
          };
      };
  };
  exports["unlessM"] = unlessM;
  exports["ap"] = ap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect"] = $PS["Effect"] || {};
  var exports = $PS["Effect"];
  var $foreign = $PS["Effect"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad = $PS["Control.Monad"];                  
  var monadEffect = {
      Applicative0: function () {
          return applicativeEffect;
      },
      Bind1: function () {
          return bindEffect;
      }
  };
  var bindEffect = {
      bind: $foreign.bindE,
      Apply0: function () {
          return applyEffect;
      }
  };
  var applyEffect = {
      apply: Control_Monad.ap(monadEffect),
      Functor0: function () {
          return functorEffect;
      }
  };
  var applicativeEffect = {
      pure: $foreign.pureE,
      Apply0: function () {
          return applyEffect;
      }
  };
  var functorEffect = {
      map: Control_Applicative.liftA1(applicativeEffect)
  };
  exports["functorEffect"] = functorEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect.Class"] = $PS["Effect.Class"] || {};
  var exports = $PS["Effect.Class"];
  var Control_Category = $PS["Control.Category"];
  var Effect = $PS["Effect"];                
  var monadEffectEffect = {
      liftEffect: Control_Category.identity(Control_Category.categoryFn),
      Monad0: function () {
          return Effect.monadEffect;
      }
  };
  var liftEffect = function (dict) {
      return dict.liftEffect;
  };
  exports["liftEffect"] = liftEffect;
  exports["monadEffectEffect"] = monadEffectEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Exists"] = $PS["Data.Exists"] || {};
  var exports = $PS["Data.Exists"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var runExists = Unsafe_Coerce.unsafeCoerce;
  var mkExists = Unsafe_Coerce.unsafeCoerce;
  exports["mkExists"] = mkExists;
  exports["runExists"] = runExists;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Coyoneda"] = $PS["Data.Coyoneda"] || {};
  var exports = $PS["Data.Coyoneda"];
  var Control_Category = $PS["Control.Category"];
  var Data_Exists = $PS["Data.Exists"];                          
  var CoyonedaF = (function () {
      function CoyonedaF(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CoyonedaF.create = function (value0) {
          return function (value1) {
              return new CoyonedaF(value0, value1);
          };
      };
      return CoyonedaF;
  })();
  var Coyoneda = function (x) {
      return x;
  };
  var unCoyoneda = function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return f(v1.value0)(v1.value1);
          })(v);
      };
  };
  var coyoneda = function (k) {
      return function (fi) {
          return Coyoneda(Data_Exists.mkExists(new CoyonedaF(k, fi)));
      };
  };
  var functorCoyoneda = {
      map: function (f) {
          return function (v) {
              return Data_Exists.runExists(function (v1) {
                  return coyoneda(function ($84) {
                      return f(v1.value0($84));
                  })(v1.value1);
              })(v);
          };
      }
  };
  var liftCoyoneda = coyoneda(Control_Category.identity(Control_Category.categoryFn));
  exports["unCoyoneda"] = unCoyoneda;
  exports["liftCoyoneda"] = liftCoyoneda;
  exports["functorCoyoneda"] = functorCoyoneda;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Data.OrdBox"] = $PS["Halogen.Data.OrdBox"] || {};
  var exports = $PS["Halogen.Data.OrdBox"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ord = $PS["Data.Ord"];                
  var OrdBox = (function () {
      function OrdBox(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      OrdBox.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new OrdBox(value0, value1, value2);
              };
          };
      };
      return OrdBox;
  })();
  var mkOrdBox = function (dictOrd) {
      return OrdBox.create(Data_Eq.eq(dictOrd.Eq0()))(Data_Ord.compare(dictOrd));
  };
  var eqOrdBox = {
      eq: function (v) {
          return function (v1) {
              return v.value0(v.value2)(v1.value2);
          };
      }
  };
  var ordOrdBox = {
      compare: function (v) {
          return function (v1) {
              return v.value1(v.value2)(v1.value2);
          };
      },
      Eq0: function () {
          return eqOrdBox;
      }
  };
  exports["mkOrdBox"] = mkOrdBox;
  exports["ordOrdBox"] = ordOrdBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Data.Slot"] = $PS["Halogen.Data.Slot"] || {};
  var exports = $PS["Halogen.Data.Slot"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_Data_OrdBox = $PS["Halogen.Data.OrdBox"];
  var pop = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.pop(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var lookup = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.lookup(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var insert = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (val) {
                          return function (v) {
                              return Data_Map_Internal.insert(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(val)(v);
                          };
                      };
                  };
              };
          };
      };
  };
  var foreachSlot = function (dictApplicative) {
      return function (v) {
          return function (k) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Map_Internal.foldableMap)(function ($37) {
                  return k($37);
              })(v);
          };
      };
  };
  var empty = Data_Map_Internal.empty;
  exports["empty"] = empty;
  exports["lookup"] = lookup;
  exports["insert"] = insert;
  exports["pop"] = pop;
  exports["foreachSlot"] = foreachSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Query.Input"] = $PS["Halogen.Query.Input"] || {};
  var exports = $PS["Halogen.Query.Input"];
  var RefUpdate = (function () {
      function RefUpdate(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      RefUpdate.create = function (value0) {
          return function (value1) {
              return new RefUpdate(value0, value1);
          };
      };
      return RefUpdate;
  })();
  var Action = (function () {
      function Action(value0) {
          this.value0 = value0;
      };
      Action.create = function (value0) {
          return new Action(value0);
      };
      return Action;
  })();
  var functorInput = {
      map: function (f) {
          return function (m) {
              if (m instanceof RefUpdate) {
                  return new RefUpdate(m.value0, m.value1);
              };
              if (m instanceof Action) {
                  return new Action(f(m.value0));
              };
              throw new Error("Failed pattern match at Halogen.Query.Input (line 19, column 1 - line 19, column 46): " + [ m.constructor.name ]);
          };
      }
  };
  exports["RefUpdate"] = RefUpdate;
  exports["Action"] = Action;
  exports["functorInput"] = functorInput;
})(PS);
(function(exports) {
  /* eslint-disable no-eq-null, eqeqeq */

  "use strict";

  exports["null"] = null;

  exports.nullable = function (a, r, f) {
    return a == null ? r : f(a);
  };

  exports.notNull = function (x) {
    return x;
  };
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Nullable"] = $PS["Data.Nullable"] || {};
  var exports = $PS["Data.Nullable"];
  var $foreign = $PS["Data.Nullable"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var toNullable = Data_Maybe.maybe($foreign["null"])($foreign.notNull);
  var toMaybe = function (n) {
      return $foreign.nullable(n, Data_Maybe.Nothing.value, Data_Maybe.Just.create);
  };
  exports["toMaybe"] = toMaybe;
  exports["toNullable"] = toNullable;
  exports["null"] = $foreign["null"];
})(PS);
(function(exports) {
  "use strict";

  exports.new = function (val) {
    return function () {
      return { value: val };
    };
  };

  exports.read = function (ref) {
    return function () {
      return ref.value;
    };
  };

  exports.modifyImpl = function (f) {
    return function (ref) {
      return function () {
        var t = f(ref.value);
        ref.value = t.state;
        return t.value;
      };
    };
  };

  exports.write = function (val) {
    return function (ref) {
      return function () {
        ref.value = val;
      };
    };
  };
})(PS["Effect.Ref"] = PS["Effect.Ref"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect.Ref"] = $PS["Effect.Ref"] || {};
  var exports = $PS["Effect.Ref"];
  var $foreign = $PS["Effect.Ref"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];                
  var modify$prime = $foreign.modifyImpl;
  var modify = function (f) {
      return modify$prime(function (s) {
          var s$prime = f(s);
          return {
              state: s$prime,
              value: s$prime
          };
      });
  };
  var modify_ = function (f) {
      return function (s) {
          return Data_Functor["void"](Effect.functorEffect)(modify(f)(s));
      };
  };
  exports["modify'"] = modify$prime;
  exports["modify_"] = modify_;
  exports["new"] = $foreign["new"];
  exports["read"] = $foreign.read;
  exports["write"] = $foreign.write;
})(PS);
(function(exports) {
  "use strict";

  exports.typeOf = function (value) {
    return typeof value;
  };
})(PS["Foreign"] = PS["Foreign"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Foreign"] = $PS["Foreign"] || {};
  var exports = $PS["Foreign"];
  var $foreign = $PS["Foreign"];
  exports["typeOf"] = $foreign.typeOf;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.VDom.Machine"] = $PS["Halogen.VDom.Machine"] || {};
  var exports = $PS["Halogen.VDom.Machine"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var Step = (function () {
      function Step(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Step.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Step(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Step;
  })();
  var unStep = Unsafe_Coerce.unsafeCoerce;
  var step = function (v, a) {
      return v.value2(v.value1, a);
  };
  var mkStep = Unsafe_Coerce.unsafeCoerce;
  var halt = function (v) {
      return v.value3(v.value1);
  };
  var extract = unStep(function (v) {
      return v.value0;
  });
  exports["Step"] = Step;
  exports["mkStep"] = mkStep;
  exports["unStep"] = unStep;
  exports["extract"] = extract;
  exports["step"] = step;
  exports["halt"] = halt;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGetAny = function (key, obj) {
    return obj[key];
  };

  exports.unsafeHasAny = function (key, obj) {
    return obj.hasOwnProperty(key);
  };

  exports.unsafeSetAny = function (key, val, obj) {
      obj[key] = val;
  };

  exports.forE = function (a, f) {
    var b = [];
    for (var i = 0; i < a.length; i++) {
      b.push(f(i, a[i]));
    }
    return b;
  };

  exports.forEachE = function (a, f) {
    for (var i = 0; i < a.length; i++) {
      f(a[i]);
    }
  };

  exports.forInE = function (o, f) {
    var ks = Object.keys(o);
    for (var i = 0; i < ks.length; i++) {
      var k = ks[i];
      f(k, o[k]);
    }
  };

  exports.diffWithIxE = function (a1, a2, f1, f2, f3) {
    var a3 = [];
    var l1 = a1.length;
    var l2 = a2.length;
    var i  = 0;
    while (1) {
      if (i < l1) {
        if (i < l2) {
          a3.push(f1(i, a1[i], a2[i]));
        } else {
          f2(i, a1[i]);
        }
      } else if (i < l2) {
        a3.push(f3(i, a2[i]));
      } else {
        break;
      }
      i++;
    }
    return a3;
  };

  exports.strMapWithIxE = function (as, fk, f) {
    var o = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      o[k] = f(k, i, a);
    }
    return o;
  };

  exports.diffWithKeyAndIxE = function (o1, as, fk, f1, f2, f3) {
    var o2 = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      if (o1.hasOwnProperty(k)) {
        o2[k] = f1(k, i, o1[k], a);
      } else {
        o2[k] = f3(k, i, a);
      }
    }
    for (var k in o1) {
      if (k in o2) {
        continue;
      }
      f2(k, o1[k]);
    }
    return o2;
  };

  exports.refEq = function (a, b) {
    return a === b;
  };

  exports.createTextNode = function (s, doc) {
    return doc.createTextNode(s);
  };

  exports.setTextContent = function (s, n) {
    n.textContent = s;
  };

  exports.createElement = function (ns, name, doc) {
    if (ns != null) {
      return doc.createElementNS(ns, name);
    } else {
      return doc.createElement(name)
    }
  };

  exports.insertChildIx = function (i, a, b) {
    var n = b.childNodes.item(i) || null;
    if (n !== a) {
      b.insertBefore(a, n);
    }
  };

  exports.removeChild = function (a, b) {
    if (b && a.parentNode === b) {
      b.removeChild(a);
    }
  };

  exports.parentNode = function (a) {
    return a.parentNode;
  };

  exports.setAttribute = function (ns, attr, val, el) {
    if (ns != null) {
      el.setAttributeNS(ns, attr, val);
    } else {
      el.setAttribute(attr, val);
    }
  };

  exports.removeAttribute = function (ns, attr, el) {
    if (ns != null) {
      el.removeAttributeNS(ns, attr);
    } else {
      el.removeAttribute(attr);
    }
  };

  exports.hasAttribute = function (ns, attr, el) {
    if (ns != null) {
      return el.hasAttributeNS(ns, attr);
    } else {
      return el.hasAttribute(attr);
    }
  };

  exports.addEventListener = function (ev, listener, el) {
    el.addEventListener(ev, listener, false);
  };

  exports.removeEventListener = function (ev, listener, el) {
    el.removeEventListener(ev, listener, false);
  };

  exports.jsUndefined = void 0;
})(PS["Halogen.VDom.Util"] = PS["Halogen.VDom.Util"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.VDom.Util"] = $PS["Halogen.VDom.Util"] || {};
  var exports = $PS["Halogen.VDom.Util"];
  var $foreign = $PS["Halogen.VDom.Util"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var unsafeLookup = $foreign.unsafeGetAny;
  var unsafeFreeze = Unsafe_Coerce.unsafeCoerce;
  var pokeMutMap = $foreign.unsafeSetAny;
  var newMutMap = Foreign_Object_ST["new"];
  exports["newMutMap"] = newMutMap;
  exports["pokeMutMap"] = pokeMutMap;
  exports["unsafeFreeze"] = unsafeFreeze;
  exports["unsafeLookup"] = unsafeLookup;
  exports["unsafeGetAny"] = $foreign.unsafeGetAny;
  exports["unsafeHasAny"] = $foreign.unsafeHasAny;
  exports["unsafeSetAny"] = $foreign.unsafeSetAny;
  exports["forE"] = $foreign.forE;
  exports["forEachE"] = $foreign.forEachE;
  exports["forInE"] = $foreign.forInE;
  exports["diffWithIxE"] = $foreign.diffWithIxE;
  exports["diffWithKeyAndIxE"] = $foreign.diffWithKeyAndIxE;
  exports["strMapWithIxE"] = $foreign.strMapWithIxE;
  exports["refEq"] = $foreign.refEq;
  exports["createTextNode"] = $foreign.createTextNode;
  exports["setTextContent"] = $foreign.setTextContent;
  exports["createElement"] = $foreign.createElement;
  exports["insertChildIx"] = $foreign.insertChildIx;
  exports["removeChild"] = $foreign.removeChild;
  exports["parentNode"] = $foreign.parentNode;
  exports["setAttribute"] = $foreign.setAttribute;
  exports["removeAttribute"] = $foreign.removeAttribute;
  exports["hasAttribute"] = $foreign.hasAttribute;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
  exports["jsUndefined"] = $foreign.jsUndefined;
})(PS);
(function(exports) {
  "use strict";

  exports.eventListener = function (fn) {
    return function () {
      return function (event) {
        return fn(event)();
      };
    };
  };

  exports.addEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.addEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };

  exports.removeEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.removeEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };
})(PS["Web.Event.EventTarget"] = PS["Web.Event.EventTarget"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.Event.EventTarget"] = $PS["Web.Event.EventTarget"] || {};
  var exports = $PS["Web.Event.EventTarget"];
  var $foreign = $PS["Web.Event.EventTarget"];
  exports["eventListener"] = $foreign.eventListener;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.VDom.DOM.Prop"] = $PS["Halogen.VDom.DOM.Prop"] || {};
  var exports = $PS["Halogen.VDom.DOM.Prop"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Foreign = $PS["Foreign"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];                
  var Created = (function () {
      function Created(value0) {
          this.value0 = value0;
      };
      Created.create = function (value0) {
          return new Created(value0);
      };
      return Created;
  })();
  var Removed = (function () {
      function Removed(value0) {
          this.value0 = value0;
      };
      Removed.create = function (value0) {
          return new Removed(value0);
      };
      return Removed;
  })();
  var Attribute = (function () {
      function Attribute(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Attribute.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Attribute(value0, value1, value2);
              };
          };
      };
      return Attribute;
  })();
  var Property = (function () {
      function Property(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Property.create = function (value0) {
          return function (value1) {
              return new Property(value0, value1);
          };
      };
      return Property;
  })();
  var Handler = (function () {
      function Handler(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Handler.create = function (value0) {
          return function (value1) {
              return new Handler(value0, value1);
          };
      };
      return Handler;
  })();
  var Ref = (function () {
      function Ref(value0) {
          this.value0 = value0;
      };
      Ref.create = function (value0) {
          return new Ref(value0);
      };
      return Ref;
  })();
  var unsafeGetProperty = Halogen_VDom_Util.unsafeGetAny;
  var setProperty = Halogen_VDom_Util.unsafeSetAny;
  var removeProperty = function (key, el) {
      var v = Halogen_VDom_Util.hasAttribute(Data_Nullable["null"], key, el);
      if (v) {
          return Halogen_VDom_Util.removeAttribute(Data_Nullable["null"], key, el);
      };
      var v1 = Foreign.typeOf(Halogen_VDom_Util.unsafeGetAny(key, el));
      if (v1 === "string") {
          return Halogen_VDom_Util.unsafeSetAny(key, "", el);
      };
      if (key === "rowSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      if (key === "colSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      return Halogen_VDom_Util.unsafeSetAny(key, Halogen_VDom_Util.jsUndefined, el);
  };
  var propToStrKey = function (v) {
      if (v instanceof Attribute && v.value0 instanceof Data_Maybe.Just) {
          return "attr/" + (v.value0.value0 + (":" + v.value1));
      };
      if (v instanceof Attribute) {
          return "attr/:" + v.value1;
      };
      if (v instanceof Property) {
          return "prop/" + v.value0;
      };
      if (v instanceof Handler) {
          return "handler/" + v.value0;
      };
      if (v instanceof Ref) {
          return "ref";
      };
      throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 182, column 16 - line 187, column 16): " + [ v.constructor.name ]);
  };
  var propFromString = Unsafe_Coerce.unsafeCoerce; 
  var functorProp = {
      map: function (v) {
          return function (v1) {
              if (v1 instanceof Handler) {
                  return new Handler(v1.value0, Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(v))(v1.value1));
              };
              if (v1 instanceof Ref) {
                  return new Ref(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(v))(v1.value0));
              };
              return v1;
          };
      }
  };
  var buildProp = function (emit) {
      return function (el) {
          var removeProp = function (prevEvents) {
              return function (v, v1) {
                  if (v1 instanceof Attribute) {
                      return Halogen_VDom_Util.removeAttribute(Data_Nullable.toNullable(v1.value0), v1.value1, el);
                  };
                  if (v1 instanceof Property) {
                      return removeProperty(v1.value0, el);
                  };
                  if (v1 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v1.value0, prevEvents);
                      return Halogen_VDom_Util.removeEventListener(v1.value0, Data_Tuple.fst(handler), el);
                  };
                  if (v1 instanceof Ref) {
                      return Data_Unit.unit;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 169, column 5 - line 179, column 18): " + [ v1.constructor.name ]);
              };
          };
          var mbEmit = function (v) {
              if (v instanceof Data_Maybe.Just) {
                  return emit(v.value0)();
              };
              return Data_Unit.unit;
          };
          var haltProp = function (state) {
              var v = Foreign_Object.lookup("ref")(state.props);
              if (v instanceof Data_Maybe.Just && v.value0 instanceof Ref) {
                  return mbEmit(v.value0.value0(new Removed(el)));
              };
              return Data_Unit.unit;
          };
          var diffProp = function (prevEvents, events) {
              return function (v, v1, v11, v2) {
                  if (v11 instanceof Attribute && v2 instanceof Attribute) {
                      var $57 = v11.value2 === v2.value2;
                      if ($57) {
                          return v2;
                      };
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v11 instanceof Property && v2 instanceof Property) {
                      var v4 = Halogen_VDom_Util.refEq(v11.value1, v2.value1);
                      if (v4) {
                          return v2;
                      };
                      if (v2.value0 === "value") {
                          var elVal = unsafeGetProperty("value", el);
                          var $66 = Halogen_VDom_Util.refEq(elVal, v2.value1);
                          if ($66) {
                              return v2;
                          };
                          setProperty(v2.value0, v2.value1, el);
                          return v2;
                      };
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v11 instanceof Handler && v2 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v2.value0, prevEvents);
                      Effect_Ref.write(v2.value1)(Data_Tuple.snd(handler))();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, handler, events);
                      return v2;
                  };
                  return v2;
              };
          };
          var applyProp = function (events) {
              return function (v, v1, v2) {
                  if (v2 instanceof Attribute) {
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v2 instanceof Property) {
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v2 instanceof Handler) {
                      var v3 = Halogen_VDom_Util.unsafeGetAny(v2.value0, events);
                      if (Halogen_VDom_Util.unsafeHasAny(v2.value0, events)) {
                          Effect_Ref.write(v2.value1)(Data_Tuple.snd(v3))();
                          return v2;
                      };
                      var ref = Effect_Ref["new"](v2.value1)();
                      var listener = Web_Event_EventTarget.eventListener(function (ev) {
                          return function __do() {
                              var f$prime = Effect_Ref.read(ref)();
                              return mbEmit(f$prime(ev));
                          };
                      })();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, new Data_Tuple.Tuple(listener, ref), events);
                      Halogen_VDom_Util.addEventListener(v2.value0, listener, el);
                      return v2;
                  };
                  if (v2 instanceof Ref) {
                      mbEmit(v2.value0(new Created(el)));
                      return v2;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 113, column 5 - line 135, column 15): " + [ v2.constructor.name ]);
              };
          };
          var patchProp = function (state, ps2) {
              var events = Halogen_VDom_Util.newMutMap();
              var onThis = removeProp(state.events);
              var onThese = diffProp(state.events, events);
              var onThat = applyProp(events);
              var props = Halogen_VDom_Util.diffWithKeyAndIxE(state.props, ps2, propToStrKey, onThese, onThis, onThat);
              var nextState = {
                  events: Halogen_VDom_Util.unsafeFreeze(events),
                  props: props
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, nextState, patchProp, haltProp));
          };
          var renderProp = function (ps1) {
              var events = Halogen_VDom_Util.newMutMap();
              var ps1$prime = Halogen_VDom_Util.strMapWithIxE(ps1, propToStrKey, applyProp(events));
              var state = {
                  events: Halogen_VDom_Util.unsafeFreeze(events),
                  props: ps1$prime
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, state, patchProp, haltProp));
          };
          return renderProp;
      };
  };
  exports["Attribute"] = Attribute;
  exports["Property"] = Property;
  exports["Handler"] = Handler;
  exports["Ref"] = Ref;
  exports["Created"] = Created;
  exports["Removed"] = Removed;
  exports["propFromString"] = propFromString;
  exports["buildProp"] = buildProp;
  exports["functorProp"] = functorProp;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.VDom.Types"] = $PS["Halogen.VDom.Types"] || {};
  var exports = $PS["Halogen.VDom.Types"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Text = (function () {
      function Text(value0) {
          this.value0 = value0;
      };
      Text.create = function (value0) {
          return new Text(value0);
      };
      return Text;
  })();
  var Elem = (function () {
      function Elem(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Elem.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Elem(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Elem;
  })();
  var Keyed = (function () {
      function Keyed(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Keyed.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Keyed(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Keyed;
  })();
  var Widget = (function () {
      function Widget(value0) {
          this.value0 = value0;
      };
      Widget.create = function (value0) {
          return new Widget(value0);
      };
      return Widget;
  })();
  var Grafted = (function () {
      function Grafted(value0) {
          this.value0 = value0;
      };
      Grafted.create = function (value0) {
          return new Grafted(value0);
      };
      return Grafted;
  })();
  var Graft = (function () {
      function Graft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Graft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Graft(value0, value1, value2);
              };
          };
      };
      return Graft;
  })();
  var unGraft = function (f) {
      return function ($55) {
          return f($55);
      };
  };
  var graft = Unsafe_Coerce.unsafeCoerce;
  var bifunctorGraft = {
      bimap: function (f) {
          return function (g) {
              return unGraft(function (v) {
                  return graft(new Graft(function ($57) {
                      return f(v.value0($57));
                  }, function ($58) {
                      return g(v.value1($58));
                  }, v.value2));
              });
          };
      }
  };
  var bifunctorVDom = {
      bimap: function (v) {
          return function (v1) {
              return function (v2) {
                  if (v2 instanceof Text) {
                      return new Text(v2.value0);
                  };
                  if (v2 instanceof Grafted) {
                      return new Grafted(Data_Bifunctor.bimap(bifunctorGraft)(v)(v1)(v2.value0));
                  };
                  return new Grafted(graft(new Graft(v, v1, v2)));
              };
          };
      }
  };
  var runGraft = unGraft(function (v) {
      var go = function (v2) {
          if (v2 instanceof Text) {
              return new Text(v2.value0);
          };
          if (v2 instanceof Elem) {
              return new Elem(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(go)(v2.value3));
          };
          if (v2 instanceof Keyed) {
              return new Keyed(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Tuple.functorTuple)(go))(v2.value3));
          };
          if (v2 instanceof Widget) {
              return new Widget(v.value1(v2.value0));
          };
          if (v2 instanceof Grafted) {
              return new Grafted(Data_Bifunctor.bimap(bifunctorGraft)(v.value0)(v.value1)(v2.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.Types (line 86, column 7 - line 86, column 27): " + [ v2.constructor.name ]);
      };
      return go(v.value2);
  });
  exports["Text"] = Text;
  exports["Elem"] = Elem;
  exports["Keyed"] = Keyed;
  exports["Widget"] = Widget;
  exports["Grafted"] = Grafted;
  exports["runGraft"] = runGraft;
  exports["bifunctorVDom"] = bifunctorVDom;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.HTML.Core"] = $PS["Halogen.HTML.Core"] || {};
  var exports = $PS["Halogen.HTML.Core"];
  var DOM_HTML_Indexed_InputType = $PS["DOM.HTML.Indexed.InputType"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];          
  var HTML = function (x) {
      return x;
  };
  var widget = function ($19) {
      return HTML(Halogen_VDom_Types.Widget.create($19));
  };
  var toPropValue = function (dict) {
      return dict.toPropValue;
  };
  var text = function ($20) {
      return HTML(Halogen_VDom_Types.Text.create($20));
  };
  var ref = function (f) {
      return Halogen_VDom_DOM_Prop.Ref.create(function ($21) {
          return f((function (v) {
              if (v instanceof Halogen_VDom_DOM_Prop.Created) {
                  return new Data_Maybe.Just(v.value0);
              };
              if (v instanceof Halogen_VDom_DOM_Prop.Removed) {
                  return Data_Maybe.Nothing.value;
              };
              throw new Error("Failed pattern match at Halogen.HTML.Core (line 103, column 21 - line 105, column 23): " + [ v.constructor.name ]);
          })($21));
      });
  };
  var prop = function (dictIsProp) {
      return function (v) {
          var $22 = Halogen_VDom_DOM_Prop.Property.create(v);
          var $23 = toPropValue(dictIsProp);
          return function ($24) {
              return $22($23($24));
          };
      };
  };
  var isPropString = {
      toPropValue: Halogen_VDom_DOM_Prop.propFromString
  };
  var isPropInputType = {
      toPropValue: function ($38) {
          return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_InputType.renderInputType($38));
      }
  };
  var handler = Halogen_VDom_DOM_Prop.Handler.create;
  var element = function (ns) {
      return function (name) {
          return function (props) {
              return function (children) {
                  return new Halogen_VDom_Types.Elem(ns, name, props, children);
              };
          };
      };
  };
  var bifunctorHTML = {
      bimap: function (f) {
          return function (g) {
              return function (v) {
                  return Data_Bifunctor.bimap(Halogen_VDom_Types.bifunctorVDom)(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Halogen_VDom_DOM_Prop.functorProp)(Data_Functor.map(Halogen_Query_Input.functorInput)(g))))(f)(v);
              };
          };
      }
  };
  var attr = function (ns) {
      return function (v) {
          return Halogen_VDom_DOM_Prop.Attribute.create(ns)(v);
      };
  };
  exports["widget"] = widget;
  exports["text"] = text;
  exports["element"] = element;
  exports["prop"] = prop;
  exports["attr"] = attr;
  exports["handler"] = handler;
  exports["ref"] = ref;
  exports["bifunctorHTML"] = bifunctorHTML;
  exports["isPropString"] = isPropString;
  exports["isPropInputType"] = isPropInputType;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Plus"] = $PS["Control.Plus"] || {};
  var exports = $PS["Control.Plus"];
  var empty = function (dict) {
      return dict.empty;
  };
  exports["empty"] = empty;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.NonEmpty"] = $PS["Data.NonEmpty"] || {};
  var exports = $PS["Data.NonEmpty"];
  var Control_Plus = $PS["Control.Plus"];                      
  var NonEmpty = (function () {
      function NonEmpty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      NonEmpty.create = function (value0) {
          return function (value1) {
              return new NonEmpty(value0, value1);
          };
      };
      return NonEmpty;
  })();
  var singleton = function (dictPlus) {
      return function (a) {
          return new NonEmpty(a, Control_Plus.empty(dictPlus));
      };
  };
  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.List.NonEmpty"] = $PS["Data.List.NonEmpty"] || {};
  var exports = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var singleton = (function () {
      var $172 = Data_NonEmpty.singleton(Data_List_Types.plusList);
      return function ($173) {
          return Data_List_Types.NonEmptyList($172($173));
      };
  })();
  var cons = function (y) {
      return function (v) {
          return new Data_NonEmpty.NonEmpty(y, new Data_List_Types.Cons(v.value0, v.value1));
      };
  };
  exports["singleton"] = singleton;
  exports["cons"] = cons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Applicative.Free"] = $PS["Control.Applicative.Free"] || {};
  var exports = $PS["Control.Applicative.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Pure = (function () {
      function Pure(value0) {
          this.value0 = value0;
      };
      Pure.create = function (value0) {
          return new Pure(value0);
      };
      return Pure;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var Ap = (function () {
      function Ap(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Ap.create = function (value0) {
          return function (value1) {
              return new Ap(value0, value1);
          };
      };
      return Ap;
  })();
  var mkAp = function (fba) {
      return function (fb) {
          return new Ap(fba, fb);
      };
  };
  var liftFreeAp = Lift.create;
  var goLeft = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_valStack) {
              return function ($copy_nat) {
                  return function ($copy_func) {
                      return function ($copy_count) {
                          var $tco_var_dictApplicative = $copy_dictApplicative;
                          var $tco_var_fStack = $copy_fStack;
                          var $tco_var_valStack = $copy_valStack;
                          var $tco_var_nat = $copy_nat;
                          var $tco_var_func = $copy_func;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(dictApplicative, fStack, valStack, nat, func, count) {
                              if (func instanceof Pure) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: Control_Applicative.pure(dictApplicative)(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Lift) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: nat(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Ap) {
                                  $tco_var_dictApplicative = dictApplicative;
                                  $tco_var_fStack = fStack;
                                  $tco_var_valStack = Data_List_NonEmpty.cons(func.value1)(valStack);
                                  $tco_var_nat = nat;
                                  $tco_var_func = func.value0;
                                  $copy_count = count + 1 | 0;
                                  return;
                              };
                              throw new Error("Failed pattern match at Control.Applicative.Free (line 102, column 41 - line 105, column 81): " + [ func.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_valStack, $tco_var_nat, $tco_var_func, $copy_count);
                          };
                          return $tco_result;
                      };
                  };
              };
          };
      };
  };
  var goApply = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_vals) {
              return function ($copy_gVal) {
                  var $tco_var_dictApplicative = $copy_dictApplicative;
                  var $tco_var_fStack = $copy_fStack;
                  var $tco_var_vals = $copy_vals;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(dictApplicative, fStack, vals, gVal) {
                      if (fStack instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return new Data_Either.Left(gVal);
                      };
                      if (fStack instanceof Data_List_Types.Cons) {
                          var gRes = Control_Apply.apply(dictApplicative.Apply0())(fStack.value0.func)(gVal);
                          var $14 = fStack.value0.count === 1;
                          if ($14) {
                              if (fStack.value1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return new Data_Either.Left(gRes);
                              };
                              $tco_var_dictApplicative = dictApplicative;
                              $tco_var_fStack = fStack.value1;
                              $tco_var_vals = vals;
                              $copy_gVal = gRes;
                              return;
                          };
                          if (vals instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Data_Either.Left(gRes);
                          };
                          if (vals instanceof Data_List_Types.Cons) {
                              $tco_done = true;
                              return Data_Either.Right.create(new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                  func: gRes,
                                  count: fStack.value0.count - 1 | 0
                              }, fStack.value1), new Data_NonEmpty.NonEmpty(vals.value0, vals.value1)));
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 83, column 11 - line 88, column 50): " + [ vals.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 72, column 3 - line 88, column 50): " + [ fStack.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_vals, $copy_gVal);
                  };
                  return $tco_result;
              };
          };
      };
  };
  var functorFreeAp = {
      map: function (f) {
          return function (x) {
              return mkAp(new Pure(f))(x);
          };
      }
  };
  var foldFreeAp = function (dictApplicative) {
      return function (nat) {
          return function (z) {
              var go = function ($copy_v) {
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(v) {
                      if (v.value1.value0 instanceof Pure) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(Control_Applicative.pure(dictApplicative)(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 54, column 17 - line 56, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Lift) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(nat(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 57, column 17 - line 59, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Ap) {
                          var nextVals = new Data_NonEmpty.NonEmpty(v.value1.value0.value1, v.value1.value1);
                          $copy_v = goLeft(dictApplicative)(v.value0)(nextVals)(nat)(v.value1.value0.value0)(1);
                          return;
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 53, column 5 - line 62, column 47): " + [ v.value1.value0.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($copy_v);
                  };
                  return $tco_result;
              };
              return go(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_List_NonEmpty.singleton(z)));
          };
      };
  };
  var retractFreeAp = function (dictApplicative) {
      return foldFreeAp(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  };
  var applyFreeAp = {
      apply: function (fba) {
          return function (fb) {
              return mkAp(fba)(fb);
          };
      },
      Functor0: function () {
          return functorFreeAp;
      }
  };
  var applicativeFreeAp = {
      pure: Pure.create,
      Apply0: function () {
          return applyFreeAp;
      }
  };
  var hoistFreeAp = function (f) {
      return foldFreeAp(applicativeFreeAp)(function ($37) {
          return liftFreeAp(f($37));
      });
  };
  exports["retractFreeAp"] = retractFreeAp;
  exports["hoistFreeAp"] = hoistFreeAp;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Rec.Class"] = $PS["Control.Monad.Rec.Class"] || {};
  var exports = $PS["Control.Monad.Rec.Class"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];                
  var Loop = (function () {
      function Loop(value0) {
          this.value0 = value0;
      };
      Loop.create = function (value0) {
          return new Loop(value0);
      };
      return Loop;
  })();
  var Done = (function () {
      function Done(value0) {
          this.value0 = value0;
      };
      Done.create = function (value0) {
          return new Done(value0);
      };
      return Done;
  })();
  var tailRecM = function (dict) {
      return dict.tailRecM;
  };
  var monadRecEffect = {
      tailRecM: function (f) {
          return function (a) {
              var fromDone = function (v) {
                  if (v instanceof Done) {
                      return v.value0;
                  };
                  throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 113, column 30 - line 113, column 44): " + [ v.constructor.name ]);
              };
              return function __do() {
                  var r = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(f(a))();
                  (function () {
                      while (!(function __do() {
                          var v = Effect_Ref.read(r)();
                          if (v instanceof Loop) {
                              var e = f(v.value0)();
                              Effect_Ref.write(e)(r)();
                              return false;
                          };
                          if (v instanceof Done) {
                              return true;
                          };
                          throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 104, column 22 - line 109, column 28): " + [ v.constructor.name ]);
                      })()) {

                      };
                      return {};
                  })();
                  return Data_Functor.map(Effect.functorEffect)(fromDone)(Effect_Ref.read(r))();
              };
          };
      },
      Monad0: function () {
          return Effect.monadEffect;
      }
  };
  exports["Loop"] = Loop;
  exports["Done"] = Done;
  exports["tailRecM"] = tailRecM;
  exports["monadRecEffect"] = monadRecEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.CatQueue"] = $PS["Data.CatQueue"] || {};
  var exports = $PS["Data.CatQueue"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var CatQueue = (function () {
      function CatQueue(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatQueue.create = function (value0) {
          return function (value1) {
              return new CatQueue(value0, value1);
          };
      };
      return CatQueue;
  })();
  var uncons = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return Data_Maybe.Nothing.value;
          };
          if (v.value0 instanceof Data_List_Types.Nil) {
              $copy_v = new CatQueue(Data_List.reverse(v.value1), Data_List_Types.Nil.value);
              return;
          };
          if (v.value0 instanceof Data_List_Types.Cons) {
              $tco_done = true;
              return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0.value0, new CatQueue(v.value0.value1, v.value1)));
          };
          throw new Error("Failed pattern match at Data.CatQueue (line 83, column 1 - line 83, column 63): " + [ v.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var snoc = function (v) {
      return function (a) {
          return new CatQueue(v.value0, new Data_List_Types.Cons(a, v.value1));
      };
  };
  var $$null = function (v) {
      if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };
  var empty = new CatQueue(Data_List_Types.Nil.value, Data_List_Types.Nil.value);
  exports["empty"] = empty;
  exports["null"] = $$null;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.CatList"] = $PS["Data.CatList"] || {};
  var exports = $PS["Data.CatList"];
  var Data_CatQueue = $PS["Data.CatQueue"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var CatNil = (function () {
      function CatNil() {

      };
      CatNil.value = new CatNil();
      return CatNil;
  })();
  var CatCons = (function () {
      function CatCons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatCons.create = function (value0) {
          return function (value1) {
              return new CatCons(value0, value1);
          };
      };
      return CatCons;
  })();
  var link = function (v) {
      return function (v1) {
          if (v instanceof CatNil) {
              return v1;
          };
          if (v1 instanceof CatNil) {
              return v;
          };
          if (v instanceof CatCons) {
              return new CatCons(v.value0, Data_CatQueue.snoc(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.CatList (line 109, column 1 - line 109, column 54): " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var foldr = function (k) {
      return function (b) {
          return function (q) {
              var foldl = function ($copy_v) {
                  return function ($copy_c) {
                      return function ($copy_v1) {
                          var $tco_var_v = $copy_v;
                          var $tco_var_c = $copy_c;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(v, c, v1) {
                              if (v1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return c;
                              };
                              if (v1 instanceof Data_List_Types.Cons) {
                                  $tco_var_v = v;
                                  $tco_var_c = v(c)(v1.value0);
                                  $copy_v1 = v1.value1;
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.CatList (line 125, column 3 - line 125, column 59): " + [ v.constructor.name, c.constructor.name, v1.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_v, $tco_var_c, $copy_v1);
                          };
                          return $tco_result;
                      };
                  };
              };
              var go = function ($copy_xs) {
                  return function ($copy_ys) {
                      var $tco_var_xs = $copy_xs;
                      var $tco_done1 = false;
                      var $tco_result;
                      function $tco_loop(xs, ys) {
                          var v = Data_CatQueue.uncons(xs);
                          if (v instanceof Data_Maybe.Nothing) {
                              $tco_done1 = true;
                              return foldl(function (x) {
                                  return function (i) {
                                      return i(x);
                                  };
                              })(b)(ys);
                          };
                          if (v instanceof Data_Maybe.Just) {
                              $tco_var_xs = v.value0.value1;
                              $copy_ys = new Data_List_Types.Cons(k(v.value0.value0), ys);
                              return;
                          };
                          throw new Error("Failed pattern match at Data.CatList (line 121, column 14 - line 123, column 67): " + [ v.constructor.name ]);
                      };
                      while (!$tco_done1) {
                          $tco_result = $tco_loop($tco_var_xs, $copy_ys);
                      };
                      return $tco_result;
                  };
              };
              return go(q)(Data_List_Types.Nil.value);
          };
      };
  };
  var uncons = function (v) {
      if (v instanceof CatNil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof CatCons) {
          return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, (function () {
              var $45 = Data_CatQueue["null"](v.value1);
              if ($45) {
                  return CatNil.value;
              };
              return foldr(link)(CatNil.value)(v.value1);
          })()));
      };
      throw new Error("Failed pattern match at Data.CatList (line 100, column 1 - line 100, column 61): " + [ v.constructor.name ]);
  };
  var empty = CatNil.value;
  var append = link;
  var semigroupCatList = {
      append: append
  };
  var snoc = function (cat) {
      return function (a) {
          return append(cat)(new CatCons(a, Data_CatQueue.empty));
      };
  };
  exports["empty"] = empty;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
  exports["semigroupCatList"] = semigroupCatList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Free"] = $PS["Control.Monad.Free"] || {};
  var exports = $PS["Control.Monad.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Data_CatList = $PS["Data.CatList"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Free = (function () {
      function Free(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Free.create = function (value0) {
          return function (value1) {
              return new Free(value0, value1);
          };
      };
      return Free;
  })();
  var Return = (function () {
      function Return(value0) {
          this.value0 = value0;
      };
      Return.create = function (value0) {
          return new Return(value0);
      };
      return Return;
  })();
  var Bind = (function () {
      function Bind(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Bind.create = function (value0) {
          return function (value1) {
              return new Bind(value0, value1);
          };
      };
      return Bind;
  })();
  var toView = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          var runExpF = function (v2) {
              return v2;
          };
          var concatF = function (v2) {
              return function (r) {
                  return new Free(v2.value0, Data_Semigroup.append(Data_CatList.semigroupCatList)(v2.value1)(r));
              };
          };
          if (v.value0 instanceof Return) {
              var v2 = Data_CatList.uncons(v.value1);
              if (v2 instanceof Data_Maybe.Nothing) {
                  $tco_done = true;
                  return new Return(v.value0.value0);
              };
              if (v2 instanceof Data_Maybe.Just) {
                  $copy_v = concatF(runExpF(v2.value0.value0)(v.value0.value0))(v2.value0.value1);
                  return;
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 227, column 7 - line 231, column 64): " + [ v2.constructor.name ]);
          };
          if (v.value0 instanceof Bind) {
              $tco_done = true;
              return new Bind(v.value0.value0, function (a) {
                  return concatF(v.value0.value1(a))(v.value1);
              });
          };
          throw new Error("Failed pattern match at Control.Monad.Free (line 225, column 3 - line 233, column 56): " + [ v.value0.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var fromView = function (f) {
      return new Free(f, Data_CatList.empty);
  };
  var freeMonad = {
      Applicative0: function () {
          return freeApplicative;
      },
      Bind1: function () {
          return freeBind;
      }
  };
  var freeFunctor = {
      map: function (k) {
          return function (f) {
              return Control_Bind.bindFlipped(freeBind)((function () {
                  var $119 = Control_Applicative.pure(freeApplicative);
                  return function ($120) {
                      return $119(k($120));
                  };
              })())(f);
          };
      }
  };
  var freeBind = {
      bind: function (v) {
          return function (k) {
              return new Free(v.value0, Data_CatList.snoc(v.value1)(k));
          };
      },
      Apply0: function () {
          return freeApply;
      }
  };
  var freeApply = {
      apply: Control_Monad.ap(freeMonad),
      Functor0: function () {
          return freeFunctor;
      }
  };
  var freeApplicative = {
      pure: function ($121) {
          return fromView(Return.create($121));
      },
      Apply0: function () {
          return freeApply;
      }
  };
  var liftF = function (f) {
      return fromView(new Bind(f, (function () {
          var $122 = Control_Applicative.pure(freeApplicative);
          return function ($123) {
              return $122($123);
          };
      })()));
  };
  var substFree = function (k) {
      var go = function (f) {
          var v = toView(f);
          if (v instanceof Return) {
              return Control_Applicative.pure(freeApplicative)(v.value0);
          };
          if (v instanceof Bind) {
              return Control_Bind.bind(freeBind)(k(v.value0))(Data_Functor.map(Data_Functor.functorFn)(go)(v.value1));
          };
          throw new Error("Failed pattern match at Control.Monad.Free (line 168, column 10 - line 170, column 33): " + [ v.constructor.name ]);
      };
      return go;
  };
  var hoistFree = function (k) {
      return substFree(function ($124) {
          return liftF(k($124));
      });
  };
  var foldFree = function (dictMonadRec) {
      return function (k) {
          var go = function (f) {
              var v = toView(f);
              if (v instanceof Return) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Done.create)(Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(v.value0));
              };
              if (v instanceof Bind) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(function ($135) {
                      return Control_Monad_Rec_Class.Loop.create(v.value1($135));
                  })(k(v.value0));
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 158, column 10 - line 160, column 37): " + [ v.constructor.name ]);
          };
          return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go);
      };
  };
  exports["liftF"] = liftF;
  exports["hoistFree"] = hoistFree;
  exports["foldFree"] = foldFree;
  exports["freeFunctor"] = freeFunctor;
  exports["freeBind"] = freeBind;
  exports["freeApplicative"] = freeApplicative;
  exports["freeMonad"] = freeMonad;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Query.HalogenM"] = $PS["Halogen.Query.HalogenM"] || {};
  var exports = $PS["Halogen.Query.HalogenM"];
  var Control_Applicative_Free = $PS["Control.Applicative.Free"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Class = $PS["Effect.Class"];                                
  var SubscriptionId = function (x) {
      return x;
  };
  var ForkId = function (x) {
      return x;
  };
  var State = (function () {
      function State(value0) {
          this.value0 = value0;
      };
      State.create = function (value0) {
          return new State(value0);
      };
      return State;
  })();
  var Subscribe = (function () {
      function Subscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Subscribe.create = function (value0) {
          return function (value1) {
              return new Subscribe(value0, value1);
          };
      };
      return Subscribe;
  })();
  var Unsubscribe = (function () {
      function Unsubscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Unsubscribe.create = function (value0) {
          return function (value1) {
              return new Unsubscribe(value0, value1);
          };
      };
      return Unsubscribe;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var ChildQuery = (function () {
      function ChildQuery(value0) {
          this.value0 = value0;
      };
      ChildQuery.create = function (value0) {
          return new ChildQuery(value0);
      };
      return ChildQuery;
  })();
  var Raise = (function () {
      function Raise(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Raise.create = function (value0) {
          return function (value1) {
              return new Raise(value0, value1);
          };
      };
      return Raise;
  })();
  var Par = (function () {
      function Par(value0) {
          this.value0 = value0;
      };
      Par.create = function (value0) {
          return new Par(value0);
      };
      return Par;
  })();
  var Fork = (function () {
      function Fork(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Fork.create = function (value0) {
          return function (value1) {
              return new Fork(value0, value1);
          };
      };
      return Fork;
  })();
  var Kill = (function () {
      function Kill(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Kill.create = function (value0) {
          return function (value1) {
              return new Kill(value0, value1);
          };
      };
      return Kill;
  })();
  var GetRef = (function () {
      function GetRef(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      GetRef.create = function (value0) {
          return function (value1) {
              return new GetRef(value0, value1);
          };
      };
      return GetRef;
  })();
  var HalogenAp = function (x) {
      return x;
  };
  var HalogenM = function (x) {
      return x;
  };
  var subscribe = function (es) {
      return HalogenM(Control_Monad_Free.liftF(new Subscribe(function (v) {
          return es;
      }, Control_Category.identity(Control_Category.categoryFn))));
  };
  var raise = function (o) {
      return HalogenM(Control_Monad_Free.liftF(new Raise(o, Data_Unit.unit)));
  };
  var ordSubscriptionId = Data_Ord.ordInt;
  var ordForkId = Data_Ord.ordInt;
  var monadTransHalogenM = {
      lift: function (dictMonad) {
          return function ($135) {
              return HalogenM(Control_Monad_Free.liftF(Lift.create($135)));
          };
      }
  };
  var monadHalogenM = Control_Monad_Free.freeMonad;
  var monadStateHalogenM = {
      state: function ($136) {
          return HalogenM(Control_Monad_Free.liftF(State.create($136)));
      },
      Monad0: function () {
          return monadHalogenM;
      }
  };
  var monadEffectHalogenM = function (dictMonadEffect) {
      return {
          liftEffect: (function () {
              var $141 = Effect_Class.liftEffect(dictMonadEffect);
              return function ($142) {
                  return HalogenM(Control_Monad_Free.liftF(Lift.create($141($142))));
              };
          })(),
          Monad0: function () {
              return monadHalogenM;
          }
      };
  };
  var hoist = function (dictFunctor) {
      return function (nat) {
          return function (v) {
              var go = function (v1) {
                  if (v1 instanceof State) {
                      return new State(v1.value0);
                  };
                  if (v1 instanceof Subscribe) {
                      return new Subscribe(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Unsubscribe) {
                      return new Unsubscribe(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Lift) {
                      return new Lift(nat(v1.value0));
                  };
                  if (v1 instanceof ChildQuery) {
                      return new ChildQuery(v1.value0);
                  };
                  if (v1 instanceof Raise) {
                      return new Raise(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Par) {
                      return new Par(Data_Newtype.over()()(HalogenAp)(Control_Applicative_Free.hoistFreeAp(hoist(dictFunctor)(nat)))(v1.value0));
                  };
                  if (v1 instanceof Fork) {
                      return new Fork(hoist(dictFunctor)(nat)(v1.value0), v1.value1);
                  };
                  if (v1 instanceof Kill) {
                      return new Kill(v1.value0, v1.value1);
                  };
                  if (v1 instanceof GetRef) {
                      return new GetRef(v1.value0, v1.value1);
                  };
                  throw new Error("Failed pattern match at Halogen.Query.HalogenM (line 300, column 8 - line 310, column 29): " + [ v1.constructor.name ]);
              };
              return Control_Monad_Free.hoistFree(go)(v);
          };
      };
  };
  var getRef = function (p) {
      return HalogenM(Control_Monad_Free.liftF(new GetRef(p, Control_Category.identity(Control_Category.categoryFn))));
  };
  var functorHalogenM = Control_Monad_Free.freeFunctor;
  var bindHalogenM = Control_Monad_Free.freeBind;           
  var applicativeHalogenM = Control_Monad_Free.freeApplicative;
  exports["State"] = State;
  exports["Subscribe"] = Subscribe;
  exports["Unsubscribe"] = Unsubscribe;
  exports["Lift"] = Lift;
  exports["ChildQuery"] = ChildQuery;
  exports["Raise"] = Raise;
  exports["Par"] = Par;
  exports["Fork"] = Fork;
  exports["Kill"] = Kill;
  exports["GetRef"] = GetRef;
  exports["raise"] = raise;
  exports["SubscriptionId"] = SubscriptionId;
  exports["subscribe"] = subscribe;
  exports["ForkId"] = ForkId;
  exports["getRef"] = getRef;
  exports["hoist"] = hoist;
  exports["functorHalogenM"] = functorHalogenM;
  exports["applicativeHalogenM"] = applicativeHalogenM;
  exports["bindHalogenM"] = bindHalogenM;
  exports["monadEffectHalogenM"] = monadEffectHalogenM;
  exports["monadTransHalogenM"] = monadTransHalogenM;
  exports["monadStateHalogenM"] = monadStateHalogenM;
  exports["ordSubscriptionId"] = ordSubscriptionId;
  exports["ordForkId"] = ordForkId;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Query.HalogenQ"] = $PS["Halogen.Query.HalogenQ"] || {};
  var exports = $PS["Halogen.Query.HalogenQ"];           
  var Initialize = (function () {
      function Initialize(value0) {
          this.value0 = value0;
      };
      Initialize.create = function (value0) {
          return new Initialize(value0);
      };
      return Initialize;
  })();
  var Finalize = (function () {
      function Finalize(value0) {
          this.value0 = value0;
      };
      Finalize.create = function (value0) {
          return new Finalize(value0);
      };
      return Finalize;
  })();
  var Receive = (function () {
      function Receive(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Receive.create = function (value0) {
          return function (value1) {
              return new Receive(value0, value1);
          };
      };
      return Receive;
  })();
  var Action = (function () {
      function Action(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Action.create = function (value0) {
          return function (value1) {
              return new Action(value0, value1);
          };
      };
      return Action;
  })();
  var Query = (function () {
      function Query(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Query.create = function (value0) {
          return function (value1) {
              return new Query(value0, value1);
          };
      };
      return Query;
  })();
  exports["Initialize"] = Initialize;
  exports["Finalize"] = Finalize;
  exports["Receive"] = Receive;
  exports["Action"] = Action;
  exports["Query"] = Query;
})(PS);
(function(exports) {
  "use strict";

  exports.getElementsByClassName = function (classNames) {
    return function (doc) {
      return function () {
        return doc.getElementsByClassName(classNames);
      };
    };
  };

  exports._getAttribute = function (name) {
    return function (element) {
      return function () {
        return element.getAttribute(name);
      };
    };
  };

  exports._closest = function (selector) {
    return function(element) {
      return function () {
        return element.closest(selector);
      };
    };
  };
})(PS["Web.DOM.Element"] = PS["Web.DOM.Element"] || {});
(function(exports) {
  "use strict";

  exports._unsafeReadProtoTagged = function (nothing, just, name, value) {
    if (typeof window !== "undefined") {
      var ty = window[name];
      if (ty != null && value instanceof ty) {
        return just(value);
      }
    }
    var obj = value;
    while (obj != null) {
      var proto = Object.getPrototypeOf(obj);
      var constructorName = proto.constructor.name;
      if (constructorName === name) {
        return just(value);
      } else if (constructorName === "Object") {
        return nothing;
      }
      obj = proto;
    }
    return nothing;
  };
})(PS["Web.Internal.FFI"] = PS["Web.Internal.FFI"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.Internal.FFI"] = $PS["Web.Internal.FFI"] || {};
  var exports = $PS["Web.Internal.FFI"];
  var $foreign = $PS["Web.Internal.FFI"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var unsafeReadProtoTagged = function (name) {
      return function (value) {
          return $foreign["_unsafeReadProtoTagged"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, name, value);
      };
  };
  exports["unsafeReadProtoTagged"] = unsafeReadProtoTagged;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.DOM.Element"] = $PS["Web.DOM.Element"] || {};
  var exports = $PS["Web.DOM.Element"];
  var $foreign = $PS["Web.DOM.Element"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Internal_FFI = $PS["Web.Internal.FFI"];                
  var toParentNode = Unsafe_Coerce.unsafeCoerce;              
  var toNode = Unsafe_Coerce.unsafeCoerce;
  var getAttribute = function (attr) {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $4 = $foreign["_getAttribute"](attr);
      return function ($5) {
          return $3($4($5));
      };
  };                                                               
  var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("Element");
  var closest = function (qs) {
      var $6 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $7 = $foreign["_closest"](qs);
      return function ($8) {
          return $6($7($8));
      };
  };
  exports["fromEventTarget"] = fromEventTarget;
  exports["toNode"] = toNode;
  exports["toParentNode"] = toParentNode;
  exports["getAttribute"] = getAttribute;
  exports["closest"] = closest;
  exports["getElementsByClassName"] = $foreign.getElementsByClassName;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.VDom.DOM"] = $PS["Halogen.VDom.DOM"] || {};
  var exports = $PS["Halogen.VDom.DOM"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Web_DOM_Element = $PS["Web.DOM.Element"];
  var haltWidget = function (v) {
      return Halogen_VDom_Machine.halt(v.widget);
  };
  var patchWidget = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchWidget(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Widget) {
          var res = Halogen_VDom_Machine.step(state.widget, vdom.value0);
          var res$prime = Halogen_VDom_Machine.unStep(function (v) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v.value0, {
                  build: state.build,
                  widget: res
              }, patchWidget, haltWidget));
          })(res);
          return res$prime;
      };
      haltWidget(state);
      return state.build(vdom);
  };
  var haltText = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      return Halogen_VDom_Util.removeChild(v.node, parent);
  };
  var patchText = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchText(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Text) {
          if (state.value === vdom.value0) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, state, patchText, haltText));
          };
          if (Data_Boolean.otherwise) {
              var nextState = {
                  build: state.build,
                  node: state.node,
                  value: vdom.value0
              };
              Halogen_VDom_Util.setTextContent(vdom.value0, state.node);
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchText, haltText));
          };
      };
      haltText(state);
      return state.build(vdom);
  };
  var haltKeyed = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, parent);
      Halogen_VDom_Util.forInE(v.children, function (v1, s) {
          return Halogen_VDom_Machine.halt(s);
      });
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var haltElem = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, parent);
      Halogen_VDom_Util.forEachE(v.children, Halogen_VDom_Machine.halt);
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var eqElemSpec = function (ns1, v, ns2, v1) {
      var $58 = v === v1;
      if ($58) {
          if (ns1 instanceof Data_Maybe.Just && (ns2 instanceof Data_Maybe.Just && ns1.value0 === ns2.value0)) {
              return true;
          };
          if (ns1 instanceof Data_Maybe.Nothing && ns2 instanceof Data_Maybe.Nothing) {
              return true;
          };
          return false;
      };
      return false;
  };
  var patchElem = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchElem(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Elem && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          var v1 = Data_Array.length(state.children);
          if (v1 === 0 && v === 0) {
              var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: attrs2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
          };
          var onThis = function (v2, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (ix, s, v2) {
              var res = Halogen_VDom_Machine.step(s, v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var onThat = function (ix, v2) {
              var res = state.build(v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var children2 = Halogen_VDom_Util.diffWithIxE(state.children, vdom.value3, onThese, onThis, onThat);
          var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: attrs2,
              ns: vdom.value0,
              name: vdom.value1,
              children: children2
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
      };
      haltElem(state);
      return state.build(vdom);
  };
  var patchKeyed = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchKeyed(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Keyed && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          if (state.length === 0 && v === 0) {
              var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: attrs2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children,
                  length: 0
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
          };
          var onThis = function (v2, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (v2, ix$prime, s, v3) {
              var res = Halogen_VDom_Machine.step(s, v3.value1);
              Halogen_VDom_Util.insertChildIx(ix$prime, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var onThat = function (v2, ix, v3) {
              var res = state.build(v3.value1);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var children2 = Halogen_VDom_Util.diffWithKeyAndIxE(state.children, vdom.value3, Data_Tuple.fst, onThese, onThis, onThat);
          var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: attrs2,
              ns: vdom.value0,
              name: vdom.value1,
              children: children2,
              length: v
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
      };
      haltKeyed(state);
      return state.build(vdom);
  };
  var buildWidget = function (v, build, w) {
      var res = v.buildWidget(v)(w);
      var res$prime = Halogen_VDom_Machine.unStep(function (v1) {
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v1.value0, {
              build: build,
              widget: res
          }, patchWidget, haltWidget));
      })(res);
      return res$prime;
  };
  var buildText = function (v, build, s) {
      var node = Halogen_VDom_Util.createTextNode(s, v.document);
      var state = {
          build: build,
          node: node,
          value: s
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchText, haltText));
  };
  var buildKeyed = function (v, build, ns1, name1, as1, ch1) {
      var el = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(el);
      var onChild = function (v1, ix, v2) {
          var res = build(v2.value1);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), node);
          return res;
      };
      var children = Halogen_VDom_Util.strMapWithIxE(ch1, Data_Tuple.fst, onChild);
      var attrs = v.buildAttributes(el)(as1);
      var state = {
          build: build,
          node: node,
          attrs: attrs,
          ns: ns1,
          name: name1,
          children: children,
          length: Data_Array.length(ch1)
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchKeyed, haltKeyed));
  };
  var buildElem = function (v, build, ns1, name1, as1, ch1) {
      var el = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(el);
      var onChild = function (ix, child) {
          var res = build(child);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), node);
          return res;
      };
      var children = Halogen_VDom_Util.forE(ch1, onChild);
      var attrs = v.buildAttributes(el)(as1);
      var state = {
          build: build,
          node: node,
          attrs: attrs,
          ns: ns1,
          name: name1,
          children: children
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchElem, haltElem));
  };
  var buildVDom = function (spec) {
      var build = function (v) {
          if (v instanceof Halogen_VDom_Types.Text) {
              return buildText(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Elem) {
              return buildElem(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Keyed) {
              return buildKeyed(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Widget) {
              return buildWidget(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Grafted) {
              return build(Halogen_VDom_Types.runGraft(v.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.DOM (line 58, column 27 - line 63, column 52): " + [ v.constructor.name ]);
      };
      return build;
  };
  exports["buildVDom"] = buildVDom;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.VDom.Thunk"] = $PS["Halogen.VDom.Thunk"] || {};
  var exports = $PS["Halogen.VDom.Thunk"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];        
  var Thunk = (function () {
      function Thunk(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Thunk.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Thunk(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Thunk;
  })();                                          
  var unsafeEqThunk = function (v, v1) {
      return Halogen_VDom_Util.refEq(v.value0, v1.value0) && (Halogen_VDom_Util.refEq(v.value1, v1.value1) && v.value1(v.value3, v1.value3));
  };
  var thunk = function (tid, eqFn, f, a) {
      return new Thunk(tid, eqFn, f, a);
  };
  var runThunk = function (v) {
      return v.value2(v.value3);
  };
  var mapThunk = function (k) {
      return function (v) {
          return new Thunk(v.value0, v.value1, function ($46) {
              return k(v.value2($46));
          }, v.value3);
      };
  };
  var hoist = mapThunk;
  var buildThunk = function (toVDom) {
      var haltThunk = function (state) {
          return Halogen_VDom_Machine.halt(state.vdom);
      };
      var patchThunk = function (state, t2) {
          var $43 = unsafeEqThunk(state.thunk, t2);
          if ($43) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(state.vdom), state, patchThunk, haltThunk));
          };
          var vdom = Halogen_VDom_Machine.step(state.vdom, toVDom(runThunk(t2)));
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
              vdom: vdom,
              thunk: t2
          }, patchThunk, haltThunk));
      };
      var renderThunk = function (spec) {
          return function (t) {
              var vdom = Halogen_VDom_DOM.buildVDom(spec)(toVDom(runThunk(t)));
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
                  thunk: t,
                  vdom: vdom
              }, patchThunk, haltThunk));
          };
      };
      return renderThunk;
  };
  exports["buildThunk"] = buildThunk;
  exports["hoist"] = hoist;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Component"] = $PS["Halogen.Component"] || {};
  var exports = $PS["Halogen.Component"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_VDom_Thunk = $PS["Halogen.VDom.Thunk"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ComponentSlot = (function () {
      function ComponentSlot(value0) {
          this.value0 = value0;
      };
      ComponentSlot.create = function (value0) {
          return new ComponentSlot(value0);
      };
      return ComponentSlot;
  })();
  var ThunkSlot = (function () {
      function ThunkSlot(value0) {
          this.value0 = value0;
      };
      ThunkSlot.create = function (value0) {
          return new ThunkSlot(value0);
      };
      return ThunkSlot;
  })();
  var unComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var unComponent = Unsafe_Coerce.unsafeCoerce;
  var mkEval = function (args) {
      return function (v) {
          if (v instanceof Halogen_Query_HalogenQ.Initialize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.initialize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Finalize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.finalize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Receive) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.receive(v.value0)))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Action) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(args.handleAction(v.value0))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Query) {
              return Data_Coyoneda.unCoyoneda(function (g) {
                  var $25 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(Data_Maybe.maybe(v.value1(Data_Unit.unit))(g));
                  return function ($26) {
                      return $25(args.handleQuery($26));
                  };
              })(v.value0);
          };
          throw new Error("Failed pattern match at Halogen.Component (line 182, column 15 - line 192, column 70): " + [ v.constructor.name ]);
      };
  };
  var mkComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var mkComponent = Unsafe_Coerce.unsafeCoerce;
  var hoistSlot = function (dictFunctor) {
      return function (nat) {
          return function (v) {
              if (v instanceof ComponentSlot) {
                  return unComponentSlot(function (slot) {
                      return ComponentSlot.create(mkComponentSlot({
                          get: slot.get,
                          pop: slot.pop,
                          set: slot.set,
                          component: hoist(dictFunctor)(nat)(slot.component),
                          input: slot.input,
                          output: slot.output
                      }));
                  })(v.value0);
              };
              if (v instanceof ThunkSlot) {
                  return ThunkSlot.create(Halogen_VDom_Thunk.hoist(Data_Bifunctor.lmap(Halogen_HTML_Core.bifunctorHTML)(hoistSlot(dictFunctor)(nat)))(v.value0));
              };
              throw new Error("Failed pattern match at Halogen.Component (line 279, column 17 - line 284, column 53): " + [ v.constructor.name ]);
          };
      };
  };
  var hoist = function (dictFunctor) {
      return function (nat) {
          return unComponent(function (c) {
              return mkComponent({
                  initialState: c.initialState,
                  render: (function () {
                      var $27 = Data_Bifunctor.lmap(Halogen_HTML_Core.bifunctorHTML)(hoistSlot(dictFunctor)(nat));
                      return function ($28) {
                          return $27(c.render($28));
                      };
                  })(),
                  "eval": (function () {
                      var $29 = Halogen_Query_HalogenM.hoist(dictFunctor)(nat);
                      return function ($30) {
                          return $29(c["eval"]($30));
                      };
                  })()
              });
          });
      };
  };
  var defaultEval = {
      handleAction: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)),
      handleQuery: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Nothing.value)),
      receive: Data_Function["const"](Data_Maybe.Nothing.value),
      initialize: Data_Maybe.Nothing.value,
      finalize: Data_Maybe.Nothing.value
  };
  var componentSlot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (comp) {
                          return function (input) {
                              return function (output) {
                                  return mkComponentSlot({
                                      get: Halogen_Data_Slot.lookup()(dictIsSymbol)(dictOrd)(label)(p),
                                      pop: Halogen_Data_Slot.pop()(dictIsSymbol)(dictOrd)(label)(p),
                                      set: Halogen_Data_Slot.insert()(dictIsSymbol)(dictOrd)(label)(p),
                                      component: comp,
                                      input: input,
                                      output: output
                                  });
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["mkComponent"] = mkComponent;
  exports["unComponent"] = unComponent;
  exports["hoist"] = hoist;
  exports["mkEval"] = mkEval;
  exports["defaultEval"] = defaultEval;
  exports["ComponentSlot"] = ComponentSlot;
  exports["ThunkSlot"] = ThunkSlot;
  exports["componentSlot"] = componentSlot;
  exports["unComponentSlot"] = unComponentSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.HTML.Elements"] = $PS["Halogen.HTML.Elements"] || {};
  var exports = $PS["Halogen.HTML.Elements"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var element = Halogen_HTML_Core.element(Data_Maybe.Nothing.value);
  var input = function (props) {
      return element("input")(props)([  ]);
  };                 
  var div = element("div");
  var div_ = div([  ]);
  exports["div"] = div;
  exports["div_"] = div_;
  exports["input"] = input;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.HTML.Event.EventTypes"] = $PS["Web.HTML.Event.EventTypes"] || {};
  var exports = $PS["Web.HTML.Event.EventTypes"];
  var domcontentloaded = "DOMContentLoaded";
  var blur = "blur";
  exports["blur"] = blur;
  exports["domcontentloaded"] = domcontentloaded;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.UIEvent.KeyboardEvent.EventTypes"] = $PS["Web.UIEvent.KeyboardEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.KeyboardEvent.EventTypes"];
  var keydown = "keydown";
  exports["keydown"] = keydown;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.UIEvent.MouseEvent.EventTypes"] = $PS["Web.UIEvent.MouseEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var mouseup = "mouseup";  
  var mousemove = "mousemove";  
  var mousedown = "mousedown";
  var dblclick = "dblclick";
  var click = "click";
  exports["click"] = click;
  exports["dblclick"] = dblclick;
  exports["mousedown"] = mousedown;
  exports["mousemove"] = mousemove;
  exports["mouseup"] = mouseup;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.HTML.Events"] = $PS["Halogen.HTML.Events"] || {};
  var exports = $PS["Halogen.HTML.Events"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_HTML_Event_EventTypes = $PS["Web.HTML.Event.EventTypes"];
  var Web_UIEvent_KeyboardEvent_EventTypes = $PS["Web.UIEvent.KeyboardEvent.EventTypes"];
  var Web_UIEvent_MouseEvent_EventTypes = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var mouseHandler = Unsafe_Coerce.unsafeCoerce;
  var keyHandler = Unsafe_Coerce.unsafeCoerce;
  var handler = function (et) {
      return function (f) {
          return Halogen_HTML_Core.handler(et)(function (ev) {
              return new Data_Maybe.Just(new Halogen_Query_Input.Action(f(ev)));
          });
      };
  };                                                       
  var onClick = (function () {
      var $1 = handler(Web_UIEvent_MouseEvent_EventTypes.click);
      return function ($2) {
          return $1(mouseHandler($2));
      };
  })();
  var onDoubleClick = (function () {
      var $3 = handler(Web_UIEvent_MouseEvent_EventTypes.dblclick);
      return function ($4) {
          return $3(mouseHandler($4));
      };
  })();                                                      
  var onKeyDown = (function () {
      var $9 = handler(Web_UIEvent_KeyboardEvent_EventTypes.keydown);
      return function ($10) {
          return $9(keyHandler($10));
      };
  })();                                                
  var onMouseDown = (function () {
      var $13 = handler(Web_UIEvent_MouseEvent_EventTypes.mousedown);
      return function ($14) {
          return $13(mouseHandler($14));
      };
  })();
  var onMouseMove = (function () {
      var $19 = handler(Web_UIEvent_MouseEvent_EventTypes.mousemove);
      return function ($20) {
          return $19(mouseHandler($20));
      };
  })();
  var onMouseUp = (function () {
      var $25 = handler(Web_UIEvent_MouseEvent_EventTypes.mouseup);
      return function ($26) {
          return $25(mouseHandler($26));
      };
  })();
  var focusHandler = Unsafe_Coerce.unsafeCoerce;
  var onBlur = (function () {
      var $41 = handler(Web_HTML_Event_EventTypes.blur);
      return function ($42) {
          return $41(focusHandler($42));
      };
  })();
  exports["onClick"] = onClick;
  exports["onDoubleClick"] = onDoubleClick;
  exports["onMouseDown"] = onMouseDown;
  exports["onMouseMove"] = onMouseMove;
  exports["onMouseUp"] = onMouseUp;
  exports["onKeyDown"] = onKeyDown;
  exports["onBlur"] = onBlur;
})(PS);
(function(exports) {
  "use strict";

  exports.split = function (sep) {
    return function (s) {
      return s.split(sep);
    };
  };

  exports.joinWith = function (s) {
    return function (xs) {
      return xs.join(s);
    };
  };
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.String.Common"] = $PS["Data.String.Common"] || {};
  var exports = $PS["Data.String.Common"];
  var $foreign = $PS["Data.String.Common"];
  exports["split"] = $foreign.split;
  exports["joinWith"] = $foreign.joinWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.HTML.Properties"] = $PS["Halogen.HTML.Properties"] || {};
  var exports = $PS["Halogen.HTML.Properties"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var ref = (function () {
      var go = function (p) {
          return function (mel) {
              return new Data_Maybe.Just(new Halogen_Query_Input.RefUpdate(p, mel));
          };
      };
      return function ($9) {
          return Halogen_HTML_Core.ref(go($9));
      };
  })();
  var prop = function (dictIsProp) {
      return Halogen_HTML_Core.prop(dictIsProp);
  };                                                        
  var type_ = function (dictIsProp) {
      return prop(dictIsProp)("type");
  };
  var value = prop(Halogen_HTML_Core.isPropString)("value"); 
  var classes = (function () {
      var $12 = prop(Halogen_HTML_Core.isPropString)("className");
      var $13 = Data_String_Common.joinWith(" ");
      var $14 = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap());
      return function ($15) {
          return $12($13($14($15)));
      };
  })();
  var class_ = (function () {
      var $16 = prop(Halogen_HTML_Core.isPropString)("className");
      var $17 = Data_Newtype.unwrap();
      return function ($18) {
          return $16($17($18));
      };
  })();
  var attr = Halogen_HTML_Core.attr(Data_Maybe.Nothing.value);
  exports["attr"] = attr;
  exports["ref"] = ref;
  exports["class_"] = class_;
  exports["classes"] = classes;
  exports["type_"] = type_;
  exports["value"] = value;
})(PS);
(function(exports) {
  "use strict";

  exports._read = function (nothing, just, value) {
    var tag = Object.prototype.toString.call(value);
    if (tag.indexOf("[object HTML") === 0 && tag.indexOf("Element]") === tag.length - 8) {
      return just(value);
    } else {
      return nothing;
    }
  };

  exports.focus = function (elt) {
    return function () {
      return elt.focus();
    };
  };

  // - CSSOM ---------------------------------------------------------------------

  exports.getBoundingClientRect = function (el) {
    return function () {
      var rect = el.getBoundingClientRect();
      return {
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
        width: rect.width,
        height: rect.height
      };
    };
  };
})(PS["Web.HTML.HTMLElement"] = PS["Web.HTML.HTMLElement"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.HTML.HTMLElement"] = $PS["Web.HTML.HTMLElement"] || {};
  var exports = $PS["Web.HTML.HTMLElement"];
  var $foreign = $PS["Web.HTML.HTMLElement"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;       
  var toElement = Unsafe_Coerce.unsafeCoerce;
  var fromEventTarget = function (x) {
      return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
  };
  var fromElement = function (x) {
      return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
  };
  exports["fromElement"] = fromElement;
  exports["fromEventTarget"] = fromEventTarget;
  exports["toElement"] = toElement;
  exports["toNode"] = toNode;
  exports["focus"] = $foreign.focus;
  exports["getBoundingClientRect"] = $foreign.getBoundingClientRect;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Query"] = $PS["Halogen.Query"] || {};
  var exports = $PS["Halogen.Query"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var getHTMLElementRef = (function () {
      var $13 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(function (v) {
          return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(v);
      });
      return function ($14) {
          return $13(Halogen_Query_HalogenM.getRef($14));
      };
  })();
  exports["getHTMLElementRef"] = getHTMLElementRef;
})(PS);
(function(exports) {
  "use strict";

  exports._currentTarget = function (e) {
    return e.currentTarget;
  };

  exports._target = function (e) {
    return e.target;
  };

  exports.preventDefault = function (e) {
    return function () {
      return e.preventDefault();
    };
  };

  exports.stopPropagation = function (e) {
    return function () {
      return e.stopPropagation();
    };
  };
})(PS["Web.Event.Event"] = PS["Web.Event.Event"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.Event.Event"] = $PS["Web.Event.Event"] || {};
  var exports = $PS["Web.Event.Event"];
  var $foreign = $PS["Web.Event.Event"];
  var Data_Nullable = $PS["Data.Nullable"];
  var target = function ($1) {
      return Data_Nullable.toMaybe($foreign["_target"]($1));
  };                                 
  var currentTarget = function ($5) {
      return Data_Nullable.toMaybe($foreign["_currentTarget"]($5));
  };
  exports["target"] = target;
  exports["currentTarget"] = currentTarget;
  exports["stopPropagation"] = $foreign.stopPropagation;
  exports["preventDefault"] = $foreign.preventDefault;
})(PS);
(function(exports) {
  "use strict";

  // ----------------------------------------------------------------------------

  exports.value = function (input) {
    return function () {
      return input.value;
    };
  };

  // ----------------------------------------------------------------------------

  exports.select = function (input) {
    return function () {
      input.select();
    };
  };
})(PS["Web.HTML.HTMLInputElement"] = PS["Web.HTML.HTMLInputElement"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.HTML.HTMLInputElement"] = $PS["Web.HTML.HTMLInputElement"] || {};
  var exports = $PS["Web.HTML.HTMLInputElement"];
  var $foreign = $PS["Web.HTML.HTMLInputElement"];
  var Web_Internal_FFI = $PS["Web.Internal.FFI"];                           
  var fromHTMLElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLInputElement");
  exports["fromHTMLElement"] = fromHTMLElement;
  exports["value"] = $foreign.value;
  exports["select"] = $foreign.select;
})(PS);
(function(exports) {
  "use strict";

  exports.code = function (e) {
    return e.code;
  };

  exports.shiftKey = function (e) {
    return e.shiftKey;
  };
})(PS["Web.UIEvent.KeyboardEvent"] = PS["Web.UIEvent.KeyboardEvent"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.UIEvent.KeyboardEvent"] = $PS["Web.UIEvent.KeyboardEvent"] || {};
  var exports = $PS["Web.UIEvent.KeyboardEvent"];
  var $foreign = $PS["Web.UIEvent.KeyboardEvent"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Internal_FFI = $PS["Web.Internal.FFI"];
  var toEvent = Unsafe_Coerce.unsafeCoerce;                                 
  var fromEvent = Web_Internal_FFI.unsafeReadProtoTagged("KeyboardEvent");
  exports["fromEvent"] = fromEvent;
  exports["toEvent"] = toEvent;
  exports["code"] = $foreign.code;
  exports["shiftKey"] = $foreign.shiftKey;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Component.LabelEditor"] = $PS["Component.LabelEditor"] || {};
  var exports = $PS["Component.LabelEditor"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var DOM_HTML_Indexed_InputType = $PS["DOM.HTML.Indexed.InputType"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Halogen_Query = $PS["Halogen.Query"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Web_Event_Event = $PS["Web.Event.Event"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_HTMLInputElement = $PS["Web.HTML.HTMLInputElement"];
  var Web_UIEvent_KeyboardEvent = $PS["Web.UIEvent.KeyboardEvent"];                
  var Noop = (function () {
      function Noop() {

      };
      Noop.value = new Noop();
      return Noop;
  })();
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var StopPropagation = (function () {
      function StopPropagation(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      StopPropagation.create = function (value0) {
          return function (value1) {
              return new StopPropagation(value0, value1);
          };
      };
      return StopPropagation;
  })();
  var HandleKeyPress = (function () {
      function HandleKeyPress(value0) {
          this.value0 = value0;
      };
      HandleKeyPress.create = function (value0) {
          return new HandleKeyPress(value0);
      };
      return HandleKeyPress;
  })();
  var Cancelled = (function () {
      function Cancelled() {

      };
      Cancelled.value = new Cancelled();
      return Cancelled;
  })();
  var Finished = (function () {
      function Finished() {

      };
      Finished.value = new Finished();
      return Finished;
  })();
  var receive = function (v) {
      return Data_Maybe.Nothing.value;
  };
  var inputRef = "input";
  var render = function (dictMonadAff) {
      return function (state) {
          var attributes = (function () {
              if (state.finalized) {
                  return [ Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputText.value), Halogen_HTML_Properties.value(state.original), Halogen_HTML_Properties.class_("label-editor"), Halogen_HTML_Properties.ref(inputRef) ];
              };
              return [ Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputText.value), Halogen_HTML_Properties.value(state.original), Halogen_HTML_Properties.class_("label-editor"), Halogen_HTML_Properties.ref(inputRef), Halogen_HTML_Events.onBlur(function (v) {
                  return Finished.value;
              }), Halogen_HTML_Events.onKeyDown(function (ke) {
                  return new HandleKeyPress(ke);
              }) ];
          })();
          return Halogen_HTML_Elements.input(attributes);
      };
  };
  var initialState = function (input) {
      return {
          original: input,
          finalized: false
      };
  };
  var handleAction = function (dictMonadAff) {
      return function (v) {
          if (v instanceof Noop) {
              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
          };
          if (v instanceof Initialize) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query.getHTMLElementRef(inputRef))(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (e) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLElement.focus(e)))(function () {
                      return Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (input) {
                          return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLInputElement.select(input));
                      })(Web_HTML_HTMLInputElement.fromHTMLElement(e));
                  });
              }));
          };
          if (v instanceof StopPropagation) {
              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Event_Event.stopPropagation(v.value0)))(function () {
                  return handleAction(dictMonadAff)(v.value1);
              });
          };
          if (v instanceof HandleKeyPress) {
              if (Web_UIEvent_KeyboardEvent.code(v.value0) === "Enter") {
                  return handleAction(dictMonadAff)(new StopPropagation(Web_UIEvent_KeyboardEvent.toEvent(v.value0), Finished.value));
              };
              if (Web_UIEvent_KeyboardEvent.code(v.value0) === "Escape") {
                  return handleAction(dictMonadAff)(new StopPropagation(Web_UIEvent_KeyboardEvent.toEvent(v.value0), Cancelled.value));
              };
              if (Web_UIEvent_KeyboardEvent.code(v.value0) === "Tab") {
                  return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
              };
              if (Data_Boolean.otherwise) {
                  return handleAction(dictMonadAff)(new StopPropagation(Web_UIEvent_KeyboardEvent.toEvent(v.value0), Noop.value));
              };
          };
          if (v instanceof Cancelled) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      var $15 = {};
                      for (var $16 in v1) {
                          if ({}.hasOwnProperty.call(v1, $16)) {
                              $15[$16] = v1[$16];
                          };
                      };
                      $15.finalized = true;
                      return $15;
                  }))(function () {
                      return Halogen_Query_HalogenM.raise(state.original);
                  });
              });
          };
          if (v instanceof Finished) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      var $18 = {};
                      for (var $19 in v1) {
                          if ({}.hasOwnProperty.call(v1, $19)) {
                              $18[$19] = v1[$19];
                          };
                      };
                      $18.finalized = true;
                      return $18;
                  }))(function () {
                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query.getHTMLElementRef(inputRef))(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (e) {
                          return Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (input) {
                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_HTML_HTMLInputElement.value(input)))(function (value) {
                                  return Halogen_Query_HalogenM.raise(value);
                              });
                          })(Web_HTML_HTMLInputElement.fromHTMLElement(e));
                      }));
                  });
              });
          };
          throw new Error("Failed pattern match at Component.LabelEditor (line 89, column 17 - line 115, column 22): " + [ v.constructor.name ]);
      };
  };
  var mkComponent = function (dictMonadAff) {
      return function (v) {
          return Halogen_Component.mkComponent({
              initialState: initialState,
              render: render(dictMonadAff),
              "eval": Halogen_Component.mkEval({
                  handleAction: handleAction(dictMonadAff),
                  handleQuery: Halogen_Component.defaultEval.handleQuery,
                  receive: receive,
                  initialize: new Data_Maybe.Just(Initialize.value),
                  finalize: Halogen_Component.defaultEval.finalize
              })
          });
      };
  };
  exports["mkComponent"] = mkComponent;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Component.Slots"] = $PS["Component.Slots"] || {};
  var exports = $PS["Component.Slots"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var _labelEditor = Type_Proxy["Proxy"].value;
  exports["_labelEditor"] = _labelEditor;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.HTML"] = $PS["Halogen.HTML"] || {};
  var exports = $PS["Halogen.HTML"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var slot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (component) {
                          return function (input) {
                              return function (outputQuery) {
                                  return Halogen_HTML_Core.widget(new Halogen_Component.ComponentSlot(Halogen_Component.componentSlot()(dictIsSymbol)(dictOrd)(label)(p)(component)(input)(function ($6) {
                                      return Data_Maybe.Just.create(outputQuery($6));
                                  })));
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["slot"] = slot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Events.Node"] = $PS["App.Events.Node"] || {};
  var exports = $PS["App.Events.Node"];
  var App_Control_DragAction = $PS["App.Control.DragAction"];
  var App_Control_NodeAction = $PS["App.Control.NodeAction"];
  var App_Control_ResizeAction = $PS["App.Control.ResizeAction"];
  var App_Data_CSSClasses = $PS["App.Data.CSSClasses"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var Component_LabelEditor = $PS["Component.LabelEditor"];
  var Component_Slots = $PS["Component.Slots"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_HTML = $PS["Halogen.HTML"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Web_UIEvent_MouseEvent = $PS["Web.UIEvent.MouseEvent"];                
  var selectHandler = function (wrap) {
      return function (selection) {
          return Halogen_HTML_Events.onClick(function (mouseEvent) {
              var selectAction = new App_Control_NodeAction.Select(selection);
              var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
              return wrap(new App_Control_NodeAction.StopPropagation(event, selectAction));
          });
      };
  };
  var nsResizer = function (wrap) {
      return function (id) {
          var wrap$prime = function ($1) {
              return wrap(App_Control_NodeAction.ResizeAction.create($1));
          };
          return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.ns_resizer), Halogen_HTML_Events.onMouseDown(function (mouseEvent) {
              var mouseDownAction = new App_Control_ResizeAction.NSStart(mouseEvent, id);
              var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
              return wrap$prime(new App_Control_ResizeAction.StopPropagation(event, mouseDownAction));
          }) ])([  ]);
      };
  };
  var finishEditHandler = function (wrap) {
      return function (id) {
          return function (output) {
              return wrap(new App_Control_NodeAction.FinishEdit(id, output));
          };
      };
  };
  var labelEditor = function (dictMonadAff) {
      return function (wrap) {
          return function (id) {
              return function (input) {
                  var handler = finishEditHandler(wrap)(id);
                  var component = Component_LabelEditor.mkComponent(dictMonadAff)(Data_Unit.unit);
                  return Halogen_HTML.slot()({
                      reflectSymbol: function () {
                          return "labelEditor";
                      }
                  })(App_Data_NodeCommon.nodeIdOrd)(Component_Slots["_labelEditor"])(id)(component)(input)(handler);
              };
          };
      };
  };
  var ewResizer = function (wrap) {
      return function (id) {
          var wrap$prime = function ($2) {
              return wrap(App_Control_NodeAction.ResizeAction.create($2));
          };
          return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.ew_resizer), Halogen_HTML_Events.onMouseDown(function (mouseEvent) {
              var mouseDownAction = new App_Control_ResizeAction.EWStart(mouseEvent, id);
              var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
              return wrap$prime(new App_Control_ResizeAction.StopPropagation(event, mouseDownAction));
          }) ])([  ]);
      };
  };
  var editLabelHandler = function (wrap) {
      return function (id) {
          return Halogen_HTML_Events.onDoubleClick(function (mouseEvent) {
              var selectAction = App_Control_NodeAction.EditLabel.create(id);
              var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
              return wrap(new App_Control_NodeAction.StopPropagation(event, selectAction));
          });
      };
  };
  var dragStartHandler = function (wrap) {
      return function (id) {
          return Halogen_HTML_Events.onMouseDown(function (mouseEvent) {
              var mouseDownAction = new App_Control_DragAction.MouseDown(mouseEvent, id);
              var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
              return wrap(new App_Control_DragAction.StopPropagation(event, mouseDownAction));
          });
      };
  };
  exports["selectHandler"] = selectHandler;
  exports["editLabelHandler"] = editLabelHandler;
  exports["dragStartHandler"] = dragStartHandler;
  exports["labelEditor"] = labelEditor;
  exports["ewResizer"] = ewResizer;
  exports["nsResizer"] = nsResizer;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["CSS.Render"] = $PS["CSS.Render"] || {};
  var exports = $PS["CSS.Render"];
  var CSS_Property = $PS["CSS.Property"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var collect$prime = function (v) {
      return function (v1) {
          if (v instanceof CSS_Property.Plain && v1 instanceof CSS_Property.Plain) {
              return [ new Data_Either.Right(new Data_Tuple.Tuple(v.value0, v1.value0)) ];
          };
          if (v instanceof CSS_Property.Prefixed && v1 instanceof CSS_Property.Plain) {
              return Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                  return Data_Either.Right.create(new Data_Tuple.Tuple(v3.value0 + v3.value1, v1.value0));
              })(v.value0);
          };
          if (v instanceof CSS_Property.Plain && v1 instanceof CSS_Property.Prefixed) {
              return Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                  return Data_Either.Right.create(new Data_Tuple.Tuple(v.value0, v2.value0 + v2.value1));
              })(v1.value0);
          };
          if (v instanceof CSS_Property.Prefixed && v1 instanceof CSS_Property.Prefixed) {
              return Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                  return Data_Maybe.maybe(new Data_Either.Left(v2.value0 + v2.value1))((function () {
                      var $174 = Data_Tuple.Tuple.create(v2.value0 + v2.value1);
                      return function ($175) {
                          return Data_Either.Right.create($174((function (v3) {
                              return v2.value0 + v3;
                          })($175)));
                      };
                  })())(Data_Foldable.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString)(v2.value0)(v1.value0));
              })(v.value0);
          };
          throw new Error("Failed pattern match at CSS.Render (line 156, column 1 - line 156, column 80): " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var collect = function (v) {
      return collect$prime(v.value0)(v.value1);
  };
  exports["collect"] = collect;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Utils"] = $PS["App.Utils"] || {};
  var exports = $PS["App.Utils"];
  var CSS_Render = $PS["CSS.Render"];
  var CSS_Stylesheet = $PS["CSS.Stylesheet"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];                
  var maybeDiv$prime = function (condition) {
      return function (valueFunc) {
          if (condition) {
              return valueFunc(Data_Unit.unit);
          };
          return Halogen_HTML_Elements.div_([  ]);
      };
  };
  var maybeDiv = function (condition) {
      return function (value) {
          if (condition) {
              return value;
          };
          return Halogen_HTML_Elements.div_([  ]);
      };
  };
  var filterBySecond = function (input) {
      return Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(Data_Array.filter(Data_Tuple.snd)(input));
  };
  var cssToStyle = (function () {
      var toString = (function () {
          var $6 = Data_String_Common.joinWith("; ");
          var $7 = Foreign_Object.foldMap(Data_Monoid.monoidArray)(function (key) {
              return function (val) {
                  return [ key + (": " + val) ];
              };
          });
          return function ($8) {
              return $6($7($8));
          };
      })();
      var rights = Data_Array.concatMap(Data_Foldable.foldMap(Data_Foldable.foldableEither)(Data_Monoid.monoidArray)(Data_Array.singleton));
      var property = function (v) {
          if (v instanceof CSS_Stylesheet.Property) {
              return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
          };
          return Data_Maybe.Nothing.value;
      };
      var rules = function (rs) {
          var properties = Control_Bind.bind(Control_Bind.bindArray)(Data_Array.mapMaybe(property)(rs))(function ($9) {
              return rights(CSS_Render.collect($9));
          });
          return Foreign_Object.fromFoldable(Data_Foldable.foldableArray)(properties);
      };
      var $10 = Halogen_HTML_Properties.attr("style");
      return function ($11) {
          return $10(toString(rules(CSS_Stylesheet.runS($11))));
      };
  })();
  exports["filterBySecond"] = filterBySecond;
  exports["maybeDiv"] = maybeDiv;
  exports["maybeDiv'"] = maybeDiv$prime;
  exports["cssToStyle"] = cssToStyle;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.View.ViewState"] = $PS["App.View.ViewState"] || {};
  var exports = $PS["App.View.ViewState"];
  var NoParent = (function () {
      function NoParent() {

      };
      NoParent.value = new NoParent();
      return NoParent;
  })();
  var ShowParentEdge = (function () {
      function ShowParentEdge() {

      };
      ShowParentEdge.value = new ShowParentEdge();
      return ShowParentEdge;
  })();
  var HideParentEdge = (function () {
      function HideParentEdge() {

      };
      HideParentEdge.value = new HideParentEdge();
      return HideParentEdge;
  })();
  var parentStateEq = {
      eq: function (x) {
          return function (y) {
              if (x instanceof NoParent && y instanceof NoParent) {
                  return true;
              };
              if (x instanceof ShowParentEdge && y instanceof ShowParentEdge) {
                  return true;
              };
              if (x instanceof HideParentEdge && y instanceof HideParentEdge) {
                  return true;
              };
              return false;
          };
      }
  };
  exports["NoParent"] = NoParent;
  exports["ShowParentEdge"] = ShowParentEdge;
  exports["HideParentEdge"] = HideParentEdge;
  exports["parentStateEq"] = parentStateEq;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Data.NodeImpl.ScrollerNode"] = $PS["App.Data.NodeImpl.ScrollerNode"] || {};
  var exports = $PS["App.Data.NodeImpl.ScrollerNode"];
  var App_Control_NodeAction = $PS["App.Control.NodeAction"];
  var App_Data_CSSClasses = $PS["App.Data.CSSClasses"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var App_Events_Node = $PS["App.Events.Node"];
  var App_Utils = $PS["App.Utils"];
  var App_View_ViewState = $PS["App.View.ViewState"];
  var CSS_Stylesheet = $PS["CSS.Stylesheet"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Show = $PS["Data.Show"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];                
  var ScrollerNodeImpl = function (x) {
      return x;
  };
  var setPosition = function (v) {
      return function (position) {
          return ScrollerNodeImpl({
              id: v.id,
              label: v.label,
              position: position,
              maxWidth: v.maxWidth,
              maxHeight: v.maxHeight
          });
      };
  };
  var setMaxWidth = function (v) {
      return function (value) {
          return {
              id: v.id,
              label: v.label,
              position: v.position,
              maxWidth: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Ord.max(Data_Ord.ordNumber)(0.0))(value),
              maxHeight: v.maxHeight
          };
      };
  };
  var setMaxHeight = function (v) {
      return function (value) {
          return {
              id: v.id,
              label: v.label,
              position: v.position,
              maxWidth: v.maxWidth,
              maxHeight: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Ord.max(Data_Ord.ordNumber)(0.0))(value)
          };
      };
  };
  var setLabel = function (v) {
      return function (value) {
          return {
              id: v.id,
              label: value,
              position: v.position,
              maxWidth: v.maxWidth,
              maxHeight: v.maxHeight
          };
      };
  };
  var renderContents = function (dictMonadAff) {
      return function (wrap) {
          return function (viewState) {
              return function (v) {
                  return function (children) {
                      var props = Halogen_HTML_Properties.classes(App_Utils.filterBySecond([ new Data_Tuple.Tuple(App_Data_CSSClasses.selection_container, true), new Data_Tuple.Tuple(App_Data_CSSClasses.selected, Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(viewState.selected)(new Data_Maybe.Just(v.id))), new Data_Tuple.Tuple(App_Data_CSSClasses.resized, Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(viewState.resized)(new Data_Maybe.Just(v.id))) ]));
                      var labelEditor = App_Utils["maybeDiv'"](Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(viewState.editing)(new Data_Maybe.Just(v.id)))(function (v1) {
                          return App_Events_Node.labelEditor(dictMonadAff)(wrap)(v.id)(v.label);
                      });
                      var containerProps = App_Utils.filterBySecond([ new Data_Tuple.Tuple(Halogen_HTML_Properties.class_(App_Data_CSSClasses.contents_container), true), new Data_Tuple.Tuple(App_Events_Node.selectHandler(wrap)(new Data_Maybe.Just(v.id)), viewState.reactsToMouse), new Data_Tuple.Tuple(App_Events_Node.editLabelHandler(wrap)(v.id), viewState.reactsToMouse), new Data_Tuple.Tuple(App_Events_Node.dragStartHandler(function ($41) {
                          return wrap(App_Control_NodeAction.DragAction.create($41));
                      })(v.id), viewState.reactsToMouse) ]);
                      return Halogen_HTML_Elements.div([ props, App_Utils.cssToStyle(Data_Semigroup.append(CSS_Stylesheet.semigroupCSS)(App_Data_NodeCommon.maxWidthToCSS(v.maxWidth))(App_Data_NodeCommon.maxHeightToCSS(v.maxHeight))) ])([ Halogen_HTML_Elements.div(containerProps)([ labelEditor, Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.node_label) ])([ Halogen_HTML_Core.text(v.label) ]) ]), children, App_Events_Node.ewResizer(wrap)(v.id), App_Events_Node.nsResizer(wrap)(v.id) ]);
                  };
              };
          };
      };
  };
  var renderBeacon = function (path) {
      return function (closest) {
          var classes = App_Utils.filterBySecond([ new Data_Tuple.Tuple(App_Data_CSSClasses.beacon, true), new Data_Tuple.Tuple(App_Data_CSSClasses.closest, closest) ]);
          return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes(classes), Halogen_HTML_Properties.attr("path")(path) ])([  ]);
      };
  };
  var renderFCBeacon = function (viewState) {
      return function (id) {
          return App_Utils.maybeDiv(viewState.viewBeacons)(renderBeacon("fc-" + Data_Show.show(App_Data_NodeCommon.nodeIdShow)(id))(Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.eqNodePath))(viewState.closestBeacon)(Data_Maybe.Just.create(new App_Data_NodeCommon.FirstChild(id)))));
      };
  };
  var renderNSBeacon = function (viewState) {
      return function (id) {
          return App_Utils.maybeDiv(viewState.viewBeacons && Data_Eq.notEq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.NoParent.value))(renderBeacon("ns-" + Data_Show.show(App_Data_NodeCommon.nodeIdShow)(id))(Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.eqNodePath))(viewState.closestBeacon)(Data_Maybe.Just.create(new App_Data_NodeCommon.NextSibling(id)))));
      };
  };
  var scrollerNodeLayoutNode = {
      render: function (dictMonadAff) {
          return function (wrap) {
              return function (renderChildren) {
                  return function (parentState) {
                      return function (v) {
                          var viewState = (function () {
                              var $35 = Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(parentState.dragged)(new Data_Maybe.Just(v.id));
                              if ($35) {
                                  return {
                                      viewBeacons: false,
                                      closestBeacon: parentState.closestBeacon,
                                      dragged: parentState.dragged,
                                      editing: parentState.editing,
                                      haveNextSibling: parentState.haveNextSibling,
                                      parentState: parentState.parentState,
                                      reactsToMouse: parentState.reactsToMouse,
                                      resized: parentState.resized,
                                      selected: parentState.selected
                                  };
                              };
                              return parentState;
                          })();
                          var siblingRail = App_Utils.maybeDiv(viewState.haveNextSibling && Data_Eq.eq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.ShowParentEdge.value))(Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.sibling_rail) ])([  ]));
                          var parentEdge = App_Utils.maybeDiv(Data_Eq.eq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.ShowParentEdge.value))(Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.parent_edge) ])([  ]));
                          var nsBeacon = renderNSBeacon(viewState)(v.id);
                          var fcBeacon = renderFCBeacon(viewState)(v.id);
                          var classes = App_Utils.filterBySecond([ new Data_Tuple.Tuple(App_Data_CSSClasses.node, true), new Data_Tuple.Tuple(App_Data_CSSClasses.scroller, true), new Data_Tuple.Tuple(App_Data_CSSClasses.root, Data_Eq.eq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.NoParent.value)), new Data_Tuple.Tuple(App_Data_CSSClasses.child, Data_Eq.notEq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.NoParent.value)), new Data_Tuple.Tuple(App_Data_CSSClasses.dragged, Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(viewState.dragged)(new Data_Maybe.Just(v.id))) ]);
                          var childViewState = {
                              parentState: App_View_ViewState.HideParentEdge.value,
                              closestBeacon: viewState.closestBeacon,
                              dragged: viewState.dragged,
                              editing: viewState.editing,
                              haveNextSibling: viewState.haveNextSibling,
                              reactsToMouse: viewState.reactsToMouse,
                              resized: viewState.resized,
                              selected: viewState.selected,
                              viewBeacons: viewState.viewBeacons
                          };
                          return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes(classes), App_Utils.cssToStyle(App_Data_NodeCommon.positionToCSS(v.position)) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.position_capture) ])([ renderContents(dictMonadAff)(wrap)(viewState)(v)(Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes([ App_Data_CSSClasses.child_area, App_Data_CSSClasses.scroller ]) ])(Data_Array.cons(fcBeacon)(renderChildren(childViewState)(v.id)))), parentEdge ]), siblingRail, nsBeacon ]);
                      };
                  };
              };
          };
      }
  };
  var moveAbsolutePosition = function (v) {
      return function (dx) {
          return function (dy) {
              if (v.position instanceof App_Data_NodeCommon.Absolute) {
                  return ScrollerNodeImpl({
                      id: v.id,
                      label: v.label,
                      position: new App_Data_NodeCommon.Absolute({
                          x: v.position.value0.x + dx,
                          y: v.position.value0.y + dy
                      }),
                      maxWidth: v.maxWidth,
                      maxHeight: v.maxHeight
                  });
              };
              return v;
          };
      };
  };
  var encodeScrollerNodeImpl = Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Data_NodeCommon.nodeIdEncode)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Data_NodeCommon.encodeNodePosition)(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
      reflectSymbol: function () {
          return "position";
      }
  })())({
      reflectSymbol: function () {
          return "maxWidth";
      }
  })())({
      reflectSymbol: function () {
          return "maxHeight";
      }
  })())({
      reflectSymbol: function () {
          return "label";
      }
  })())({
      reflectSymbol: function () {
          return "id";
      }
  })())();
  var decodeScrollerNodeImpl = Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Data_NodeCommon.nodeIdDecode))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Data_NodeCommon.decodeNodePosition))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
      reflectSymbol: function () {
          return "position";
      }
  })()())({
      reflectSymbol: function () {
          return "maxWidth";
      }
  })()())({
      reflectSymbol: function () {
          return "maxHeight";
      }
  })()())({
      reflectSymbol: function () {
          return "label";
      }
  })()())({
      reflectSymbol: function () {
          return "id";
      }
  })()())();
  var construct = function (id) {
      return function (position) {
          return {
              id: id,
              label: "New Task",
              position: position,
              maxWidth: Data_Maybe.Nothing.value,
              maxHeight: Data_Maybe.Nothing.value
          };
      };
  };
  exports["construct"] = construct;
  exports["setPosition"] = setPosition;
  exports["moveAbsolutePosition"] = moveAbsolutePosition;
  exports["setLabel"] = setLabel;
  exports["setMaxWidth"] = setMaxWidth;
  exports["setMaxHeight"] = setMaxHeight;
  exports["encodeScrollerNodeImpl"] = encodeScrollerNodeImpl;
  exports["decodeScrollerNodeImpl"] = decodeScrollerNodeImpl;
  exports["scrollerNodeLayoutNode"] = scrollerNodeLayoutNode;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Data.NodeImpl.TreeNode"] = $PS["App.Data.NodeImpl.TreeNode"] || {};
  var exports = $PS["App.Data.NodeImpl.TreeNode"];
  var App_Control_NodeAction = $PS["App.Control.NodeAction"];
  var App_Data_CSSClasses = $PS["App.Data.CSSClasses"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var App_Events_Node = $PS["App.Events.Node"];
  var App_Utils = $PS["App.Utils"];
  var App_View_ViewState = $PS["App.View.ViewState"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Show = $PS["Data.Show"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];                
  var TreeNodeImpl = function (x) {
      return x;
  };
  var setPosition = function (v) {
      return function (position) {
          return TreeNodeImpl({
              id: v.id,
              label: v.label,
              position: position,
              maxWidth: v.maxWidth
          });
      };
  };
  var setMaxWidth = function (v) {
      return function (value) {
          return {
              id: v.id,
              label: v.label,
              position: v.position,
              maxWidth: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Ord.max(Data_Ord.ordNumber)(0.0))(value)
          };
      };
  };
  var setMaxHeight = function (impl) {
      return function (v) {
          return impl;
      };
  };
  var setLabel = function (v) {
      return function (value) {
          return {
              id: v.id,
              label: value,
              position: v.position,
              maxWidth: v.maxWidth
          };
      };
  };
  var renderContents = function (dictMonadAff) {
      return function (wrap) {
          return function (viewState) {
              return function (v) {
                  var labelEditor = App_Utils["maybeDiv'"](Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(viewState.editing)(new Data_Maybe.Just(v.id)))(function (v1) {
                      return App_Events_Node.labelEditor(dictMonadAff)(wrap)(v.id)(v.label);
                  });
                  var containerProps = App_Utils.filterBySecond([ new Data_Tuple.Tuple(Halogen_HTML_Properties.class_(App_Data_CSSClasses.contents_container), true), new Data_Tuple.Tuple(App_Events_Node.selectHandler(wrap)(new Data_Maybe.Just(v.id)), viewState.reactsToMouse), new Data_Tuple.Tuple(App_Events_Node.editLabelHandler(wrap)(v.id), viewState.reactsToMouse), new Data_Tuple.Tuple(App_Events_Node.dragStartHandler(function ($37) {
                      return wrap(App_Control_NodeAction.DragAction.create($37));
                  })(v.id), viewState.reactsToMouse) ]);
                  var classes = App_Utils.filterBySecond([ new Data_Tuple.Tuple(App_Data_CSSClasses.selection_container, true), new Data_Tuple.Tuple(App_Data_CSSClasses.selected, Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(viewState.selected)(new Data_Maybe.Just(v.id))), new Data_Tuple.Tuple(App_Data_CSSClasses.resized, Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(viewState.resized)(new Data_Maybe.Just(v.id))) ]);
                  return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes(classes), App_Utils.cssToStyle(App_Data_NodeCommon.maxWidthToCSS(v.maxWidth)) ])([ Halogen_HTML_Elements.div(containerProps)([ labelEditor, Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.node_label) ])([ Halogen_HTML_Core.text(v.label) ]) ]), App_Events_Node.ewResizer(wrap)(v.id) ]);
              };
          };
      };
  };
  var renderBeacon = function (path) {
      return function (closest) {
          var classes = App_Utils.filterBySecond([ new Data_Tuple.Tuple(App_Data_CSSClasses.beacon, true), new Data_Tuple.Tuple(App_Data_CSSClasses.closest, closest) ]);
          return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes(classes), Halogen_HTML_Properties.attr("path")(path) ])([  ]);
      };
  };
  var renderFCBeacon = function (viewState) {
      return function (id) {
          return App_Utils.maybeDiv(viewState.viewBeacons)(renderBeacon("fc-" + Data_Show.show(App_Data_NodeCommon.nodeIdShow)(id))(Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.eqNodePath))(viewState.closestBeacon)(Data_Maybe.Just.create(new App_Data_NodeCommon.FirstChild(id)))));
      };
  };
  var renderNSBeacon = function (viewState) {
      return function (id) {
          return App_Utils.maybeDiv(viewState.viewBeacons && Data_Eq.notEq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.NoParent.value))(renderBeacon("ns-" + Data_Show.show(App_Data_NodeCommon.nodeIdShow)(id))(Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.eqNodePath))(viewState.closestBeacon)(Data_Maybe.Just.create(new App_Data_NodeCommon.NextSibling(id)))));
      };
  };
  var treeNodeLayoutNode = {
      render: function (dictMonadAff) {
          return function (wrap) {
              return function (renderChildren) {
                  return function (parentState) {
                      return function (v) {
                          var viewState = (function () {
                              var $31 = Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(parentState.dragged)(new Data_Maybe.Just(v.id));
                              if ($31) {
                                  return {
                                      viewBeacons: false,
                                      closestBeacon: parentState.closestBeacon,
                                      dragged: parentState.dragged,
                                      editing: parentState.editing,
                                      haveNextSibling: parentState.haveNextSibling,
                                      parentState: parentState.parentState,
                                      reactsToMouse: parentState.reactsToMouse,
                                      resized: parentState.resized,
                                      selected: parentState.selected
                                  };
                              };
                              return parentState;
                          })();
                          var siblingRail = App_Utils.maybeDiv(viewState.haveNextSibling && Data_Eq.eq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.ShowParentEdge.value))(Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.sibling_rail) ])([  ]));
                          var parentEdge = App_Utils.maybeDiv(Data_Eq.eq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.ShowParentEdge.value))(Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.parent_edge) ])([  ]));
                          var nsBeacon = renderNSBeacon(viewState)(v.id);
                          var fcBeacon = renderFCBeacon(viewState)(v.id);
                          var classes = App_Utils.filterBySecond([ new Data_Tuple.Tuple(App_Data_CSSClasses.node, true), new Data_Tuple.Tuple(App_Data_CSSClasses.root, Data_Eq.eq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.NoParent.value)), new Data_Tuple.Tuple(App_Data_CSSClasses.child, Data_Eq.notEq(App_View_ViewState.parentStateEq)(viewState.parentState)(App_View_ViewState.NoParent.value)), new Data_Tuple.Tuple(App_Data_CSSClasses.dragged, Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(viewState.dragged)(new Data_Maybe.Just(v.id))) ]);
                          var childViewState = {
                              parentState: App_View_ViewState.ShowParentEdge.value,
                              closestBeacon: viewState.closestBeacon,
                              dragged: viewState.dragged,
                              editing: viewState.editing,
                              haveNextSibling: viewState.haveNextSibling,
                              reactsToMouse: viewState.reactsToMouse,
                              resized: viewState.resized,
                              selected: viewState.selected,
                              viewBeacons: viewState.viewBeacons
                          };
                          return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes(classes), App_Utils.cssToStyle(App_Data_NodeCommon.positionToCSS(v.position)) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.position_capture) ])([ renderContents(dictMonadAff)(wrap)(viewState)(v), parentEdge ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_(App_Data_CSSClasses.child_area) ])(Data_Array.cons(fcBeacon)(renderChildren(childViewState)(v.id))), siblingRail, nsBeacon ]);
                      };
                  };
              };
          };
      }
  };
  var moveAbsolutePosition = function (v) {
      return function (dx) {
          return function (dy) {
              if (v.position instanceof App_Data_NodeCommon.Absolute) {
                  return TreeNodeImpl({
                      id: v.id,
                      label: v.label,
                      position: new App_Data_NodeCommon.Absolute({
                          x: v.position.value0.x + dx,
                          y: v.position.value0.y + dy
                      }),
                      maxWidth: v.maxWidth
                  });
              };
              return v;
          };
      };
  };
  var encodeTreeNodeImpl = Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Data_NodeCommon.nodeIdEncode)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJNumber))(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Data_NodeCommon.encodeNodePosition)(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
      reflectSymbol: function () {
          return "position";
      }
  })())({
      reflectSymbol: function () {
          return "maxWidth";
      }
  })())({
      reflectSymbol: function () {
          return "label";
      }
  })())({
      reflectSymbol: function () {
          return "id";
      }
  })())();
  var decodeTreeNodeImpl = Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Data_NodeCommon.nodeIdDecode))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(Data_Argonaut_Decode_Class.decodeJsonNumber))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Data_NodeCommon.decodeNodePosition))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
      reflectSymbol: function () {
          return "position";
      }
  })()())({
      reflectSymbol: function () {
          return "maxWidth";
      }
  })()())({
      reflectSymbol: function () {
          return "label";
      }
  })()())({
      reflectSymbol: function () {
          return "id";
      }
  })()())();
  var construct = function (id) {
      return function (position) {
          return {
              id: id,
              label: "New Task",
              position: position,
              maxWidth: Data_Maybe.Nothing.value
          };
      };
  };
  exports["construct"] = construct;
  exports["setPosition"] = setPosition;
  exports["moveAbsolutePosition"] = moveAbsolutePosition;
  exports["setLabel"] = setLabel;
  exports["setMaxWidth"] = setMaxWidth;
  exports["setMaxHeight"] = setMaxHeight;
  exports["encodeTreeNodeImpl"] = encodeTreeNodeImpl;
  exports["decodeTreeNodeImpl"] = decodeTreeNodeImpl;
  exports["treeNodeLayoutNode"] = treeNodeLayoutNode;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Data.Node"] = $PS["App.Data.Node"] || {};
  var exports = $PS["App.Data.Node"];
  var App_Class_LayoutNode = $PS["App.Class.LayoutNode"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var App_Data_NodeImpl_ScrollerNode = $PS["App.Data.NodeImpl.ScrollerNode"];
  var App_Data_NodeImpl_TreeNode = $PS["App.Data.NodeImpl.TreeNode"];
  var Data_Argonaut_Decode_Generic = $PS["Data.Argonaut.Decode.Generic"];
  var Data_Argonaut_Encode_Generic = $PS["Data.Argonaut.Encode.Generic"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];                                
  var TreeNodeType = (function () {
      function TreeNodeType() {

      };
      TreeNodeType.value = new TreeNodeType();
      return TreeNodeType;
  })();
  var ScrollerNodeType = (function () {
      function ScrollerNodeType() {

      };
      ScrollerNodeType.value = new ScrollerNodeType();
      return ScrollerNodeType;
  })();
  var TreeNode = (function () {
      function TreeNode(value0) {
          this.value0 = value0;
      };
      TreeNode.create = function (value0) {
          return new TreeNode(value0);
      };
      return TreeNode;
  })();
  var ScrollerNode = (function () {
      function ScrollerNode(value0) {
          this.value0 = value0;
      };
      ScrollerNode.create = function (value0) {
          return new ScrollerNode(value0);
      };
      return ScrollerNode;
  })();
  var setPosition = function (node) {
      return function (position) {
          if (node instanceof TreeNode) {
              return TreeNode.create(App_Data_NodeImpl_TreeNode.setPosition(node.value0)(position));
          };
          if (node instanceof ScrollerNode) {
              return ScrollerNode.create(App_Data_NodeImpl_ScrollerNode.setPosition(node.value0)(position));
          };
          throw new Error("Failed pattern match at App.Data.Node (line 45, column 3 - line 47, column 83): " + [ node.constructor.name ]);
      };
  };
  var setMaxWidth = function (node) {
      return function (value) {
          if (node instanceof TreeNode) {
              return TreeNode.create(App_Data_NodeImpl_TreeNode.setMaxWidth(node.value0)(value));
          };
          if (node instanceof ScrollerNode) {
              return ScrollerNode.create(App_Data_NodeImpl_ScrollerNode.setMaxWidth(node.value0)(value));
          };
          throw new Error("Failed pattern match at App.Data.Node (line 63, column 3 - line 65, column 80): " + [ node.constructor.name ]);
      };
  };
  var setMaxHeight = function (node) {
      return function (value) {
          if (node instanceof TreeNode) {
              return TreeNode.create(App_Data_NodeImpl_TreeNode.setMaxHeight(node.value0)(value));
          };
          if (node instanceof ScrollerNode) {
              return ScrollerNode.create(App_Data_NodeImpl_ScrollerNode.setMaxHeight(node.value0)(value));
          };
          throw new Error("Failed pattern match at App.Data.Node (line 69, column 3 - line 71, column 81): " + [ node.constructor.name ]);
      };
  };
  var setLabel = function (node) {
      return function (value) {
          if (node instanceof TreeNode) {
              return TreeNode.create(App_Data_NodeImpl_TreeNode.setLabel(node.value0)(value));
          };
          if (node instanceof ScrollerNode) {
              return ScrollerNode.create(App_Data_NodeImpl_ScrollerNode.setLabel(node.value0)(value));
          };
          throw new Error("Failed pattern match at App.Data.Node (line 57, column 3 - line 59, column 77): " + [ node.constructor.name ]);
      };
  };
  var nodeLayoutNode = {
      render: function (dictMonadAff) {
          return function (wrap) {
              return function (renderChildren) {
                  return function (viewState) {
                      return function (node) {
                          if (node instanceof TreeNode) {
                              return App_Class_LayoutNode.render(App_Data_NodeImpl_TreeNode.treeNodeLayoutNode)(dictMonadAff)(wrap)(renderChildren)(viewState)(node.value0);
                          };
                          if (node instanceof ScrollerNode) {
                              return App_Class_LayoutNode.render(App_Data_NodeImpl_ScrollerNode.scrollerNodeLayoutNode)(dictMonadAff)(wrap)(renderChildren)(viewState)(node.value0);
                          };
                          throw new Error("Failed pattern match at App.Data.Node (line 22, column 5 - line 24, column 69): " + [ node.constructor.name ]);
                      };
                  };
              };
          };
      }
  };
  var moveAbsolutePosition = function (node) {
      return function (dx) {
          return function (dy) {
              if (node instanceof TreeNode) {
                  return TreeNode.create(App_Data_NodeImpl_TreeNode.moveAbsolutePosition(node.value0)(dx)(dy));
              };
              if (node instanceof ScrollerNode) {
                  return ScrollerNode.create(App_Data_NodeImpl_ScrollerNode.moveAbsolutePosition(node.value0)(dx)(dy));
              };
              throw new Error("Failed pattern match at App.Data.Node (line 51, column 3 - line 53, column 89): " + [ node.constructor.name ]);
          };
      };
  };
  var genericNode = {
      to: function (x) {
          if (x instanceof Data_Generic_Rep.Inl) {
              return new TreeNode(x.value0);
          };
          if (x instanceof Data_Generic_Rep.Inr) {
              return new ScrollerNode(x.value0);
          };
          throw new Error("Failed pattern match at App.Data.Node (line 15, column 1 - line 15, column 46): " + [ x.constructor.name ]);
      },
      from: function (x) {
          if (x instanceof TreeNode) {
              return new Data_Generic_Rep.Inl(x.value0);
          };
          if (x instanceof ScrollerNode) {
              return new Data_Generic_Rep.Inr(x.value0);
          };
          throw new Error("Failed pattern match at App.Data.Node (line 15, column 1 - line 15, column 46): " + [ x.constructor.name ]);
      }
  };
  var errorNode = function (id) {
      return TreeNode.create({
          id: id,
          label: "Error! id: " + Data_Show.show(App_Data_NodeCommon.nodeIdShow)(id),
          position: App_Data_NodeCommon.Static.value,
          maxWidth: Data_Maybe.Nothing.value
      });
  };
  var encodeNode = {
      encodeJson: Data_Argonaut_Encode_Generic.genericEncodeJson(genericNode)(Data_Argonaut_Encode_Generic.encodeRepSum(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "TreeNode";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(App_Data_NodeImpl_TreeNode.encodeTreeNodeImpl)))(Data_Argonaut_Encode_Generic.encodeRepConstructor({
          reflectSymbol: function () {
              return "ScrollerNode";
          }
      })(Data_Argonaut_Encode_Generic.encodeRepArgsArgument(App_Data_NodeImpl_ScrollerNode.encodeScrollerNodeImpl))))
  };
  var decodeNode = {
      decodeJson: Data_Argonaut_Decode_Generic.genericDecodeJson(genericNode)(Data_Argonaut_Decode_Generic.decodeRepSum(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "TreeNode";
          }
      })(App_Data_NodeImpl_TreeNode.decodeTreeNodeImpl))(Data_Argonaut_Decode_Generic.decodeRepConstructorArg({
          reflectSymbol: function () {
              return "ScrollerNode";
          }
      })(App_Data_NodeImpl_ScrollerNode.decodeScrollerNodeImpl)))
  };
  var constructNode = function (v) {
      return function (id) {
          return function (position) {
              if (v instanceof TreeNodeType) {
                  return TreeNode.create(App_Data_NodeImpl_TreeNode.construct(id)(position));
              };
              if (v instanceof ScrollerNodeType) {
                  return ScrollerNode.create(App_Data_NodeImpl_ScrollerNode.construct(id)(position));
              };
              throw new Error("Failed pattern match at App.Data.Node (line 39, column 1 - line 39, column 60): " + [ v.constructor.name, id.constructor.name, position.constructor.name ]);
          };
      };
  };
  exports["errorNode"] = errorNode;
  exports["TreeNodeType"] = TreeNodeType;
  exports["ScrollerNodeType"] = ScrollerNodeType;
  exports["constructNode"] = constructNode;
  exports["setPosition"] = setPosition;
  exports["moveAbsolutePosition"] = moveAbsolutePosition;
  exports["setLabel"] = setLabel;
  exports["setMaxWidth"] = setMaxWidth;
  exports["setMaxHeight"] = setMaxHeight;
  exports["encodeNode"] = encodeNode;
  exports["decodeNode"] = decodeNode;
  exports["nodeLayoutNode"] = nodeLayoutNode;
})(PS);
(function(exports) {
  "use strict";          

  exports.sqrt = Math.sqrt;
})(PS["Math"] = PS["Math"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Math"] = $PS["Math"] || {};
  var exports = $PS["Math"];
  var $foreign = $PS["Math"];
  exports["sqrt"] = $foreign.sqrt;
})(PS);
(function(exports) {
  "use strict";

  exports.toArray = function (list) {
    return function () {
      return [].slice.call(list);
    };
  };
})(PS["Web.DOM.HTMLCollection"] = PS["Web.DOM.HTMLCollection"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.DOM.HTMLCollection"] = $PS["Web.DOM.HTMLCollection"] || {};
  var exports = $PS["Web.DOM.HTMLCollection"];
  var $foreign = $PS["Web.DOM.HTMLCollection"];
  exports["toArray"] = $foreign.toArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.Drag"] = $PS["App.Control.Drag"] || {};
  var exports = $PS["App.Control.Drag"];
  var App_Control_DragAction = $PS["App.Control.DragAction"];
  var App_Control_DragState = $PS["App.Control.DragState"];
  var App_Control_MapMode = $PS["App.Control.MapMode"];
  var App_Control_StateChangeType = $PS["App.Control.StateChangeType"];
  var App_Data_Node = $PS["App.Data.Node"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ring = $PS["Data.Ring"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var $$Math = $PS["Math"];
  var Web_DOM_Element = $PS["Web.DOM.Element"];
  var Web_DOM_HTMLCollection = $PS["Web.DOM.HTMLCollection"];
  var Web_Event_Event = $PS["Web.Event.Event"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_UIEvent_MouseEvent = $PS["Web.UIEvent.MouseEvent"];                
  var setNodePosition = function (state) {
      return function (id) {
          return function (x) {
              return function (y) {
                  return {
                      nodes: Data_Map_Internal.update(App_Data_NodeCommon.nodeIdOrd)(function (node) {
                          return Data_Maybe.Just.create(App_Data_Node.setPosition(node)(new App_Data_NodeCommon.Absolute({
                              x: x,
                              y: y
                          })));
                      })(id)(state.nodes),
                      relations: state.relations,
                      selected: state.selected,
                      mode: state.mode,
                      maxId: state.maxId
                  };
              };
          };
      };
  };
  var setDragState = function (mstate) {
      return function (dstate) {
          return {
              nodes: mstate.nodes,
              relations: mstate.relations,
              selected: mstate.selected,
              mode: new App_Control_MapMode.Drag(dstate),
              maxId: mstate.maxId
          };
      };
  };
  var resetNodePosition = function (state) {
      return function (id) {
          return {
              nodes: Data_Map_Internal.update(App_Data_NodeCommon.nodeIdOrd)(function (node) {
                  return Data_Maybe.Just.create(App_Data_Node.setPosition(node)(App_Data_NodeCommon.Static.value));
              })(id)(state.nodes),
              relations: state.relations,
              selected: state.selected,
              mode: state.mode,
              maxId: state.maxId
          };
      };
  };
  var setNodePath = function (state) {
      return function (nodeId) {
          return function (path) {
              if (path instanceof App_Data_NodeCommon.Top) {
                  var removeChild = (function () {
                      var $58 = Data_List.filter(function (v) {
                          return Data_Eq.notEq(App_Data_NodeCommon.nodeIdEq)(v)(nodeId);
                      });
                      return function ($59) {
                          return Data_Maybe.Just.create($58($59));
                      };
                  })();
                  var state$prime = Data_Maybe.fromMaybe(state)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(nodeId)(state.relations.parents))(function (parentId) {
                      var parents$prime = Data_Map_Internal["delete"](App_Data_NodeCommon.nodeIdOrd)(nodeId)(state.relations.parents);
                      var children$prime = Data_Map_Internal.update(App_Data_NodeCommon.nodeIdOrd)(removeChild)(parentId)(state.relations.children);
                      return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                          relations: {
                              parents: parents$prime,
                              children: children$prime
                          },
                          maxId: state.maxId,
                          mode: state.mode,
                          nodes: state.nodes,
                          selected: state.selected
                      });
                  }));
                  return setNodePosition(state$prime)(nodeId)(path.value0.value0)(path.value0.value1);
              };
              if (path instanceof App_Data_NodeCommon.FirstChild) {
                  var state$prime = setNodePath(state)(nodeId)(App_Data_NodeCommon.Top.create(new Data_Tuple.Tuple(0.0, 0.0)));
                  var parents$prime = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(nodeId)(path.value0)(state$prime.relations.parents);
                  var childList = new Data_List_Types.Cons(nodeId, Data_Maybe.fromMaybe(Data_List.fromFoldable(Data_Foldable.foldableArray)([  ]))(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(path.value0)(state$prime.relations.children)));
                  var children$prime = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(path.value0)(childList)(state$prime.relations.children);
                  var state$prime$prime = {
                      relations: {
                          parents: parents$prime,
                          children: children$prime
                      },
                      maxId: state$prime.maxId,
                      mode: state$prime.mode,
                      nodes: state$prime.nodes,
                      selected: state$prime.selected
                  };
                  return resetNodePosition(state$prime$prime)(nodeId);
              };
              if (path instanceof App_Data_NodeCommon.NextSibling) {
                  var state$prime = setNodePath(state)(nodeId)(App_Data_NodeCommon.Top.create(new Data_Tuple.Tuple(0.0, 0.0)));
                  var v = Data_Maybe.fromMaybe(new Data_Tuple.Tuple(state$prime.relations.parents, state$prime.relations.children))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(path.value0)(state$prime.relations.parents))(function (parentId) {
                      return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(parentId)(state$prime.relations.children))(function (childList) {
                          return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.elemIndex(App_Data_NodeCommon.nodeIdEq)(path.value0)(childList))(function (siblingIndex) {
                              return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.insertAt(siblingIndex + 1 | 0)(nodeId)(childList))(function (childList$prime) {
                                  var parents$prime = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(nodeId)(parentId)(state$prime.relations.parents);
                                  var children$prime = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(parentId)(childList$prime)(state$prime.relations.children);
                                  return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new Data_Tuple.Tuple(parents$prime, children$prime));
                              });
                          });
                      });
                  }));
                  var state$prime$prime = {
                      relations: {
                          parents: v.value0,
                          children: v.value1
                      },
                      maxId: state$prime.maxId,
                      mode: state$prime.mode,
                      nodes: state$prime.nodes,
                      selected: state$prime.selected
                  };
                  return resetNodePosition(state$prime$prime)(nodeId);
              };
              throw new Error("Failed pattern match at App.Control.Drag (line 223, column 3 - line 265, column 39): " + [ path.constructor.name ]);
          };
      };
  };
  var onMouseUp = function (state) {
      var $29 = App_Control_MapMode.isHookedToDrag(state.mode);
      if ($29) {
          var state$prime = {
              mode: App_Control_MapMode.Idle.value,
              maxId: state.maxId,
              nodes: state.nodes,
              relations: state.relations,
              selected: state.selected
          };
          var v = App_Control_MapMode.getDragNodeId(state.mode);
          var v1 = App_Control_MapMode.getClosestBeacon(state.mode);
          if (v1 instanceof Data_Maybe.Just && v instanceof Data_Maybe.Just) {
              return new Data_Tuple.Tuple(setNodePath(state$prime)(v.value0)(v1.value0), App_Control_StateChangeType.Persistent.value);
          };
          return new Data_Tuple.Tuple(state$prime, App_Control_StateChangeType.Persistent.value);
      };
      return new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value);
  };
  var onMouseDown = function (state) {
      return function (event) {
          return function (id) {
              return function (xoffset) {
                  return function (yoffset) {
                      var dragState = {
                          nodeId: id,
                          lastMouseX: Data_Int.toNumber(Web_UIEvent_MouseEvent.clientX(event)),
                          lastMouseY: Data_Int.toNumber(Web_UIEvent_MouseEvent.clientY(event)),
                          nodeXOffset: xoffset,
                          nodeYOffset: yoffset,
                          state: new App_Control_DragState.Hooked(0.0),
                          closestBeacon: Data_Maybe.Nothing.value
                      };
                      return new Data_Tuple.Tuple({
                          mode: new App_Control_MapMode.Drag(dragState),
                          maxId: state.maxId,
                          nodes: state.nodes,
                          relations: state.relations,
                          selected: state.selected
                      }, App_Control_StateChangeType.Ephemeral.value);
                  };
              };
          };
      };
  };
  var moveNodePosition = function (state) {
      return function (id) {
          return function (dx) {
              return function (dy) {
                  return {
                      nodes: Data_Map_Internal.update(App_Data_NodeCommon.nodeIdOrd)(function (node) {
                          return Data_Maybe.Just.create(App_Data_Node.moveAbsolutePosition(node)(dx)(dy));
                      })(id)(state.nodes),
                      relations: state.relations,
                      selected: state.selected,
                      mode: state.mode,
                      maxId: state.maxId
                  };
              };
          };
      };
  };
  var getDragState = function (s) {
      if (s.mode instanceof App_Control_MapMode.Drag) {
          return new Data_Maybe.Just(s.mode.value0);
      };
      return Data_Maybe.Nothing.value;
  };
  var emptyDOMRect = {
      bottom: 0.0,
      height: 0.0,
      left: 0.0,
      right: 0.0,
      top: 0.0,
      width: 0.0
  };
  var getEventTargetRect = function (dictMonadEffect) {
      return function (mouseEvent) {
          var mhtmlElement = Control_Bind.bind(Data_Maybe.bindMaybe)(Web_Event_Event.target(Web_UIEvent_MouseEvent.toEvent(mouseEvent)))(function (targetElement) {
              return Web_HTML_HTMLElement.fromEventTarget(targetElement);
          });
          if (mhtmlElement instanceof Data_Maybe.Just) {
              return Effect_Class.liftEffect(dictMonadEffect)(Web_HTML_HTMLElement.getBoundingClientRect(mhtmlElement.value0));
          };
          if (mhtmlElement instanceof Data_Maybe.Nothing) {
              return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(emptyDOMRect);
          };
          throw new Error("Failed pattern match at App.Control.Drag (line 103, column 3 - line 105, column 33): " + [ mhtmlElement.constructor.name ]);
      };
  };
  var computeBeaconDistance = function (x) {
      return function (y) {
          return function (v) {
              var dy = v.y - y;
              var dx = v.x - x;
              return {
                  distance: $$Math.sqrt(dx * dx + dy * dy),
                  path: v.path
              };
          };
      };
  };
  var findClosestBeacon = function (beacons) {
      return function (x) {
          return function (y) {
              var distanceBeacons = Data_Functor.map(Data_Functor.functorArray)(computeBeaconDistance(x)(y))(beacons);
              var sorted = Data_Array.sortBy(function (a) {
                  return function (b) {
                      return Data_Ord.compare(Data_Ord.ordNumber)(a.distance)(b.distance);
                  };
              })(distanceBeacons);
              var v = Data_Array.head(sorted);
              if (v instanceof Data_Maybe.Just && v.value0.distance < 50.0) {
                  return new Data_Maybe.Just(v.value0.path);
              };
              return Data_Maybe.Nothing.value;
          };
      };
  };
  var onMouseMove = function (state) {
      return function (event) {
          return function (beacons) {
              return Data_Maybe.fromMaybe(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value))(Control_Bind.bind(Data_Maybe.bindMaybe)(getDragState(state))(function (startingDragState) {
                  var dragData = App_Control_DragState.toDragData(startingDragState)(event);
                  var dragState = {
                      lastMouseX: dragData.x,
                      lastMouseY: dragData.y,
                      closestBeacon: findClosestBeacon(beacons)(dragData.x + startingDragState.nodeXOffset)(dragData.y + startingDragState.nodeYOffset),
                      nodeId: startingDragState.nodeId,
                      nodeXOffset: startingDragState.nodeXOffset,
                      nodeYOffset: startingDragState.nodeYOffset,
                      state: startingDragState.state
                  };
                  return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((function () {
                      if (dragState.state instanceof App_Control_DragState.Hooked) {
                          var n$prime = dragState.state.value0 + Data_Ord.abs(Data_Ord.ordNumber)(Data_Ring.ringNumber)(dragData.dx) + Data_Ord.abs(Data_Ord.ordNumber)(Data_Ring.ringNumber)(dragData.dy);
                          var $44 = n$prime > 10.0;
                          if ($44) {
                              var state$prime = setDragState(state)({
                                  nodeId: dragState.nodeId,
                                  lastMouseX: dragState.lastMouseX,
                                  lastMouseY: dragState.lastMouseY,
                                  nodeXOffset: dragState.nodeXOffset,
                                  nodeYOffset: dragState.nodeYOffset,
                                  state: App_Control_DragState.Dragging.value,
                                  closestBeacon: dragState.closestBeacon
                              });
                              return new Data_Tuple.Tuple(setNodePath(state$prime)(dragState.nodeId)(App_Data_NodeCommon.Top.create(new Data_Tuple.Tuple(dragData.x + startingDragState.nodeXOffset, dragData.y + startingDragState.nodeYOffset))), App_Control_StateChangeType.Persistent.value);
                          };
                          return new Data_Tuple.Tuple(setDragState(state)({
                              nodeId: dragState.nodeId,
                              lastMouseX: dragState.lastMouseX,
                              lastMouseY: dragState.lastMouseY,
                              nodeXOffset: dragState.nodeXOffset,
                              nodeYOffset: dragState.nodeYOffset,
                              state: new App_Control_DragState.Hooked(n$prime),
                              closestBeacon: dragState.closestBeacon
                          }), App_Control_StateChangeType.Ephemeral.value);
                      };
                      if (dragState.state instanceof App_Control_DragState.Dragging) {
                          var state$prime = setDragState(state)(dragState);
                          return new Data_Tuple.Tuple(moveNodePosition(state$prime)(dragState.nodeId)(dragData.dx)(dragData.dy), App_Control_StateChangeType.Ephemeral.value);
                      };
                      throw new Error("Failed pattern match at App.Control.Drag (line 208, column 10 - line 218, column 90): " + [ dragState.state.constructor.name ]);
                  })());
              }));
          };
      };
  };
  var beaconPathToNodePath = function (s) {
      var parts = Data_String_Common.split("-")(s);
      return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(parts)(0))(function (pathType) {
          return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(App_Data_NodeCommon.NodeId)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(parts)(1))(Data_Int.fromString)))(function (nodeId) {
              if (pathType === "ns") {
                  return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new App_Data_NodeCommon.NextSibling(nodeId));
              };
              if (pathType === "fc") {
                  return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new App_Data_NodeCommon.FirstChild(nodeId));
              };
              return Data_Maybe.Nothing.value;
          });
      });
  };
  var parseBeaconPath = function (element) {
      return function __do() {
          var mpathAttribute = Web_DOM_Element.getAttribute("path")(element)();
          return Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(beaconPathToNodePath)(mpathAttribute));
      };
  };
  var elementToBeacon = function (htmlElement) {
      return function __do() {
          var domRect = Web_HTML_HTMLElement.getBoundingClientRect(htmlElement)();
          var x = domRect.left + 0.5 * domRect.width;
          var y = domRect.top + 0.5 * domRect.height;
          var mpath = parseBeaconPath(Web_HTML_HTMLElement.toElement(htmlElement))();
          return Data_Functor.map(Data_Maybe.functorMaybe)(function (path) {
              return {
                  x: x,
                  y: y,
                  path: path
              };
          })(mpath);
      };
  };
  var getBeaconRects = function (htmlRoot) {
      return function __do() {
          var beaconCollection = Web_DOM_Element.getElementsByClassName("beacon")(Web_HTML_HTMLElement.toElement(htmlRoot))();
          var beaconElementArray = Web_DOM_HTMLCollection.toArray(beaconCollection)();
          return Data_Functor.map(Effect.functorEffect)(Data_Array.catMaybes)(Data_Traversable.traverse(Data_Traversable.traversableArray)(Effect.applicativeEffect)(Data_Functor.map(Effect.functorEffect)(Control_Bind.join(Data_Maybe.bindMaybe)))(Data_Functor.map(Data_Functor.functorArray)((function () {
              var $60 = Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Effect.applicativeEffect)(elementToBeacon);
              return function ($61) {
                  return $60(Web_HTML_HTMLElement.fromElement($61));
              };
          })())(beaconElementArray)))();
      };
  };
  var handleAction = function (dictMonadEffect) {
      return function (action) {
          return function (state) {
              if (action instanceof App_Control_DragAction.StopPropagation) {
                  return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Web_Event_Event.stopPropagation(action.value0)))(function () {
                      return handleAction(dictMonadEffect)(action.value1)(state);
                  });
              };
              if (action instanceof App_Control_DragAction.MouseDown) {
                  return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(getEventTargetRect(Effect_Class.monadEffectEffect)(action.value0)))(function (domRect) {
                      var yoffset = domRect.top - Data_Int.toNumber(Web_UIEvent_MouseEvent.clientY(action.value0));
                      var xoffset = domRect.left - Data_Int.toNumber(Web_UIEvent_MouseEvent.clientX(action.value0));
                      return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(onMouseDown(state)(action.value0)(action.value1)(xoffset)(yoffset));
                  });
              };
              if (action instanceof App_Control_DragAction.MouseUp) {
                  var $52 = App_Control_MapMode.isHookedToDrag(state.mode);
                  if ($52) {
                      return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(onMouseUp(state));
                  };
                  return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
              };
              if (action instanceof App_Control_DragAction.MouseMove) {
                  var mhtmlMap = Control_Bind.bind(Data_Maybe.bindMaybe)(Web_Event_Event.currentTarget(Web_UIEvent_MouseEvent.toEvent(action.value0)))(Web_HTML_HTMLElement.fromEventTarget);
                  return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)((function () {
                      if (mhtmlMap instanceof Data_Maybe.Just) {
                          return getBeaconRects(mhtmlMap.value0);
                      };
                      if (mhtmlMap instanceof Data_Maybe.Nothing) {
                          return Control_Applicative.pure(Effect.applicativeEffect)([  ]);
                      };
                      throw new Error("Failed pattern match at App.Control.Drag (line 56, column 22 - line 58, column 29): " + [ mhtmlMap.constructor.name ]);
                  })()))(function (beacons) {
                      var $56 = App_Control_MapMode.isHookedToDrag(state.mode);
                      if ($56) {
                          return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(onMouseMove(state)(action.value0)(beacons));
                      };
                      return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                  });
              };
              throw new Error("Failed pattern match at App.Control.Drag (line 37, column 3 - line 62, column 37): " + [ action.constructor.name ]);
          };
      };
  };
  exports["handleAction"] = handleAction;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.MapAction"] = $PS["App.Control.MapAction"] || {};
  var exports = $PS["App.Control.MapAction"];                                      
  var NodeAction = (function () {
      function NodeAction(value0) {
          this.value0 = value0;
      };
      NodeAction.create = function (value0) {
          return new NodeAction(value0);
      };
      return NodeAction;
  })();
  var DragAction = (function () {
      function DragAction(value0) {
          this.value0 = value0;
      };
      DragAction.create = function (value0) {
          return new DragAction(value0);
      };
      return DragAction;
  })();
  var ResizeAction = (function () {
      function ResizeAction(value0) {
          this.value0 = value0;
      };
      ResizeAction.create = function (value0) {
          return new ResizeAction(value0);
      };
      return ResizeAction;
  })();
  var NewTopNode = (function () {
      function NewTopNode(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      NewTopNode.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new NewTopNode(value0, value1, value2);
              };
          };
      };
      return NewTopNode;
  })();
  var HandleMapKeyPress = (function () {
      function HandleMapKeyPress(value0) {
          this.value0 = value0;
      };
      HandleMapKeyPress.create = function (value0) {
          return new HandleMapKeyPress(value0);
      };
      return HandleMapKeyPress;
  })();
  exports["NodeAction"] = NodeAction;
  exports["DragAction"] = DragAction;
  exports["ResizeAction"] = ResizeAction;
  exports["NewTopNode"] = NewTopNode;
  exports["HandleMapKeyPress"] = HandleMapKeyPress;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.MapState"] = $PS["App.Control.MapState"] || {};
  var exports = $PS["App.Control.MapState"];
  var App_Control_MapMode = $PS["App.Control.MapMode"];
  var App_View_ViewState = $PS["App.View.ViewState"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var toInitialViewState = function (state) {
      return {
          viewBeacons: App_Control_MapMode.isDrag(state.mode),
          dragged: App_Control_MapMode.getDragNodeId(state.mode),
          parentState: App_View_ViewState.NoParent.value,
          selected: state.selected,
          closestBeacon: App_Control_MapMode.getClosestBeacon(state.mode),
          haveNextSibling: false,
          reactsToMouse: App_Control_MapMode.reactsToMouse(state.mode),
          editing: App_Control_MapMode.getEditNodeId(state.mode),
          resized: App_Control_MapMode.getResizedNodeId(state.mode)
      };
  };
  var initialState = function (v) {
      return {
          nodes: Data_Map_Internal.empty,
          relations: {
              children: Data_Map_Internal.empty,
              parents: Data_Map_Internal.empty
          },
          selected: Data_Maybe.Nothing.value,
          mode: App_Control_MapMode.Idle.value,
          maxId: 1
      };
  };
  exports["initialState"] = initialState;
  exports["toInitialViewState"] = toInitialViewState;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Capabilities.Logging"] = $PS["Capabilities.Logging"] || {};
  var exports = $PS["Capabilities.Logging"];
  var Data_Ordering = $PS["Data.Ordering"];                                  
  var Debug = (function () {
      function Debug() {

      };
      Debug.value = new Debug();
      return Debug;
  })();
  var Info = (function () {
      function Info() {

      };
      Info.value = new Info();
      return Info;
  })();
  var Warning = (function () {
      function Warning() {

      };
      Warning.value = new Warning();
      return Warning;
  })();
  var $$Error = (function () {
      function $$Error() {

      };
      $$Error.value = new $$Error();
      return $$Error;
  })();
  var logLevelEq = {
      eq: function (x) {
          return function (y) {
              if (x instanceof Debug && y instanceof Debug) {
                  return true;
              };
              if (x instanceof Info && y instanceof Info) {
                  return true;
              };
              if (x instanceof Warning && y instanceof Warning) {
                  return true;
              };
              if (x instanceof $$Error && y instanceof $$Error) {
                  return true;
              };
              return false;
          };
      }
  };
  var logLevelOrd = {
      compare: function (x) {
          return function (y) {
              if (x instanceof Debug && y instanceof Debug) {
                  return Data_Ordering.EQ.value;
              };
              if (x instanceof Debug) {
                  return Data_Ordering.LT.value;
              };
              if (y instanceof Debug) {
                  return Data_Ordering.GT.value;
              };
              if (x instanceof Info && y instanceof Info) {
                  return Data_Ordering.EQ.value;
              };
              if (x instanceof Info) {
                  return Data_Ordering.LT.value;
              };
              if (y instanceof Info) {
                  return Data_Ordering.GT.value;
              };
              if (x instanceof Warning && y instanceof Warning) {
                  return Data_Ordering.EQ.value;
              };
              if (x instanceof Warning) {
                  return Data_Ordering.LT.value;
              };
              if (y instanceof Warning) {
                  return Data_Ordering.GT.value;
              };
              if (x instanceof $$Error && y instanceof $$Error) {
                  return Data_Ordering.EQ.value;
              };
              throw new Error("Failed pattern match at Capabilities.Logging (line 15, column 1 - line 15, column 44): " + [ x.constructor.name, y.constructor.name ]);
          };
      },
      Eq0: function () {
          return logLevelEq;
      }
  };
  var log = function (dict) {
      return dict.log;
  };
  var formatLog = function (level) {
      return function (s) {
          if (level instanceof Debug) {
              return "[Debug] " + s;
          };
          if (level instanceof Info) {
              return "[Info ] " + s;
          };
          if (level instanceof Warning) {
              return "[Warn ] " + s;
          };
          if (level instanceof $$Error) {
              return "[Error] " + s;
          };
          throw new Error("Failed pattern match at Capabilities.Logging (line 22, column 3 - line 26, column 31): " + [ level.constructor.name ]);
      };
  };
  exports["log"] = log;
  exports["Debug"] = Debug;
  exports["Info"] = Info;
  exports["Error"] = $$Error;
  exports["formatLog"] = formatLog;
  exports["logLevelOrd"] = logLevelOrd;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Maybe.Trans"] = $PS["Control.Monad.Maybe.Trans"] || {};
  var exports = $PS["Control.Monad.Maybe.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];                    
  var MaybeT = function (x) {
      return x;
  };
  var runMaybeT = function (v) {
      return v;
  };
  var functorMaybeT = function (dictFunctor) {
      return {
          map: function (f) {
              return function (v) {
                  return Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Maybe.functorMaybe)(f))(v);
              };
          }
      };
  };
  var monadMaybeT = function (dictMonad) {
      return {
          Applicative0: function () {
              return applicativeMaybeT(dictMonad);
          },
          Bind1: function () {
              return bindMaybeT(dictMonad);
          }
      };
  };
  var bindMaybeT = function (dictMonad) {
      return {
          bind: function (v) {
              return function (f) {
                  return Control_Bind.bind(dictMonad.Bind1())(v)(function (v1) {
                      if (v1 instanceof Data_Maybe.Nothing) {
                          return Control_Applicative.pure(dictMonad.Applicative0())(Data_Maybe.Nothing.value);
                      };
                      if (v1 instanceof Data_Maybe.Just) {
                          var v2 = f(v1.value0);
                          return v2;
                      };
                      throw new Error("Failed pattern match at Control.Monad.Maybe.Trans (line 55, column 11 - line 57, column 42): " + [ v1.constructor.name ]);
                  });
              };
          },
          Apply0: function () {
              return applyMaybeT(dictMonad);
          }
      };
  };
  var applyMaybeT = function (dictMonad) {
      return {
          apply: Control_Monad.ap(monadMaybeT(dictMonad)),
          Functor0: function () {
              return functorMaybeT(((dictMonad.Bind1()).Apply0()).Functor0());
          }
      };
  };
  var applicativeMaybeT = function (dictMonad) {
      return {
          pure: (function () {
              var $74 = Control_Applicative.pure(dictMonad.Applicative0());
              return function ($75) {
                  return MaybeT($74(Data_Maybe.Just.create($75)));
              };
          })(),
          Apply0: function () {
              return applyMaybeT(dictMonad);
          }
      };
  };
  var altMaybeT = function (dictMonad) {
      return {
          alt: function (v) {
              return function (v1) {
                  return Control_Bind.bind(dictMonad.Bind1())(v)(function (m) {
                      if (m instanceof Data_Maybe.Nothing) {
                          return v1;
                      };
                      return Control_Applicative.pure(dictMonad.Applicative0())(m);
                  });
              };
          },
          Functor0: function () {
              return functorMaybeT(((dictMonad.Bind1()).Apply0()).Functor0());
          }
      };
  };
  var plusMaybeT = function (dictMonad) {
      return {
          empty: Control_Applicative.pure(dictMonad.Applicative0())(Data_Maybe.Nothing.value),
          Alt0: function () {
              return altMaybeT(dictMonad);
          }
      };
  };
  var alternativeMaybeT = function (dictMonad) {
      return {
          Applicative0: function () {
              return applicativeMaybeT(dictMonad);
          },
          Plus1: function () {
              return plusMaybeT(dictMonad);
          }
      };
  };
  var monadPlusMaybeT = function (dictMonad) {
      return {
          Monad0: function () {
              return monadMaybeT(dictMonad);
          },
          Alternative1: function () {
              return alternativeMaybeT(dictMonad);
          }
      };
  };
  exports["runMaybeT"] = runMaybeT;
  exports["bindMaybeT"] = bindMaybeT;
  exports["monadPlusMaybeT"] = monadPlusMaybeT;
})(PS);
(function(exports) {
  "use strict";                                               

  exports._querySelector = function (selector) {
    return function (node) {
      return function () {
        return node.querySelector(selector);
      };
    };
  };
})(PS["Web.DOM.ParentNode"] = PS["Web.DOM.ParentNode"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.DOM.ParentNode"] = $PS["Web.DOM.ParentNode"] || {};
  var exports = $PS["Web.DOM.ParentNode"];
  var $foreign = $PS["Web.DOM.ParentNode"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var querySelector = function (qs) {
      var $0 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $1 = $foreign["_querySelector"](qs);
      return function ($2) {
          return $0($1($2));
      };
  };
  exports["querySelector"] = querySelector;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.Resize"] = $PS["App.Control.Resize"] || {};
  var exports = $PS["App.Control.Resize"];
  var App_Control_MapMode = $PS["App.Control.MapMode"];
  var App_Control_ResizeAction = $PS["App.Control.ResizeAction"];
  var App_Control_ResizeState = $PS["App.Control.ResizeState"];
  var App_Control_StateChangeType = $PS["App.Control.StateChangeType"];
  var App_Data_Node = $PS["App.Data.Node"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var Capabilities_Logging = $PS["Capabilities.Logging"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Maybe_Trans = $PS["Control.Monad.Maybe.Trans"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Show = $PS["Data.Show"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Web_DOM_Element = $PS["Web.DOM.Element"];
  var Web_DOM_ParentNode = $PS["Web.DOM.ParentNode"];
  var Web_Event_Event = $PS["Web.Event.Event"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_UIEvent_MouseEvent = $PS["Web.UIEvent.MouseEvent"];                
  var setNodeMaxWidth = function (state) {
      return function (id) {
          return function (value) {
              return {
                  nodes: Data_Map_Internal.update(App_Data_NodeCommon.nodeIdOrd)(function (node) {
                      return Data_Maybe.Just.create(App_Data_Node.setMaxWidth(node)(value));
                  })(id)(state.nodes),
                  relations: state.relations,
                  selected: state.selected,
                  mode: state.mode,
                  maxId: state.maxId
              };
          };
      };
  };
  var setNodeMaxHeight = function (state) {
      return function (id) {
          return function (value) {
              return {
                  nodes: Data_Map_Internal.update(App_Data_NodeCommon.nodeIdOrd)(function (node) {
                      return Data_Maybe.Just.create(App_Data_Node.setMaxHeight(node)(value));
                  })(id)(state.nodes),
                  relations: state.relations,
                  selected: state.selected,
                  mode: state.mode,
                  maxId: state.maxId
              };
          };
      };
  };
  var resizeModeM = function (dictMonadEffect) {
      return function (state) {
          return function (f) {
              if (state.mode instanceof App_Control_MapMode.Resize) {
                  return f(state.mode.value0);
              };
              return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(state);
          };
      };
  };
  var resizeMode = function (state) {
      return function (f) {
          if (state.mode instanceof App_Control_MapMode.Resize) {
              return f(state.mode.value0);
          };
          return state;
      };
  };
  var liftMaybe = function (dictMonadPlus) {
      return function (v) {
          if (v instanceof Data_Maybe.Just) {
              return Control_Applicative.pure((dictMonadPlus.Alternative1()).Applicative0())(v.value0);
          };
          if (v instanceof Data_Maybe.Nothing) {
              return Control_Plus.empty((dictMonadPlus.Alternative1()).Plus1());
          };
          throw new Error("Failed pattern match at App.Control.Resize (line 165, column 1 - line 165, column 55): " + [ v.constructor.name ]);
      };
  };
  var startResizeFromSelectionContainer = function (dictLogger) {
      return function (dictMonadEffect) {
          return function (dictMonadPlus) {
              return function (direction) {
                  return function (me) {
                      return function (id) {
                          return function (state) {
                              var startResizeFromElement = function (element) {
                                  return Control_Bind.bind((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Web_HTML_HTMLElement.getBoundingClientRect(element)))(function (rect) {
                                      return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Capabilities_Logging.log(dictLogger)(Capabilities_Logging.Info.value)("Starting dims " + (Data_Show.show(Data_Show.showNumber)(rect.width) + (" " + Data_Show.show(Data_Show.showNumber)(rect.height)))))(function () {
                                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())({
                                              mode: new App_Control_MapMode.Resize({
                                                  direction: direction,
                                                  x: Data_Int.toNumber(Web_UIEvent_MouseEvent.clientX(me)),
                                                  y: Data_Int.toNumber(Web_UIEvent_MouseEvent.clientY(me)),
                                                  width: rect.width,
                                                  height: rect.height,
                                                  id: id
                                              }),
                                              maxId: state.maxId,
                                              nodes: state.nodes,
                                              relations: state.relations,
                                              selected: state.selected
                                          });
                                      });
                                  });
                              };
                              return Control_Bind.bind((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Control_Monad_Maybe_Trans.runMaybeT(Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(Effect.monadEffect))(liftMaybe(Control_Monad_Maybe_Trans.monadPlusMaybeT(Effect.monadEffect))(Control_Bind.bind(Data_Maybe.bindMaybe)(Web_Event_Event.currentTarget(Web_UIEvent_MouseEvent.toEvent(me)))(Web_DOM_Element.fromEventTarget)))(function (resizer) {
                                  return Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(Effect.monadEffect))(Data_Newtype.wrap()(Web_DOM_Element.closest(".selection_container")(resizer)))(function (node) {
                                      return liftMaybe(Control_Monad_Maybe_Trans.monadPlusMaybeT(Effect.monadEffect))(Web_HTML_HTMLElement.fromElement(node));
                                  });
                              }))))(function (melement) {
                                  return Data_Maybe.maybe(Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(state))(startResizeFromElement)(melement);
                              });
                          };
                      };
                  };
              };
          };
      };
  };
  var startEWResize = function (dictLogger) {
      return function (dictMonadEffect) {
          return function (dictMonadPlus) {
              return startResizeFromSelectionContainer(dictLogger)(dictMonadEffect)(dictMonadPlus)(App_Control_ResizeState.EW.value);
          };
      };
  };
  var startNSResize = function (dictLogger) {
      return function (dictMonadEffect) {
          return function (dictMonadPlus) {
              return startResizeFromSelectionContainer(dictLogger)(dictMonadEffect)(dictMonadPlus)(App_Control_ResizeState.NS.value);
          };
      };
  };
  var affectsWidth = function (v) {
      if (v instanceof App_Control_ResizeState.EW) {
          return true;
      };
      return false;
  };
  var affectsHeight = function (v) {
      if (v instanceof App_Control_ResizeState.NS) {
          return true;
      };
      return false;
  };
  var stopResize = function (dictMonadEffect) {
      return function (me) {
          return function (state) {
              var resetWidthIfNeeded = function (rs) {
                  return function (s) {
                      return function (r) {
                          var $35 = affectsWidth(rs.direction) && r.width + 3.0 < rs.width;
                          if ($35) {
                              return setNodeMaxWidth(s)(rs.id)(Data_Maybe.Nothing.value);
                          };
                          return s;
                      };
                  };
              };
              var resetHeightIfNeeded = function (rs) {
                  return function (s) {
                      return function (r) {
                          var $36 = affectsHeight(rs.direction) && r.height + 3.0 < rs.height;
                          if ($36) {
                              return setNodeMaxHeight(s)(rs.id)(Data_Maybe.Nothing.value);
                          };
                          return s;
                      };
                  };
              };
              var stopResize$prime = function (rstate) {
                  return function (localState) {
                      return function (element) {
                          return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Web_HTML_HTMLElement.getBoundingClientRect(element)))(function (rect) {
                              var noWidthState = resetWidthIfNeeded(rstate)(localState)(rect);
                              var finalState = resetHeightIfNeeded(rstate)(noWidthState)(rect);
                              return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(finalState);
                          });
                      };
                  };
              };
              return resizeModeM(dictMonadEffect)(state)(function (rstate) {
                  var state$prime = {
                      mode: App_Control_MapMode.Idle.value,
                      maxId: state.maxId,
                      nodes: state.nodes,
                      relations: state.relations,
                      selected: state.selected
                  };
                  return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Control_Monad_Maybe_Trans.runMaybeT(Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(dictMonadEffect.Monad0()))(liftMaybe(Control_Monad_Maybe_Trans.monadPlusMaybeT(dictMonadEffect.Monad0()))(Control_Bind.bind(Data_Maybe.bindMaybe)(Web_Event_Event.currentTarget(Web_UIEvent_MouseEvent.toEvent(me)))(Web_DOM_Element.fromEventTarget)))(function (root) {
                      return Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(dictMonadEffect.Monad0()))(Data_Newtype.wrap()(Effect_Class.liftEffect(dictMonadEffect)(Web_DOM_ParentNode.querySelector(".resized")(Web_DOM_Element.toParentNode(root)))))(function (resizer) {
                          return liftMaybe(Control_Monad_Maybe_Trans.monadPlusMaybeT(dictMonadEffect.Monad0()))(Web_HTML_HTMLElement.fromElement(resizer));
                      });
                  })))(function (melement) {
                      return Data_Maybe.maybe(Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(state$prime))(stopResize$prime(rstate)(state$prime))(melement);
                  });
              });
          };
      };
  };
  var updateSize = function (me) {
      return function (state) {
          var updateNodeWidthIfNeeded = function (rs) {
              return function (s) {
                  var $37 = affectsWidth(rs.direction);
                  if ($37) {
                      return setNodeMaxWidth(s)(rs.id)(new Data_Maybe.Just(rs.width));
                  };
                  return s;
              };
          };
          var updateNodeHeightIfNeeded = function (rs) {
              return function (s) {
                  var $38 = affectsHeight(rs.direction);
                  if ($38) {
                      return setNodeMaxHeight(s)(rs.id)(new Data_Maybe.Just(rs.height));
                  };
                  return s;
              };
          };
          return resizeMode(state)(function (rstate) {
              var dy = Data_Int.toNumber(Web_UIEvent_MouseEvent.clientY(me)) - rstate.y;
              var height = rstate.height + dy;
              var dx = Data_Int.toNumber(Web_UIEvent_MouseEvent.clientX(me)) - rstate.x;
              var width = rstate.width + dx;
              var rstate$prime = {
                  x: rstate.x + dx,
                  y: rstate.y + dy,
                  width: width,
                  height: height,
                  direction: rstate.direction,
                  id: rstate.id
              };
              var state$prime = {
                  mode: new App_Control_MapMode.Resize(rstate$prime),
                  maxId: state.maxId,
                  nodes: state.nodes,
                  relations: state.relations,
                  selected: state.selected
              };
              var widthState = updateNodeWidthIfNeeded(rstate$prime)(state$prime);
              var finalState = updateNodeHeightIfNeeded(rstate$prime)(widthState);
              return finalState;
          });
      };
  };
  var handleAction = function (dictLogger) {
      return function (dictMonadEffect) {
          return function (dictMonadPlus) {
              return function (action) {
                  return function (state) {
                      if (action instanceof App_Control_ResizeAction.StopPropagation) {
                          return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Web_Event_Event.stopPropagation(action.value0)))(function () {
                              return handleAction(dictLogger)(dictMonadEffect)(dictMonadPlus)(action.value1)(state);
                          });
                      };
                      if (action instanceof App_Control_ResizeAction.EWStart) {
                          var $42 = Data_Eq.eq(App_Control_MapMode.modeEq)(state.mode)(App_Control_MapMode.Idle.value);
                          if ($42) {
                              return Data_Functor.map((((dictLogger.Monad0()).Bind1()).Apply0()).Functor0())(function (v) {
                                  return new Data_Tuple.Tuple(v, App_Control_StateChangeType.Ephemeral.value);
                              })(startEWResize(dictLogger)(dictMonadEffect)(dictMonadPlus)(action.value0)(action.value1)(state));
                          };
                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                      };
                      if (action instanceof App_Control_ResizeAction.NSStart) {
                          var $45 = Data_Eq.eq(App_Control_MapMode.modeEq)(state.mode)(App_Control_MapMode.Idle.value);
                          if ($45) {
                              return Data_Functor.map((((dictLogger.Monad0()).Bind1()).Apply0()).Functor0())(function (v) {
                                  return new Data_Tuple.Tuple(v, App_Control_StateChangeType.Ephemeral.value);
                              })(startNSResize(dictLogger)(dictMonadEffect)(dictMonadPlus)(action.value0)(action.value1)(state));
                          };
                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                      };
                      if (action instanceof App_Control_ResizeAction.MouseMove) {
                          var $48 = App_Control_MapMode.isHookedToResize(state.mode);
                          if ($48) {
                              return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(updateSize(action.value0)(state), App_Control_StateChangeType.Ephemeral.value));
                          };
                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                      };
                      if (action instanceof App_Control_ResizeAction.MouseUp) {
                          var $50 = App_Control_MapMode.isHookedToResize(state.mode);
                          if ($50) {
                              var state$prime = updateSize(action.value0)(state);
                              return Data_Functor.map((((dictLogger.Monad0()).Bind1()).Apply0()).Functor0())(function (v) {
                                  return new Data_Tuple.Tuple(v, App_Control_StateChangeType.Persistent.value);
                              })(stopResize(dictMonadEffect)(action.value0)(state$prime));
                          };
                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                      };
                      throw new Error("Failed pattern match at App.Control.Resize (line 25, column 3 - line 49, column 37): " + [ action.constructor.name ]);
                  };
              };
          };
      };
  };
  exports["handleAction"] = handleAction;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.Node"] = $PS["App.Control.Node"] || {};
  var exports = $PS["App.Control.Node"];
  var App_Control_Drag = $PS["App.Control.Drag"];
  var App_Control_MapMode = $PS["App.Control.MapMode"];
  var App_Control_NodeAction = $PS["App.Control.NodeAction"];
  var App_Control_Resize = $PS["App.Control.Resize"];
  var App_Control_StateChangeType = $PS["App.Control.StateChangeType"];
  var App_Data_Node = $PS["App.Data.Node"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Effect_Class = $PS["Effect.Class"];
  var Web_Event_Event = $PS["Web.Event.Event"];                
  var nodeType = function (shift) {
      if (shift) {
          return App_Data_Node.ScrollerNodeType.value;
      };
      return App_Data_Node.TreeNodeType.value;
  };
  var newNode = function (id) {
      return function (shift) {
          return function (v) {
              return function (state) {
                  if (v instanceof App_Data_NodeCommon.Top) {
                      var position = new App_Data_NodeCommon.Absolute({
                          x: v.value0.value0,
                          y: v.value0.value1
                      });
                      var node = App_Data_Node.constructNode(nodeType(shift))(id)(position);
                      var nodes = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(id)(node)(state.nodes);
                      return {
                          nodes: nodes,
                          relations: state.relations,
                          selected: state.selected,
                          mode: state.mode,
                          maxId: state.maxId
                      };
                  };
                  if (v instanceof App_Data_NodeCommon.NextSibling) {
                      var node = App_Data_Node.constructNode(nodeType(shift))(id)(App_Data_NodeCommon.Static.value);
                      var nodes = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(id)(node)(state.nodes);
                      return Data_Maybe["fromMaybe'"](function (v1) {
                          return newNode(id)(shift)(new App_Data_NodeCommon.FirstChild(v.value0))(state);
                      })(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(v.value0)(state.relations.parents))(function (parentId) {
                          return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(parentId)(state.relations.children))(function (childList) {
                              return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.elemIndex(App_Data_NodeCommon.nodeIdEq)(v.value0)(childList))(function (siblingIndex) {
                                  return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.insertAt(siblingIndex + 1 | 0)(id)(childList))(function (childList$prime) {
                                      var parents = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(id)(parentId)(state.relations.parents);
                                      var children = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(parentId)(childList$prime)(state.relations.children);
                                      return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                          nodes: nodes,
                                          relations: {
                                              parents: parents,
                                              children: children
                                          },
                                          maxId: state.maxId,
                                          mode: state.mode,
                                          selected: state.selected
                                      });
                                  });
                              });
                          });
                      }));
                  };
                  if (v instanceof App_Data_NodeCommon.FirstChild) {
                      var parents = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(id)(v.value0)(state.relations.parents);
                      var node = App_Data_Node.constructNode(nodeType(shift))(id)(App_Data_NodeCommon.Static.value);
                      var nodes = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(id)(node)(state.nodes);
                      var childList = new Data_List_Types.Cons(id, Data_Maybe.fromMaybe(Data_List.fromFoldable(Data_Foldable.foldableArray)([  ]))(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(v.value0)(state.relations.children)));
                      var children = Data_Map_Internal.insert(App_Data_NodeCommon.nodeIdOrd)(v.value0)(childList)(state.relations.children);
                      return {
                          nodes: nodes,
                          relations: {
                              children: children,
                              parents: parents
                          },
                          selected: state.selected,
                          mode: state.mode,
                          maxId: state.maxId
                      };
                  };
                  throw new Error("Failed pattern match at App.Control.Node (line 80, column 1 - line 80, column 59): " + [ id.constructor.name, shift.constructor.name, v.constructor.name, state.constructor.name ]);
              };
          };
      };
  };
  var handleAction = function (dictLogger) {
      return function (dictMonadEffect) {
          return function (dictMonadPlus) {
              return function (action) {
                  return function (state) {
                      if (action instanceof App_Control_NodeAction.StopPropagation) {
                          return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Web_Event_Event.stopPropagation(action.value0)))(function () {
                              return handleAction(dictLogger)(dictMonadEffect)(dictMonadPlus)(action.value1)(state);
                          });
                      };
                      if (action instanceof App_Control_NodeAction.DragAction) {
                          return App_Control_Drag.handleAction(dictMonadEffect)(action.value0)(state);
                      };
                      if (action instanceof App_Control_NodeAction.ResizeAction) {
                          return App_Control_Resize.handleAction(dictLogger)(dictMonadEffect)(dictMonadPlus)(action.value0)(state);
                      };
                      if (action instanceof App_Control_NodeAction.Select) {
                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                              selected: action.value0,
                              maxId: state.maxId,
                              mode: state.mode,
                              nodes: state.nodes,
                              relations: state.relations
                          }, App_Control_StateChangeType.Ephemeral.value));
                      };
                      if (action instanceof App_Control_NodeAction.EditLabel) {
                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                              mode: new App_Control_MapMode.Editing(action.value0),
                              maxId: state.maxId,
                              nodes: state.nodes,
                              relations: state.relations,
                              selected: state.selected
                          }, App_Control_StateChangeType.Ephemeral.value));
                      };
                      if (action instanceof App_Control_NodeAction.FinishEdit) {
                          var nodes = Data_Map_Internal.update(App_Data_NodeCommon.nodeIdOrd)((function () {
                              var $38 = Data_Function.flip(App_Data_Node.setLabel)(action.value1);
                              return function ($39) {
                                  return Data_Maybe.Just.create($38($39));
                              };
                          })())(action.value0)(state.nodes);
                          var mode = (function () {
                              var $31 = Data_Eq.eq(App_Control_MapMode.modeEq)(state.mode)(new App_Control_MapMode.Editing(action.value0));
                              if ($31) {
                                  return App_Control_MapMode.Idle.value;
                              };
                              return state.mode;
                          })();
                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                              nodes: nodes,
                              mode: mode,
                              maxId: state.maxId,
                              relations: state.relations,
                              selected: state.selected
                          }, App_Control_StateChangeType.Persistent.value));
                      };
                      throw new Error("Failed pattern match at App.Control.Node (line 27, column 3 - line 46, column 68): " + [ action.constructor.name ]);
                  };
              };
          };
      };
  };
  var deleteNode = function (id) {
      return function (state) {
          var popResult = Data_Map_Internal.pop(App_Data_NodeCommon.nodeIdOrd)(id)(state.relations.parents);
          var nodes = Data_Map_Internal["delete"](App_Data_NodeCommon.nodeIdOrd)(id)(state.nodes);
          var children = Data_Map_Internal["delete"](App_Data_NodeCommon.nodeIdOrd)(id)(state.relations.children);
          var childList = Data_Maybe.fromMaybe(Data_List.fromFoldable(Data_Foldable.foldableArray)([  ]))(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(id)(state.relations.children));
          if (popResult instanceof Data_Maybe.Just) {
              var children$prime = Data_Map_Internal.update(App_Data_NodeCommon.nodeIdOrd)((function () {
                  var $40 = Data_List.filter(function (v) {
                      return Data_Eq.notEq(App_Data_NodeCommon.nodeIdEq)(v)(id);
                  });
                  return function ($41) {
                      return Data_Maybe.Just.create($40($41));
                  };
              })())(popResult.value0.value0)(children);
              var state$prime = {
                  nodes: nodes,
                  relations: {
                      children: children$prime,
                      parents: popResult.value0.value1
                  },
                  maxId: state.maxId,
                  mode: state.mode,
                  selected: state.selected
              };
              return Data_Foldable.foldr(Data_List_Types.foldableList)(deleteNode)(state$prime)(childList);
          };
          if (popResult instanceof Data_Maybe.Nothing) {
              var state$prime = {
                  nodes: nodes,
                  relations: {
                      children: children,
                      parents: state.relations.parents
                  },
                  maxId: state.maxId,
                  mode: state.mode,
                  selected: state.selected
              };
              return Data_Foldable.foldr(Data_List_Types.foldableList)(deleteNode)(state$prime)(childList);
          };
          throw new Error("Failed pattern match at App.Control.Node (line 63, column 3 - line 78, column 40): " + [ popResult.constructor.name ]);
      };
  };
  exports["handleAction"] = handleAction;
  exports["deleteNode"] = deleteNode;
  exports["newNode"] = newNode;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Data.MapRef"] = $PS["App.Data.MapRef"] || {};
  var exports = $PS["App.Data.MapRef"];
  var mapRef = "main-map";
  exports["mapRef"] = mapRef;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Events.Map"] = $PS["App.Events.Map"] || {};
  var exports = $PS["App.Events.Map"];
  var App_Control_DragAction = $PS["App.Control.DragAction"];
  var App_Control_MapAction = $PS["App.Control.MapAction"];
  var App_Control_MapMode = $PS["App.Control.MapMode"];
  var App_Control_NodeAction = $PS["App.Control.NodeAction"];
  var App_Control_ResizeAction = $PS["App.Control.ResizeAction"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Web_UIEvent_MouseEvent = $PS["Web.UIEvent.MouseEvent"];                
  var resizeStopHandler = function (wrap) {
      return Halogen_HTML_Events.onMouseUp(function (mouseEvent) {
          var mouseUpAction = new App_Control_ResizeAction.MouseUp(mouseEvent);
          var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
          return wrap(new App_Control_ResizeAction.StopPropagation(event, mouseUpAction));
      });
  };
  var resizeMoveHandler = function (wrap) {
      return Halogen_HTML_Events.onMouseMove(function (mouseEvent) {
          var mouseMoveAction = new App_Control_ResizeAction.MouseMove(mouseEvent);
          var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
          return wrap(new App_Control_ResizeAction.StopPropagation(event, mouseMoveAction));
      });
  };
  var newTopNodeHandler = function (wrap) {
      return Halogen_HTML_Events.onDoubleClick(function (mouseEvent) {
          var newAction = new App_Control_MapAction.NewTopNode(Web_UIEvent_MouseEvent.shiftKey(mouseEvent), Web_UIEvent_MouseEvent.clientX(mouseEvent), Web_UIEvent_MouseEvent.clientY(mouseEvent));
          return wrap(newAction);
      });
  };
  var dragStopHandler = function (wrap) {
      return Halogen_HTML_Events.onMouseUp(function (mouseEvent) {
          var mouseUpAction = new App_Control_DragAction.MouseUp(mouseEvent);
          var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
          return wrap(new App_Control_DragAction.StopPropagation(event, mouseUpAction));
      });
  };
  var dragMoveHandler = function (wrap) {
      return Halogen_HTML_Events.onMouseMove(function (mouseEvent) {
          var mouseMoveAction = new App_Control_DragAction.MouseMove(mouseEvent);
          var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
          return wrap(new App_Control_DragAction.StopPropagation(event, mouseMoveAction));
      });
  };
  var deselectHandler = function (wrap) {
      return Halogen_HTML_Events.onClick(function (mouseEvent) {
          var selectAction = new App_Control_NodeAction.Select(Data_Maybe.Nothing.value);
          var event = Web_UIEvent_MouseEvent.toEvent(mouseEvent);
          return wrap(new App_Control_NodeAction.StopPropagation(event, selectAction));
      });
  };
  var mapActionsForMode = function (wrap) {
      return function (mode) {
          if (App_Control_MapMode.isHookedToDrag(mode)) {
              return [ dragMoveHandler(function ($2) {
                  return wrap(App_Control_MapAction.DragAction.create($2));
              }), dragStopHandler(function ($3) {
                  return wrap(App_Control_MapAction.DragAction.create($3));
              }) ];
          };
          if (App_Control_MapMode.isHookedToResize(mode)) {
              return [ resizeMoveHandler(function ($4) {
                  return wrap(App_Control_MapAction.ResizeAction.create($4));
              }), resizeStopHandler(function ($5) {
                  return wrap(App_Control_MapAction.ResizeAction.create($5));
              }) ];
          };
          if (Data_Boolean.otherwise) {
              return [ deselectHandler(function ($6) {
                  return wrap(App_Control_MapAction.NodeAction.create($6));
              }), newTopNodeHandler(wrap) ];
          };
          throw new Error("Failed pattern match at App.Events.Map (line 23, column 1 - line 23, column 100): " + [ wrap.constructor.name, mode.constructor.name ]);
      };
  };
  exports["mapActionsForMode"] = mapActionsForMode;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Control.Map"] = $PS["App.Control.Map"] || {};
  var exports = $PS["App.Control.Map"];
  var App_Class_LayoutNode = $PS["App.Class.LayoutNode"];
  var App_Control_Drag = $PS["App.Control.Drag"];
  var App_Control_MapAction = $PS["App.Control.MapAction"];
  var App_Control_MapMode = $PS["App.Control.MapMode"];
  var App_Control_MapState = $PS["App.Control.MapState"];
  var App_Control_Node = $PS["App.Control.Node"];
  var App_Control_Resize = $PS["App.Control.Resize"];
  var App_Control_StateChangeType = $PS["App.Control.StateChangeType"];
  var App_Data_MapRef = $PS["App.Data.MapRef"];
  var App_Data_Node = $PS["App.Data.Node"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var App_Events_Map = $PS["App.Events.Map"];
  var Capabilities_Logging = $PS["Capabilities.Logging"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Web_Event_Event = $PS["Web.Event.Event"];
  var Web_UIEvent_KeyboardEvent = $PS["Web.UIEvent.KeyboardEvent"];                
  var render = function (dictMonadAff) {
      return function (wrap) {
          return function (state) {
              var viewState = App_Control_MapState.toInitialViewState(state);
              var toNode = function (nodeId) {
                  return Data_Maybe.fromMaybe(App_Data_Node.errorNode(nodeId))(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(nodeId)(state.nodes));
              };
              var nodeWrap = function ($58) {
                  return wrap(App_Control_MapAction.NodeAction.create($58));
              };
              var noParent = function (nodeId) {
                  return Data_Eq.eq(Data_Maybe.eqMaybe(App_Data_NodeCommon.nodeIdEq))(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(nodeId)(state.relations.parents))(Data_Maybe.Nothing.value);
              };
              var rootNodes = Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(Data_Map_Internal.values(Data_Map_Internal.filterKeys(App_Data_NodeCommon.nodeIdOrd)(noParent)(state.nodes)));
              var getChildren = function (nodeId) {
                  var v = Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(nodeId)(state.relations.children);
                  if (v instanceof Data_Maybe.Nothing) {
                      return [  ];
                  };
                  if (v instanceof Data_Maybe.Just) {
                      return Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(Data_Functor.map(Data_List_Types.functorList)(toNode)(v.value0));
                  };
                  throw new Error("Failed pattern match at App.Control.Map (line 58, column 7 - line 60, column 65): " + [ v.constructor.name ]);
              };
              var renderChildren = function (localViewState) {
                  return function (nodeId) {
                      var v = Data_Array.unsnoc(getChildren(nodeId));
                      if (v instanceof Data_Maybe.Just) {
                          return Data_Array.snoc(Data_Functor.map(Data_Functor.functorArray)(App_Class_LayoutNode.render(App_Data_Node.nodeLayoutNode)(dictMonadAff)(nodeWrap)(renderChildren)({
                              viewBeacons: localViewState.viewBeacons,
                              dragged: localViewState.dragged,
                              parentState: localViewState.parentState,
                              selected: localViewState.selected,
                              closestBeacon: localViewState.closestBeacon,
                              haveNextSibling: true,
                              reactsToMouse: localViewState.reactsToMouse,
                              editing: localViewState.editing,
                              resized: localViewState.resized
                          }))(v.value0.init))(App_Class_LayoutNode.render(App_Data_Node.nodeLayoutNode)(dictMonadAff)(nodeWrap)(renderChildren)({
                              viewBeacons: localViewState.viewBeacons,
                              dragged: localViewState.dragged,
                              parentState: localViewState.parentState,
                              selected: localViewState.selected,
                              closestBeacon: localViewState.closestBeacon,
                              haveNextSibling: false,
                              reactsToMouse: localViewState.reactsToMouse,
                              editing: localViewState.editing,
                              resized: localViewState.resized
                          })(v.value0.last));
                      };
                      if (v instanceof Data_Maybe.Nothing) {
                          return [  ];
                      };
                      throw new Error("Failed pattern match at App.Control.Map (line 64, column 7 - line 69, column 22): " + [ v.constructor.name ]);
                  };
              };
              var attributes = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Halogen_HTML_Properties.ref(App_Data_MapRef.mapRef), Halogen_HTML_Properties.class_("map") ])(App_Events_Map.mapActionsForMode(wrap)(state.mode));
              return Halogen_HTML_Elements.div(attributes)(Data_Functor.map(Data_Functor.functorArray)(App_Class_LayoutNode.render(App_Data_Node.nodeLayoutNode)(dictMonadAff)(nodeWrap)(renderChildren)(viewState))(rootNodes));
          };
      };
  };
  var handleAction = function (dictMonadAff) {
      return function (dictLogger) {
          return function (dictMonadPlus) {
              return function (action) {
                  return function (state) {
                      var findParent = function (parents) {
                          return function (mid) {
                              return Data_Functor.map(Data_Maybe.functorMaybe)(function (id) {
                                  return Data_Maybe.fromMaybe(id)(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(id)(parents));
                              })(mid);
                          };
                      };
                      var findPreviousSibling = function (parents) {
                          return function (children) {
                              return function (mid) {
                                  var pid = findParent(parents)(mid);
                                  var findPreviousSibling$prime = function ($copy_v) {
                                      return function ($copy_v1) {
                                          var $tco_var_v = $copy_v;
                                          var $tco_done = false;
                                          var $tco_result;
                                          function $tco_loop(v, v1) {
                                              if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Cons) {
                                                  if (Data_Eq.eq(App_Data_NodeCommon.nodeIdEq)(v.value1.value0)(v1)) {
                                                      $tco_done = true;
                                                      return new Data_Maybe.Just(v.value0);
                                                  };
                                                  if (Data_Eq.eq(App_Data_NodeCommon.nodeIdEq)(v.value0)(v1)) {
                                                      $tco_done = true;
                                                      return Data_Maybe.Nothing.value;
                                                  };
                                                  if (Data_Boolean.otherwise) {
                                                      $tco_var_v = new Data_List_Types.Cons(v.value1.value0, v.value1.value1);
                                                      $copy_v1 = v1;
                                                      return;
                                                  };
                                              };
                                              $tco_done = true;
                                              return Data_Maybe.Nothing.value;
                                          };
                                          while (!$tco_done) {
                                              $tco_result = $tco_loop($tco_var_v, $copy_v1);
                                          };
                                          return $tco_result;
                                      };
                                  };
                                  var childList = Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (id) {
                                      return Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(id)(children);
                                  })(pid));
                                  if (childList instanceof Data_Maybe.Just && mid instanceof Data_Maybe.Just) {
                                      return Data_Maybe.Just.create(Data_Maybe.fromMaybe(mid.value0)(findPreviousSibling$prime(childList.value0)(mid.value0)));
                                  };
                                  return mid;
                              };
                          };
                      };
                      var findNextSibling = function (parents) {
                          return function (children) {
                              return function (mid) {
                                  var pid = findParent(parents)(mid);
                                  var findNextSibling$prime = function ($copy_v) {
                                      return function ($copy_v1) {
                                          var $tco_var_v = $copy_v;
                                          var $tco_done1 = false;
                                          var $tco_result;
                                          function $tco_loop(v, v1) {
                                              if (v instanceof Data_List_Types.Cons) {
                                                  if (Data_Eq.eq(App_Data_NodeCommon.nodeIdEq)(v.value0)(v1)) {
                                                      $tco_done1 = true;
                                                      return Data_List.head(v.value1);
                                                  };
                                                  if (Data_Boolean.otherwise) {
                                                      $tco_var_v = v.value1;
                                                      $copy_v1 = v1;
                                                      return;
                                                  };
                                              };
                                              if (v instanceof Data_List_Types.Nil) {
                                                  $tco_done1 = true;
                                                  return Data_Maybe.Nothing.value;
                                              };
                                              throw new Error("Failed pattern match at App.Control.Map (line 153, column 5 - line 153, column 62): " + [ v.constructor.name, v1.constructor.name ]);
                                          };
                                          while (!$tco_done1) {
                                              $tco_result = $tco_loop($tco_var_v, $copy_v1);
                                          };
                                          return $tco_result;
                                      };
                                  };
                                  var childList = Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (id) {
                                      return Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(id)(children);
                                  })(pid));
                                  if (childList instanceof Data_Maybe.Just && mid instanceof Data_Maybe.Just) {
                                      return Data_Maybe.Just.create(Data_Maybe.fromMaybe(mid.value0)(findNextSibling$prime(childList.value0)(mid.value0)));
                                  };
                                  return mid;
                              };
                          };
                      };
                      var findFirstChild = function (children) {
                          return function (mid) {
                              return Data_Functor.map(Data_Maybe.functorMaybe)(function (id) {
                                  return Data_Maybe.fromMaybe(id)(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_List.head)(Data_Map_Internal.lookup(App_Data_NodeCommon.nodeIdOrd)(id)(children))));
                              })(mid);
                          };
                      };
                      if (action instanceof App_Control_MapAction.NodeAction) {
                          return App_Control_Node.handleAction(dictLogger)(dictMonadAff.MonadEffect0())(dictMonadPlus)(action.value0)(state);
                      };
                      if (action instanceof App_Control_MapAction.DragAction) {
                          return App_Control_Drag.handleAction(dictMonadAff.MonadEffect0())(action.value0)(state);
                      };
                      if (action instanceof App_Control_MapAction.ResizeAction) {
                          return App_Control_Resize.handleAction(dictLogger)(dictMonadAff.MonadEffect0())(dictMonadPlus)(action.value0)(state);
                      };
                      if (action instanceof App_Control_MapAction.NewTopNode) {
                          var x$prime = Data_Int.toNumber(action.value1) - 40.0;
                          var y$prime = Data_Int.toNumber(action.value2) - 20.0;
                          var id = App_Data_NodeCommon.nextId(state.maxId);
                          var state$prime = App_Control_Node.newNode(id)(action.value0)(App_Data_NodeCommon.Top.create(new Data_Tuple.Tuple(x$prime, y$prime)))(state);
                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                              maxId: id,
                              selected: new Data_Maybe.Just(id),
                              mode: new App_Control_MapMode.Editing(id),
                              nodes: state$prime.nodes,
                              relations: state$prime.relations
                          }, App_Control_StateChangeType.Persistent.value));
                      };
                      if (action instanceof App_Control_MapAction.HandleMapKeyPress) {
                          if (Web_UIEvent_KeyboardEvent.code(action.value0) === "Tab") {
                              return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Capabilities_Logging.log(dictLogger)(Capabilities_Logging.Info.value)(" " + Web_UIEvent_KeyboardEvent.code(action.value0)))(function () {
                                  return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(action.value0))))(function () {
                                      if (state.selected instanceof Data_Maybe.Just) {
                                          var newId = App_Data_NodeCommon.nextId(state.maxId);
                                          var state$prime = App_Control_Node.newNode(newId)(Web_UIEvent_KeyboardEvent.shiftKey(action.value0))(new App_Data_NodeCommon.FirstChild(state.selected.value0))(state);
                                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                                              maxId: newId,
                                              selected: new Data_Maybe.Just(newId),
                                              mode: new App_Control_MapMode.Editing(newId),
                                              nodes: state$prime.nodes,
                                              relations: state$prime.relations
                                          }, App_Control_StateChangeType.Persistent.value));
                                      };
                                      if (state.selected instanceof Data_Maybe.Nothing) {
                                          return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                                      };
                                      throw new Error("Failed pattern match at App.Control.Map (line 92, column 9 - line 99, column 50): " + [ state.selected.constructor.name ]);
                                  });
                              });
                          };
                          if (Web_UIEvent_KeyboardEvent.code(action.value0) === "Enter") {
                              return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(action.value0))))(function () {
                                  if (state.selected instanceof Data_Maybe.Just) {
                                      var newId = App_Data_NodeCommon.nextId(state.maxId);
                                      var state$prime = App_Control_Node.newNode(newId)(Web_UIEvent_KeyboardEvent.shiftKey(action.value0))(new App_Data_NodeCommon.NextSibling(state.selected.value0))(state);
                                      return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                                          maxId: newId,
                                          selected: new Data_Maybe.Just(newId),
                                          mode: new App_Control_MapMode.Editing(newId),
                                          nodes: state$prime.nodes,
                                          relations: state$prime.relations
                                      }, App_Control_StateChangeType.Persistent.value));
                                  };
                                  if (state.selected instanceof Data_Maybe.Nothing) {
                                      return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                                  };
                                  throw new Error("Failed pattern match at App.Control.Map (line 102, column 9 - line 109, column 50): " + [ state.selected.constructor.name ]);
                              });
                          };
                          if (Web_UIEvent_KeyboardEvent.code(action.value0) === "Delete" || Web_UIEvent_KeyboardEvent.code(action.value0) === "Backspace") {
                              return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(action.value0))))(function () {
                                  if (state.selected instanceof Data_Maybe.Just) {
                                      var state$prime = App_Control_Node.deleteNode(state.selected.value0)(state);
                                      return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                                          selected: Data_Maybe.Nothing.value,
                                          maxId: state$prime.maxId,
                                          mode: state$prime.mode,
                                          nodes: state$prime.nodes,
                                          relations: state$prime.relations
                                      }, App_Control_StateChangeType.Persistent.value));
                                  };
                                  if (state.selected instanceof Data_Maybe.Nothing) {
                                      return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                                  };
                                  throw new Error("Failed pattern match at App.Control.Map (line 112, column 9 - line 119, column 50): " + [ state.selected.constructor.name ]);
                              });
                          };
                          if (Web_UIEvent_KeyboardEvent.code(action.value0) === "ArrowLeft") {
                              return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(action.value0))))(function () {
                                  return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                                      selected: findParent(state.relations.parents)(state.selected),
                                      maxId: state.maxId,
                                      mode: state.mode,
                                      nodes: state.nodes,
                                      relations: state.relations
                                  }, App_Control_StateChangeType.Ephemeral.value));
                              });
                          };
                          if (Web_UIEvent_KeyboardEvent.code(action.value0) === "ArrowRight") {
                              return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(action.value0))))(function () {
                                  return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                                      selected: findFirstChild(state.relations.children)(state.selected),
                                      maxId: state.maxId,
                                      mode: state.mode,
                                      nodes: state.nodes,
                                      relations: state.relations
                                  }, App_Control_StateChangeType.Ephemeral.value));
                              });
                          };
                          if (Web_UIEvent_KeyboardEvent.code(action.value0) === "ArrowDown") {
                              return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(action.value0))))(function () {
                                  return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                                      selected: findNextSibling(state.relations.parents)(state.relations.children)(state.selected),
                                      maxId: state.maxId,
                                      mode: state.mode,
                                      nodes: state.nodes,
                                      relations: state.relations
                                  }, App_Control_StateChangeType.Ephemeral.value));
                              });
                          };
                          if (Web_UIEvent_KeyboardEvent.code(action.value0) === "ArrowUp") {
                              return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(action.value0))))(function () {
                                  return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple({
                                      selected: findPreviousSibling(state.relations.parents)(state.relations.children)(state.selected),
                                      maxId: state.maxId,
                                      mode: state.mode,
                                      nodes: state.nodes,
                                      relations: state.relations
                                  }, App_Control_StateChangeType.Ephemeral.value));
                              });
                          };
                          if (Data_Boolean.otherwise) {
                              return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Capabilities_Logging.log(dictLogger)(Capabilities_Logging.Info.value)("unhandled keypress " + Web_UIEvent_KeyboardEvent.code(action.value0)))(function () {
                                  return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(new Data_Tuple.Tuple(state, App_Control_StateChangeType.NoChange.value));
                              });
                          };
                      };
                      throw new Error("Failed pattern match at App.Control.Map (line 75, column 3 - line 134, column 39): " + [ action.constructor.name ]);
                  };
              };
          };
      };
  };
  exports["render"] = render;
  exports["handleAction"] = handleAction;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Environment"] = $PS["App.Environment"] || {};
  var exports = $PS["App.Environment"];
  var Capabilities_Logging = $PS["Capabilities.Logging"];                
  var mkEnvironment = function (v) {
      return {
          logLevel: Capabilities_Logging.Debug.value
      };
  };
  exports["mkEnvironment"] = mkEnvironment;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Reader.Class"] = $PS["Control.Monad.Reader.Class"] || {};
  var exports = $PS["Control.Monad.Reader.Class"];
  var Data_Functor = $PS["Data.Functor"];
  var ask = function (dict) {
      return dict.ask;
  };
  var asks = function (dictMonadAsk) {
      return function (f) {
          return Data_Functor.map((((dictMonadAsk.Monad0()).Bind1()).Apply0()).Functor0())(f)(ask(dictMonadAsk));
      };
  };
  exports["ask"] = ask;
  exports["asks"] = asks;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Trans.Class"] = $PS["Control.Monad.Trans.Class"] || {};
  var exports = $PS["Control.Monad.Trans.Class"];
  var lift = function (dict) {
      return dict.lift;
  };
  exports["lift"] = lift;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Reader.Trans"] = $PS["Control.Monad.Reader.Trans"] || {};
  var exports = $PS["Control.Monad.Reader.Trans"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect_Class = $PS["Effect.Class"];                
  var ReaderT = function (x) {
      return x;
  };
  var runReaderT = function (v) {
      return v;
  };
  var monadTransReaderT = {
      lift: function (dictMonad) {
          return function ($64) {
              return ReaderT(Data_Function["const"]($64));
          };
      }
  };
  var mapReaderT = function (f) {
      return function (v) {
          return function ($65) {
              return f(v($65));
          };
      };
  };
  var functorReaderT = function (dictFunctor) {
      return {
          map: (function () {
              var $66 = Data_Functor.map(dictFunctor);
              return function ($67) {
                  return mapReaderT($66($67));
              };
          })()
      };
  };
  var applyReaderT = function (dictApply) {
      return {
          apply: function (v) {
              return function (v1) {
                  return function (r) {
                      return Control_Apply.apply(dictApply)(v(r))(v1(r));
                  };
              };
          },
          Functor0: function () {
              return functorReaderT(dictApply.Functor0());
          }
      };
  };
  var bindReaderT = function (dictBind) {
      return {
          bind: function (v) {
              return function (k) {
                  return function (r) {
                      return Control_Bind.bind(dictBind)(v(r))(function (a) {
                          var v1 = k(a);
                          return v1(r);
                      });
                  };
              };
          },
          Apply0: function () {
              return applyReaderT(dictBind.Apply0());
          }
      };
  };
  var applicativeReaderT = function (dictApplicative) {
      return {
          pure: (function () {
              var $71 = Control_Applicative.pure(dictApplicative);
              return function ($72) {
                  return ReaderT(Data_Function["const"]($71($72)));
              };
          })(),
          Apply0: function () {
              return applyReaderT(dictApplicative.Apply0());
          }
      };
  };
  var monadReaderT = function (dictMonad) {
      return {
          Applicative0: function () {
              return applicativeReaderT(dictMonad.Applicative0());
          },
          Bind1: function () {
              return bindReaderT(dictMonad.Bind1());
          }
      };
  };
  var monadAskReaderT = function (dictMonad) {
      return {
          ask: Control_Applicative.pure(dictMonad.Applicative0()),
          Monad0: function () {
              return monadReaderT(dictMonad);
          }
      };
  };
  var monadEffectReader = function (dictMonadEffect) {
      return {
          liftEffect: (function () {
              var $74 = Control_Monad_Trans_Class.lift(monadTransReaderT)(dictMonadEffect.Monad0());
              var $75 = Effect_Class.liftEffect(dictMonadEffect);
              return function ($76) {
                  return $74($75($76));
              };
          })(),
          Monad0: function () {
              return monadReaderT(dictMonadEffect.Monad0());
          }
      };
  };
  var altReaderT = function (dictAlt) {
      return {
          alt: function (v) {
              return function (v1) {
                  return function (r) {
                      return Control_Alt.alt(dictAlt)(v(r))(v1(r));
                  };
              };
          },
          Functor0: function () {
              return functorReaderT(dictAlt.Functor0());
          }
      };
  };
  var plusReaderT = function (dictPlus) {
      return {
          empty: Data_Function["const"](Control_Plus.empty(dictPlus)),
          Alt0: function () {
              return altReaderT(dictPlus.Alt0());
          }
      };
  };
  exports["runReaderT"] = runReaderT;
  exports["applicativeReaderT"] = applicativeReaderT;
  exports["plusReaderT"] = plusReaderT;
  exports["bindReaderT"] = bindReaderT;
  exports["monadReaderT"] = monadReaderT;
  exports["monadTransReaderT"] = monadTransReaderT;
  exports["monadEffectReader"] = monadEffectReader;
  exports["monadAskReaderT"] = monadAskReaderT;
})(PS);
(function(exports) {
  /* globals setImmediate, clearImmediate, setTimeout, clearTimeout */
  /* eslint-disable no-unused-vars, no-prototype-builtins, no-use-before-define, no-unused-labels, no-param-reassign */
  "use strict";

  var Aff = function () {
    // A unique value for empty.
    var EMPTY = {};

    /*

  An awkward approximation. We elide evidence we would otherwise need in PS for
  efficiency sake.

  data Aff eff a
    = Pure a
    | Throw Error
    | Catch (Aff eff a) (Error -> Aff eff a)
    | Sync (Eff eff a)
    | Async ((Either Error a -> Eff eff Unit) -> Eff eff (Canceler eff))
    | forall b. Bind (Aff eff b) (b -> Aff eff a)
    | forall b. Bracket (Aff eff b) (BracketConditions eff b) (b -> Aff eff a)
    | forall b. Fork Boolean (Aff eff b) ?(Fiber eff b -> a)
    | Sequential (ParAff aff a)

  */  
    var PURE    = "Pure";
    var THROW   = "Throw";
    var CATCH   = "Catch";
    var SYNC    = "Sync";
    var ASYNC   = "Async";
    var BIND    = "Bind";
    var BRACKET = "Bracket";
    var FORK    = "Fork";
    var SEQ     = "Sequential";

    /*

  data ParAff eff a
    = forall b. Map (b -> a) (ParAff eff b)
    | forall b. Apply (ParAff eff (b -> a)) (ParAff eff b)
    | Alt (ParAff eff a) (ParAff eff a)
    | ?Par (Aff eff a)

  */  
    var MAP   = "Map";
    var APPLY = "Apply";
    var ALT   = "Alt";

    // Various constructors used in interpretation
    var CONS      = "Cons";      // Cons-list, for stacks
    var RESUME    = "Resume";    // Continue indiscriminately
    var RELEASE   = "Release";   // Continue with bracket finalizers
    var FINALIZER = "Finalizer"; // A non-interruptible effect
    var FINALIZED = "Finalized"; // Marker for finalization
    var FORKED    = "Forked";    // Reference to a forked fiber, with resumption stack
    var FIBER     = "Fiber";     // Actual fiber reference
    var THUNK     = "Thunk";     // Primed effect, ready to invoke

    function Aff(tag, _1, _2, _3) {
      this.tag = tag;
      this._1  = _1;
      this._2  = _2;
      this._3  = _3;
    }

    function AffCtr(tag) {
      var fn = function (_1, _2, _3) {
        return new Aff(tag, _1, _2, _3);
      };
      fn.tag = tag;
      return fn;
    }

    function nonCanceler(error) {
      return new Aff(PURE, void 0);
    }

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function runSync(left, right, eff) {
      try {
        return right(eff());
      } catch (error) {
        return left(error);
      }
    }

    function runAsync(left, eff, k) {
      try {
        return eff(k)();
      } catch (error) {
        k(left(error))();
        return nonCanceler;
      }
    }

    var Scheduler = function () {
      var limit    = 1024;
      var size     = 0;
      var ix       = 0;
      var queue    = new Array(limit);
      var draining = false;

      function drain() {
        var thunk;
        draining = true;
        while (size !== 0) {
          size--;
          thunk     = queue[ix];
          queue[ix] = void 0;
          ix        = (ix + 1) % limit;
          thunk();
        }
        draining = false;
      }

      return {
        isDraining: function () {
          return draining;
        },
        enqueue: function (cb) {
          var i, tmp;
          if (size === limit) {
            tmp = draining;
            drain();
            draining = tmp;
          }

          queue[(ix + size) % limit] = cb;
          size++;

          if (!draining) {
            drain();
          }
        }
      };
    }();

    function Supervisor(util) {
      var fibers  = {};
      var fiberId = 0;
      var count   = 0;

      return {
        register: function (fiber) {
          var fid = fiberId++;
          fiber.onComplete({
            rethrow: true,
            handler: function (result) {
              return function () {
                count--;
                delete fibers[fid];
              };
            }
          })();
          fibers[fid] = fiber;
          count++;
        },
        isEmpty: function () {
          return count === 0;
        },
        killAll: function (killError, cb) {
          return function () {
            if (count === 0) {
              return cb();
            }

            var killCount = 0;
            var kills     = {};

            function kill(fid) {
              kills[fid] = fibers[fid].kill(killError, function (result) {
                return function () {
                  delete kills[fid];
                  killCount--;
                  if (util.isLeft(result) && util.fromLeft(result)) {
                    setTimeout(function () {
                      throw util.fromLeft(result);
                    }, 0);
                  }
                  if (killCount === 0) {
                    cb();
                  }
                };
              })();
            }

            for (var k in fibers) {
              if (fibers.hasOwnProperty(k)) {
                killCount++;
                kill(k);
              }
            }

            fibers  = {};
            fiberId = 0;
            count   = 0;

            return function (error) {
              return new Aff(SYNC, function () {
                for (var k in kills) {
                  if (kills.hasOwnProperty(k)) {
                    kills[k]();
                  }
                }
              });
            };
          };
        }
      };
    }

    // Fiber state machine
    var SUSPENDED   = 0; // Suspended, pending a join.
    var CONTINUE    = 1; // Interpret the next instruction.
    var STEP_BIND   = 2; // Apply the next bind.
    var STEP_RESULT = 3; // Handle potential failure from a result.
    var PENDING     = 4; // An async effect is running.
    var RETURN      = 5; // The current stack has returned.
    var COMPLETED   = 6; // The entire fiber has completed.

    function Fiber(util, supervisor, aff) {
      // Monotonically increasing tick, increased on each asynchronous turn.
      var runTick = 0;

      // The current branch of the state machine.
      var status = SUSPENDED;

      // The current point of interest for the state machine branch.
      var step      = aff;  // Successful step
      var fail      = null; // Failure step
      var interrupt = null; // Asynchronous interrupt

      // Stack of continuations for the current fiber.
      var bhead = null;
      var btail = null;

      // Stack of attempts and finalizers for error recovery. Every `Cons` is also
      // tagged with current `interrupt` state. We use this to track which items
      // should be ignored or evaluated as a result of a kill.
      var attempts = null;

      // A special state is needed for Bracket, because it cannot be killed. When
      // we enter a bracket acquisition or finalizer, we increment the counter,
      // and then decrement once complete.
      var bracketCount = 0;

      // Each join gets a new id so they can be revoked.
      var joinId  = 0;
      var joins   = null;
      var rethrow = true;

      // Each invocation of `run` requires a tick. When an asynchronous effect is
      // resolved, we must check that the local tick coincides with the fiber
      // tick before resuming. This prevents multiple async continuations from
      // accidentally resuming the same fiber. A common example may be invoking
      // the provided callback in `makeAff` more than once, but it may also be an
      // async effect resuming after the fiber was already cancelled.
      function run(localRunTick) {
        var tmp, result, attempt;
        while (true) {
          tmp       = null;
          result    = null;
          attempt   = null;

          switch (status) {
          case STEP_BIND:
            status = CONTINUE;
            try {
              step   = bhead(step);
              if (btail === null) {
                bhead = null;
              } else {
                bhead = btail._1;
                btail = btail._2;
              }
            } catch (e) {
              status = RETURN;
              fail   = util.left(e);
              step   = null;
            }
            break;

          case STEP_RESULT:
            if (util.isLeft(step)) {
              status = RETURN;
              fail   = step;
              step   = null;
            } else if (bhead === null) {
              status = RETURN;
            } else {
              status = STEP_BIND;
              step   = util.fromRight(step);
            }
            break;

          case CONTINUE:
            switch (step.tag) {
            case BIND:
              if (bhead) {
                btail = new Aff(CONS, bhead, btail);
              }
              bhead  = step._2;
              status = CONTINUE;
              step   = step._1;
              break;

            case PURE:
              if (bhead === null) {
                status = RETURN;
                step   = util.right(step._1);
              } else {
                status = STEP_BIND;
                step   = step._1;
              }
              break;

            case SYNC:
              status = STEP_RESULT;
              step   = runSync(util.left, util.right, step._1);
              break;

            case ASYNC:
              status = PENDING;
              step   = runAsync(util.left, step._1, function (result) {
                return function () {
                  if (runTick !== localRunTick) {
                    return;
                  }
                  runTick++;
                  Scheduler.enqueue(function () {
                    // It's possible to interrupt the fiber between enqueuing and
                    // resuming, so we need to check that the runTick is still
                    // valid.
                    if (runTick !== localRunTick + 1) {
                      return;
                    }
                    status = STEP_RESULT;
                    step   = result;
                    run(runTick);
                  });
                };
              });
              return;

            case THROW:
              status = RETURN;
              fail   = util.left(step._1);
              step   = null;
              break;

            // Enqueue the Catch so that we can call the error handler later on
            // in case of an exception.
            case CATCH:
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead    = null;
              btail    = null;
              status   = CONTINUE;
              step     = step._1;
              break;

            // Enqueue the Bracket so that we can call the appropriate handlers
            // after resource acquisition.
            case BRACKET:
              bracketCount++;
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead  = null;
              btail  = null;
              status = CONTINUE;
              step   = step._1;
              break;

            case FORK:
              status = STEP_RESULT;
              tmp    = Fiber(util, supervisor, step._2);
              if (supervisor) {
                supervisor.register(tmp);
              }
              if (step._1) {
                tmp.run();
              }
              step = util.right(tmp);
              break;

            case SEQ:
              status = CONTINUE;
              step   = sequential(util, supervisor, step._1);
              break;
            }
            break;

          case RETURN:
            bhead = null;
            btail = null;
            // If the current stack has returned, and we have no other stacks to
            // resume or finalizers to run, the fiber has halted and we can
            // invoke all join callbacks. Otherwise we need to resume.
            if (attempts === null) {
              status = COMPLETED;
              step   = interrupt || fail || step;
            } else {
              // The interrupt status for the enqueued item.
              tmp      = attempts._3;
              attempt  = attempts._1;
              attempts = attempts._2;

              switch (attempt.tag) {
              // We cannot recover from an unmasked interrupt. Otherwise we should
              // continue stepping, or run the exception handler if an exception
              // was raised.
              case CATCH:
                // We should compare the interrupt status as well because we
                // only want it to apply if there has been an interrupt since
                // enqueuing the catch.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  status = RETURN;
                } else if (fail) {
                  status = CONTINUE;
                  step   = attempt._2(util.fromLeft(fail));
                  fail   = null;
                }
                break;

              // We cannot resume from an unmasked interrupt or exception.
              case RESUME:
                // As with Catch, we only want to ignore in the case of an
                // interrupt since enqueing the item.
                if (interrupt && interrupt !== tmp && bracketCount === 0 || fail) {
                  status = RETURN;
                } else {
                  bhead  = attempt._1;
                  btail  = attempt._2;
                  status = STEP_BIND;
                  step   = util.fromRight(step);
                }
                break;

              // If we have a bracket, we should enqueue the handlers,
              // and continue with the success branch only if the fiber has
              // not been interrupted. If the bracket acquisition failed, we
              // should not run either.
              case BRACKET:
                bracketCount--;
                if (fail === null) {
                  result   = util.fromRight(step);
                  // We need to enqueue the Release with the same interrupt
                  // status as the Bracket that is initiating it.
                  attempts = new Aff(CONS, new Aff(RELEASE, attempt._2, result), attempts, tmp);
                  // We should only coninue as long as the interrupt status has not changed or
                  // we are currently within a non-interruptable finalizer.
                  if (interrupt === tmp || bracketCount > 0) {
                    status = CONTINUE;
                    step   = attempt._3(result);
                  }
                }
                break;

              // Enqueue the appropriate handler. We increase the bracket count
              // because it should not be cancelled.
              case RELEASE:
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                // It has only been killed if the interrupt status has changed
                // since we enqueued the item, and the bracket count is 0. If the
                // bracket count is non-zero then we are in a masked state so it's
                // impossible to be killed.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  step = attempt._1.killed(util.fromLeft(interrupt))(attempt._2);
                } else if (fail) {
                  step = attempt._1.failed(util.fromLeft(fail))(attempt._2);
                } else {
                  step = attempt._1.completed(util.fromRight(step))(attempt._2);
                }
                fail = null;
                bracketCount++;
                break;

              case FINALIZER:
                bracketCount++;
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                step     = attempt._1;
                break;

              case FINALIZED:
                bracketCount--;
                status = RETURN;
                step   = attempt._1;
                fail   = attempt._2;
                break;
              }
            }
            break;

          case COMPLETED:
            for (var k in joins) {
              if (joins.hasOwnProperty(k)) {
                rethrow = rethrow && joins[k].rethrow;
                runEff(joins[k].handler(step));
              }
            }
            joins = null;
            // If we have an interrupt and a fail, then the thread threw while
            // running finalizers. This should always rethrow in a fresh stack.
            if (interrupt && fail) {
              setTimeout(function () {
                throw util.fromLeft(fail);
              }, 0);
            // If we have an unhandled exception, and no other fiber has joined
            // then we need to throw the exception in a fresh stack.
            } else if (util.isLeft(step) && rethrow) {
              setTimeout(function () {
                // Guard on reathrow because a completely synchronous fiber can
                // still have an observer which was added after-the-fact.
                if (rethrow) {
                  throw util.fromLeft(step);
                }
              }, 0);
            }
            return;
          case SUSPENDED:
            status = CONTINUE;
            break;
          case PENDING: return;
          }
        }
      }

      function onComplete(join) {
        return function () {
          if (status === COMPLETED) {
            rethrow = rethrow && join.rethrow;
            join.handler(step)();
            return function () {};
          }

          var jid    = joinId++;
          joins      = joins || {};
          joins[jid] = join;

          return function() {
            if (joins !== null) {
              delete joins[jid];
            }
          };
        };
      }

      function kill(error, cb) {
        return function () {
          if (status === COMPLETED) {
            cb(util.right(void 0))();
            return function () {};
          }

          var canceler = onComplete({
            rethrow: false,
            handler: function (/* unused */) {
              return cb(util.right(void 0));
            }
          })();

          switch (status) {
          case SUSPENDED:
            interrupt = util.left(error);
            status    = COMPLETED;
            step      = interrupt;
            run(runTick);
            break;
          case PENDING:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              if (status === PENDING) {
                attempts = new Aff(CONS, new Aff(FINALIZER, step(error)), attempts, interrupt);
              }
              status   = RETURN;
              step     = null;
              fail     = null;
              run(++runTick);
            }
            break;
          default:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              status = RETURN;
              step   = null;
              fail   = null;
            }
          }

          return canceler;
        };
      }

      function join(cb) {
        return function () {
          var canceler = onComplete({
            rethrow: false,
            handler: cb
          })();
          if (status === SUSPENDED) {
            run(runTick);
          }
          return canceler;
        };
      }

      return {
        kill: kill,
        join: join,
        onComplete: onComplete,
        isSuspended: function () {
          return status === SUSPENDED;
        },
        run: function () {
          if (status === SUSPENDED) {
            if (!Scheduler.isDraining()) {
              Scheduler.enqueue(function () {
                run(runTick);
              });
            } else {
              run(runTick);
            }
          }
        }
      };
    }

    function runPar(util, supervisor, par, cb) {
      // Table of all forked fibers.
      var fiberId   = 0;
      var fibers    = {};

      // Table of currently running cancelers, as a product of `Alt` behavior.
      var killId    = 0;
      var kills     = {};

      // Error used for early cancelation on Alt branches.
      var early     = new Error("[ParAff] Early exit");

      // Error used to kill the entire tree.
      var interrupt = null;

      // The root pointer of the tree.
      var root      = EMPTY;

      // Walks a tree, invoking all the cancelers. Returns the table of pending
      // cancellation fibers.
      function kill(error, par, cb) {
        var step  = par;
        var head  = null;
        var tail  = null;
        var count = 0;
        var kills = {};
        var tmp, kid;

        loop: while (true) {
          tmp = null;

          switch (step.tag) {
          case FORKED:
            if (step._3 === EMPTY) {
              tmp = fibers[step._1];
              kills[count++] = tmp.kill(error, function (result) {
                return function () {
                  count--;
                  if (count === 0) {
                    cb(result)();
                  }
                };
              });
            }
            // Terminal case.
            if (head === null) {
              break loop;
            }
            // Go down the right side of the tree.
            step = head._2;
            if (tail === null) {
              head = null;
            } else {
              head = tail._1;
              tail = tail._2;
            }
            break;
          case MAP:
            step = step._2;
            break;
          case APPLY:
          case ALT:
            if (head) {
              tail = new Aff(CONS, head, tail);
            }
            head = step;
            step = step._1;
            break;
          }
        }

        if (count === 0) {
          cb(util.right(void 0))();
        } else {
          // Run the cancelation effects. We alias `count` because it's mutable.
          kid = 0;
          tmp = count;
          for (; kid < tmp; kid++) {
            kills[kid] = kills[kid]();
          }
        }

        return kills;
      }

      // When a fiber resolves, we need to bubble back up the tree with the
      // result, computing the applicative nodes.
      function join(result, head, tail) {
        var fail, step, lhs, rhs, tmp, kid;

        if (util.isLeft(result)) {
          fail = result;
          step = null;
        } else {
          step = result;
          fail = null;
        }

        loop: while (true) {
          lhs = null;
          rhs = null;
          tmp = null;
          kid = null;

          // We should never continue if the entire tree has been interrupted.
          if (interrupt !== null) {
            return;
          }

          // We've made it all the way to the root of the tree, which means
          // the tree has fully evaluated.
          if (head === null) {
            cb(fail || step)();
            return;
          }

          // The tree has already been computed, so we shouldn't try to do it
          // again. This should never happen.
          // TODO: Remove this?
          if (head._3 !== EMPTY) {
            return;
          }

          switch (head.tag) {
          case MAP:
            if (fail === null) {
              head._3 = util.right(head._1(util.fromRight(step)));
              step    = head._3;
            } else {
              head._3 = fail;
            }
            break;
          case APPLY:
            lhs = head._1._3;
            rhs = head._2._3;
            // If we have a failure we should kill the other side because we
            // can't possible yield a result anymore.
            if (fail) {
              head._3 = fail;
              tmp     = true;
              kid     = killId++;

              kills[kid] = kill(early, fail === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(fail, null, null);
                  } else {
                    join(fail, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            } else if (lhs === EMPTY || rhs === EMPTY) {
              // We can only proceed if both sides have resolved.
              return;
            } else {
              step    = util.right(util.fromRight(lhs)(util.fromRight(rhs)));
              head._3 = step;
            }
            break;
          case ALT:
            lhs = head._1._3;
            rhs = head._2._3;
            // We can only proceed if both have resolved or we have a success
            if (lhs === EMPTY && util.isLeft(rhs) || rhs === EMPTY && util.isLeft(lhs)) {
              return;
            }
            // If both sides resolve with an error, we should continue with the
            // first error
            if (lhs !== EMPTY && util.isLeft(lhs) && rhs !== EMPTY && util.isLeft(rhs)) {
              fail    = step === lhs ? rhs : lhs;
              step    = null;
              head._3 = fail;
            } else {
              head._3 = step;
              tmp     = true;
              kid     = killId++;
              // Once a side has resolved, we need to cancel the side that is still
              // pending before we can continue.
              kills[kid] = kill(early, step === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(step, null, null);
                  } else {
                    join(step, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            }
            break;
          }

          if (tail === null) {
            head = null;
          } else {
            head = tail._1;
            tail = tail._2;
          }
        }
      }

      function resolve(fiber) {
        return function (result) {
          return function () {
            delete fibers[fiber._1];
            fiber._3 = result;
            join(result, fiber._2._1, fiber._2._2);
          };
        };
      }

      // Walks the applicative tree, substituting non-applicative nodes with
      // `FORKED` nodes. In this tree, all applicative nodes use the `_3` slot
      // as a mutable slot for memoization. In an unresolved state, the `_3`
      // slot is `EMPTY`. In the cases of `ALT` and `APPLY`, we always walk
      // the left side first, because both operations are left-associative. As
      // we `RETURN` from those branches, we then walk the right side.
      function run() {
        var status = CONTINUE;
        var step   = par;
        var head   = null;
        var tail   = null;
        var tmp, fid;

        loop: while (true) {
          tmp = null;
          fid = null;

          switch (status) {
          case CONTINUE:
            switch (step.tag) {
            case MAP:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(MAP, step._1, EMPTY, EMPTY);
              step = step._2;
              break;
            case APPLY:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(APPLY, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            case ALT:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(ALT, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            default:
              // When we hit a leaf value, we suspend the stack in the `FORKED`.
              // When the fiber resolves, it can bubble back up the tree.
              fid    = fiberId++;
              status = RETURN;
              tmp    = step;
              step   = new Aff(FORKED, fid, new Aff(CONS, head, tail), EMPTY);
              tmp    = Fiber(util, supervisor, tmp);
              tmp.onComplete({
                rethrow: false,
                handler: resolve(step)
              })();
              fibers[fid] = tmp;
              if (supervisor) {
                supervisor.register(tmp);
              }
            }
            break;
          case RETURN:
            // Terminal case, we are back at the root.
            if (head === null) {
              break loop;
            }
            // If we are done with the right side, we need to continue down the
            // left. Otherwise we should continue up the stack.
            if (head._1 === EMPTY) {
              head._1 = step;
              status  = CONTINUE;
              step    = head._2;
              head._2 = EMPTY;
            } else {
              head._2 = step;
              step    = head;
              if (tail === null) {
                head  = null;
              } else {
                head  = tail._1;
                tail  = tail._2;
              }
            }
          }
        }

        // Keep a reference to the tree root so it can be cancelled.
        root = step;

        for (fid = 0; fid < fiberId; fid++) {
          fibers[fid].run();
        }
      }

      // Cancels the entire tree. If there are already subtrees being canceled,
      // we need to first cancel those joins. We will then add fresh joins for
      // all pending branches including those that were in the process of being
      // canceled.
      function cancel(error, cb) {
        interrupt = util.left(error);
        var innerKills;
        for (var kid in kills) {
          if (kills.hasOwnProperty(kid)) {
            innerKills = kills[kid];
            for (kid in innerKills) {
              if (innerKills.hasOwnProperty(kid)) {
                innerKills[kid]();
              }
            }
          }
        }

        kills = null;
        var newKills = kill(error, root, cb);

        return function (killError) {
          return new Aff(ASYNC, function (killCb) {
            return function () {
              for (var kid in newKills) {
                if (newKills.hasOwnProperty(kid)) {
                  newKills[kid]();
                }
              }
              return nonCanceler;
            };
          });
        };
      }

      run();

      return function (killError) {
        return new Aff(ASYNC, function (killCb) {
          return function () {
            return cancel(killError, killCb);
          };
        });
      };
    }

    function sequential(util, supervisor, par) {
      return new Aff(ASYNC, function (cb) {
        return function () {
          return runPar(util, supervisor, par, cb);
        };
      });
    }

    Aff.EMPTY       = EMPTY;
    Aff.Pure        = AffCtr(PURE);
    Aff.Throw       = AffCtr(THROW);
    Aff.Catch       = AffCtr(CATCH);
    Aff.Sync        = AffCtr(SYNC);
    Aff.Async       = AffCtr(ASYNC);
    Aff.Bind        = AffCtr(BIND);
    Aff.Bracket     = AffCtr(BRACKET);
    Aff.Fork        = AffCtr(FORK);
    Aff.Seq         = AffCtr(SEQ);
    Aff.ParMap      = AffCtr(MAP);
    Aff.ParApply    = AffCtr(APPLY);
    Aff.ParAlt      = AffCtr(ALT);
    Aff.Fiber       = Fiber;
    Aff.Supervisor  = Supervisor;
    Aff.Scheduler   = Scheduler;
    Aff.nonCanceler = nonCanceler;

    return Aff;
  }();

  exports._pure = Aff.Pure;

  exports._throwError = Aff.Throw;

  exports._catchError = function (aff) {
    return function (k) {
      return Aff.Catch(aff, k);
    };
  };

  exports._map = function (f) {
    return function (aff) {
      if (aff.tag === Aff.Pure.tag) {
        return Aff.Pure(f(aff._1));
      } else {
        return Aff.Bind(aff, function (value) {
          return Aff.Pure(f(value));
        });
      }
    };
  };

  exports._bind = function (aff) {
    return function (k) {
      return Aff.Bind(aff, k);
    };
  };

  exports._fork = function (immediate) {
    return function (aff) {
      return Aff.Fork(immediate, aff);
    };
  };

  exports._liftEffect = Aff.Sync;

  exports._parAffMap = function (f) {
    return function (aff) {
      return Aff.ParMap(f, aff);
    };
  };

  exports._parAffApply = function (aff1) {
    return function (aff2) {
      return Aff.ParApply(aff1, aff2);
    };
  };

  exports.makeAff = Aff.Async;

  exports.generalBracket = function (acquire) {
    return function (options) {
      return function (k) {
        return Aff.Bracket(acquire, options, k);
      };
    };
  };

  exports._makeFiber = function (util, aff) {
    return function () {
      return Aff.Fiber(util, null, aff);
    };
  };  

  exports._sequential = Aff.Seq;
})(PS["Effect.Aff"] = PS["Effect.Aff"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Error.Class"] = $PS["Control.Monad.Error.Class"] || {};
  var exports = $PS["Control.Monad.Error.Class"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];                        
  var throwError = function (dict) {
      return dict.throwError;
  };
  var catchError = function (dict) {
      return dict.catchError;
  };
  var $$try = function (dictMonadError) {
      return function (a) {
          return catchError(dictMonadError)(Data_Functor.map(((((dictMonadError.MonadThrow0()).Monad0()).Bind1()).Apply0()).Functor0())(Data_Either.Right.create)(a))((function () {
              var $21 = Control_Applicative.pure(((dictMonadError.MonadThrow0()).Monad0()).Applicative0());
              return function ($22) {
                  return $21(Data_Either.Left.create($22));
              };
          })());
      };
  };
  exports["catchError"] = catchError;
  exports["throwError"] = throwError;
  exports["try"] = $$try;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Parallel.Class"] = $PS["Control.Parallel.Class"] || {};
  var exports = $PS["Control.Parallel.Class"];
  var sequential = function (dict) {
      return dict.sequential;
  };
  var parallel = function (dict) {
      return dict.parallel;
  };
  exports["parallel"] = parallel;
  exports["sequential"] = sequential;
})(PS);
(function(exports) {
  "use strict";

  exports.error = function (msg) {
    return new Error(msg);
  };

  exports.throwException = function (e) {
    return function () {
      throw e;
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect.Exception"] = $PS["Effect.Exception"] || {};
  var exports = $PS["Effect.Exception"];
  var $foreign = $PS["Effect.Exception"];
  var $$throw = function ($2) {
      return $foreign.throwException($foreign.error($2));
  };
  exports["throw"] = $$throw;
  exports["error"] = $foreign.error;
  exports["throwException"] = $foreign.throwException;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafePerformEffect = function (f) {
    return f();
  };
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect.Unsafe"] = $PS["Effect.Unsafe"] || {};
  var exports = $PS["Effect.Unsafe"];
  var $foreign = $PS["Effect.Unsafe"];
  exports["unsafePerformEffect"] = $foreign.unsafePerformEffect;
})(PS);
(function(exports) {
  "use strict";

  // module Partial.Unsafe

  exports._unsafePartial = function (f) {
    return f();
  };
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});
(function(exports) {
  "use strict";

  // module Partial

  exports._crashWith = function (msg) {
    throw new Error(msg);
  };
})(PS["Partial"] = PS["Partial"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Partial"] = $PS["Partial"] || {};
  var exports = $PS["Partial"];
  var $foreign = $PS["Partial"];         
  var crashWith = function (dictPartial) {
      return $foreign["_crashWith"];
  };
  exports["crashWith"] = crashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Partial.Unsafe"] = $PS["Partial.Unsafe"] || {};
  var exports = $PS["Partial.Unsafe"];
  var $foreign = $PS["Partial.Unsafe"];
  var Partial = $PS["Partial"];                
  var unsafePartial = $foreign["_unsafePartial"];
  var unsafeCrashWith = function (msg) {
      return unsafePartial(function (dictPartial) {
          return Partial.crashWith()(msg);
      });
  };
  exports["unsafeCrashWith"] = unsafeCrashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect.Aff"] = $PS["Effect.Aff"] || {};
  var exports = $PS["Effect.Aff"];
  var $foreign = $PS["Effect.Aff"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Unsafe = $PS["Effect.Unsafe"];
  var Partial_Unsafe = $PS["Partial.Unsafe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Canceler = function (x) {
      return x;
  };
  var suspendAff = $foreign["_fork"](false);
  var functorParAff = {
      map: $foreign["_parAffMap"]
  };
  var functorAff = {
      map: $foreign["_map"]
  };
  var forkAff = $foreign["_fork"](true);
  var ffiUtil = (function () {
      var unsafeFromRight = function (v) {
          if (v instanceof Data_Either.Right) {
              return v.value0;
          };
          if (v instanceof Data_Either.Left) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 404, column 21 - line 406, column 54): " + [ v.constructor.name ]);
      };
      var unsafeFromLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return v.value0;
          };
          if (v instanceof Data_Either.Right) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 399, column 20 - line 401, column 54): " + [ v.constructor.name ]);
      };
      var isLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return true;
          };
          if (v instanceof Data_Either.Right) {
              return false;
          };
          throw new Error("Failed pattern match at Effect.Aff (line 394, column 12 - line 396, column 20): " + [ v.constructor.name ]);
      };
      return {
          isLeft: isLeft,
          fromLeft: unsafeFromLeft,
          fromRight: unsafeFromRight,
          left: Data_Either.Left.create,
          right: Data_Either.Right.create
      };
  })();
  var makeFiber = function (aff) {
      return $foreign["_makeFiber"](ffiUtil, aff);
  };
  var launchAff = function (aff) {
      return function __do() {
          var fiber = makeFiber(aff)();
          fiber.run();
          return fiber;
      };
  };
  var bracket = function (acquire) {
      return function (completed) {
          return $foreign.generalBracket(acquire)({
              killed: Data_Function["const"](completed),
              failed: Data_Function["const"](completed),
              completed: Data_Function["const"](completed)
          });
      };
  };
  var applyParAff = {
      apply: $foreign["_parAffApply"],
      Functor0: function () {
          return functorParAff;
      }
  };
  var monadAff = {
      Applicative0: function () {
          return applicativeAff;
      },
      Bind1: function () {
          return bindAff;
      }
  };
  var bindAff = {
      bind: $foreign["_bind"],
      Apply0: function () {
          return applyAff;
      }
  };
  var applyAff = {
      apply: Control_Monad.ap(monadAff),
      Functor0: function () {
          return functorAff;
      }
  };
  var applicativeAff = {
      pure: $foreign["_pure"],
      Apply0: function () {
          return applyAff;
      }
  };
  var $$finally = function (fin) {
      return function (a) {
          return bracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(Data_Function["const"](fin))(Data_Function["const"](a));
      };
  };
  var monadEffectAff = {
      liftEffect: $foreign["_liftEffect"],
      Monad0: function () {
          return monadAff;
      }
  };
  var effectCanceler = (function () {
      var $42 = Effect_Class.liftEffect(monadEffectAff);
      return function ($43) {
          return Canceler(Data_Function["const"]($42($43)));
      };
  })();
  var joinFiber = function (v) {
      return $foreign.makeAff(function (k) {
          return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.join(k));
      });
  };
  var functorFiber = {
      map: function (f) {
          return function (t) {
              return Effect_Unsafe.unsafePerformEffect(makeFiber(Data_Functor.map(functorAff)(f)(joinFiber(t))));
          };
      }
  };
  var killFiber = function (e) {
      return function (v) {
          return Control_Bind.bind(bindAff)(Effect_Class.liftEffect(monadEffectAff)(v.isSuspended))(function (v1) {
              if (v1) {
                  return Effect_Class.liftEffect(monadEffectAff)(Data_Functor["void"](Effect.functorEffect)(v.kill(e, Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))));
              };
              return $foreign.makeAff(function (k) {
                  return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.kill(e, k));
              });
          });
      };
  };   
  var monadThrowAff = {
      throwError: $foreign["_throwError"],
      Monad0: function () {
          return monadAff;
      }
  };
  var monadErrorAff = {
      catchError: $foreign["_catchError"],
      MonadThrow0: function () {
          return monadThrowAff;
      }
  };                                                            
  var runAff = function (k) {
      return function (aff) {
          return launchAff(Control_Bind.bindFlipped(bindAff)((function () {
              var $46 = Effect_Class.liftEffect(monadEffectAff);
              return function ($47) {
                  return $46(k($47));
              };
          })())(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
      };
  };
  var runAff_ = function (k) {
      return function (aff) {
          return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
      };
  };
  var parallelAff = {
      parallel: Unsafe_Coerce.unsafeCoerce,
      sequential: $foreign["_sequential"],
      Monad0: function () {
          return monadAff;
      },
      Applicative1: function () {
          return applicativeParAff;
      }
  };
  var applicativeParAff = {
      pure: (function () {
          var $50 = Control_Parallel_Class.parallel(parallelAff);
          var $51 = Control_Applicative.pure(applicativeAff);
          return function ($52) {
              return $50($51($52));
          };
      })(),
      Apply0: function () {
          return applyParAff;
      }
  };
  var monadRecAff = {
      tailRecM: function (k) {
          var go = function (a) {
              return Control_Bind.bind(bindAff)(k(a))(function (res) {
                  if (res instanceof Control_Monad_Rec_Class.Done) {
                      return Control_Applicative.pure(applicativeAff)(res.value0);
                  };
                  if (res instanceof Control_Monad_Rec_Class.Loop) {
                      return go(res.value0);
                  };
                  throw new Error("Failed pattern match at Effect.Aff (line 102, column 7 - line 104, column 22): " + [ res.constructor.name ]);
              });
          };
          return go;
      },
      Monad0: function () {
          return monadAff;
      }
  };
  var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit));
  var altAff = {
      alt: function (a1) {
          return function (a2) {
              return Control_Monad_Error_Class.catchError(monadErrorAff)(a1)(Data_Function["const"](a2));
          };
      },
      Functor0: function () {
          return functorAff;
      }
  };
  var plusAff = {
      empty: Control_Monad_Error_Class.throwError(monadThrowAff)(Effect_Exception.error("Always fails")),
      Alt0: function () {
          return altAff;
      }
  };
  exports["runAff_"] = runAff_;
  exports["forkAff"] = forkAff;
  exports["suspendAff"] = suspendAff;
  exports["finally"] = $$finally;
  exports["killFiber"] = killFiber;
  exports["joinFiber"] = joinFiber;
  exports["nonCanceler"] = nonCanceler;
  exports["effectCanceler"] = effectCanceler;
  exports["functorAff"] = functorAff;
  exports["applicativeAff"] = applicativeAff;
  exports["bindAff"] = bindAff;
  exports["monadAff"] = monadAff;
  exports["plusAff"] = plusAff;
  exports["monadRecAff"] = monadRecAff;
  exports["monadThrowAff"] = monadThrowAff;
  exports["monadEffectAff"] = monadEffectAff;
  exports["applicativeParAff"] = applicativeParAff;
  exports["parallelAff"] = parallelAff;
  exports["functorFiber"] = functorFiber;
  exports["makeAff"] = $foreign.makeAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect.Aff.Class"] = $PS["Effect.Aff.Class"] || {};
  var exports = $PS["Effect.Aff.Class"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Effect_Aff = $PS["Effect.Aff"];                
  var monadAffAff = {
      liftAff: Control_Category.identity(Control_Category.categoryFn),
      MonadEffect0: function () {
          return Effect_Aff.monadEffectAff;
      }
  };
  var liftAff = function (dict) {
      return dict.liftAff;
  };
  var monadAffReader = function (dictMonadAff) {
      return {
          liftAff: (function () {
              var $26 = Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)((dictMonadAff.MonadEffect0()).Monad0());
              var $27 = liftAff(dictMonadAff);
              return function ($28) {
                  return $26($27($28));
              };
          })(),
          MonadEffect0: function () {
              return Control_Monad_Reader_Trans.monadEffectReader(dictMonadAff.MonadEffect0());
          }
      };
  };
  exports["monadAffAff"] = monadAffAff;
  exports["monadAffReader"] = monadAffReader;
})(PS);
(function(exports) {
  "use strict";

  exports.log = function (s) {
    return function () {
      console.log(s);
    };
  };

  exports.warn = function (s) {
    return function () {
      console.warn(s);
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect.Console"] = $PS["Effect.Console"] || {};
  var exports = $PS["Effect.Console"];
  var $foreign = $PS["Effect.Console"];
  exports["log"] = $foreign.log;
  exports["warn"] = $foreign.warn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Effect.Class.Console"] = $PS["Effect.Class.Console"] || {};
  var exports = $PS["Effect.Class.Console"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var log = function (dictMonadEffect) {
      var $30 = Effect_Class.liftEffect(dictMonadEffect);
      return function ($31) {
          return $30(Effect_Console.log($31));
      };
  };
  exports["log"] = log;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Type.Equality"] = $PS["Type.Equality"] || {};
  var exports = $PS["Type.Equality"];
  var refl = {
      proof: function (a) {
          return a;
      },
      Coercible0: function () {
          return undefined;
      }
  };
  var proof = function (dict) {
      return dict.proof;
  };
  var from = function (dictTypeEquals) {
      var v = proof(dictTypeEquals)(function (a) {
          return a;
      });
      return v;
  };
  exports["from"] = from;
  exports["refl"] = refl;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["App.Monad"] = $PS["App.Monad"] || {};
  var exports = $PS["App.Monad"];
  var Capabilities_Logging = $PS["Capabilities.Logging"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Reader_Class = $PS["Control.Monad.Reader.Class"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Ord = $PS["Data.Ord"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Class_Console = $PS["Effect.Class.Console"];
  var Type_Equality = $PS["Type.Equality"];
  var appMPlus = Control_Monad_Reader_Trans.plusReaderT(Effect_Aff.plusAff);
  var runAppM = function (m) {
      return function (env) {
          return Control_Monad_Reader_Trans.runReaderT(Data_Newtype.unwrap()(m))(env);
      };
  };
  var appMMonadEffect = Control_Monad_Reader_Trans.monadEffectReader(Effect_Aff.monadEffectAff);
  var appMMonadAff = Effect_Aff_Class.monadAffReader(Effect_Aff_Class.monadAffAff);
  var appMMonad = Control_Monad_Reader_Trans.monadReaderT(Effect_Aff.monadAff);
  var appMMonadAsk = function (dictTypeEquals) {
      return {
          ask: Control_Monad_Reader_Class.asks(Control_Monad_Reader_Trans.monadAskReaderT(Effect_Aff.monadAff))(Type_Equality.from(dictTypeEquals)),
          Monad0: function () {
              return appMMonad;
          }
      };
  };                                                                                 
  var appMBind = Control_Monad_Reader_Trans.bindReaderT(Effect_Aff.bindAff);   
  var appMApplicative = Control_Monad_Reader_Trans.applicativeReaderT(Effect_Aff.applicativeAff);
  var appMLogger = {
      log: function (level) {
          return function (s) {
              return Control_Bind.bind(appMBind)(Control_Monad_Reader_Class.ask(appMMonadAsk(Type_Equality.refl)))(function (env) {
                  return Control_Applicative.when(appMApplicative)(Data_Ord.lessThanOrEq(Capabilities_Logging.logLevelOrd)(env.logLevel)(level))(Effect_Class.liftEffect(appMMonadEffect)(Effect_Class_Console.log(Effect_Class.monadEffectEffect)(Capabilities_Logging.formatLog(level)(s))));
              });
          };
      },
      Monad0: function () {
          return appMMonad;
      }
  };
  var appMAlternative = {
      Applicative0: function () {
          return appMApplicative;
      },
      Plus1: function () {
          return appMPlus;
      }
  };
  var appMMonadPlus = {
      Monad0: function () {
          return appMMonad;
      },
      Alternative1: function () {
          return appMAlternative;
      }
  };
  exports["runAppM"] = runAppM;
  exports["appMMonadPlus"] = appMMonadPlus;
  exports["appMMonadAff"] = appMMonadAff;
  exports["appMLogger"] = appMLogger;
})(PS);
(function(exports) {
  "use strict";

  exports._jsonParser = function (fail, succ, s) {
    try {
      return succ(JSON.parse(s));
    }
    catch (e) {
      return fail(e.message);
    }
  };
})(PS["Data.Argonaut.Parser"] = PS["Data.Argonaut.Parser"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Data.Argonaut.Parser"] = $PS["Data.Argonaut.Parser"] || {};
  var exports = $PS["Data.Argonaut.Parser"];
  var $foreign = $PS["Data.Argonaut.Parser"];
  var Data_Either = $PS["Data.Either"];                
  var jsonParser = function (j) {
      return $foreign["_jsonParser"](Data_Either.Left.create, Data_Either.Right.create, j);
  };
  exports["jsonParser"] = jsonParser;
})(PS);
(function(exports) {
  "use strict";

  exports.reallyUnsafeRefEq = function (a) {
    return function (b) {
      return a === b;
    };
  };
})(PS["Unsafe.Reference"] = PS["Unsafe.Reference"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Unsafe.Reference"] = $PS["Unsafe.Reference"] || {};
  var exports = $PS["Unsafe.Reference"];
  var $foreign = $PS["Unsafe.Reference"];
  var unsafeRefEq = $foreign.reallyUnsafeRefEq;
  exports["unsafeRefEq"] = unsafeRefEq;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Subscription"] = $PS["Halogen.Subscription"] || {};
  var exports = $PS["Halogen.Subscription"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Safe_Coerce = $PS["Safe.Coerce"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];
  var unsubscribe = function (v) {
      return v;
  };
  var subscribe = function (v) {
      return function (k) {
          return v((function () {
              var $55 = Data_Functor["void"](Effect.functorEffect);
              return function ($56) {
                  return $55(k($56));
              };
          })());
      };
  };                                                                               
  var notify = function (v) {
      return function (a) {
          return v(a);
      };
  };                                                                   
  var makeEmitter = Safe_Coerce.coerce();
  var create = function __do() {
      var subscribers = Effect_Ref["new"]([  ])();
      return {
          emitter: function (k) {
              return function __do() {
                  Effect_Ref.modify_(function (v) {
                      return Data_Semigroup.append(Data_Semigroup.semigroupArray)(v)([ k ]);
                  })(subscribers)();
                  return Effect_Ref.modify_(Data_Array.deleteBy(Unsafe_Reference.unsafeRefEq)(k))(subscribers);
              };
          },
          listener: function (a) {
              return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(subscribers))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(function (k) {
                  return k(a);
              }));
          }
      };
  };
  exports["create"] = create;
  exports["notify"] = notify;
  exports["makeEmitter"] = makeEmitter;
  exports["subscribe"] = subscribe;
  exports["unsubscribe"] = unsubscribe;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Query.Event"] = $PS["Halogen.Query.Event"] || {};
  var exports = $PS["Halogen.Query.Event"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Effect = $PS["Effect"];
  var Halogen_Subscription = $PS["Halogen.Subscription"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];                
  var eventListener = function (eventType) {
      return function (target) {
          return function (f) {
              return Halogen_Subscription.makeEmitter(function (push) {
                  return function __do() {
                      var listener = Web_Event_EventTarget.eventListener(function (ev) {
                          return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(push)(f(ev));
                      })();
                      Web_Event_EventTarget.addEventListener(eventType)(listener)(false)(target)();
                      return Web_Event_EventTarget.removeEventListener(eventType)(listener)(false)(target);
                  };
              });
          };
      };
  };
  exports["eventListener"] = eventListener;
})(PS);
(function(exports) {
  "use strict";

  exports.window = function () {
    return window;
  };
})(PS["Web.HTML"] = PS["Web.HTML"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.HTML"] = $PS["Web.HTML"] || {};
  var exports = $PS["Web.HTML"];
  var $foreign = $PS["Web.HTML"];
  exports["window"] = $foreign.window;
})(PS);
(function(exports) {
  "use strict";

  exports._readyState = function (doc) {
    return function () {
      return doc.readyState;
    };
  };
})(PS["Web.HTML.HTMLDocument"] = PS["Web.HTML.HTMLDocument"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.HTML.HTMLDocument.ReadyState"] = $PS["Web.HTML.HTMLDocument.ReadyState"] || {};
  var exports = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var Data_Maybe = $PS["Data.Maybe"];                      
  var Loading = (function () {
      function Loading() {

      };
      Loading.value = new Loading();
      return Loading;
  })();
  var Interactive = (function () {
      function Interactive() {

      };
      Interactive.value = new Interactive();
      return Interactive;
  })();
  var Complete = (function () {
      function Complete() {

      };
      Complete.value = new Complete();
      return Complete;
  })();
  var parse = function (v) {
      if (v === "loading") {
          return new Data_Maybe.Just(Loading.value);
      };
      if (v === "interactive") {
          return new Data_Maybe.Just(Interactive.value);
      };
      if (v === "complete") {
          return new Data_Maybe.Just(Complete.value);
      };
      return Data_Maybe.Nothing.value;
  };
  exports["Loading"] = Loading;
  exports["parse"] = parse;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.HTML.HTMLDocument"] = $PS["Web.HTML.HTMLDocument"] || {};
  var exports = $PS["Web.HTML.HTMLDocument"];
  var $foreign = $PS["Web.HTML.HTMLDocument"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect = $PS["Effect"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_HTML_HTMLDocument_ReadyState = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var toParentNode = Unsafe_Coerce.unsafeCoerce;
  var toEventTarget = Unsafe_Coerce.unsafeCoerce;
  var toDocument = Unsafe_Coerce.unsafeCoerce;
  var readyState = (function () {
      var $0 = Data_Functor.map(Effect.functorEffect)((function () {
          var $2 = Data_Maybe.fromMaybe(Web_HTML_HTMLDocument_ReadyState.Loading.value);
          return function ($3) {
              return $2(Web_HTML_HTMLDocument_ReadyState.parse($3));
          };
      })());
      return function ($1) {
          return $0($foreign["_readyState"]($1));
      };
  })();
  exports["toDocument"] = toDocument;
  exports["toParentNode"] = toParentNode;
  exports["toEventTarget"] = toEventTarget;
  exports["readyState"] = readyState;
})(PS);
(function(exports) {
  "use strict";

  exports.document = function (window) {
    return function () {
      return window.document;
    };
  };

  exports.localStorage = function (window) {
    return function () {
      return window.localStorage;
    };
  };
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.HTML.Window"] = $PS["Web.HTML.Window"] || {};
  var exports = $PS["Web.HTML.Window"];
  var $foreign = $PS["Web.HTML.Window"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var toEventTarget = Unsafe_Coerce.unsafeCoerce;
  exports["toEventTarget"] = toEventTarget;
  exports["document"] = $foreign.document;
  exports["localStorage"] = $foreign.localStorage;
})(PS);
(function(exports) {
  "use strict";

  exports._getItem = function (key) {
    return function (storage) {
      return function () {
        return storage.getItem(key);
      };
    };
  };

  exports.setItem = function (key) {
    return function (value) {
      return function (storage) {
        return function () {
          storage.setItem(key, value);
        };
      };
    };
  };
})(PS["Web.Storage.Storage"] = PS["Web.Storage.Storage"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.Storage.Storage"] = $PS["Web.Storage.Storage"] || {};
  var exports = $PS["Web.Storage.Storage"];
  var $foreign = $PS["Web.Storage.Storage"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var getItem = function (s) {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $4 = $foreign["_getItem"](s);
      return function ($5) {
          return $3($4($5));
      };
  };
  exports["getItem"] = getItem;
  exports["setItem"] = $foreign.setItem;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Component.App"] = $PS["Component.App"] || {};
  var exports = $PS["Component.App"];
  var App_Control_Map = $PS["App.Control.Map"];
  var App_Control_MapAction = $PS["App.Control.MapAction"];
  var App_Control_MapMode = $PS["App.Control.MapMode"];
  var App_Control_MapState = $PS["App.Control.MapState"];
  var App_Control_StateChangeType = $PS["App.Control.StateChangeType"];
  var App_Data_Node = $PS["App.Data.Node"];
  var App_Data_NodeCommon = $PS["App.Data.NodeCommon"];
  var Capabilities_Logging = $PS["Capabilities.Logging"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Parser = $PS["Data.Argonaut.Parser"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_Query_Event = $PS["Halogen.Query.Event"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var Web_Storage_Storage = $PS["Web.Storage.Storage"];
  var Web_UIEvent_KeyboardEvent = $PS["Web.UIEvent.KeyboardEvent"];
  var Web_UIEvent_KeyboardEvent_EventTypes = $PS["Web.UIEvent.KeyboardEvent.EventTypes"];                
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var MapAction = (function () {
      function MapAction(value0) {
          this.value0 = value0;
      };
      MapAction.create = function (value0) {
          return new MapAction(value0);
      };
      return MapAction;
  })();
  var mkComponent = function (dictLogger) {
      return function (dictMonadAff) {
          return function (dictMonadPlus) {
              return function (v) {
                  var stateForSaving = function (state) {
                      return {
                          nodes: state.nodes,
                          relations: state.relations,
                          selected: Data_Maybe.Nothing.value,
                          mode: state.mode,
                          maxId: state.maxId
                      };
                  };
                  var saveState = function (state) {
                      return Control_Bind.bind((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.localStorage)(Web_HTML.window)))(function (storage) {
                          var json = Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Data_NodeCommon.nodeIdEncode)(Data_Argonaut_Encode_Class.gEncodeJsonCons(App_Control_MapMode.modeEncode)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeMap(App_Data_NodeCommon.nodeIdOrd)(App_Data_NodeCommon.nodeIdEncode)(App_Data_Node.encodeNode))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeMap(App_Data_NodeCommon.nodeIdOrd)(App_Data_NodeCommon.nodeIdEncode)(Data_Argonaut_Encode_Class.encodeJsonList(App_Data_NodeCommon.nodeIdEncode)))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeMap(App_Data_NodeCommon.nodeIdOrd)(App_Data_NodeCommon.nodeIdEncode)(App_Data_NodeCommon.nodeIdEncode))(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
                              reflectSymbol: function () {
                                  return "parents";
                              }
                          })())({
                              reflectSymbol: function () {
                                  return "children";
                              }
                          })())())(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(App_Data_NodeCommon.nodeIdEncode))(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
                              reflectSymbol: function () {
                                  return "selected";
                              }
                          })())({
                              reflectSymbol: function () {
                                  return "relations";
                              }
                          })())({
                              reflectSymbol: function () {
                                  return "nodes";
                              }
                          })())({
                              reflectSymbol: function () {
                                  return "mode";
                              }
                          })())({
                              reflectSymbol: function () {
                                  return "maxId";
                              }
                          })())())(stateForSaving(state));
                          return Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Storage_Storage.setItem("cmstate")(Data_Argonaut_Core.stringify(json))(storage));
                      });
                  };
                  var render = function (state) {
                      return App_Control_Map.render(dictMonadAff)(MapAction.create)(state);
                  };
                  var mapLeft = function (v1) {
                      return function (v2) {
                          if (v2 instanceof Data_Either.Right) {
                              return new Data_Either.Right(v2.value0);
                          };
                          if (v2 instanceof Data_Either.Left) {
                              return Data_Either.Left.create(v1(v2.value0));
                          };
                          throw new Error("Failed pattern match at Component.App (line 85, column 3 - line 85, column 64): " + [ v1.constructor.name, v2.constructor.name ]);
                      };
                  };
                  var fromEither$prime = function (dictShow) {
                      return function (v1) {
                          return function (v2) {
                              if (v2 instanceof Data_Either.Right) {
                                  return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(v2.value0);
                              };
                              if (v2 instanceof Data_Either.Left) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)((dictLogger.Monad0()).Bind1())(Capabilities_Logging.log(dictLogger)(Capabilities_Logging["Error"].value)("Error: " + Data_Show.show(dictShow)(v2.value0)))(function () {
                                      return Control_Applicative.pure((dictLogger.Monad0()).Applicative0())(v1(Data_Unit.unit));
                                  });
                              };
                              throw new Error("Failed pattern match at Component.App (line 89, column 3 - line 89, column 72): " + [ v1.constructor.name, v2.constructor.name ]);
                          };
                      };
                  };
                  var loadState = function (v1) {
                      return Control_Bind.bind((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.localStorage)(Web_HTML.window)))(function (storage) {
                          return Control_Bind.bind((dictLogger.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Web_Storage_Storage.getItem("cmstate")(storage)))(function (mvalue) {
                              return fromEither$prime(Data_Show.showString)(App_Control_MapState.initialState)(Control_Bind.bind(Data_Either.bindEither)(Data_Either.note("Empty storage")(mvalue))(function (value) {
                                  return Control_Bind.bind(Data_Either.bindEither)(Data_Argonaut_Parser.jsonParser(value))(function (json) {
                                      return mapLeft(Data_Argonaut_Decode_Error.printJsonDecodeError)(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Data_NodeCommon.nodeIdDecode))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(App_Control_MapMode.modeDecode))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeMap(App_Data_NodeCommon.nodeIdOrd)(App_Data_NodeCommon.nodeIdDecode)(App_Data_Node.decodeNode)))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeMap(App_Data_NodeCommon.nodeIdOrd)(App_Data_NodeCommon.nodeIdDecode)(Data_Argonaut_Decode_Class.decodeList(App_Data_NodeCommon.nodeIdDecode))))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeMap(App_Data_NodeCommon.nodeIdOrd)(App_Data_NodeCommon.nodeIdDecode)(App_Data_NodeCommon.nodeIdDecode)))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                                          reflectSymbol: function () {
                                              return "parents";
                                          }
                                      })()())({
                                          reflectSymbol: function () {
                                              return "children";
                                          }
                                      })()())()))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldMaybe(App_Data_NodeCommon.nodeIdDecode))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
                                          reflectSymbol: function () {
                                              return "selected";
                                          }
                                      })()())({
                                          reflectSymbol: function () {
                                              return "relations";
                                          }
                                      })()())({
                                          reflectSymbol: function () {
                                              return "nodes";
                                          }
                                      })()())({
                                          reflectSymbol: function () {
                                              return "mode";
                                          }
                                      })()())({
                                          reflectSymbol: function () {
                                              return "maxId";
                                          }
                                      })()())())(json));
                                  });
                              }));
                          });
                      });
                  };
                  var handleAction = function (action) {
                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)((function () {
                              if (action instanceof Initialize) {
                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window)))(function (document) {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.subscribe(Halogen_Query_Event.eventListener(Web_UIEvent_KeyboardEvent_EventTypes.keydown)(Web_HTML_HTMLDocument.toEventTarget(document))((function () {
                                          var $27 = Data_Functor.map(Data_Maybe.functorMaybe)(function ($29) {
                                              return MapAction.create(App_Control_MapAction.HandleMapKeyPress.create($29));
                                          });
                                          return function ($28) {
                                              return $27(Web_UIEvent_KeyboardEvent.fromEvent($28));
                                          };
                                      })()))))(function () {
                                          return Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(function (v1) {
                                              return new Data_Tuple.Tuple(v1, App_Control_StateChangeType.Ephemeral.value);
                                          })(Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)(dictLogger.Monad0())(loadState(Data_Unit.unit)));
                                      });
                                  });
                              };
                              if (action instanceof MapAction) {
                                  return Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)(dictLogger.Monad0())(App_Control_Map.handleAction(dictMonadAff)(dictLogger)(dictMonadPlus)(action.value0)(state));
                              };
                              throw new Error("Failed pattern match at Component.App (line 52, column 7 - line 63, column 44): " + [ action.constructor.name ]);
                          })())(function (v1) {
                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Eq.notEq(App_Control_StateChangeType.typeEq)(v1.value1)(App_Control_StateChangeType.NoChange.value))(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(Data_Function["const"](v1.value0))))(function () {
                                  return Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Eq.eq(App_Control_StateChangeType.typeEq)(v1.value1)(App_Control_StateChangeType.Persistent.value))(Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)(dictLogger.Monad0())(saveState(v1.value0)));
                              });
                          });
                      });
                  };
                  return Halogen_Component.mkComponent({
                      initialState: App_Control_MapState.initialState,
                      render: render,
                      "eval": Halogen_Component.mkEval({
                          handleAction: handleAction,
                          handleQuery: Halogen_Component.defaultEval.handleQuery,
                          receive: Halogen_Component.defaultEval.receive,
                          initialize: new Data_Maybe.Just(Initialize.value),
                          finalize: Halogen_Component.defaultEval.finalize
                      })
                  });
              };
          };
      };
  };
  exports["mkComponent"] = mkComponent;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Monad.Fork.Class"] = $PS["Control.Monad.Fork.Class"] || {};
  var exports = $PS["Control.Monad.Fork.Class"];
  var Effect_Aff = $PS["Effect.Aff"];
  var monadForkAff = {
      suspend: Effect_Aff.suspendAff,
      fork: Effect_Aff.forkAff,
      join: Effect_Aff.joinFiber,
      Monad0: function () {
          return Effect_Aff.monadAff;
      },
      Functor1: function () {
          return Effect_Aff.functorFiber;
      }
  };
  var fork = function (dict) {
      return dict.fork;
  };
  exports["fork"] = fork;
  exports["monadForkAff"] = monadForkAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Control.Parallel"] = $PS["Control.Parallel"] || {};
  var exports = $PS["Control.Parallel"];
  var Control_Category = $PS["Control.Category"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Foldable = $PS["Data.Foldable"];                      
  var parTraverse_ = function (dictParallel) {
      return function (dictFoldable) {
          return function (f) {
              var $17 = Control_Parallel_Class.sequential(dictParallel);
              var $18 = Data_Foldable.traverse_(dictParallel.Applicative1())(dictFoldable)((function () {
                  var $20 = Control_Parallel_Class.parallel(dictParallel);
                  return function ($21) {
                      return $20(f($21));
                  };
              })());
              return function ($19) {
                  return $17($18($19));
              };
          };
      };
  };
  var parSequence_ = function (dictParallel) {
      return function (dictFoldable) {
          return parTraverse_(dictParallel)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["parSequence_"] = parSequence_;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Aff.Driver.State"] = $PS["Halogen.Aff.Driver.State"] || {};
  var exports = $PS["Halogen.Aff.Driver.State"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var unRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var unDriverStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX_ = function (dictApplicative) {
      return function (f) {
          return unDriverStateX(function (st) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Foldable.foldableMaybe)(f)(st.rendering);
          });
      };
  };
  var mkRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX = function (dictFunctor) {
      return function (f) {
          return unDriverStateX(function (st) {
              return mkRenderStateX(f(st.rendering));
          });
      };
  };
  var mkDriverStateXRef = Unsafe_Coerce.unsafeCoerce;
  var mapDriverState = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var initDriverState = function (component) {
      return function (input) {
          return function (handler) {
              return function (lchs) {
                  return function __do() {
                      var selfRef = Effect_Ref["new"]({})();
                      var childrenIn = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var childrenOut = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var handlerRef = Effect_Ref["new"](handler)();
                      var pendingQueries = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var pendingOuts = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var pendingHandlers = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                      var fresh = Effect_Ref["new"](1)();
                      var subscriptions = Effect_Ref["new"](new Data_Maybe.Just(Data_Map_Internal.empty))();
                      var forks = Effect_Ref["new"](Data_Map_Internal.empty)();
                      var ds = {
                          component: component,
                          state: component.initialState(input),
                          refs: Data_Map_Internal.empty,
                          children: Halogen_Data_Slot.empty,
                          childrenIn: childrenIn,
                          childrenOut: childrenOut,
                          selfRef: selfRef,
                          handlerRef: handlerRef,
                          pendingQueries: pendingQueries,
                          pendingOuts: pendingOuts,
                          pendingHandlers: pendingHandlers,
                          rendering: Data_Maybe.Nothing.value,
                          fresh: fresh,
                          subscriptions: subscriptions,
                          forks: forks,
                          lifecycleHandlers: lchs
                      };
                      Effect_Ref.write(ds)(selfRef)();
                      return mkDriverStateXRef(selfRef);
                  };
              };
          };
      };
  };
  exports["mapDriverState"] = mapDriverState;
  exports["unDriverStateX"] = unDriverStateX;
  exports["renderStateX"] = renderStateX;
  exports["renderStateX_"] = renderStateX_;
  exports["unRenderStateX"] = unRenderStateX;
  exports["initDriverState"] = initDriverState;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Query.ChildQuery"] = $PS["Halogen.Query.ChildQuery"] || {};
  var exports = $PS["Halogen.Query.ChildQuery"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var unChildQueryBox = Unsafe_Coerce.unsafeCoerce;
  exports["unChildQueryBox"] = unChildQueryBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Aff.Driver.Eval"] = $PS["Halogen.Aff.Driver.Eval"] || {};
  var exports = $PS["Halogen.Aff.Driver.Eval"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Applicative_Free = $PS["Control.Applicative.Free"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Query_ChildQuery = $PS["Halogen.Query.ChildQuery"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_Subscription = $PS["Halogen.Subscription"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];                
  var unsubscribe = function (sid) {
      return function (ref) {
          return function __do() {
              var v = Effect_Ref.read(ref)();
              var subs = Effect_Ref.read(v.subscriptions)();
              return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Halogen_Subscription.unsubscribe)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordSubscriptionId)(sid))(subs))();
          };
      };
  };
  var queueOrRun = function (ref) {
      return function (au) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return au;
              };
              if (v instanceof Data_Maybe.Just) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write(new Data_Maybe.Just(new Data_List_Types.Cons(au, v.value0)))(ref));
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 182, column 33 - line 184, column 57): " + [ v.constructor.name ]);
          });
      };
  };
  var handleLifecycle = function (lchs) {
      return function (f) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
              initializers: Data_List_Types.Nil.value,
              finalizers: Data_List_Types.Nil.value
          })(lchs)))(function () {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(function (result) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(lchs)))(function (v) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff))(v.finalizers))(function () {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(v.initializers))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(result);
                          });
                      });
                  });
              });
          });
      };
  };
  var handleAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var fresh = function (f) {
      return function (ref) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["modify'"](function (i) {
                  return {
                      state: i + 1 | 0,
                      value: f(i)
                  };
              })(v.fresh));
          });
      };
  };
  var evalQ = function (render) {
      return function (ref) {
          return function (q) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
                  return evalM(render)(ref)(v["component"]["eval"](new Halogen_Query_HalogenQ.Query(Data_Functor.map(Data_Coyoneda.functorCoyoneda)(Data_Maybe.Just.create)(Data_Coyoneda.liftCoyoneda(q)), Data_Function["const"](Data_Maybe.Nothing.value))));
              });
          };
      };
  };
  var evalM = function (render) {
      return function (initRef) {
          return function (v) {
              var evalChildQuery = function (ref) {
                  return function (cqb) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                          return Halogen_Query_ChildQuery.unChildQueryBox(function (v2) {
                              var evalChild = function (v3) {
                                  return Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v3)))(function (dsx) {
                                      return Halogen_Aff_Driver_State.unDriverStateX(function (ds) {
                                          return evalQ(render)(ds.selfRef)(v2.value1);
                                      })(dsx);
                                  }));
                              };
                              return Data_Functor.map(Effect_Aff.functorAff)(v2.value2)(Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(v2.value0(Effect_Aff.applicativeParAff)(evalChild)(v1.children)));
                          })(cqb);
                      });
                  };
              };
              var go = function (ref) {
                  return function (v1) {
                      if (v1 instanceof Halogen_Query_HalogenM.State) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              var v3 = v1.value0(v2.state);
                              if (Unsafe_Reference.unsafeRefEq(v2.state)(v3.value1)) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                              };
                              if (Data_Boolean.otherwise) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
                                      component: v2.component,
                                      state: v3.value1,
                                      refs: v2.refs,
                                      children: v2.children,
                                      childrenIn: v2.childrenIn,
                                      childrenOut: v2.childrenOut,
                                      selfRef: v2.selfRef,
                                      handlerRef: v2.handlerRef,
                                      pendingQueries: v2.pendingQueries,
                                      pendingOuts: v2.pendingOuts,
                                      pendingHandlers: v2.pendingHandlers,
                                      rendering: v2.rendering,
                                      fresh: v2.fresh,
                                      subscriptions: v2.subscriptions,
                                      forks: v2.forks,
                                      lifecycleHandlers: v2.lifecycleHandlers
                                  })(ref)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleLifecycle(v2.lifecycleHandlers)(render(v2.lifecycleHandlers)(ref)))(function () {
                                          return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                                      });
                                  });
                              };
                              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 86, column 7 - line 92, column 21): " + [ v3.constructor.name ]);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Subscribe) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.SubscriptionId)(ref))(function (sid) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Halogen_Subscription.subscribe(v1.value0(sid))(function (act) {
                                  return handleAff(evalF(render)(ref)(new Halogen_Query_Input.Action(act)));
                              })))(function (finalize) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordSubscriptionId)(sid)(finalize)))(v2.subscriptions)))(function () {
                                          return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(sid));
                                      });
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Unsubscribe) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(unsubscribe(v1.value0)(ref)))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Lift) {
                          return v1.value0;
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.ChildQuery) {
                          return evalChildQuery(ref)(v1.value0);
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Raise) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.handlerRef)))(function (handler) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(queueOrRun(v2.pendingOuts)(handler(v1.value0)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Par) {
                          return Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(Control_Applicative_Free.retractFreeAp(Effect_Aff.applicativeParAff)(Control_Applicative_Free.hoistFreeAp((function () {
                              var $78 = Control_Parallel_Class.parallel(Effect_Aff.parallelAff);
                              var $79 = evalM(render)(ref);
                              return function ($80) {
                                  return $78($79($80));
                              };
                          })())(v1.value0)));
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Fork) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.ForkId)(ref))(function (fid) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (doneRef) {
                                      return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Effect_Aff["finally"](Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                          Effect_Ref.modify_(Data_Map_Internal["delete"](Halogen_Query_HalogenM.ordForkId)(fid))(v2.forks)();
                                          return Effect_Ref.write(true)(doneRef)();
                                      }))(evalM(render)(ref)(v1.value0))))(function (fiber) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Monad.unlessM(Effect.monadEffect)(Effect_Ref.read(doneRef))(Effect_Ref.modify_(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordForkId)(fid)(fiber))(v2.forks))))(function () {
                                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(fid));
                                          });
                                      });
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Kill) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.forks)))(function (forkMap) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Effect_Aff.killFiber(Effect_Exception.error("Cancelled")))(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordForkId)(v1.value0)(forkMap)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.GetRef) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(Data_Map_Internal.lookup(Data_Ord.ordString)(v1.value0)(v2.refs)));
                          });
                      };
                      throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 83, column 12 - line 133, column 33): " + [ v1.constructor.name ]);
                  };
              };
              return Control_Monad_Free.foldFree(Effect_Aff.monadRecAff)(go(initRef))(v);
          };
      };
  };
  var evalF = function (render) {
      return function (ref) {
          return function (v) {
              if (v instanceof Halogen_Query_Input.RefUpdate) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Function.flip(Effect_Ref.modify_)(ref)(Halogen_Aff_Driver_State.mapDriverState(function (st) {
                      return {
                          component: st.component,
                          state: st.state,
                          refs: Data_Map_Internal.alter(Data_Ord.ordString)(Data_Function["const"](v.value1))(v.value0)(st.refs),
                          children: st.children,
                          childrenIn: st.childrenIn,
                          childrenOut: st.childrenOut,
                          selfRef: st.selfRef,
                          handlerRef: st.handlerRef,
                          pendingQueries: st.pendingQueries,
                          pendingOuts: st.pendingOuts,
                          pendingHandlers: st.pendingHandlers,
                          rendering: st.rendering,
                          fresh: st.fresh,
                          subscriptions: st.subscriptions,
                          forks: st.forks,
                          lifecycleHandlers: st.lifecycleHandlers
                      };
                  })));
              };
              if (v instanceof Halogen_Query_Input.Action) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                      return evalM(render)(ref)(v1["component"]["eval"](new Halogen_Query_HalogenQ.Action(v.value0, Data_Unit.unit)));
                  });
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 52, column 20 - line 58, column 62): " + [ v.constructor.name ]);
          };
      };
  };
  exports["evalF"] = evalF;
  exports["evalQ"] = evalQ;
  exports["evalM"] = evalM;
  exports["handleLifecycle"] = handleLifecycle;
  exports["queueOrRun"] = queueOrRun;
  exports["handleAff"] = handleAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Aff.Driver"] = $PS["Halogen.Aff.Driver"] || {};
  var exports = $PS["Halogen.Aff.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_Eval = $PS["Halogen.Aff.Driver.Eval"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_Subscription = $PS["Halogen.Subscription"];                
  var newLifecycleHandlers = Effect_Ref["new"]({
      initializers: Data_List_Types.Nil.value,
      finalizers: Data_List_Types.Nil.value
  });
  var handlePending = function (ref) {
      return function __do() {
          var queue = Effect_Ref.read(ref)();
          Effect_Ref.write(Data_Maybe.Nothing.value)(ref)();
          return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(queue)((function () {
              var $28 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
              return function ($29) {
                  return Halogen_Aff_Driver_Eval.handleAff($28(Data_List.reverse($29)));
              };
          })())();
      };
  };
  var cleanupSubscriptionsAndForks = function (v) {
      return function __do() {
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)(Halogen_Subscription.unsubscribe)))(Effect_Ref.read(v.subscriptions))();
          Effect_Ref.write(Data_Maybe.Nothing.value)(v.subscriptions)();
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)((function () {
              var $30 = Effect_Aff.killFiber(Effect_Exception.error("finalized"));
              return function ($31) {
                  return Halogen_Aff_Driver_Eval.handleAff($30($31));
              };
          })()))(Effect_Ref.read(v.forks))();
          return Effect_Ref.write(Data_Map_Internal.empty)(v.forks)();
      };
  };
  var runUI = function (renderSpec) {
      return function (component) {
          return function (i) {
              var squashChildInitializers = function (lchs) {
                  return function (preInits) {
                      return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                          var parentInitializer = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Initialize(Data_Unit.unit)));
                          return Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: new Data_List_Types.Cons(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(Data_List.reverse(handlers.initializers)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(parentInitializer)(function () {
                                          return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                              handlePending(st.pendingQueries)();
                                              return handlePending(st.pendingOuts)();
                                          });
                                      });
                                  }), preInits),
                                  finalizers: handlers.finalizers
                              };
                          })(lchs);
                      });
                  };
              };
              var runComponent = function (lchs) {
                  return function (handler) {
                      return function (j) {
                          return Halogen_Component.unComponent(function (c) {
                              return function __do() {
                                  var lchs$prime = newLifecycleHandlers();
                                  var $$var = Halogen_Aff_Driver_State.initDriverState(c)(j)(handler)(lchs$prime)();
                                  var pre = Effect_Ref.read(lchs)();
                                  Effect_Ref.write({
                                      initializers: Data_List_Types.Nil.value,
                                      finalizers: pre.finalizers
                                  })(lchs)();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(Halogen_Aff_Driver_State.unDriverStateX((function () {
                                      var $32 = render(lchs);
                                      return function ($33) {
                                          return $32((function (v) {
                                              return v.selfRef;
                                          })($33));
                                      };
                                  })()))(Effect_Ref.read($$var))();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(squashChildInitializers(lchs)(pre.initializers))(Effect_Ref.read($$var))();
                                  return $$var;
                              };
                          });
                      };
                  };
              };
              var renderChild = function (lchs) {
                  return function (handler) {
                      return function (childrenInRef) {
                          return function (childrenOutRef) {
                              return Halogen_Component.unComponentSlot(function (slot) {
                                  return function __do() {
                                      var childrenIn = Data_Functor.map(Effect.functorEffect)(slot.pop)(Effect_Ref.read(childrenInRef))();
                                      var $$var = (function () {
                                          if (childrenIn instanceof Data_Maybe.Just) {
                                              Effect_Ref.write(childrenIn.value0.value1)(childrenInRef)();
                                              var dsx = Effect_Ref.read(childrenIn.value0.value0)();
                                              Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                                                  return function __do() {
                                                      Data_Function.flip(Effect_Ref.write)(st.handlerRef)((function () {
                                                          var $34 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                          return function ($35) {
                                                              return $34(slot.output($35));
                                                          };
                                                      })())();
                                                      return Halogen_Aff_Driver_Eval.handleAff(Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Receive(slot.input, Data_Unit.unit))))();
                                                  };
                                              })(dsx)();
                                              return childrenIn.value0.value0;
                                          };
                                          if (childrenIn instanceof Data_Maybe.Nothing) {
                                              return runComponent(lchs)((function () {
                                                  var $36 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                  return function ($37) {
                                                      return $36(slot.output($37));
                                                  };
                                              })())(slot.input)(slot.component)();
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 210, column 14 - line 219, column 98): " + [ childrenIn.constructor.name ]);
                                      })();
                                      var isDuplicate = Data_Functor.map(Effect.functorEffect)(function ($38) {
                                          return Data_Maybe.isJust(slot.get($38));
                                      })(Effect_Ref.read(childrenOutRef))();
                                      Control_Applicative.when(Effect.applicativeEffect)(isDuplicate)(Effect_Console.warn("Halogen: Duplicate slot address was detected during rendering, unexpected results may occur"))();
                                      Effect_Ref.modify_(slot.set($$var))(childrenOutRef)();
                                      return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read($$var))(Halogen_Aff_Driver_State.renderStateX(Effect.functorEffect)(function (v) {
                                          if (v instanceof Data_Maybe.Nothing) {
                                              return Effect_Exception["throw"]("Halogen internal error: child was not initialized in renderChild");
                                          };
                                          if (v instanceof Data_Maybe.Just) {
                                              return Control_Applicative.pure(Effect.applicativeEffect)(renderSpec.renderChild(v.value0));
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 224, column 37 - line 226, column 50): " + [ v.constructor.name ]);
                                      }))();
                                  };
                              });
                          };
                      };
                  };
              };
              var render = function (lchs) {
                  return function ($$var) {
                      return function __do() {
                          var v = Effect_Ref.read($$var)();
                          var shouldProcessHandlers = Data_Functor.map(Effect.functorEffect)(Data_Maybe.isNothing)(Effect_Ref.read(v.pendingHandlers))();
                          Control_Applicative.when(Effect.applicativeEffect)(shouldProcessHandlers)(Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(v.pendingHandlers))();
                          Effect_Ref.write(Halogen_Data_Slot.empty)(v.childrenOut)();
                          Effect_Ref.write(v.children)(v.childrenIn)();
                          var selfRef = Control_Category.identity(Control_Category.categoryFn)(v.selfRef);
                          var pendingQueries = Control_Category.identity(Control_Category.categoryFn)(v.pendingQueries);
                          var pendingHandlers = Control_Category.identity(Control_Category.categoryFn)(v.pendingHandlers);
                          var handler = (function () {
                              var $39 = Halogen_Aff_Driver_Eval.queueOrRun(pendingHandlers);
                              var $40 = Data_Functor["void"](Effect_Aff.functorAff);
                              var $41 = Halogen_Aff_Driver_Eval.evalF(render)(selfRef);
                              return function ($42) {
                                  return $39($40($41($42)));
                              };
                          })();
                          var childHandler = (function () {
                              var $43 = Halogen_Aff_Driver_Eval.queueOrRun(pendingQueries);
                              return function ($44) {
                                  return $43(handler(Halogen_Query_Input.Action.create($44)));
                              };
                          })();
                          var rendering = renderSpec.render(function ($45) {
                              return Halogen_Aff_Driver_Eval.handleAff(handler($45));
                          })(renderChild(lchs)(childHandler)(v.childrenIn)(v.childrenOut))(v.component.render(v.state))(v.rendering)();
                          var children = Effect_Ref.read(v.childrenOut)();
                          var childrenIn = Effect_Ref.read(v.childrenIn)();
                          Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(childrenIn)(function (v1) {
                              return function __do() {
                                  var childDS = Effect_Ref.read(v1)();
                                  Halogen_Aff_Driver_State.renderStateX_(Effect.applicativeEffect)(renderSpec.removeChild)(childDS)();
                                  return finalize(lchs)(childDS)();
                              };
                          })();
                          Data_Function.flip(Effect_Ref.modify_)(v.selfRef)(Halogen_Aff_Driver_State.mapDriverState(function (ds$prime) {
                              return {
                                  component: ds$prime.component,
                                  state: ds$prime.state,
                                  refs: ds$prime.refs,
                                  children: children,
                                  childrenIn: ds$prime.childrenIn,
                                  childrenOut: ds$prime.childrenOut,
                                  selfRef: ds$prime.selfRef,
                                  handlerRef: ds$prime.handlerRef,
                                  pendingQueries: ds$prime.pendingQueries,
                                  pendingOuts: ds$prime.pendingOuts,
                                  pendingHandlers: ds$prime.pendingHandlers,
                                  rendering: new Data_Maybe.Just(rendering),
                                  fresh: ds$prime.fresh,
                                  subscriptions: ds$prime.subscriptions,
                                  forks: ds$prime.forks,
                                  lifecycleHandlers: ds$prime.lifecycleHandlers
                              };
                          }))();
                          return Control_Applicative.when(Effect.applicativeEffect)(shouldProcessHandlers)(Data_Function.flip(Control_Monad_Rec_Class.tailRecM(Control_Monad_Rec_Class.monadRecEffect))(Data_Unit.unit)(function (v1) {
                              return function __do() {
                                  var handlers = Effect_Ref.read(pendingHandlers)();
                                  Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(pendingHandlers)();
                                  Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)((function () {
                                      var $46 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
                                      return function ($47) {
                                          return Halogen_Aff_Driver_Eval.handleAff($46(Data_List.reverse($47)));
                                      };
                                  })())(handlers)();
                                  var mmore = Effect_Ref.read(pendingHandlers)();
                                  var $21 = Data_Maybe.maybe(false)(Data_List["null"])(mmore);
                                  if ($21) {
                                      return Data_Functor.voidLeft(Effect.functorEffect)(Effect_Ref.write(Data_Maybe.Nothing.value)(pendingHandlers))(new Control_Monad_Rec_Class.Done(Data_Unit.unit))();
                                  };
                                  return new Control_Monad_Rec_Class.Loop(Data_Unit.unit);
                              };
                          }))();
                      };
                  };
              };
              var finalize = function (lchs) {
                  return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                      return function __do() {
                          cleanupSubscriptionsAndForks(st)();
                          var f = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Finalize(Data_Unit.unit)));
                          Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: handlers.initializers,
                                  finalizers: new Data_List_Types.Cons(f, handlers.finalizers)
                              };
                          })(lchs)();
                          return Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(st.children)(function (v) {
                              return function __do() {
                                  var dsx = Effect_Ref.read(v)();
                                  return finalize(lchs)(dsx)();
                              };
                          })();
                      };
                  });
              };
              var evalDriver = function (disposed) {
                  return function (ref) {
                      return function (q) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(disposed)))(function (v) {
                              if (v) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                              };
                              return Halogen_Aff_Driver_Eval.evalQ(render)(ref)(q);
                          });
                      };
                  };
              };
              var dispose = function (disposed) {
                  return function (lchs) {
                      return function (dsx) {
                          return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                              var v = Effect_Ref.read(disposed)();
                              if (v) {
                                  return Data_Unit.unit;
                              };
                              Effect_Ref.write(true)(disposed)();
                              finalize(lchs)(dsx)();
                              return Halogen_Aff_Driver_State.unDriverStateX(function (v1) {
                                  return function __do() {
                                      var v2 = Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(Effect_Ref.read(v1.selfRef))();
                                      return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v2.rendering)(renderSpec.dispose)();
                                  };
                              })(dsx)();
                          });
                      };
                  };
              };
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(newLifecycleHandlers))(function (lchs) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (disposed) {
                      return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                          var sio = Halogen_Subscription.create();
                          var dsx = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref.read)(runComponent(lchs)((function () {
                              var $48 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
                              var $49 = Halogen_Subscription.notify(sio.listener);
                              return function ($50) {
                                  return $48($49($50));
                              };
                          })())(i)(component))();
                          return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                              return Control_Applicative.pure(Effect.applicativeEffect)({
                                  query: evalDriver(disposed)(st.selfRef),
                                  messages: sio.emitter,
                                  dispose: dispose(disposed)(lchs)(dsx)
                              });
                          })(dsx)();
                      });
                  });
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.Aff.Util"] = $PS["Halogen.Aff.Util"] || {};
  var exports = $PS["Halogen.Aff.Util"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Web_DOM_ParentNode = $PS["Web.DOM.ParentNode"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Event_EventTypes = $PS["Web.HTML.Event.EventTypes"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLDocument_ReadyState = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];                
  var selectElement = function (query) {
      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Bind.bindFlipped(Effect.bindEffect)(Control_Bind.composeKleisliFlipped(Effect.bindEffect)((function () {
          var $2 = Web_DOM_ParentNode.querySelector(query);
          return function ($3) {
              return $2(Web_HTML_HTMLDocument.toParentNode($3));
          };
      })())(Web_HTML_Window.document))(Web_HTML.window)))(function (mel) {
          return Control_Applicative.pure(Effect_Aff.applicativeAff)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(mel));
      });
  };
  var runHalogenAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var awaitLoad = Effect_Aff.makeAff(function (callback) {
      return function __do() {
          var rs = Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_HTMLDocument.readyState)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))();
          if (rs instanceof Web_HTML_HTMLDocument_ReadyState.Loading) {
              var et = Data_Functor.map(Effect.functorEffect)(Web_HTML_Window.toEventTarget)(Web_HTML.window)();
              var listener = Web_Event_EventTarget.eventListener(function (v) {
                  return callback(new Data_Either.Right(Data_Unit.unit));
              })();
              Web_Event_EventTarget.addEventListener(Web_HTML_Event_EventTypes.domcontentloaded)(listener)(false)(et)();
              return Effect_Aff.effectCanceler(Web_Event_EventTarget.removeEventListener(Web_HTML_Event_EventTypes.domcontentloaded)(listener)(false)(et));
          };
          callback(new Data_Either.Right(Data_Unit.unit))();
          return Effect_Aff.nonCanceler;
      };
  });
  var awaitBody = Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(awaitLoad)(function () {
      return Control_Bind.bind(Effect_Aff.bindAff)(selectElement("body"))(function (body) {
          return Data_Maybe.maybe(Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Could not find body")))(Control_Applicative.pure(Effect_Aff.applicativeAff))(body);
      });
  });
  exports["awaitBody"] = awaitBody;
  exports["runHalogenAff"] = runHalogenAff;
})(PS);
(function(exports) {
  "use strict";

  var getEffProp = function (name) {
    return function (node) {
      return function () {
        return node[name];
      };
    };
  };                                                   

  exports._parentNode = getEffProp("parentNode");          

  exports._nextSibling = getEffProp("nextSibling");

  exports.insertBefore = function (node1) {
    return function (node2) {
      return function (parent) {
        return function () {
          parent.insertBefore(node1, node2);
        };
      };
    };
  };

  exports.appendChild = function (node) {
    return function (parent) {
      return function () {
        parent.appendChild(node);
      };
    };
  };

  exports.removeChild = function (node) {
    return function (parent) {
      return function () {
        parent.removeChild(node);
      };
    };
  };
})(PS["Web.DOM.Node"] = PS["Web.DOM.Node"] || {});
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Web.DOM.Node"] = $PS["Web.DOM.Node"] || {};
  var exports = $PS["Web.DOM.Node"];
  var $foreign = $PS["Web.DOM.Node"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var parentNode = (function () {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($4) {
          return $3($foreign["_parentNode"]($4));
      };
  })();
  var nextSibling = (function () {
      var $14 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($15) {
          return $14($foreign["_nextSibling"]($15));
      };
  })();
  exports["parentNode"] = parentNode;
  exports["nextSibling"] = nextSibling;
  exports["insertBefore"] = $foreign.insertBefore;
  exports["appendChild"] = $foreign.appendChild;
  exports["removeChild"] = $foreign.removeChild;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Halogen.VDom.Driver"] = $PS["Halogen.VDom.Driver"] || {};
  var exports = $PS["Halogen.VDom.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver = $PS["Halogen.Aff.Driver"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Thunk = $PS["Halogen.VDom.Thunk"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];
  var Web_DOM_Node = $PS["Web.DOM.Node"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var substInParent = function (v) {
      return function (v1) {
          return function (v2) {
              if (v1 instanceof Data_Maybe.Just && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.insertBefore(v)(v1.value0)(v2.value0));
              };
              if (v1 instanceof Data_Maybe.Nothing && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(v)(v2.value0));
              };
              return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
          };
      };
  };
  var removeChild = function (v) {
      return function __do() {
          var npn = Web_DOM_Node.parentNode(v.node)();
          return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function (pn) {
              return Web_DOM_Node.removeChild(v.node)(pn);
          })(npn)();
      };
  };
  var mkSpec = function (handler) {
      return function (renderChildRef) {
          return function (document) {
              var getNode = Halogen_Aff_Driver_State.unRenderStateX(function (v) {
                  return v.node;
              });
              var done = function (st) {
                  if (st instanceof Data_Maybe.Just) {
                      return Halogen_VDom_Machine.halt(st.value0);
                  };
                  return Data_Unit.unit;
              };
              var buildWidget = function (spec) {
                  var buildThunk = Halogen_VDom_Thunk.buildThunk(Data_Newtype.unwrap())(spec);
                  var renderComponentSlot = function (cs) {
                      var renderChild = Effect_Ref.read(renderChildRef)();
                      var rsx = renderChild(cs)();
                      var node = getNode(rsx);
                      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, Data_Maybe.Nothing.value, patch, done));
                  };
                  var render = function (slot) {
                      if (slot instanceof Halogen_Component.ComponentSlot) {
                          return renderComponentSlot(slot.value0);
                      };
                      if (slot instanceof Halogen_Component.ThunkSlot) {
                          var step = buildThunk(slot.value0);
                          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(step), new Data_Maybe.Just(step), patch, done));
                      };
                      throw new Error("Failed pattern match at Halogen.VDom.Driver (line 85, column 7 - line 90, column 75): " + [ slot.constructor.name ]);
                  };
                  var patch = function (st, slot) {
                      if (st instanceof Data_Maybe.Just) {
                          if (slot instanceof Halogen_Component.ComponentSlot) {
                              Halogen_VDom_Machine.halt(st.value0);
                              return renderComponentSlot(slot.value0);
                          };
                          if (slot instanceof Halogen_Component.ThunkSlot) {
                              var step$prime = Halogen_VDom_Machine.step(st.value0, slot.value0);
                              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(step$prime), new Data_Maybe.Just(step$prime), patch, done));
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 98, column 22 - line 104, column 79): " + [ slot.constructor.name ]);
                      };
                      return render(slot);
                  };
                  return render;
              };
              var buildAttributes = Halogen_VDom_DOM_Prop.buildProp(handler);
              return {
                  buildWidget: buildWidget,
                  buildAttributes: buildAttributes,
                  document: document
              };
          };
      };
  };
  var renderSpec = function (document) {
      return function (container) {
          var render = function (handler) {
              return function (child) {
                  return function (v) {
                      return function (v1) {
                          if (v1 instanceof Data_Maybe.Nothing) {
                              return function __do() {
                                  var renderChildRef = Effect_Ref["new"](child)();
                                  var spec = mkSpec(handler)(renderChildRef)(document);
                                  var machine = Halogen_VDom_DOM.buildVDom(spec)(v);
                                  var node = Halogen_VDom_Machine.extract(machine);
                                  Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(node)(Web_HTML_HTMLElement.toNode(container)))();
                                  return {
                                      machine: machine,
                                      node: node,
                                      renderChildRef: renderChildRef
                                  };
                              };
                          };
                          if (v1 instanceof Data_Maybe.Just) {
                              return function __do() {
                                  Effect_Ref.write(child)(v1.value0.renderChildRef)();
                                  var parent = Web_DOM_Node.parentNode(v1.value0.node)();
                                  var nextSib = Web_DOM_Node.nextSibling(v1.value0.node)();
                                  var machine$prime = Halogen_VDom_Machine.step(v1.value0.machine, v);
                                  var newNode = Halogen_VDom_Machine.extract(machine$prime);
                                  Control_Applicative.when(Effect.applicativeEffect)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Unsafe_Reference.unsafeRefEq)(v1.value0.node)(newNode))(substInParent(newNode)(nextSib)(parent))();
                                  return {
                                      machine: machine$prime,
                                      node: newNode,
                                      renderChildRef: v1.value0.renderChildRef
                                  };
                              };
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 159, column 5 - line 175, column 80): " + [ v1.constructor.name ]);
                      };
                  };
              };
          };
          return {
              render: render,
              renderChild: Control_Category.identity(Control_Category.categoryFn),
              removeChild: removeChild,
              dispose: removeChild
          };
      };
  };
  var runUI = function (component) {
      return function (i) {
          return function (element) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toDocument)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))))(function (document) {
                  return Halogen_Aff_Driver.runUI(renderSpec(document)(element))(component)(i);
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.4
  "use strict";
  $PS["Main"] = $PS["Main"] || {};
  var exports = $PS["Main"];
  var App_Environment = $PS["App.Environment"];
  var App_Monad = $PS["App.Monad"];
  var Component_App = $PS["Component.App"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Halogen_Aff_Util = $PS["Halogen.Aff.Util"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_VDom_Driver = $PS["Halogen.VDom.Driver"];                
  var main = Halogen_Aff_Util.runHalogenAff(Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitBody)(function (body) {
      var environment = App_Environment.mkEnvironment(Data_Unit.unit);
      var component = Component_App.mkComponent(App_Monad.appMLogger)(App_Monad.appMMonadAff)(App_Monad.appMMonadPlus)(Data_Unit.unit);
      var component$prime = Halogen_Component.hoist(Effect_Aff.functorAff)(Data_Function.flip(App_Monad.runAppM)(environment))(component);
      return Halogen_VDom_Driver.runUI(component$prime)(Data_Unit.unit)(body);
  }));
  exports["main"] = main;
})(PS);
PS["Main"].main();